import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { postRequestForm } from "../../../helper/api";
import Loader from "../../../components/Loader";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import { getItemFromLocalStorage } from "../../../helper/helper";

const AddProduct = () => {
  const [name, setName] = useState("");
  const [images, setImages] = React.useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);

  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setFeaturedImage(imageList[addUpdateIndex].file);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const addProductHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("status", typeof status === "object"?status.value:status);

      if (featuredImage) {
        formData.append("featuredImage", featuredImage);
      }
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        "/api/secure/product/create-product",
        token,
        formData
      );

      if (response?.result?.status === 200 || response?.result?.status === 201) {
        toast.success("Product Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        window.location.assign("/Products");
      }
    } catch (error) {
      console.log("Create Product APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const getLabelByValue = (statusOptions, value) => {
    const option = statusOptions.find((option) => option.value === value);
    return option?.label;
  };
  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={addProductHandler}
            >
              <Row>
                <Col sm={12} className="text-center">
                  <Form.Group className="mb-3">
                    <Form.Label>Add Product Icon</Form.Label>
                    <ImageUploading
                      value={images}
                      onChange={onChangeImage}
                      dataURLKey="data_url"
                      maxNumber={1}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        isDragging,
                        dragProps,
                      }) => (
                        <div className="upload__image-wrapper mx-auto mb-5">
                          <button
                            type="button"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            {/* <i className="fal fa-plus"></i> */}
                          </button>
                          {imageList
                            ? imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img src={image["data_url"]} alt="" />
                                  <div className="image-item__btn-wrapper">
                                    <button
                                      type="button"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <FontAwesomeIcon icon={faPencilAlt} />
                                    </button>
                                  </div>
                                </div>
                              ))
                            : ""}
                        </div>
                      )}
                    </ImageUploading>
                  </Form.Group>
                </Col>
                <Col xl={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="Name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={6} sm={12}>
                <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <ReactSelect
                      placeholder="Select Status"
                      onChange={statusChange}
                      options={statusOptions}
                      value={status ? status : ""}
                    />
                  </Form.Group>
                </Col>

                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default AddProduct;

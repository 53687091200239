import { Formik } from "formik";
import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import * as Yup from "yup";
import { getRequest, postRequestForm } from "../../helper/api";
import { useState } from "react";
import Loader from "../../components/Loader";
import { useEffect } from "react";
import { getItemFromLocalStorage } from "../../helper/helper";

function BankTransfer({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [previousBankAccount, setPreviousBankAccount] = useState(null);
  const bankDetailSchema = Yup.object().shape({
    bankName: Yup.string().required("*Bank name is Required"),
    accountNumber: Yup.string().required("*Account No. is Required"),
    mobileNumber: Yup.string().required("*Mobile No. is Required"),
    accountHolderName: Yup.string().required("*Account No. is Required"),
    ifscCode: Yup.string().required("*IFSC Code is Required"),
    panCard: Yup.string().required("*Pan Card is Required"),
  });

  const bankDetailHandler = async (values) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const userId = getItemFromLocalStorage("USER");
      const { result, error } = await postRequestForm(
        `/api/secure/bankAccount/register-bank-account`,
        token,
        {
          userId: JSON.parse(userId)._id,
          bankName: values.bankName,
          accountHolderName: values.accountHolderName,
          panCard: values.panCard,
          type: "bankTransfer",
          accountNumber: values.accountNumber,
          mobileNumber: values.mobileNumber,
          ifscCode: values.ifscCode,
        }
      );
      if (result?.status === 201) {
        setIsSuccess(true);
      } else if (error?.response?.status === 400) {
        setIsSuccess(false);
        alert(error?.response?.data?.message);
      }
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
      getBankAccount();
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    }
  };
  const getBankAccount = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const userId = getItemFromLocalStorage("USER");
      const { result, error } = await getRequest(
        `/api/secure/bankAccount/get-single-account-of-user`,
        token,
        {
          userId: JSON.parse(userId)._id,
          bankType: "bankTransfer",
        }
      );
      if (result?.status === 200) {
        console.log("result?.data?.bankAccount",result)
        setPreviousBankAccount(result?.data?.bankAccount);
        // console.log("result?.data?.bankAccount",result?.data?.bankAccount)
      } else if (error?.response?.status === 400) {
        setPreviousBankAccount(null);
      }
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getBankAccount();
    }
  }, [currentlyActive, actualComponent]);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Formik
          initialValues={{
            bankName: previousBankAccount ? previousBankAccount.bankName : "",
            accountNumber: previousBankAccount
              ? previousBankAccount.accountNumber
              : "",
            mobileNumber: previousBankAccount
              ? previousBankAccount.mobileNumber
              : "",
            accountHolderName: previousBankAccount
              ? previousBankAccount.accountHolderName
              : "",
            ifscCode: previousBankAccount ? previousBankAccount.ifscCode : "",
            panCard: previousBankAccount ? previousBankAccount.panCard : "",
          }}
          validationSchema={bankDetailSchema}
          onSubmit={bankDetailHandler}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row className="justify-content-around mt-4">
                <Col lg={12} className="bank-transfer">
                  <h5 className="text-center">Bank Transfer</h5>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        errors.bankName && touched.bankName
                          ? "form-label-error"
                          : null
                      }
                    >
                      Bank Name
                    </Form.Label>
                    <Form.Control
                      className={
                        errors.bankName && touched.bankName
                          ? "form-control-error"
                          : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="bankName"
                      type="text"
                      placeholder="Bank Name"
                      required
                      defaultValue={values.bankName}
                      disabled={previousBankAccount === null ? false : true}
                    />
                    {errors.bankName && touched.bankName && (
                      <div className="error-message">{errors.bankName}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        errors.accountHolderName && touched.accountHolderName
                          ? "form-label-error"
                          : null
                      }
                    >
                      Account Holder Name
                    </Form.Label>
                    <Form.Control
                      className={
                        errors.accountHolderName && touched.accountHolderName
                          ? "form-control-error"
                          : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="accountHolderName"
                      type="text"
                      placeholder="Account Holder Name"
                      required
                      defaultValue={values.accountHolderName}
                      disabled={previousBankAccount === null ? false : true}
                    />
                    {errors.accountHolderName && touched.accountHolderName && (
                      <div className="error-message">
                        {errors.accountHolderName}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        errors.accountNumber && touched.accountNumber
                          ? "form-label-error"
                          : null
                      }
                    >
                      Account Number
                    </Form.Label>
                    <Form.Control
                      className={
                        errors.accountNumber && touched.accountNumber
                          ? "form-control-error"
                          : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="accountNumber"
                      type="text"
                      defaultValue={values.accountNumber}
                      placeholder="Account Number"
                      required
                      disabled={previousBankAccount === null ? false : true}
                    />
                    {errors.accountNumber && touched.accountNumber && (
                      <div className="error-message">
                        {errors.accountNumber}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        errors.ifscCode && touched.ifscCode
                          ? "form-label-error"
                          : null
                      }
                    >
                      IFSC Code
                    </Form.Label>
                    <Form.Control
                      className={
                        errors.ifscCode && touched.ifscCode
                          ? "form-control-error"
                          : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="ifscCode"
                      type="text"
                      defaultValue={values.ifscCode}
                      placeholder="IFSC Code"
                      required
                      disabled={previousBankAccount === null ? false : true}
                    />
                    {errors.ifscCode && touched.ifscCode && (
                      <div className="error-message">{errors.ifscCode}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        errors.mobileNumber && touched.mobileNumber
                          ? "form-label-error"
                          : null
                      }
                    >
                      Mobile No.
                    </Form.Label>
                    <Form.Control
                      className={
                        errors.mobileNumber && touched.mobileNumber
                          ? "form-control-error"
                          : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="mobileNumber"
                      type="text"
                      placeholder="Mobile No"
                      required
                      defaultValue={values.mobileNumber}
                      disabled={previousBankAccount === null ? false : true}
                    />
                    {errors.mobileNumber && touched.mobileNumber && (
                      <div className="error-message">{errors.mobileNumber}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        errors.panCard && touched.panCard
                          ? "form-label-error"
                          : null
                      }
                    >
                      PAN Card *
                    </Form.Label>
                    <Form.Control
                      className={
                        errors.panCard && touched.panCard
                          ? "form-control-error"
                          : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="panCard"
                      type="text"
                      placeholder="PAN Card"
                      required
                      defaultValue={values.panCard}
                      disabled={previousBankAccount === null ? false : true}
                    />
                    {errors.panCard && touched.panCard && (
                      <div className="error-message">{errors.panCard}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  {isSuccess && <Alert variant={"success"}>Data Saved!</Alert>}
                </Col>
              </Row>
              <div className="para">
                <p>
                  Please exercise caution when filling in the required
                  bank/Paytm details, as it cannot be changed once submitted. It
                  is important to double-check and ensure the accuracy of the
                  information before providing it. We assure you that these
                  details will be kept confidential and will not be disclosed to
                  any third parties. Your privacy and security are of utmost
                  importance to us.
                </p>
              </div>
              <div className="button">
                <button type="submit">SUBMIT</button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}

export default BankTransfer;

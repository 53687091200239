import React, { useState } from "react";
import Layout from "../../layouts/auth/Layout";
import { Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import PublicImages from "../../constants/public/images";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { postRequestForm } from "../../helper/api";
import Loader from "./../../components/Loader";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const forgetPasswordHandler = async (values) => {
    try {
      setLoading(true);
      const res = await postRequestForm("/api/auth/forget-password", "", {
        email: values.email?.toLowerCase(),
      });

      if (res?.result?.status === 200) {
        const response = res.result.data;
        const user = response?.user;

        navigate(`/otp`, {
          state: { _id: user._id, pathToGo: "set-new-password" },
        });
      }
    } catch (error) {
      console.log("Create Profile API error", error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Layout>
      <section className="forget-password">
        <Container>
          <Row className=" bod">
            <Col lg={6} md={12} xs={12} className="d-none d-lg-block">
              <Image fluid src={PublicImages.ForgetPasswordImage} alt="" />
            </Col>
            <Col
              lg={6}
              md={12}
              xs={12}
              className="align-self-center text-center d-flex flex-column align-items-center"
            >
              {loading ? (
                <Loader loading={loading} />
              ) : (
                <Formik
                  initialValues={{
                    email,
                  }}
                  validationSchema={forgetPasswordSchema}
                  onSubmit={forgetPasswordHandler}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <h1 className="forget">Forgot Password?</h1>
                      <p>Please Enter Your Email Address Below</p>
                      <InputGroup className="mb-3 w-100" size="lg">
                        <Form.Control
                          type="text"
                          placeholder="Email Address    *"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          defaultValue={values.email}
                        />
                      </InputGroup>
                      <div id="status">
                        <div
                          className={`alert ${
                            (errors.email && touched.email) ||
                            (errors.password && touched.password)
                              ? ""
                              : "d-none"
                          } ${errors ? "alert-danger" : "alert-success"}`}
                          role="alert"
                        >
                          {errors.email && touched.email
                            ? errors.email
                            : errors.password && touched.password
                            ? errors.password
                            : ""}
                        </div>
                      </div>
                      <InputGroup className="reset  w-100">
                        <button className="btn-default w-100">
                          RESET PASSWORD
                        </button>
                      </InputGroup>
                    </form>
                  )}
                </Formik>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default ForgetPassword;

import React, { useEffect } from "react";
import Layout from "../../../layouts/admin/Layout";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { getRequest, putRequest } from "../../../helper/api";
import { useState } from "react";

import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";

const EditLanguage = () => {
  let languageId = "";
  const location = useLocation();
  const navigate = useNavigate();
  if (location?.state?.languageId === "") {
    navigate("/Languages");
  } else {
    languageId = location?.state?.languageId;
  }
  const [isLoading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [key, setKey] = useState("");

  const updateUserHandler = async (e) => {
    e.preventDefault();
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const response = await putRequest(
        `/api/secure/language/update-language/${languageId}`,
        token,
        {
          name,
          key,
          data: [],
        }
      );

      if (response?.result?.status === 200 || response?.result?.status === 201) {
        toast.success("Language Updated", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Update Language APi error", error.message);
    }
  };
  const getLanguageById = async (languageId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");

      const response = await getRequest(
        `/api/public/language/get-language/${languageId}`,
        token
      );

      if (response?.result?.status === 200 || response?.result?.status === 201) {
        const languageData = response?.result?.data?.language;
        setName(languageData?.name);
        setKey(languageData?.key);
        setLoading(false);
      }
    } catch (error) {
      console.log("Get Language APi error", error.message);
    }
  };
  useEffect(() => {
    getLanguageById(languageId);
  }, [languageId, location]);

  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updateUserHandler}
            >
              <Row className="bg-white py-3 m-0">
                <>
                  <Col xl={6} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        placeholder="Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col xl={6} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Key</Form.Label>
                      <Form.Control
                        name="key"
                        type="text"
                        placeholder="Key"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </>

                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Update</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Loader loading={isLoading} />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default EditLanguage;

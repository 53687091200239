import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getRequest, putRequest } from "../../../helper/api";
import ReactSelect from "react-select";
import Loader from "./../../../components/Loader";
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "../../../helper/helper";

function ReferralSettings({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(true);
  const [siteId, setSiteId] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [status, setStatus] = useState();
  const [referredPersonPost, setReferredPersonPost] = useState();
  // Define the validation schema
  const validationSchema = Yup.object().shape({
    perCoinValue: Yup.string().required("Per Coin Value is required"),
    totalCoins: Yup.string().required("Total Coins is required."),
    referringReceivedCoins: Yup.string().required("Referrer Coins is required"),
    referredByReceivedCoins: Yup.string().required(
      "Referred By Coins is required"
    ),
    withdrawalLimitMinimum: Yup.string().required(
      "Withdrawal Limit Minimum is required"
    ),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        "/api/secure/site/update-referral-settings",
        token,
        {
          siteId,
          referralSettings: {
            perCoinValue: values.perCoinValue,
            totalCoins: values.totalCoins,
            referringReceivedCoins: values.referringReceivedCoins,
            referredByReceivedCoins: values.referredByReceivedCoins,
            referredPersonPost:
              typeof referredPersonPost === "object"
                ? referredPersonPost.value
                : referredPersonPost,
            withdrawalLimitMinimum: values.withdrawalLimitMinimum,
            status: typeof status === "object" ? status.value : status,
          },
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        const { referralSettings } = result?.data;
        setInitialValues(referralSettings);

        toast.success("Referral Settings Updated", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setItemInLocalStorage(
          "REFERRAL_SETTINGS",
          JSON.stringify(referralSettings)
        );
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Update Referral Settings API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getLabelByValue = (options, value) => {
    const option = options?.find((option) => option.value === value);
    return option?.label;
  };
  const getSiteSettings = async () => {
    try {
      setLoading(true);
      const response = await getRequest("/api/secure/site/site-settings");
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        console.log(response?.result?.data?.siteSettings[0].siteId);
        setInitialValues(
          response?.result?.data?.siteSettings[0].referralSettings
        );

        setStatus(
          response?.result?.data?.siteSettings[0].referralSettings.status
        );
        setReferredPersonPost(
          response?.result?.data?.siteSettings[0].referralSettings
            .referredPersonPost
        );
        setSiteId(response?.result?.data?.siteSettings[0].siteId);
      }
    } catch (error) {
      console.error("Update Site Settings API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Initial values object
  // const  initialValues = {
  //   perCoinValue: "Womanii",
  //   totalCoins: "https://www.facebook.com/profile.php?id=100092899820943",
  //   referringReceivedCoins: "https://www.instagram.com/womanii2023?r=nametag",
  //   referredByReceivedCoins: "https://www.youtube.com/channel/UCtOd4kS54HZ7RzHDFkX0ZWA",
  //   referredPersonPost: "https://twitter.com/Womanii_",
  //   withdrawalLimitMinimum: "https://appstoreconnect.apple.com/",
  //   status: "https://play.google.com/",
  // };
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const referredPersonPostOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];
  const referredPersonPostChange = (selectedOption) => {
    setReferredPersonPost(selectedOption);
  };
  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getSiteSettings();
    }
  }, [currentlyActive, actualComponent]);

  return (
    <section className="py-5">
      <Container>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="justify-content-center">
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Per Coin Value</Form.Label>
                      <Field
                        type="number"
                        name="perCoinValue"
                        placeholder="Womanii"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="perCoinValue"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12} className="d-none">
                    <Form.Group className="mb-3">
                      <Form.Label>Total Coins</Form.Label>
                      <Field
                        type="number"
                        name="totalCoins"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="totalCoins"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Referrer Coins</Form.Label>
                      <Field
                        type="number"
                        name="referringReceivedCoins"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="referringReceivedCoins"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Referred By Coins</Form.Label>
                      <Field
                        type="number"
                        name="referredByReceivedCoins"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="referredByReceivedCoins"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Referrer Post</Form.Label>
                      <ReactSelect
                        placeholder="Select Referrer Post"
                        onChange={referredPersonPostChange}
                        options={referredPersonPostOptions}
                        value={
                          typeof referredPersonPost === "object"
                            ? referredPersonPost
                            : referredPersonPost
                            ? {
                                value: referredPersonPost,
                                label: getLabelByValue(
                                  referredPersonPostOptions,
                                  referredPersonPost
                                ),
                              }
                            : ""
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Withdrawal Limit Minimum</Form.Label>
                      <Field
                        type="number"
                        name="withdrawalLimitMinimum"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="withdrawalLimitMinimum"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <ReactSelect
                        placeholder="Select Status"
                        onChange={statusChange}
                        options={statusOptions}
                        value={
                          typeof status === "object"
                            ? status
                            : status
                            ? {
                                value: status,
                                label: getLabelByValue(statusOptions, status),
                              }
                            : ""
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} className="text-center my-5">
                    <Button type="submit">Submit</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </section>
  );
}

export default ReferralSettings;

import React, { useEffect, useState } from "react";
import { Container, Col, Form, Row, Alert } from "react-bootstrap";
import PublicImages from "../../constants/public/images";
import { Formik } from "formik";
import * as Yup from "yup";
import { getRequest, postRequestForm } from "../../helper/api";
import Loader from "../../components/Loader";
import { getItemFromLocalStorage } from "../../helper/helper";

function PtmAndUpi({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [previousBankAccount, setPreviousBankAccount] = useState(null);
  const [provider, setProvider] = useState("paytm");
  const upiSchema = Yup.object().shape({
    panCard: Yup.string().required("Pan Card is Required"),
    accountNumber: Yup.string().required("Account Number is Required"),
  });
  const upiHandler = async (values) => {
    setLoading(true);
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const userId = getItemFromLocalStorage("USER");
      const { result, error } = await postRequestForm(
        `/api/secure/bankAccount/register-bank-account`,
        token,
        {
          userId: JSON.parse(userId)._id,
          type: provider,
          panCard: values.panCard,
          accountNumber: values.accountNumber,
        }
      );
      if (result?.status === 201) {
        setIsSuccess(true);
      } else if (Number(error?.response?.status) === 400) {
        setIsSuccess(false);
        alert(error?.response?.data?.message);
      }
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
      getBankAccount();
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    }
  };
  const getBankAccount = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const userId = getItemFromLocalStorage("USER");
      const { result, error } = await getRequest(
        `/api/secure/bankAccount/get-single-account-of-user`,
        token,
        {
          userId: JSON.parse(userId)._id,
          bankType: provider,
        }
      );
      if (result?.status === 200) {
        setPreviousBankAccount(result?.data?.bankAccount);
      } else if (error?.response?.status === 400) {
        setPreviousBankAccount(null);
      }
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getBankAccount();
    }
  }, [currentlyActive, actualComponent, provider]);

  return (
    <div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Formik
          initialValues={{
            panCard: previousBankAccount ? previousBankAccount.panCard : "",
            accountNumber: previousBankAccount
              ? previousBankAccount.accountNumber
              : "",
          }}
          validationSchema={upiSchema}
          onSubmit={upiHandler}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row className="justify-content-center">
                <Col lg={3} className="align-self-center">
                  <label
                    htmlFor="image1"
                    className="d-flex align-items-center justify-content-center g-5"
                  >
                    <Form.Check
                      onChange={(e) => {
                        setProvider(e.target.value);
                      }}
                      onBlur={handleBlur}
                      className="custom-radio"
                      type="radio"
                      name="provider"
                      value="paytm"
                      id="image1"
                      checked={provider === "paytm"}
                    />
                    <img
                      src={PublicImages.paytm}
                      className="img-fluid ms-2 w-75"
                      alt=""
                    />
                  </label>
                </Col>
                <Col lg={3} className="align-self-center">
                  <label
                    htmlFor="image2"
                    className="d-flex align-items-center justify-content-center g-5"
                  >
                    <Form.Check
                      onChange={(e) => {
                        setProvider(e.target.value);
                      }}
                      onBlur={handleBlur}
                      className="custom-radio"
                      type="radio"
                      name="provider"
                      value="upi"
                      id="image2"
                      checked={provider === "upi"}
                    />
                    <img
                      src={PublicImages.lipi}
                      className="img-fluid ms-2 w-75"
                      alt=""
                    />
                  </label>
                </Col>
              </Row>
              <Row className="py-3">
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        errors.accountNumber && touched.accountNumber
                          ? "form-label-error"
                          : null
                      }
                    >
                      {provider === "paytm" ? "Paytm Number" : "UPI ID"}
                    </Form.Label>
                    <Form.Control
                      className={
                        errors.accountNumber && touched.accountNumber
                          ? "form-control-error"
                          : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="accountNumber"
                      type="text"
                      placeholder={provider === "paytm" ? "Paytm Number" : "UPI ID"}
                      required
                      defaultValue={values.accountNumber}
                      disabled={previousBankAccount === null ? false : true}
                    />
                    {errors.accountNumber && touched.accountNumber && (
                      <div className="error-message">
                        {errors.accountNumber}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={
                        errors.panCard && touched.panCard
                          ? "form-label-error"
                          : null
                      }
                    >
                      PAN Card *
                    </Form.Label>
                    <Form.Control
                      className={
                        errors.panCard && touched.panCard
                          ? "form-control-error"
                          : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="panCard"
                      type="text"
                      placeholder="PAN Card"
                      required
                      defaultValue={values.panCard}
                      disabled={previousBankAccount === null ? false : true}
                    />
                    {errors.panCard && touched.panCard && (
                      <div className="error-message">{errors.panCard}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  {isSuccess && <Alert variant={"success"}>Data Saved!</Alert>}
                </Col>
              </Row>
              <div className="para">
                <p>
                  Please exercise caution when filling in the required
                  bank/Paytm details, as it cannot be changed once submitted. It
                  is important to double-check and ensure the accuracy of the
                  information before providing it. We assure you that these
                  details will be kept confidential and will not be disclosed to
                  any third parties. Your privacy and security are of utmost
                  importance to us.
                </p>
              </div>
              <div className="button">
                <button type="submit">SUBMIT</button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default PtmAndUpi;

import React from "react";
import { ThreeDots } from "react-loader-spinner";

export default function Loader({ loading }) {
  return (
    loading && (
      <div className="loadingContainer d-flex text-center justify-content-center">
        <ThreeDots
          height={80}
          width={80}
          radius={9}
          color="#e5097d"
          ariaLabel="three-dots-loading"
          visible={loading}
        />
      </div>
    )
  );
}

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { socket } from "../../helper/socket";

const ExportProgressComponent = () => {
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    console.log("socket", socket?.id);
    if (socket) {
      const socketId = socket?.id;
      socket.emit("join", { socketId });
      console.log(`Emitted join event for socket ID: ${socketId}`);

      socket.on("exportProgress", (data) => {
        console.log("exportProgress", data);
        setProgress(data.progress);
        setMessage(data.message || "");
        updateAlert(data.progress, data.message);
      });

      socket.on("exportComplete", (data) => {
        console.log("exportComplete", data);
        setProgress(100);
        setMessage(data.message || "");
        setFileUrl(data.fileUrl);
        updateAlert(100, data.message, data.fileUrl);
      });

      socket.on("exportError", (data) => {
        console.log("exportError", data);
        setMessage(data.message || "An error occurred");
        updateAlert(null, data.message);
      });
    }

    return () => {
      if (socket) {
        socket.off("exportProgress");
        socket.off("exportComplete");
        socket.off("exportError");
      }
    };
  }, []);

  const updateAlert = (progress, message, fileUrl) => {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: progress === 100 ? "Export Completed" : "Export in Progress",
        html: `<p>${
          message ? `${message} (${progress}%)` : `Progress: ${progress}%`
        }</p>`,
        icon: progress === 100 ? "success" : "info",
        showConfirmButton: progress === 100,
        confirmButtonText: "Download File",
        willClose: () => {
          if (progress === 100 && fileUrl) {
            window.open(fileUrl, "_blank"); // Trigger file download
          }
        },
      });
    } else {
      Swal.update({
        title: progress === 100 ? "Export Complete" : "Export Progress",
        html: `<p>${
          message ? `${message} (${progress}%)` : `Progress: ${progress}%`
        }</p>`,
        icon: progress === 100 ? "success" : "info",
        showConfirmButton: progress === 100,
        confirmButtonText: "Download File",
        willClose: () => {
          if (progress === 100 && fileUrl) {
            window.open(fileUrl, "_blank"); // Trigger file download
          }
        },
      });
    }
  };

  return null;
};

export default ExportProgressComponent;

import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";
import routes from "../../../helper/routes";
import { postRequestForm } from "./../../../helper/api";
import Layout from "./../../../layouts/admin/Layout";

const AddUsers = () => {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [permissionsOptions, setPermissionsOptions] = useState();
  const [permissions, setPermissions] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    permissions: "",
    password: "",
  });

  const roleOptions = [
    { value: "user", label: "User" },
    { value: "admin", label: "Admin" },
  ];
  const navigate = useNavigate();

  const getRoutes = (routes) => {
    const mappedOptions = routes
      .filter((route) => route?.layout === "ADMIN")
      .map((option) => ({
        value: option?.name,
        label: option?.name,
      }));
    setPermissionsOptions(mappedOptions);
  };
  const userFormHandler = async (e) => {
    e.preventDefault();

    // Initialize errors object
    const newErrors = {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      permissions: "",
      password: "",
    };

    // Check for errors in form fields
    if (!firstName) {
      newErrors.firstName = "First Name is required";
    }
    if (!lastName) {
      newErrors.lastName = "Last Name is required";
    }
    if (!email) {
      newErrors.email = "Email is required";
    }
    if (!role) {
      newErrors.role = "Role is required";
    }
    if (!permissions && role?.value === "admin") {
      newErrors.permissions = "Permissions are required";
    }
    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password length should be greater than 5.";
    }

    // Set the errors object
    setErrors(newErrors);

    console.log("errors", newErrors);

    if (
      !newErrors.firstName &&
      !newErrors.lastName &&
      !newErrors.email &&
      !newErrors.role &&
      !newErrors.permissions &&
      !newErrors.password
    ) {
      try {
        setLoading(true);
        const token = getItemFromLocalStorage("TOKEN");
        const selectedPermissions = permissions.map(
          (permission) => permission?.value
        );
        const { result, error } = await postRequestForm(
          `/api/secure/user`,
          token,
          {
            firstName,
            lastName,
            email,
            password,
            role: role?.value,
            permissions: selectedPermissions,
          }
        );

        if (result?.status === 200 || result?.status === 201) {
          toast.success("User Created", {
            position: "top-center", theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          navigate("/Users");
        } else if (error?.response?.status === 400) {
          console.log(`Error in Create User`);
          toast.error(`${error?.response?.data?.message}`, {
            position: "top-center", theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        console.log(`Error in Create User`, err.message);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleRoleChange = (selectedOption) => {
    setRole(selectedOption);
  };
  const handlePermissionsChange = (selectedOption) => {
    setPermissions(selectedOption);
  };
  useEffect(() => {
    getRoutes(routes);
  }, [routes]);

  return (
    <Layout>
      <Container>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <>
            <Form onSubmit={userFormHandler}>
              <Row className=" justify-content-around mt-4">
                <Col lg={4} md={6} xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={errors?.firstName ? "form-label-error" : null}
                    >
                      First Name
                    </Form.Label>
                    <Form.Control
                      className={
                        errors?.firstName ? "form-control-error" : null
                      }
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      defaultValue={firstName}
                    />
                    {errors?.firstName && (
                      <div className="error-message">{errors?.firstName}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={errors?.lastName ? "form-label-error" : null}
                    >
                      Last Name
                    </Form.Label>
                    <Form.Control
                      className={errors?.lastName ? "form-control-error" : null}
                      defaultValue={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                    />
                    {errors?.lastName && (
                      <div className="error-message">{errors?.lastName}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={errors?.email ? "form-label-error" : null}
                    >
                      Email
                    </Form.Label>
                    <Form.Control
                      className={errors?.email ? "form-control-error" : null}
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                    />
                    {errors?.email && (
                      <div className="error-message">{errors?.email}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col
                  lg={
                    role &&
                    (typeof role === "object"
                      ? role?.value !== "user"
                      : role !== "user")
                      ? 6
                      : 12
                  }
                  md={
                    role &&
                    (typeof role === "object"
                      ? role?.value !== "user"
                      : role !== "user")
                      ? 6
                      : 12
                  }
                  xs={12}
                >
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={errors?.role ? "form-label-error" : null}
                    >
                      Role
                    </Form.Label>
                    <ReactSelect
                      placeholder="Role"
                      onChange={handleRoleChange}
                      options={roleOptions}
                      required
                      isClearable
                      value={role}
                    />
                    {errors?.role && (
                      <div className="error-message">{errors?.role}</div>
                    )}
                  </Form.Group>
                </Col>
                {role &&
                  (typeof role === "object"
                    ? role?.value !== "user"
                    : role !== "user") && (
                    <Col lg={6} md={6} xs={12}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          className={
                            errors?.permissions ? "form-label-error" : null
                          }
                        >
                          Permissions
                        </Form.Label>
                        <ReactSelect
                          placeholder="Permissions"
                          onChange={handlePermissionsChange}
                          options={permissionsOptions}
                          isClearable
                          isMulti
                          value={permissions}
                        />
                        {errors?.permissions && (
                          <div className="error-message">
                            {errors?.permissions}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                <Col lg={12} md={12} xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className={errors?.password ? "form-label-error" : null}
                    >
                      Password
                    </Form.Label>
                    <Form.Control
                      className={errors?.password ? "form-control-error" : null}
                      defaultValue={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                    />
                    {errors?.password && (
                      <div className="error-message">{errors?.password}</div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <div className="button text-center">
                <button className="btn btn-light" type="submit">
                  Create
                </button>
              </div>
            </Form>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default AddUsers;

import {
  faCommentAlt,
  faHeart,
  faBookmark as notBookmarked,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBookmark as Bookmarked,
  faHeart as faHeartFilled,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, Col, Image, Nav } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from "react-router-dom";
import PublicImages from "../../../constants/public/images";

import {
  faFacebookF,
  faLinkedinIn,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { baseURL } from "../../../helper/api";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { getItemFromLocalStorage } from "../../../helper/helper";
import AdminImages from "../../../constants/admin/images";

export default function Story({
  image,
  authorName,
  authorImage,
  comments,
  likes,
  username,
  bookmarkHandler = () => {},
  isBookmarked = false,
  link,
  showBookmarkBtn = true,
  showFollowBtn = true,
  likeHandler = () => {},
  followUserHandler = () => {},
  unfollowUserHandler = () => {},
  isLiked = false,
  title,
  isWinner = false,
  challengeLink,
  isStarmarked = false,
  isFollowed = false,
}) {
  const navigate = useNavigate();
  const [IsOwned, setIsOwned] = useState(false);
  const getCurrentUrl = (link) => {
    const url = baseURL + link;
    return url;
  };

  const copyToClipboard = (link) => {
    const currentUrl = getCurrentUrl(`/${link}`);

    const tempInput = document.createElement("input");
    tempInput.value = currentUrl;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");

    document.body.removeChild(tempInput);

    toast.success("Copied to Clipboard!", {
      position: "top-center",
      theme: "colored",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    const loggedInUser = getItemFromLocalStorage("USER");
    if (loggedInUser) {
      if (username === JSON.parse(loggedInUser)?.username) {
        setIsOwned(true);
      }
    } else {
      setIsOwned(false);
    }
  }, []);

  return (
    <Col lg={4} md={6} xs={12} className="word">
      <Card className="card_style_3">
        {isStarmarked && (
          <Link
            reloadDocument={true}
            to={`/${challengeLink}`}
            className="btn-default-2 addToBookmarkButton"
            style={{
              right: isWinner ? 105 : 57,
            }}
          >
            <Image
              src={AdminImages.StarmarkGold}
              height={30}
              width={30}
              className="img-fluid"
            />
          </Link>
        )}
        {isWinner && (
          <Link
            reloadDocument={true}
            to={`/${challengeLink}`}
            className="btn-default-2 addToBookmarkButton"
            style={{
              right: 57,
            }}
          >
            <Image
              src={PublicImages.Trophy}
              height={30}
              width={30}
              className="img-fluid"
            />
          </Link>
        )}
        {showBookmarkBtn && (
          <Button
            className="btn-default-2 addToBookmarkButton"
            onClick={bookmarkHandler}
          >
            {isBookmarked ? (
              <FontAwesomeIcon icon={Bookmarked} />
            ) : (
              <FontAwesomeIcon icon={notBookmarked} />
            )}
          </Button>
        )}
        <Link reloadDocument={true} to={`/${link}`} className="w-100">
          <LazyLoadImage
            src={image}
            alt={title}
            effect="blur"
            className="card-img img-fluid w-100"
            delayTime={5000}
            wrapperClassName="d-block"
          />
        </Link>
        <Card.Body className="w-100">
          <h5 className="d-flex align-items-center m-0 author_info gap-2">
            {authorImage ? (
              <Link reloadDocument={true} to={`/profile/${username}`}>
                <LazyLoadImage
                  src={authorImage}
                  alt={username}
                  effect="blur"
                  className=" author_img"
                  delayTime={5000}
                  wrapperClassName="d-block"
                />
              </Link>
            ) : (
              <Link reloadDocument={true} to={`/profile/${username}`}>
                <svg
                  width="50px"
                  height="50px"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 224.2 319.9"
                >
                  <path
                    class="st0"
                    fill="#E5097D"
                    d="M48.1,107.5c-4.9-1.5-7.2-6.3-5-10c0.6-1,2.8-1.5,4.2-1.5c14.8-0.1,29.7-0.1,44.5-0.1  c15.7,0,22.3-5.1,26.5-20.1c3-10.7,5.9-21.5,9.4-32.1c0.8-2.4,3.3-5.4,5.6-5.9c3.8-0.9,5.2,2.8,6.1,5.9c3.6,12.5,6.9,25,10.6,37.5  c3.3,11.2,8.2,14.7,20,14.8c1.7,0,3.3,0,5,0c6.3,0.1,8.3,2.5,6.3,8.5c-0.5,1.4-2.8,2.2-5.1,4c0,6.4-0.1,13.8,0,21.3  c0.4,24.1-9.1,43.2-29,57c-1.6,1.1-2.7,3.7-2.8,5.7c-0.3,7.1,0,14.3-0.2,21.5c-0.1,3.7,1,4.9,4.8,4.8c11.8-0.2,23.7-0.1,35.5-0.1  c24,0.1,39.7,15.8,39.8,39.8c0.1,17.5,0,35,0,52.5c0,7.5-1.5,9-9.1,9c-68.6,0-137.3,0-205.9,0c-7.8,0-9.1-1.2-9.1-8.9  c0-17.7-0.1-35.3,0-53c0.2-20.6,13-36.6,31.6-38.8c8.2-1,16.6-0.5,25-0.5c6.5-0.1,13-0.1,19.5,0c2.8,0.1,3.9-1,3.8-3.8  c-0.1-7.8,0.1-15.7-0.2-23.5c-0.1-1.6-1.4-3.8-2.8-4.7c-20.2-14-29.4-33.3-28.9-57.6C48.2,122,48.1,114.8,48.1,107.5L48.1,107.5z   M212.6,308.9c0.3-0.7,0.6-1.2,0.6-1.6c0-18.2,0.4-36.3-0.2-54.5c-0.4-11.8-10.7-22.2-22.7-22.9c-15.6-0.8-31.3-0.6-46.9-0.5  c-1.7,0-3.7,1.4-5,2.6c-6.9,6.7-13.7,13.6-20.5,20.5c-4.6,4.5-6.8,4.5-11.3,0.1c-6.9-6.8-13.6-13.7-20.6-20.4  c-1.5-1.5-3.9-2.8-5.9-2.8c-13.8-0.2-27.7-0.4-41.5,0C21,229.9,11,240.6,10.9,258.1c-0.1,15.2,0,30.3,0,45.5c0,1.8,0.2,3.5,0.3,5.3  L212.6,308.9L212.6,308.9z M134,64.7l-1.3,0.2c-1.9,6.5-3.6,13.1-5.7,19.5c-3.9,12.4-14.6,21.1-27.7,21.7c-11.5,0.6-23,0.5-34.4,0.2  c-4.7-0.1-6.7,1.1-6.3,6.1c0.4,6,0.2,12,0.2,18c-0.2,30.8,20.3,53.3,50.9,55.7c22.7,1.8,46-14.4,52.8-36.2  c4.2-13.4,2.4-27.1,2.9-40.6c0-1-1.4-2.9-2.4-3.1c-14-2.1-20.9-11.1-23.9-24C137.6,76.3,135.7,70.6,134,64.7L134,64.7z M112.1,243.6  c7.1-7.3,13.4-13.7,19.6-20.2c0.7-0.7,1.4-1.7,1.4-2.5c0.1-8.9,0.1-17.8,0.1-27.3c-14.4,4.8-28.1,4.5-42.3,0.1c0,9.3,0,18,0.1,26.6  c0,0.9,0.4,2,1.1,2.7C98.4,229.5,104.9,236.2,112.1,243.6z"
                  />
                  <path
                    class="st0"
                    fill="#E5097D"
                    d="M192,212.6c0-10.7,0-21.2,0-31.7c0-29.5,0-59,0-88.5c0.1-41.8-28.1-75.3-69.5-81C77.4,5.3,38.8,37,33.5,77.9  c-2.4,18.7-1.3,37.9-1.4,56.9c-0.2,24.3-0.1,48.6,0.1,73c0,4.3-1,5.7-5.5,5.8c-4.7,0-5.4-1.9-5.4-6c0.1-38.3,0-76.6,0.1-114.9  c0.1-41,24.7-75.8,62.2-88C134.8-12,188.7,18,200.2,70.6c3,13.6,2.4,28.1,2.5,42.2c0.3,30.6,0.1,61.3,0,92  C202.8,214.2,202.5,214.4,192,212.6L192,212.6z"
                  />
                  <path
                    class="st0"
                    fill="#E5097D"
                    d="M131.8,155.1c-0.5,11.2-9.2,19.6-19.7,19.5c-10.7,0-19-8.1-19.8-19.4c4.1-0.9,7.6-1.9,9.3,4.1  c1.3,4.6,5.6,6.9,10.5,6.9s9.3-2.1,10.5-6.8C124.1,153.7,127.5,154.4,131.8,155.1L131.8,155.1z"
                  />
                  <path
                    class="st0"
                    fill="#E5097D"
                    d="M85.5,118.7c5,0,9.2,4.2,9.1,9.3c-0.1,5-4.3,9.1-9.3,9.1c-5,0-9.2-4.3-9.1-9.3  C76.2,122.8,80.5,118.7,85.5,118.7L85.5,118.7z"
                  />
                  <path
                    class="st0"
                    fill="#E5097D"
                    d="M148,127.7c0.1,4.9-4.1,9.2-9.1,9.3c-5.1,0.1-9.2-3.9-9.3-9c-0.1-5.1,3.9-9.2,9-9.3  C143.7,118.7,147.9,122.7,148,127.7L148,127.7z"
                  />
                </svg>
              </Link>
            )}
            <span className="author_name flex-grow-1">
              <Link reloadDocument={true} to={`/profile/${username}`}>
                {authorName}
              </Link>
            </span>
            {showFollowBtn && (
              <>
                {IsOwned ? (
                  <Button
                    className="btn w-auto h-auto btn-default-2"
                    onClick={() => navigate("/account")}
                    style={{
                      padding: "10px 30px",
                    }}
                  >
                    EDIT
                  </Button>
                ) : isFollowed ? (
                  <Button
                    className="btn w-auto h-auto btn-default-3"
                    onClick={() => unfollowUserHandler()}
                    style={{
                      padding: "10px 30px",
                    }}
                  >
                    Followings
                  </Button>
                ) : (
                  <Button
                    className="btn w-auto h-auto btn-default-2"
                    onClick={followUserHandler}
                    style={{
                      padding: "10px 30px",
                    }}
                  >
                    Follow
                  </Button>
                )}
              </>
            )}
          </h5>
        </Card.Body>
        <div className="post_info_cover d-flex round p-2 gap-2 w-100">
          <div className="post_info d-flex gap-3 flex-grow-1">
            <span className="d-flex gap-1 text-white">
              {likes}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  likeHandler();
                }}
              >
                {isLiked ? (
                  <FontAwesomeIcon icon={faHeartFilled} color="#000000" />
                ) : (
                  <FontAwesomeIcon icon={faHeart} color="#ffffff" />
                )}
              </a>
            </span>
            <span className="d-flex gap-2 text-white">
              {comments?.length}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <FontAwesomeIcon icon={faCommentAlt} color="#ffffff" />
              </a>
            </span>
          </div>
          <Nav as="ul" className="flex-grow-1 gap-2 justify-content-end">
            <Nav.Item as="li">
              <Nav.Link
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  getCurrentUrl(`/${link}`)
                )}`}
                target="_blank"
              >
                <FontAwesomeIcon color="#e5097d" icon={faFacebookF} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  getCurrentUrl(`/${link}`)
                )}`}
                target="_blank"
              >
                <FontAwesomeIcon color="#e5097d" icon={faXTwitter} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                  getCurrentUrl(`/${link}`)
                )}`}
                target="_blank"
              >
                <FontAwesomeIcon color="#e5097d" icon={faLinkedinIn} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                  getCurrentUrl(`/${link}`)
                )}`}
                target="_blank"
              >
                <FontAwesomeIcon color="#e5097d" icon={faWhatsapp} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  copyToClipboard(`/${link}`);
                }}
              >
                <FontAwesomeIcon color="#e5097d" icon={faCopy} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </Card>
    </Col>
  );
}

import React, { useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEye,
  faFileArrowDown,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../helper/api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { Link, useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import ReactSelect from "react-select";
import { DateRangePicker } from "react-date-range";
import { CSVLink } from "react-csv";
import { getItemFromLocalStorage } from "../../../helper/helper";

const ReportedPosts = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [statusOptions, setStatusOptions] = useState([
    { value: "active", label: "Active" },
    { value: "pending", label: "Pending" },
    { value: "deactive", label: "Deactive" },
  ]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [statusFilter, setStatusFilter] = useState("");
  const [subCategories, setSubCategories] = useState();
  const [subCategory, setSubCategory] = useState();
  const [badges, setBadges] = useState();
  const [badge, setBadge] = useState();
  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const [isWinner, setIsWinner] = useState(false);
  const [isResponded, setIsResponded] = useState(false);

  const winnerOptions = [
    { value: false, label: "All" },
    { value: true, label: "Winners Only" },
  ];
  const respondedOptions = [
    { value: false, label: "All" },
    { value: true, label: "Responded Only" },
  ];

  const getAllPosts = async (
    page = 1,
    limit = 10,
    search = "",
    subCategory = "",
    status = "reported",
    startDate = "",
    endDate = "",
    badge = "",
    user = "",
    isWinner = false,
    isResponded = false
  ) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/post/all-posts`, token, {
        page: page,
        limit: limit,
        search: search,
        subCategory: subCategory,
        status: "reported",
        startDate: startDate,
        endDate: endDate,
        // badge: badge,
        user: user,
        isWinner,
        isResponded,
      });
      setTableHead([
        {
          name: "Title",
          sortable: true,
          selector: (row) => row.title,
        },
        {
          name: "Product Type",
          selector: (row) => `${row.productId?.name} (${row.productId?.slug})`,
        },
        {
          name: "Category",
          selector: (row) =>
            `${row.categoryId?.name} (${row.categoryId?.slug})`,
        },
        {
          name: "Sub Category",
          selector: (row) =>
            `${row.subCategoryId?.name} (${row.subCategoryId?.slug})`,
        },
        {
          name: "Owner",
          selector: (row) => `${row.userId?.firstName} ${row.userId?.lastName}`,
        },
        {
          name: "Reported By",
          selector: (row) =>
            `${row?.report?.userId?.firstName} ${row?.report?.userId?.lastName}`,
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editPost(row.slug, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editReportedPost(row, index, column, id)}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
              <Link
                to={`/${row.productId.slug}/${row.categoryId?.slug}/${row.slug}`}
                target="_BLANK"
                className="btn btn-sm text-light btn-info view_btn ms-1"
              >
                <FontAwesomeIcon icon={faEye} />
              </Link>
              <Button
                className="del_btn ms-1 d-none"
                size="sm"
                variant="danger"
                onClick={() => deletePost(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201 ||
        response?.result?.status === 201
      ) {
        setTableData(response?.result?.data?.posts);
        setTotalRows(response?.result?.data?.totalPosts);
      } else {
        setTableData([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.log("Get Moderations Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllPosts(
      page,
      perPage,
      searchItem,
      subCategory ? subCategory?.value : "",
      statusFilter,
      startDate,
      endDate,
      badge ? badge?.value : "",
      user ? user?.value : "",
      isWinner,
      isResponded
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    getAllPosts(
      1,
      newPerPage,
      searchItem,
      subCategory ? subCategory?.value : "",
      statusFilter,
      startDate,
      endDate,
      badge ? badge?.value : "",
      user ? user?.value : "",
      isWinner,
      isResponded
    );
  };

  const handleStatusFilter = (selectedOption) => {
    const statusValue = selectedOption?.value;
    setStatusFilter(statusValue);
    setCurrentPage(1);
    getAllPosts(
      1,
      perPage,
      searchItem,
      subCategory ? subCategory?.value : "",
      statusValue,
      startDate,
      endDate,
      badge ? badge?.value : "",
      user ? user?.value : "",
      isWinner,
      isResponded
    );
  };

  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);
    setCurrentPage(1);
    getAllPosts(
      1,
      perPage,
      searchValue,
      subCategory ? subCategory?.value : "",
      statusFilter,
      startDate,
      endDate,
      badge ? badge?.value : "",
      user ? user?.value : "",
      isWinner,
      isResponded
    );
  };

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;

    getAllPosts(
      1,
      perPage,
      searchItem,
      subCategory ? subCategory?.value : "",
      statusFilter,
      startDate,
      endDate,
      badge ? badge?.value : "",
      user ? user?.value : "",
      isWinner,
      isResponded
    );
  };

  const handleSubCategory = (selectedOption) => {
    setSubCategory(selectedOption);
    getAllPosts(
      1,
      perPage,
      searchItem,
      selectedOption?.value,
      statusFilter,
      startDate,
      endDate,
      badge ? badge?.value : "",
      user ? user?.value : "",
      isWinner,
      isResponded
    );
  };

  const handleWinnerFilter = (selectedOption) => {
    const winnerValue = selectedOption?.value;
    setIsWinner(winnerValue);
    setCurrentPage(1);
    getAllPosts(
      1,
      perPage,
      searchItem,
      subCategory ? subCategory?.value : "",
      statusFilter,
      startDate,
      endDate,
      badge ? badge?.value : "",
      user ? user?.value : "",
      winnerValue,
      isResponded
    );
  };
  const handleRespondedFilter = (selectedOption) => {
    const respondedValue = selectedOption?.value;
    setIsResponded(respondedValue);
    setCurrentPage(1);
    getAllPosts(
      1,
      perPage,
      searchItem,
      subCategory ? subCategory?.value : "",
      statusFilter,
      startDate,
      endDate,
      badge ? badge?.value : "",
      user ? user?.value : "",
      isWinner,
      respondedValue
    );
  };
  const handleBadge = (selectedOption) => {
    setBadge(selectedOption);
    getAllPosts(
      1,
      perPage,
      searchItem,
      subCategory ? subCategory?.value : "",
      statusFilter,
      startDate,
      endDate,
      selectedOption?.value,
      user ? user?.value : "",
      isWinner,
      isResponded
    );
  };

  const handleUserChange = (selectedOption) => {
    setUser(selectedOption);
    getAllPosts(
      1,
      perPage,
      searchItem,
      subCategory ? subCategory?.value : "",
      statusFilter,
      startDate,
      endDate,
      badge ? badge?.value : "",
      selectedOption?.value,
      isWinner,
      isResponded
    );
  };

  const getAllSubCategories = async (query) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/category/get-all-sub-categories`,
        token,
        {
          search: query,
          status: "active",
        }
      );

      const mappedOptions = response?.result?.data?.categories?.map(
        (option) => ({
          value: option._id,
          label: `${option.name} (${option.parentCategoryId?.name}) {${option.parentCategoryId?.masterProductId?.name}}`,
        })
      );

      setSubCategories(mappedOptions);
    } catch (error) {
      console.log("Get All Sub Badges Error", error);
    } finally {
      setPending(false);
    }
  };

  const getAllBadges = async () => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/badge/get-badges`, token, {
        status: "active",
      });

      const mappedOptions = response?.result?.data?.badges?.map((option) => ({
        value: option._id,
        label: option.name,
      }));

      setBadges(mappedOptions);
    } catch (error) {
      console.log("Get All Sub Badges Error", error);
    } finally {
      setPending(false);
    }
  };

  const getAllUsers = async () => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/user/get-all`, token);

      const mappedOptions = response?.result?.data?.users.map((option) => ({
        value: option._id,
        label: `${option.firstName} ${option.lastName} (${option.email})`,
      }));
      setUsers(mappedOptions);
    } catch (error) {
      console.log("Get All Users Error", error);
    } finally {
      setPending(false);
    }
  };

  const csvData = useMemo(() => {
    return tableData?.map((user) => ({
      id: user._id,
      name: user.name,
      slug: user.slug,
      parentCategory: user.parentCategoryId?.name,
      productType: user.parentCategoryId?.masterProductId?.name,
      adminCategory: user.adminCategory ? "Yes" : "No",
      isDeleted: user.isDeleted ? "Yes" : "No",
      created_at: moment(user.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);

  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const editPost = async (postSlug) => {
    navigate(`/editPost`, {
      state: { postSlug },
    });
  };

  const deletePost = async (postSlug) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        `/api/secure/post/delete-post/`,
        token,
        {
          postSlug,
        }
      );

      if (response.result.status === 200) {
        toast.success("Post Deleted", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Deleting Post Error", error);
    } finally {
      getAllPosts();
      setPending(false);
    }
  };

  const editReportedPost = (row) => {
    const reportId = row?.report?._id;
    navigate(`/editReportedPost`, {
      state: { reportId },
    });
  };
  useEffect(() => {
    getAllSubCategories();
    getAllBadges();
    getAllUsers();
    getAllPosts(currentPage, perPage);
  }, [currentPage, perPage]);

  return (
    <Layout>
      <section id="Posts_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col lg={3} md={6} xs={12} className="mt-3">
              <FormControl
                type="text"
                placeholder="Search Posts"
                className="me-2"
                value={searchItem}
                onChange={handleSearchCategory}
              />
            </Col>
            {/* <Col lg={2} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Status"
                  onChange={handleStatusFilter}
                  options={statusOptions}
                  required
                  isClearable
                />
              </Form.Group>
            </Col> */}
            {/* <Col lg={3} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Badge"
                  onChange={handleBadge}
                  options={badges}
                  required
                  isClearable
                />
              </Form.Group>
            </Col> */}
            <Col lg={2} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Winner"
                  onChange={handleWinnerFilter}
                  options={winnerOptions}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Respond"
                  onChange={handleRespondedFilter}
                  options={respondedOptions}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Sub Category"
                  onChange={handleSubCategory}
                  options={subCategories}
                  value={subCategory ? subCategory : ""}
                  required
                  isClearable
                  onInputChange={(query) => {
                    getAllSubCategories(query);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Select User"
                  onChange={handleUserChange}
                  options={users}
                  value={user ? user : ""}
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Dropdown autoClose={"outside"} className="w-100">
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setStartDate();
                        setEndDate();
                        getAllPosts();
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {tableData && (
              <Col lg={1} md={6} xs={12} className="mt-3">
                <CSVLink
                  data={csvData}
                  filename={"categories.csv"}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </CSVLink>
              </Col>
            )}
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={_.sortBy(tableData, "total").reverse()}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default ReportedPosts;

import {
  faPencilAlt,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Ratio,
  Row,
} from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { Editor } from "react-draft-wysiwyg";
import { useDropzone } from "react-dropzone";
import ImageUploading from "react-images-uploading";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import PublicImages from "../../../constants/public/images";
import { getRequest, putRequest, uploadURL } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Layout from "../../../layouts/admin/Layout";

const EditChallenges = () => {
  const navigate = useNavigate();
  const [featuredFile, setFeaturedFile] = useState([]);
  const [status, setStatus] = useState();
  const [title, setTitle] = useState();
  const [rules, setRules] = useState("");
  const [loading, setLoading] = useState(false);
  const [editor, setEditor] = useState();
  const [featuredImage, setFeaturedImage] = useState();
  const [images, setImages] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [coins, setCoins] = useState(0);
  const [subCategory, setSubCategory] = useState();
  const [product, setProduct] = useState();
  const [category, setCategory] = useState();
  let challengeId = "";
  const { state } = useLocation();
  if (state?.challengeId === "") {
    navigate("/Products");
  } else {
    challengeId = state?.challengeId;
  }

  const fetchProducts = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/product/get-all-products`,
        token,
        {
          page: page,
          search: search,
          status: "active",
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, products } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: products,
            hasMore: false,
          };
        } else {
          return {
            options: products,
            hasMore: products?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Products Error", error);
    }
  };
  const fetchCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-all-categories`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          productType: product?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };
  const fetchSubCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-categories-from-master`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          category: category?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };
  const handleProductChange = (selectedOption) => {
    setProduct(selectedOption);
    setCategory();
    setSubCategory();
  };
  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption);
    setSubCategory();
  };
  const handleSubCategoryChange = (selectedOption) => {
    setSubCategory(selectedOption);
  };

  const onChangeEditorHandle = (editorState) => {
    setEditor(editorState);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept:
      product && product.name.includes("Videos")
        ? {
            "video/mp4": [".mp4", ".MP4"],
          }
        : {
            "audio/*": [],
          },
    onDrop: (acceptedFiles) => {
      setFeaturedFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
    { value: "schedule", label: "Schedule" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setFeaturedImage(imageList[addUpdateIndex].file);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const editChallengeHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", title);
      if (status) {
        formData.append(
          "status",
          typeof status === "object" ? status.value : status
        );
      } else {
        toast.error("Please select status", {
          position: "top-center", theme: "colored",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      // console.log("images",images)
      if (images.some((file) => file.hasOwnProperty("file"))) {
        if (featuredImage) {
          formData.append("photo", featuredImage);
        } else {
          toast.error("Please Select Challenge Image.", {
            position: "top-center", theme: "colored",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      }
      if (subCategory) {
        formData.append("subCategoryId", subCategory?._id);
      } else {
        toast.error("Please select sub category", {
          position: "top-center", theme: "colored",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      formData.append("challengeStartDate", startDate.toISOString());
      formData.append("challengeEndDate", endDate.toISOString());
      formData.append("coins", coins);
      if (rules) {
        formData.append("rule", rules);
      } else {
        toast.error("Please add rules", {
          position: "top-center", theme: "colored",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
      if (product.name.includes("Videos") || product.name.includes("Audio")) {
        if (featuredFile) {
          if (!featuredFile.some((file) => file.hasOwnProperty("data_url"))) {
            featuredFile.map((file) => {
              formData.append("videoFile", file);
            });
          }
        } else {
          toast.error("Please add video file", {
            position: "top-center", theme: "colored",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
        formData.append("description", "");
      }
      // else if (product.name.includes("Audio")) {
      // }
      else {
        formData.append("videoFile", "");
        if (editor) {
          formData.append(
            "description",
            draftToHtml(convertToRaw(editor.getCurrentContent()))
          );
        } else {
          toast.error("Please add description", {
            position: "top-center", theme: "colored",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      }
      console.log("formData", Object.fromEntries(formData));

      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        `/api/secure/challenge/update-challenge/${challengeId}`,
        token,
        formData
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success("Challenge Updated", {
          position: "top-center", theme: "colored",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/Challenges");
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Create Challenge APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getChallengeById = async (challengeId) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/challenge/get-challenge`,
        token,
        {
          challengeId: challengeId,
        }
      );
      const {
        name,
        status,
        subCategoryId,
        challengeStartDate,
        challengeEndDate,
        coins,
        rule,
        photo,
        description,
        videoFile,
        categoryId,
        productId,
      } = response?.result?.data?.challenge;

      setTitle(name);
      setStatus(status);
      setSubCategory({ _id: subCategoryId?._id, name: subCategoryId?.name });
      setCategory({ _id: categoryId._id, name: categoryId.name });
      setProduct({
        _id: productId._id,
        name: productId.name,
      });
      setStartDate(new Date(challengeStartDate));
      setEndDate(new Date(challengeEndDate));
      setCoins(coins);
      setRules(rule);
      if (videoFile !== undefined || videoFile !== "" || videoFile !== null) {
        setFeaturedFile([
          {
            data_url: uploadURL + videoFile,
          },
        ]);
      }
      if (photo !== undefined || photo !== "" || photo !== null) {
        setImages([
          {
            data_url: uploadURL + photo,
          },
        ]);
      }
      if (description) {
        setEditor(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(htmlToDraft(description))
          )
        );
      }
    } catch (error) {
      console.log("Get Challenge By Id Error", error);
    } finally {
      setLoading(false);
    }
  };

  const getLabelByValue = (statusOptions, value) => {
    const option = statusOptions.find((option) => option.value === value);
    return option?.label;
  };
  useEffect(() => {
    getChallengeById(challengeId);
    // getAllSubCategories();
  }, [challengeId]);
  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={editChallengeHandler}
            >
              <Row>
                <Col sm={12} className="text-center">
                  <Form.Label>Add Challenge Image</Form.Label>
                  <ImageUploading
                    value={images}
                    onChange={onChangeImage}
                    dataURLKey="data_url"
                    maxNumber={1}
                    maxFileSize={1000000}
                    acceptType={["jpeg", "jpg", "gif", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div className="upload__image-wrapper mx-auto mb-5">
                          <button
                            type="button"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            {/* <i className="fal fa-plus"></i> */}
                          </button>
                          {imageList
                            ? imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img src={image["data_url"]} alt="" />
                                  <div className="image-item__btn-wrapper">
                                    <button
                                      type="button"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <FontAwesomeIcon icon={faPencilAlt} />
                                    </button>
                                  </div>
                                </div>
                              ))
                            : ""}
                        </div>
                        {errors && (
                          <div
                            id="imageUploadingErrors"
                            className="text-center"
                          >
                            {errors.maxFileSize && (
                              <Alert className="my-1" variant="danger">
                                Selected file size exceed 1MB
                              </Alert>
                            )}
                            {errors.acceptType && (
                              <Alert className="my-1" variant="danger">
                                Your selected file type is not allow
                              </Alert>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </ImageUploading>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Challenge Title</Form.Label>
                    <Form.Control
                      name="title"
                      type="text"
                      placeholder="Title"
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <ReactSelect
                      placeholder="Select Status"
                      onChange={statusChange}
                      options={statusOptions}
                      value={typeof status === "object"
                      ? status
                      : status
                      ? {
                          value: status,
                          label: getLabelByValue(statusOptions, status),
                        }
                      : ""}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Assign Coins</Form.Label>
                    <Form.Control
                      type="number"
                      value={coins}
                      onChange={(e) => setCoins(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Start Date</Form.Label>
                    <br />
                    <DateTimePicker
                      onChange={handleStartDate}
                      value={startDate}
                      format="d-MM-y h:mm:ss a"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>End Date</Form.Label>
                    <br />
                    <DateTimePicker
                      format="d-MM-y h:mm:ss a"
                      onChange={handleEndDate}
                      value={endDate}
                      minDate={startDate}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Rules</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={rules}
                      onChange={(e) => setRules(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Product</Form.Label>
                    <AsyncPaginate
                      value={product ? product : ""}
                      loadOptions={fetchProducts}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={handleProductChange}
                      placeholder="Select Product"
                      isClearable
                      loadingMessage={() => "Loading..."}
                      noOptionsMessage={() => "No Product Found"}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Form.Group>
                </Col>
                {product && (
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Category</Form.Label>
                      <AsyncPaginate
                        value={category ? category : ""}
                        loadOptions={fetchCategories}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                        onChange={handleCategoryChange}
                        placeholder="Select Category"
                        isClearable
                        loadingMessage={() => "Loading..."}
                        noOptionsMessage={() => "No Category Found"}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}
                {category && (
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Sub Category</Form.Label>
                      <AsyncPaginate
                        value={subCategory ? subCategory : ""}
                        loadOptions={fetchSubCategories}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                        onChange={handleSubCategoryChange}
                        placeholder="Select Sub Category"
                        isClearable
                        loadingMessage={() => "Loading..."}
                        noOptionsMessage={() => "No Sub Category Found"}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}
                {/* {product && product.name.includes("Videos") ? (
                  <Col sm={12} className="text-center">
                    <Form.Group className="mb-3">
                      <Form.Label>Add File</Form.Label>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>Please upload .MP4 format for video</p>
                      </div>
                      <div className="d-flex justify-content-center w-100">
                        {featuredFile.map((file, key) => {
                          if (file.hasOwnProperty("data_url")) {
                            const fileExtension = file?.data_url
                              ?.split(".")
                              .pop(); // Get the file extension
                            if (fileExtension === "mp4") {
                              return (
                                <Ratio aspectRatio="16x9" key={key}>
                                  <video controls>
                                    <source
                                      src={file.data_url}
                                      type="video/mp4" // Hardcoded video type
                                    />
                                  </video>
                                </Ratio>
                              );
                            }
                          } else {
                            if (file.type.startsWith("video/")) {
                              return (
                                <Ratio aspectRatio="16x9" key={key}>
                                  <video controls>
                                    <source
                                      src={file.preview}
                                      type={file.type}
                                    />
                                  </video>
                                </Ratio>
                              );
                            }
                          }
                        })}
                      </div>
                    </Form.Group>
                  </Col>
                ) : (
                  <Col sm={12} className="mb-5">
                    <Form.Label>Description</Form.Label>
                    <Editor
                      editorState={editor}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      onEditorStateChange={onChangeEditorHandle}
                    />
                  </Col>
                )} */}

                {product &&
                (product.name.includes("Audio Station") ||
                  product.name.includes("Videos")) ? (
                  <>
                    <h4 className="mt-5 p-2">
                      Challenge File <span>*</span>
                    </h4>

                    {featuredFile.length == 0 && (
                      <div className="mt-1 dot">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <div className="d-flex">
                            <div className="p-2">
                              {product.name.includes("Audio Station") ? (
                                <img
                                  src={PublicImages.backup}
                                  className="img-fluid"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={PublicImages.slidshow}
                                  className="img-fluid"
                                  alt=""
                                />
                              )}
                              <input {...getInputProps()} />
                            </div>
                            <div className="p-2">
                              <h5>Drop a file here or click to upload</h5>
                              {product.name.includes("Audio Station") ? (
                                <p>.mp3, upto 5MB in size</p>
                              ) : (
                                <p>.mp4, upto 50MB in size</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-center w-100">
                      {featuredFile.map((file, key) => {
                        console.log("file", file);
                        if (file.hasOwnProperty("data_url")) {
                          if (product.name.includes("Videos")) {
                            return (
                              <div
                                className="d-flex w-100 position-relative"
                                key={key}
                              >
                                <Ratio aspectRatio="16x9" key={key}>
                                  <video controls>
                                    <source src={file.data_url} />
                                  </video>
                                </Ratio>
                                <Button
                                  onClick={() => {
                                    setFeaturedFile([]);
                                  }}
                                  className="clearVideoFile"
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </div>
                            );
                          } else if (product.name.includes("Audio Station")) {
                            return (
                              <div className="d-flex gap-4 w-100" key={key}>
                                <audio className="w-100" controls key={key}>
                                  <source src={file.data_url} />
                                </audio>
                                <Button
                                  onClick={() => {
                                    setFeaturedFile([]);
                                  }}
                                  className="clearAudioFile"
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </div>
                            );
                          }
                        } else {
                          if (product.name.includes("Videos")) {
                            return (
                              <div
                                className="d-flex w-100 position-relative"
                                key={key}
                              >
                                <Ratio aspectRatio="16x9" key={key}>
                                  <video controls>
                                    <source
                                      src={file.preview}
                                      type={file.type}
                                    />
                                  </video>
                                </Ratio>
                                <Button
                                  onClick={() => {
                                    setFeaturedFile([]);
                                  }}
                                  className="clearVideoFile"
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </div>
                            );
                          } else if (
                            file.type.startsWith("audio/") &&
                            product.name.includes("Audio Station")
                          ) {
                            return (
                              <div className="d-flex gap-4 w-100" key={key}>
                                <audio className="w-100" controls key={key}>
                                  <source
                                    src={file.preview}
                                    type={file.type} // Hardcoded audio type
                                  />
                                </audio>
                                <Button
                                  onClick={() => {
                                    setFeaturedFile([]);
                                  }}
                                  className="clearAudioFile"
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </div>
                            );
                          }
                        }
                      })}
                    </div>
                  </>
                ) : (
                  <Col sm={12} className="mb-5">
                    <Form.Label>Description</Form.Label>
                    <Editor
                      editorState={editor}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      onEditorStateChange={onChangeEditorHandle}
                    />
                  </Col>
                )}

                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Update</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default EditChallenges;

// UserProfileForm.js

import React, { useEffect, useState, useRef } from "react";
import {
  Tab,
  Col,
  Row,
  Form,
  Button,
  InputGroup,
  Alert,
  Modal,
} from "react-bootstrap";
import PublicImages from "../../constants/public/images";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import {
  getRequest,
  postRequestForm,
  putRequest,
  uploadURL,
} from "../../helper/api";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { postRequestForm } from "../../helper/api";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader";
import { getItemFromLocalStorage, setItemInLocalStorage } from "../../helper/helper";
import DatePicker from "react-date-picker";
import moment from "moment";

import Dropzone from "react-dropzone";
import { faPlus, faPen } from "@fortawesome/free-solid-svg-icons";
import FileUpload from "./../../components/public/FileUpload";

function PersonalInfo({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [place, setPlace] = useState("");
  const [username, setUsername] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [interest, setInterest] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [gender, setGender] = useState("");
  const [language, setLanguage] = useState("");
  const [workingStatus, setWorkingStatus] = useState("");
  const [isParent, setIsParent] = useState(null);
  const [email, setEmail] = useState("");
  const [images, setImages] = useState([]);
  const [profileImage, setProfileImage] = useState(null);

  const [showModal, setShowModal] = useState(false);
  // const [image, setImage] = useState(null);
  const [cropperImage, setCropperImage] = useState(null);
  const [editedImage, setEditedImage] = useState(null);
  const inputRef = useRef(null);

  const location = useLocation();
  const userFormSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    email: Yup.string().email("Invalid Email").required("Email is Required"),
  });
  const userFormHandler = async (values) => {
    // values.preventDefault();
    setLoading(true);

    try {
      const token = getItemFromLocalStorage("TOKEN");
      const userId = getItemFromLocalStorage("USER");
      const formData = new FormData();
      formData.append("firstName", values.firstName ? values.firstName : "");
      formData.append("lastName", values.lastName ? values.lastName : "");
      // formData.append("email", values.email ? values.email : "");
      formData.append("place", values.place ? values.place : "");
      formData.append("aboutMe", values.aboutMe ? values.aboutMe : "");
      formData.append("birthday", values.birthday ? values.birthday : "");
      formData.append("interest", values.interest ? values.interest : "");
      formData.append(
        "phone",
        values.contactNumber ? values.contactNumber : ""
      );
      formData.append("_id", JSON.parse(userId)._id);
      formData.append("address", values.place ? values.place : "");
      formData.append(
        "workingStatus",
        values.workingStatus ? values.workingStatus : ""
      );
      formData.append("isParent", values.isParent ? values.isParent : "");
      formData.append(
        "favouriteLanguage",
        values.language ? values.language : ""
      );
      formData.append("gender", values.gender ? values.gender : "");
      console.log("editedImage", editedImage);
      if (editedImage && editedImage.startsWith("blob:")) {
        const blob = await fetch(editedImage).then((res) => res.blob());
        const profilePic = await blobToFile(
          blob,
          `${JSON.parse(userId)._id}.png`
        );
        formData.append("profileImage", profilePic);
      }

      formData.append("interests", values.interest);

      console.log(`formData`, Object.fromEntries(formData));

      const res = await putRequest(
        `/api/secure/user/edit-profile`,
        token,
        formData
      );
      if (res.result.status === 200 || res.result.status === 201) {
        setIsSuccess(true);
        const response = res?.result?.data?.user;
        setItemInLocalStorage("USER", JSON.stringify(response, null, 2));
        setFirstName(response?.firstName);
        setLastName(response?.lastName);
        setPlace(response?.address);
        setUsername(response?.username);
        setAboutMe(response?.aboutMe);
        setBirthday(response?.birthday);
        setInterest(response?.interests);
        setContactNumber(response?.phone);
        setEmail(response?.email);
        setGender(response?.gender);
        setLanguage(response?.favouriteLanguage);
        setWorkingStatus(response?.workingStatus);
        if (
          response?.profileImage != undefined ||
          response?.profileImage ||
          response?.profileImage != null
        ) {
          setEditedImage(uploadURL + response?.profileImage);
        }
        setIsParent(response?.isParent);
      }
      console.log(`res`, res);
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    }
  };

  const getProfile = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const userId = getItemFromLocalStorage("USER");
      const res = await getRequest(`/api/secure/user/profile`, token, {
        _id: JSON.parse(userId)._id,
      });
      const response = res?.result?.data?.user;
      setFirstName(response?.firstName);
      setLastName(response?.lastName);
      setPlace(response?.address);
      setUsername(response?.username);
      setAboutMe(response?.aboutMe);
      setBirthday(response?.birthday);
      setInterest(response?.interests);
      setContactNumber(response?.phone);
      setEmail(response?.email);
      setGender(response?.gender);
      setLanguage(response?.favouriteLanguage);
      setWorkingStatus(response?.workingStatus);
      if (
        response?.profileImage != undefined ||
        response?.profileImage ||
        response?.profileImage != null
      ) {
        setEditedImage(uploadURL + response?.profileImage);
      }
      setIsParent(response?.isParent);
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };
  // Function to format date
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getProfile();
    }
  }, [currentlyActive, actualComponent]);

  const handleDrop = (dropped) => {
    // setImage(dropped[0]);
    setShowModal(true);
    const blob = URL.createObjectURL(dropped[0]);

    // Get the image type from the extension. It's the simplest way, though be careful it can lead to an incorrect result:
    setCropperImage({
      src: blob,
      type: dropped[0].type,
    });
  };

  const handleSelectImage = () => {
    inputRef.current.click();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // setImage(null);
    setEditedImage(null);
  };
  const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: "image/png",
    });
  };
  const handleSaveAvatar = () => {
    setShowModal(false);
  };

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          <Row className="mt-2 justify-content-around g-0 pb-5">
            <Col lg={12} className="d-flex align-items-center">
              <div>
                <div
                  style={{
                    position: "relative",
                    width: "87px",
                    height: "87px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px  solid #e5097d",
                    borderRadius: "50%",
                  }}
                >
                  <Dropzone
                    accept={{
                      "image/png": [".jpeg", ".jpg", ".gif", ".png"],
                    }}
                    onDrop={handleDrop}
                    noClick
                    noKeyboard
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} ref={inputRef} />
                        {editedImage && (
                          <img
                            src={editedImage}
                            alt="Avatar"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                            }}
                          />
                        )}
                        <Button
                          className="btn-default-2 p-0"
                          style={{
                            position: "absolute",
                            bottom: "0px",
                            right: "0px",
                            width: "100%",
                            height: editedImage ? "20px" : "100%",
                            cursor: "pointer",
                            borderRadius: "0",
                            fontSize: 13,
                            border: 0,
                          }}
                          onClick={handleSelectImage}
                        >
                          <FontAwesomeIcon
                            icon={editedImage ? faPen : faPlus}
                          />
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
              <h5 className="ms-2 mt-0">{firstName + " " + lastName}</h5>
            </Col>
          </Row>
          <Formik
            initialValues={{
              firstName,
              lastName,
              email,
              place,
              aboutMe,
              birthday,
              interest,
              contactNumber,
              place,
              workingStatus,
              isParent,
              language,
              gender,
              username,
            }}
            validationSchema={userFormSchema}
            onSubmit={userFormHandler}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className=" justify-content-around mt-4">
                  <Col lg={5}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        className={errors.firstName ? "form-label-error" : null}
                      >
                        First Name
                      </Form.Label>
                      <Form.Control
                        className={
                          errors.firstName ? "form-control-error" : null
                        }
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        onChange={handleChange}
                        defaultValue={values.firstName}
                      />
                      {errors.firstName && (
                        <div className="error-message">{errors.firstName}</div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        name="username"
                        placeholder="Username"
                        defaultValue={values.username}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label
                        className={errors.email ? "form-label-error" : null}
                      >
                        Email
                      </Form.Label>
                      <Form.Control
                        className={errors.email ? "form-control-error" : null}
                        defaultValue={values.email}
                        onChange={handleChange}
                        type="email"
                        name="email"
                        placeholder="Email"
                        disabled
                      />
                      {errors.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Place</Form.Label>
                      <Form.Control
                        defaultValue={values.place}
                        onChange={handleChange}
                        type="text"
                        name="place"
                        placeholder="Place"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>About me</Form.Label>
                      <Form.Control
                        defaultValue={values.aboutMe}
                        onChange={handleChange}
                        as="textarea"
                        name="aboutMe"
                        rows={3}
                        placeholder="About me"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Gender</Form.Label>
                      <Form.Select
                        aria-label="gender"
                        name="gender"
                        onChange={handleChange}
                        placeholder="Select Gender..."
                        value={values.gender}
                        // max={new Date()}
                      >
                        <option value="">Select Gender...</option>
                        <option id="Male" value="Male">
                          Male
                        </option>
                        <option id="Female" value="Female">
                          Female
                        </option>
                        <option id="Other" value="Other">
                          Other
                        </option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Favourite language</Form.Label>
                      <Form.Select
                        aria-label="language"
                        name="language"
                        onChange={handleChange}
                        value={values.language}
                      >
                        <option>Select Favourite language...</option>
                        <option id="English" value="English">
                          English
                        </option>
                        <option id="Hindi" value="Hindi">
                          Hindi
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={5}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        className={errors.lastName ? "form-label-error" : null}
                      >
                        Last Name
                      </Form.Label>
                      <Form.Control
                        className={
                          errors.lastName ? "form-control-error" : null
                        }
                        defaultValue={values.lastName}
                        onChange={handleChange}
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                      />
                      {errors.lastName && (
                        <div className="error-message">{errors.lastName}</div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        defaultValue={values.contactNumber}
                        onChange={handleChange}
                        type="text"
                        name="contactNumber"
                        placeholder="Your Contact Number"
                      />
                    </Form.Group>
                    <div className="mb-3">
                      <Form.Group>
                        <Form.Label>Working Status</Form.Label>
                      </Form.Group>
                      <div id="radio-buttons" className="py-2">
                        <div className="form-check-inline">
                          <Form.Check
                            type="radio"
                            name="workingStatus"
                            id="homeMaker"
                            label="Home Maker"
                            value="homeMaker"
                            checked={values.workingStatus === "homeMaker"}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-check-inline">
                          <Form.Check
                            type="radio"
                            name="workingStatus"
                            id="workingWomen"
                            label="Working Women"
                            value="workingWomen"
                            checked={values.workingStatus === "workingWomen"}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-check-inline">
                          <Form.Check
                            type="radio"
                            name="workingStatus"
                            id="other"
                            label="Other"
                            value="other"
                            checked={values.workingStatus === "other"}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div>
                        <Form.Label>Are you a parent?</Form.Label>
                      </div>
                      <div id="radio-buttons" className="py-2">
                        <div className="form-check-inline">
                          <Form.Check
                            type="radio"
                            name="isParent"
                            id="notParent"
                            label="No"
                            defaultValue={false}
                            checked={values.isParent ? true : false}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-check-inline">
                          <Form.Check
                            type="radio"
                            name="isParent"
                            id="parent"
                            label="Yes"
                            defaultValue={true}
                            checked={values.isParent ? true : false}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <Form.Group className="mb-3">
                      <Form.Label>Birthday:</Form.Label>
                      {/* <CustomDateInput
                        value={values.birthday && values.birthday.split("T")[0]}
                        onChange={(value) => handleChange('birthday', value)}
                        maxDate={new Date().toISOString().split('T')[0]}
                      /> */}
                      <DatePicker
                        className="form-control"
                        disableCalendar={true}
                        format="d/MM/y"
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                        onChange={(date) => {
                          console.log("date", date);
                          if (date) {
                            setFieldValue("birthday", date);
                          }
                        }}
                        value={values.birthday && values.birthday}
                        maxDate={new Date()}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Interests</Form.Label>
                      <Form.Control
                        defaultValue={interest}
                        onChange={handleChange}
                        type="text"
                        name="interest"
                        placeholder="Select Interests..."
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    {isSuccess && (
                      <Alert variant={"success"}>Data Saved!</Alert>
                    )}
                  </Col>
                </Row>
                <div className="para">
                  <h6>Note:</h6>
                  <p>
                    1. It is important to note that only one account is allowed
                    on this platform, and none of the information in your
                    profile should have been previously used on this platform.
                  </p>
                  <p>
                    2. If you encounter any difficulties while editing your
                    information, please feel free to reach out to us via email
                    at{" "}
                    <a href="mailto:womanii2023@gmail.com">
                      womanii2023@gmail.com
                    </a>
                  </p>
                </div>
                <div className="button">
                  <button className="btn btn-light" type="submit">
                    SAVE
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <Modal id="menu-modal" show={showModal} onHide={handleCloseModal}>
            <Modal.Header
              className="justify-content-center position-relative"
              style={{
                borderRadius: "25px 25px 0px 0px",
                background: "#E5097D",
              }}
            >
              <h5
                className="mb-0"
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "Editors-Note",
                  fontSize: "36px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                }}
              >
                Edit Avatar
              </h5>
              <button
                type="button"
                className="btn-close position-absolute"
                style={{
                  right: "15px",
                  top: "15px",
                  filter: "invert(1)",
                  opacity: "0.7",
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </Modal.Header>
            <FileUpload
              image={cropperImage && cropperImage.src}
              setCroppedImage={(image) => {
                setEditedImage(image);
              }}
              handleCloseModal={handleCloseModal}
              handleSaveAvatar={handleSaveAvatar}
            />
          </Modal>
        </>
      )}
    </>
  );
}

export default PersonalInfo;

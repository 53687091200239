import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getRequest, uploadURL } from "../../helper/api";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemInLocalStorage,
} from "../../helper/helper";
import Loader from "../Loader";
import LangPop from "./PopUps/LangPop";
import MenuPop from "./PopUps/MenuPop";
import { Search } from "./Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCog,
  faHistory,
  faSignOutAlt,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import moment from "moment-timezone";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Header = ({ settings, logo, user }) => {
  const [languageActive, setLanguageActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [selectlanguage, setSelectLanguage] = useState("English");
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [liveStreams, setLiveStreams] = useState(false);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const handleShowSearch = (e) => {
    e.preventDefault();
    setShowSearch(true);
  };
  const closeSearch = () => {
    setShowSearch((prevState) => !prevState);
  };
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/product/get-all-products`,
        token
      );
      setProducts(response?.result?.data?.products);
      // Store in local storage to remember that products have been loaded
      setItemInLocalStorage(
        "Products",
        JSON.stringify(response?.result?.data?.products)
      );
    } catch (error) {
      console.log("Get All Products Error", error);
    } finally {
      setLoading(false);
    }
  };
  const checkLiveStreamingHandler = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/liveStreaming/check-live-streaming`,
        token
      );
      setLiveStreams(response?.result?.data?.hasStartedLiveStreams);
    } catch (error) {
      console.log("Check All Live Streams Error", error);
    } finally {
      setLoading(false);
    }
  };
  const [indianDate, setIndianDate] = useState("");
  const [indianTime, setIndianTime] = useState("");

  const getIndianDateTime = () => {
    const currentDate = moment().tz("Asia/Kolkata");
    const date = currentDate.format("YYYY-MM-DD");
    const time = currentDate.format("hh:mm A");

    setIndianDate(date);
    setIndianTime(time);
  };

  const updateIndianDateTime = () => {
    getIndianDateTime();
    requestAnimationFrame(updateIndianDateTime);
  };

  useEffect(() => {
    getIndianDateTime(); // Set initial values
    updateIndianDateTime(); // Start the update loop

    return () => cancelAnimationFrame(updateIndianDateTime); // Cleanup on component unmount
  }, []);
  useEffect(() => {
    checkLiveStreamingHandler();
    // Check if products have already been loaded (from local storage)
    const Products = getItemFromLocalStorage("Products");

    if (!Products) {
      // If not, load products
      getAllProducts();
    } else {
      // If products have been loaded before, set the state to indicate that
      setProducts(JSON.parse(Products));
    }
  }, []);
  return loading ? (
    <Loader loading={loading} />
  ) : (
    <header id="header">
      {/* Top Header */}
      <div className="top-header">
        <Container>
          <Row>
            <Col lg={6} md={7} xs={12}>
              <Nav as="ul">
                <Nav.Item as="li">
                  <Link className="nav-link" to="/">
                    HOME
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link className="nav-link" to="/about-us">
                    ABOUT US
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link className="nav-link" to="/team">
                    TEAM
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link className="nav-link" to="/latest-posts">
                    LATEST
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link className="nav-link" to="/trending-posts">
                    TRENDING
                  </Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={6} md={5} xs={12}>
              <div className="header-style justify-content-center justify-content-md-end d-flex align-item-center px-sm-0">
                <a
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className="nav-link d-flex align-items-center gap-2"
                >
                  <FontAwesomeIcon icon={faCalendarAlt} className="p-0" />
                  <span>{indianDate}</span>
                </a>
                <a
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className="nav-link d-flex align-items-center gap-2"
                >
                  <FontAwesomeIcon icon={faHistory} className="p-0" />
                  <span className="text-uppercase">{indianTime}</span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Mid Header */}
      {/* <div className="mid-header">
        <Container>
          <Row>
            <Col lg={4} md={4} xs={12} className="align-self-center">
              <Dropdown id="languagePicker">
                <Button
                  variant="default"
                  onClick={() => {
                    setLanguageActive(true);
                  }}
                >
                  {selectlanguage}
                </Button>
              </Dropdown>
            </Col>
            <Col lg={4} md={4} xs={12} className="text-center">
              <Link to={settings.site_link}>
                <Image fluid src={uploadURL + logo} alt="" />
              </Link>
            </Col>
            <Col lg={4} md={4} xs={12} className="align-self-center">
              <Nav as="ul" className="justify-content-end">
                <Nav.Item as="li">
                  <Nav.Link href="#" onClick={handleShowSearch}>
                    <i className="fal fa-search"></i>
                  </Nav.Link>
                </Nav.Item>
                {user ? (
                  <Nav.Item as="li">
                    <Link className="nav-link" to={`/profile/${user.username}`}>
                      <i className="fal fa-user"></i> {user.firstName}{" "}
                      {user.lastName}
                    </Link>
                  </Nav.Item>
                ) : (
                  <Nav.Item as="li">
                    <Link className="nav-link" to="/login">
                      <i className="fal fa-user"></i> Sign IN
                    </Link>
                  </Nav.Item>
                )}

                <Nav.Item as="li">
                  <a
                    className="nav-link btn btn-default"
                    onClick={() => setMenuActive(true)}
                  >
                    <i className="fal fa-edit"></i> Create
                  </a>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Container>
      </div> */}
      {/* Mid Header */}
      <div className="mid-header">
        <Container>
          <Row>
            <Col lg={4} md={4} xs={12} className="align-self-center">
              <Dropdown id="languagePicker">
                <Button
                  variant="default"
                  onClick={() => {
                    setLanguageActive(true);
                  }}
                >
                  {selectlanguage}
                </Button>
              </Dropdown>
            </Col>
            <Col lg={4} md={4} xs={12} className="text-center">
              <Link to={settings.site_link}>
                <Image fluid src={uploadURL + logo} alt="" />
              </Link>
            </Col>
            <Col lg={4} md={4} xs={12} className="align-self-center">
              <Nav as="ul" className="justify-content-end">
                <Nav.Item as="li">
                  <Nav.Link href="#" onClick={handleShowSearch}>
                    <i className="fal fa-search"></i>
                  </Nav.Link>
                </Nav.Item>
                {user ? (
                  <Dropdown as="li">
                    <Dropdown.Toggle
                      className="nav-link dropdown-toggle gap-2 py-0 d-inline-flex justify-content-between align-items-center"
                      type="button"
                      id="dropdown-basic"
                      as="a"
                    >
                      {user?.profileImage ? (
                        <LazyLoadImage
                          src={uploadURL + user?.profileImage}
                          alt={user?.username}
                          effect="blur"
                          className="img-fluid rounded-circle p-0"
                          delayTime={5000}
                          wrapperClassName="d-block"
                          width={32}
                          height={32}
                        />
                      ) : (
                        <svg
                          width="32px"
                          height="32px"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 224.2 319.9"
                        >
                          <path
                            class="st0"
                            fill="#E5097D"
                            d="M48.1,107.5c-4.9-1.5-7.2-6.3-5-10c0.6-1,2.8-1.5,4.2-1.5c14.8-0.1,29.7-0.1,44.5-0.1  c15.7,0,22.3-5.1,26.5-20.1c3-10.7,5.9-21.5,9.4-32.1c0.8-2.4,3.3-5.4,5.6-5.9c3.8-0.9,5.2,2.8,6.1,5.9c3.6,12.5,6.9,25,10.6,37.5  c3.3,11.2,8.2,14.7,20,14.8c1.7,0,3.3,0,5,0c6.3,0.1,8.3,2.5,6.3,8.5c-0.5,1.4-2.8,2.2-5.1,4c0,6.4-0.1,13.8,0,21.3  c0.4,24.1-9.1,43.2-29,57c-1.6,1.1-2.7,3.7-2.8,5.7c-0.3,7.1,0,14.3-0.2,21.5c-0.1,3.7,1,4.9,4.8,4.8c11.8-0.2,23.7-0.1,35.5-0.1  c24,0.1,39.7,15.8,39.8,39.8c0.1,17.5,0,35,0,52.5c0,7.5-1.5,9-9.1,9c-68.6,0-137.3,0-205.9,0c-7.8,0-9.1-1.2-9.1-8.9  c0-17.7-0.1-35.3,0-53c0.2-20.6,13-36.6,31.6-38.8c8.2-1,16.6-0.5,25-0.5c6.5-0.1,13-0.1,19.5,0c2.8,0.1,3.9-1,3.8-3.8  c-0.1-7.8,0.1-15.7-0.2-23.5c-0.1-1.6-1.4-3.8-2.8-4.7c-20.2-14-29.4-33.3-28.9-57.6C48.2,122,48.1,114.8,48.1,107.5L48.1,107.5z   M212.6,308.9c0.3-0.7,0.6-1.2,0.6-1.6c0-18.2,0.4-36.3-0.2-54.5c-0.4-11.8-10.7-22.2-22.7-22.9c-15.6-0.8-31.3-0.6-46.9-0.5  c-1.7,0-3.7,1.4-5,2.6c-6.9,6.7-13.7,13.6-20.5,20.5c-4.6,4.5-6.8,4.5-11.3,0.1c-6.9-6.8-13.6-13.7-20.6-20.4  c-1.5-1.5-3.9-2.8-5.9-2.8c-13.8-0.2-27.7-0.4-41.5,0C21,229.9,11,240.6,10.9,258.1c-0.1,15.2,0,30.3,0,45.5c0,1.8,0.2,3.5,0.3,5.3  L212.6,308.9L212.6,308.9z M134,64.7l-1.3,0.2c-1.9,6.5-3.6,13.1-5.7,19.5c-3.9,12.4-14.6,21.1-27.7,21.7c-11.5,0.6-23,0.5-34.4,0.2  c-4.7-0.1-6.7,1.1-6.3,6.1c0.4,6,0.2,12,0.2,18c-0.2,30.8,20.3,53.3,50.9,55.7c22.7,1.8,46-14.4,52.8-36.2  c4.2-13.4,2.4-27.1,2.9-40.6c0-1-1.4-2.9-2.4-3.1c-14-2.1-20.9-11.1-23.9-24C137.6,76.3,135.7,70.6,134,64.7L134,64.7z M112.1,243.6  c7.1-7.3,13.4-13.7,19.6-20.2c0.7-0.7,1.4-1.7,1.4-2.5c0.1-8.9,0.1-17.8,0.1-27.3c-14.4,4.8-28.1,4.5-42.3,0.1c0,9.3,0,18,0.1,26.6  c0,0.9,0.4,2,1.1,2.7C98.4,229.5,104.9,236.2,112.1,243.6z"
                          />
                          <path
                            class="st0"
                            fill="#E5097D"
                            d="M192,212.6c0-10.7,0-21.2,0-31.7c0-29.5,0-59,0-88.5c0.1-41.8-28.1-75.3-69.5-81C77.4,5.3,38.8,37,33.5,77.9  c-2.4,18.7-1.3,37.9-1.4,56.9c-0.2,24.3-0.1,48.6,0.1,73c0,4.3-1,5.7-5.5,5.8c-4.7,0-5.4-1.9-5.4-6c0.1-38.3,0-76.6,0.1-114.9  c0.1-41,24.7-75.8,62.2-88C134.8-12,188.7,18,200.2,70.6c3,13.6,2.4,28.1,2.5,42.2c0.3,30.6,0.1,61.3,0,92  C202.8,214.2,202.5,214.4,192,212.6L192,212.6z"
                          />
                          <path
                            class="st0"
                            fill="#E5097D"
                            d="M131.8,155.1c-0.5,11.2-9.2,19.6-19.7,19.5c-10.7,0-19-8.1-19.8-19.4c4.1-0.9,7.6-1.9,9.3,4.1  c1.3,4.6,5.6,6.9,10.5,6.9s9.3-2.1,10.5-6.8C124.1,153.7,127.5,154.4,131.8,155.1L131.8,155.1z"
                          />
                          <path
                            class="st0"
                            fill="#E5097D"
                            d="M85.5,118.7c5,0,9.2,4.2,9.1,9.3c-0.1,5-4.3,9.1-9.3,9.1c-5,0-9.2-4.3-9.1-9.3  C76.2,122.8,80.5,118.7,85.5,118.7L85.5,118.7z"
                          />
                          <path
                            class="st0"
                            fill="#E5097D"
                            d="M148,127.7c0.1,4.9-4.1,9.2-9.1,9.3c-5.1,0.1-9.2-3.9-9.3-9c-0.1-5.1,3.9-9.2,9-9.3  C143.7,118.7,147.9,122.7,148,127.7L148,127.7z"
                          />
                        </svg>
                      )}{" "}
                      {user.firstName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Nav as="ul">
                        <Nav.Item as="li">
                          <Link
                            className="nav-link"
                            to={`/profile/${user.username}`}
                          >
                            <FontAwesomeIcon
                              icon={faUserAlt}
                              className="pe-2"
                              color="#E5097D"
                            />
                            Profile
                          </Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Link className="nav-link" to={`/account`}>
                            <FontAwesomeIcon
                              icon={faCog}
                              color="#E5097D"
                              className="pe-2"
                            />
                            Account
                          </Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Link
                            className="nav-link"
                            to={`/account/?tabKey=topic-of-intrest`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              className="pe-2"
                            >
                              <path
                                d="M2.83333 21.5C2.19167 21.5 1.64236 21.2715 1.18542 20.8146C0.728472 20.3576 0.5 19.8083 0.5 19.1667V4.58333C0.5 4.29167 0.548611 4.02917 0.645833 3.79583C0.743056 3.5625 0.869444 3.33889 1.025 3.125L2.65833 1.14167C2.81389 0.927778 3.00833 0.767361 3.24167 0.660417C3.475 0.553472 3.72778 0.5 4 0.5H18C18.2722 0.5 18.525 0.553472 18.7583 0.660417C18.9917 0.767361 19.1861 0.927778 19.3417 1.14167L20.975 3.125C21.1306 3.33889 21.2569 3.5625 21.3542 3.79583C21.4514 4.02917 21.5 4.29167 21.5 4.58333V19.1667C21.5 19.8083 21.2715 20.3576 20.8146 20.8146C20.3576 21.2715 19.8083 21.5 19.1667 21.5H2.83333ZM3.3 4H18.7L17.7083 2.83333H4.29167L3.3 4ZM2.83333 6.33333V19.1667H19.1667V6.33333H2.83333ZM11 18L15.6667 13.3333L14.0333 11.7L12.1667 13.5667V8.66667H9.83333V13.5667L7.96667 11.7L6.33333 13.3333L11 18Z"
                                fill="#E5097D"
                              />
                            </svg>
                            Topic of intrest
                          </Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                          <Nav.Link
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              removeItemFromLocalStorage("TOKEN");
                              removeItemFromLocalStorage("ROLE");
                              removeItemFromLocalStorage("USER");
                              removeItemFromLocalStorage("REFERRAL_SETTINGS");
                              removeItemFromLocalStorage("LANGUAGE_SETTINGS");
                              removeItemFromLocalStorage("SITE_LOGO");
                              removeItemFromLocalStorage("SITE_SETTINGS");
                              removeItemFromLocalStorage("Products");
                              removeItemFromLocalStorage("POPUP_SETTINGS");

                              window.location.reload();
                            }}
                            className="live me-0"
                          >
                            <FontAwesomeIcon
                              icon={faSignOutAlt}
                              color="#E5097D"
                              className="pe-2"
                            />{" "}
                            LOGOUT
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Nav.Item as="li">
                    <Link className="nav-link" to="/login">
                      <i className="fal fa-user"></i> Sign IN
                    </Link>
                  </Nav.Item>
                )}

                <Nav.Item as="li">
                  <a
                    className="nav-link btn btn-default"
                    onClick={() => setMenuActive(true)}
                  >
                    <i className="fal fa-edit"></i> Create
                  </a>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Text End */}
      <div className="text-end">
        <Navbar expand="lg" className="pb-0">
          <div className="container justify-content-end">
            <Nav as="ul">
              <Nav.Item as="li">
                <Link className="nav-link live me-2" to={"/live-streamings"}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    color={`${liveStreams ? "#48277f" : "#fff"}`}
                    className={`pe-2 ${liveStreams ? "fa-beat Blink" : ""}`}
                  />{" "}
                  LIVE {liveStreams}
                </Link>
              </Nav.Item>
            </Nav>
          </div>
        </Navbar>
      </div>

      {/* Bottom Header */}
      <Navbar expand="lg" className="bottom-header">
        <div className="container justify-content-center">
          <Nav className="flex-row" as="ul">
            {/* <Nav.Item as="li">
              <Link className="nav-link" to={{
                pathname: `/blogs`,
                state: {
                  status:"active"
                },
              }}>
                Blogs
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className="nav-link" to={`/short-stories`}>
                Short Stories
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className="nav-link" to={`/image-corner`}>
                Image Corner
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className="nav-link" to={`/videos`}>
                Videos
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className="nav-link" to={`/audio-station`}>
                Audio Station
              </Link>
            </Nav.Item> */}
            {products?.length > 0 &&
              products.map((product, index) => (
                <Nav.Item as="li" key={index}>
                  <Link
                    className="nav-link"
                    to={user ? `/${product.slug}` : `/${product.slug}/listings`}
                    state={{
                      status: product.status,
                    }}
                  >
                    {product.name}
                  </Link>
                </Nav.Item>
              ))}
            <Nav.Item as="li">
              <Link className="nav-link" to="/blogs/she-news">
                She News
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className="nav-link" to="/blogs/special-days">
                Special Days
              </Link>
            </Nav.Item>
            <Dropdown as="li">
              <Dropdown.Toggle
                className="nav-link dropdown-toggle"
                type="button"
                id="dropdown-basic"
                as="a"
              >
                More
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <div className="d-flex justify-content-around">
                  <div>
                    <Nav as="ul" className="flex-column">
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/latest-posts"}
                          className="nav-link bog"
                        >
                          Recent
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/sponsored-blogs"}
                          className="nav-link"
                        >
                          Sponsored Blogs
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/live-streamings"}
                          className="nav-link bog"
                        >
                          Live
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/editor-picks"}
                          className="nav-link"
                        >
                          Editor picks
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/trending-posts"}
                          className="nav-link bog"
                        >
                          Today's Best
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/travel"}
                          className="nav-link"
                        >
                          Travel
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/decor"}
                          className="nav-link bog"
                        >
                          Décor
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div>
                    <Nav as="ul" className="flex-column">
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/fashion-fusion"}
                          className="nav-link bog"
                        >
                          Fashion Fusion
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/beauty-tips"}
                          className="nav-link"
                        >
                          Beauty Tips
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/parenting"}
                          className="nav-link bog"
                        >
                          Parenting
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/fit-living"}
                          className="nav-link"
                        >
                          Fit Living
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/cooking-recipes"}
                          className="nav-link bog"
                        >
                          Cooking (Recipes)
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/carrier"}
                          className="nav-link"
                        >
                          Carrier
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/bollywood"}
                          className="nav-link bog"
                        >
                          Bollywood
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Navbar>

      {/* Bottom Header 2 */}
      <Navbar expand="lg" className="bottom-header2">
        <div className="container justify-content-center">
          <Nav as="ul" className="flex-row flex-wrap">
            {/* <Nav.Item as="li">
              <Link className="nav-link" to={`/blogs`}>
                Blogs
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className="nav-link" to={`/short-stories`}>
                Short Stories
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className="nav-link" to={`/videos`}>
                Videos
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className="nav-link" to={`/audio-station`}>
                Audio Station
              </Link>
            </Nav.Item> */}

            {/* {products?.length > 0 &&
              products.map((product, index) => {
                if (!product.slug.includes("image-corner")) {
                  return (
                    <Nav.Item as="li" key={index}>
                      <Link
                        className="nav-link"
                        to={`/${product.slug}`}
                        state={{
                          status: product.status,
                        }}
                      >
                        {product.name}
                      </Link>
                    </Nav.Item>
                  );
                }
              })} */}
            {products?.length > 0 &&
              products.map((product, index) => (
                <Nav.Item as="li" key={index}>
                  <Link
                    className="nav-link"
                    to={user ? `/${product.slug}` : `/${product.slug}/listings`}
                    state={{
                      status: product.status,
                    }}
                  >
                    {product.name}
                  </Link>
                </Nav.Item>
              ))}
            <Dropdown as="li">
              <Dropdown.Toggle
                className="nav-link dropdown-toggle"
                type="button"
                id="dropdown-basic"
                as="a"
              >
                More
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <div className="d-flex justify-content-around">
                  <div>
                    <Nav as="ul" className="flex-column">
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/latest-posts"}
                          className="nav-link bog"
                        >
                          Recent
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/sponsored-blogs"}
                          className="nav-link"
                        >
                          Sponsored Blogs
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/live-streamings"}
                          className="nav-link bog"
                        >
                          Live
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/editor-picks"}
                          className="nav-link"
                        >
                          Editor picks
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/trending-posts"}
                          className="nav-link bog"
                        >
                          Today's Best
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/travel"}
                          className="nav-link"
                        >
                          Travel
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/decor"}
                          className="nav-link bog"
                        >
                          Décor
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to="/blogs/she-news"
                          className="nav-link"
                        >
                          She News
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div>
                    <Nav as="ul" className="flex-column">
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/fashion-fusion"}
                          className="nav-link bog"
                        >
                          Fashion Fusion
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/beauty-tips"}
                          className="nav-link"
                        >
                          Beauty Tips
                        </Link>
                      </Nav.Item>
                      {/* {products?.length > 0 &&
                        products.map((product, index) => {
                          if (product.slug.includes("image-corner")) {
                            return (
                              <Nav.Item as="li" key={index}>
                                <Link
                                  className="nav-link"
                                  to={`/${product.slug}`}
                                  state={{
                                    status: product.status,
                                  }}
                                >
                                  {product.name}
                                </Link>
                              </Nav.Item>
                            );
                          }
                        })} */}
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/parenting"}
                          className="nav-link bog"
                        >
                          Parenting
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/fit-living"}
                          className="nav-link"
                        >
                          Fit Living
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/cooking-recipes"}
                          className="nav-link bog"
                        >
                          Cooking (Recipes)
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/carrier"}
                          className="nav-link"
                        >
                          Carrier
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to={"/blogs/bollywood"}
                          className="nav-link bog"
                        >
                          Bollywood
                        </Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Link
                          as={Dropdown.Item}
                          to="/blogs/special-days"
                          className="nav-link bog"
                        >
                          Special Days
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Navbar>
      {products?.length > 0 && (
        <MenuPop
          show={menuActive}
          products={products}
          handleClose={(modalShow) => {
            setMenuActive(modalShow);
          }}
        />
      )}
      <LangPop
        show={languageActive}
        handleLanguage={(e) => {
          setLanguageActive(false);
          setSelectLanguage(e);
        }}
        initialLanguage={selectlanguage}
      />
      {settings && logo && (
        <Search
          show={showSearch}
          settings={settings}
          logo={logo}
          onClose={closeSearch}
          rend
        />
      )}
    </header>
  );
};

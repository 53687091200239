import React, { useState, useEffect } from "react";
import Layout from "./../../../layouts/admin/Layout";
import { useLocation } from "react-router-dom";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { uploadURL, getRequest, putRequest } from "./../../../helper/api";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { toast } from "react-toastify";
import Loader from "./../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";

const Advertisement = () => {
  const [isLoading, setLoading] = useState(true);

  const [page, setPage] = useState([]);
  const [images, setImages] = useState([]);
  const [profileImage, setProfileImage] = useState("");

  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setProfileImage(imageList[addUpdateIndex].file);
  };
  const [editor, setEditor] = useState();
  const onChangeEditorHandle = (editorState) => {
    setEditor(editorState);
    console.log("editorState", editorState);
  };
  const updatePageHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("pageName", "Advertisement");
    formData.append(
      "content",
      draftToHtml(convertToRaw(editor.getCurrentContent()))
    );
    formData.append("featuredImage", profileImage);
    console.log("formData", Object.fromEntries(formData));
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const response = await putRequest(
        "/api/secure/page/update-advertisement",
        token,
        formData
      );

      // console.log("status", response);
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        toast.success("Advertisement Page Updated", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        // window.location.assign("/Users");
      }
    } catch (error) {
      console.log("Update Page APi error", error.message);
    }
  };
  const getPageByName = async (pageName) => {
    try {
      const response = await getRequest("/api/secure/page/advertisement", "", {
        pageName,
      });
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        console.log("aboutUs", response?.result?.data?.page);
        setPage(response?.result?.data?.page);
        const contentBlock = htmlToDraft(response?.result?.data?.page?.content);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        }
        setImages([
          {
            data_url: uploadURL + response?.result?.data?.page?.featuredImage,
          },
        ]);
        setLoading(false);
      }
    } catch (error) {
      console.log("Get Page APi error", error.message);
    }
  };
  useEffect(() => {
    getPageByName("Advertisement");
  }, []);

  return (
    <Layout>
      <section className="py-5">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updatePageHandler}
            >
              <Row>
                <Col sm={12} md={9}>
                  <Form.Label>
                    <b>Advertisement Content</b>
                  </Form.Label>
                  <Editor
                    editorState={editor}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={onChangeEditorHandle}
                  />
                </Col>
                <Col sm={12} md={3} className="text-center">
                  <Form.Label>
                    <b>Featured Image</b>
                  </Form.Label>
                  <ImageUploading
                    value={images}
                    onChange={onChangeImage}
                    dataURLKey="data_url"
                    maxNumber={1}
                    maxFileSize={1000000}
                    acceptType={["jpeg", "jpg", "gif", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div className="upload__image-wrapper mx-auto mb-5">
                          <button
                            type="button"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            {/* <i className="fal fa-plus"></i> */}
                          </button>
                          {imageList
                            ? imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img src={image["data_url"]} alt="" />
                                  <div className="image-item__btn-wrapper">
                                    <button
                                      type="button"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <FontAwesomeIcon icon={faPencilAlt} />
                                    </button>
                                  </div>
                                </div>
                              ))
                            : ""}
                        </div>
                        {errors && (
                          <div id="imageUploadingErrors" className="text-center">
                            {errors.maxFileSize && (
                              <Alert className="my-1" variant="danger">
                                Selected file size exceed 1MB
                              </Alert>
                            )}
                            {errors.acceptType && (
                              <Alert className="my-1" variant="danger">
                                Your selected file type is not allow
                              </Alert>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </ImageUploading>
                </Col>
                <Col xl={12} sm={12} className="text-center mt-4">
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Loader loading={isLoading} />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Advertisement;

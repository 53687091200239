import React, { useEffect, useState } from "react";
import Layout from "../../layouts/public/Layout";
import { Card, Container, Image, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader";
import { getRequest } from "../../helper/api";
import { Link } from "react-router-dom";

const LiveStreamings = () => {
  const [liveStreamLoading, setLiveStreamLoading] = useState(true);
  const [liveStreamError, setLiveStreamError] = useState("");
  const [liveStreamEnded, setLiveStreamEnded] = useState(true);
  const [liveStreamPage, setLiveStreamPage] = useState(1);
  const [allLiveStreamLength, setAllLiveStreamLength] = useState(0);
  const [liveStreams, setLiveStreams] = useState([]);

  const loadLiveStreams = async (page) => {
    try {
      const { result, error } = await getRequest(
        "/api/secure/liveStreaming/get-live-streamings",
        "",
        {
          page,
          status: ["active","scheduled"],
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { liveStreamings, currentPage, totalPages } = result?.data;
        if (page === 1) {
          setLiveStreams(liveStreamings);
          // Inside the function where you load more data
          setAllLiveStreamLength(liveStreamings);
        } else {
          setLiveStreams((prevData) => [...prevData, ...liveStreamings]);
          setAllLiveStreamLength(liveStreamings?.length);
        }

        if (currentPage === totalPages) {
          console.log("workinggg");
          setLiveStreamEnded(false);
        }
        setLiveStreamPage(page + 1);
      } else if (error?.response?.status === 400) {
        setLiveStreamEnded(false);
      }
    } catch (error) {
      setLiveStreamError(error.message);
    } finally {
      setLiveStreamLoading(false);
    }
  };

  useEffect(() => {
    loadLiveStreams(liveStreamPage);
  }, []);
  return (
    <Layout>
      <main id="live_main">
        <section className="banner py-5">
          <Container>
            <div className="justify-content-center align-item-center text-center">
              <h1>LIVE</h1>
            </div>
          </Container>
        </section>
        <section className="py-5">
          <Container>
            {liveStreamLoading ? (
              <Loader loading={true} />
            ) : (
              <div>
                {liveStreamError ? (
                  liveStreamError
                ) : (
                  <InfiniteScroll
                    dataLength={allLiveStreamLength}
                    next={() => {
                      loadLiveStreams(liveStreamPage);
                    }}
                    inverse={false}
                    hasMore={liveStreamEnded}
                    loader={<Loader loading={true} />}
                    scrollThreshold={0.9}
                    endMessage={
                      <p
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <b>Yay! You've seen it all!</b>
                      </p>
                    }
                  >
                    <Row className="m-0">
                      {liveStreams?.map((liveStream, index) => {
                        return (
                          <div key={index} className="col-md-4 mb-4">
                            <Card>
                              <Link to={`/live-streamings/${liveStream?._id}`}>
                                <div className="liveStreamVideoContainer">
                                  <Image
                                    fluid
                                    className="liveStreamVideo w-100"
                                    style={{ objectFit: "cover" }}
                                    src={`https://img.youtube.com/vi/${liveStream?.videoId}/hqdefault.jpg`}
                                    alt=""
                                  />
                                </div>
                                <Card.Body>
                                  <Card.Title>
                                    <h5>{liveStream?.title}</h5>
                                  </Card.Title>
                                </Card.Body>
                              </Link>
                            </Card>
                          </div>
                        );
                      })}
                    </Row>
                  </InfiniteScroll>
                )}
              </div>
            )}
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default LiveStreamings;

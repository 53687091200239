import React, { useState, useEffect } from "react";
import { Header } from "./../../components/public/Header";
import { Footer } from "./../../components/public/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest } from "../../helper/api";
import Loader from "./../../components/Loader";
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "../../helper/helper";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import PublicImages from "../../constants/public/images";
import { Image } from "react-bootstrap";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const route = useLocation();
  const [PageLoader, setPageLoader] = useState(true);
  const [startUpPopupSettings, setStartUpPopupSettings] = useState(null);
  const [siteSettings, setSiteSettings] = useState(null);
  const [languageSettings, setLanguageSettings] = useState(null);
  const [logo, setLogo] = useState(null);
  const [user, setUser] = useState(null);

  const initializeSite = async () => {
    try {
      if (
        getItemFromLocalStorage("SITE_SETTINGS") &&
        getItemFromLocalStorage("REFERRAL_SETTINGS") &&
        getItemFromLocalStorage("LANGUAGE_SETTINGS") &&
        getItemFromLocalStorage("SITE_LOGO") &&
        getItemFromLocalStorage("POPUP_SETTINGS")
      ) {
        setSiteSettings(JSON.parse(getItemFromLocalStorage("SITE_SETTINGS")));
        setLogo(getItemFromLocalStorage("SITE_LOGO"));
        setUser(JSON.parse(getItemFromLocalStorage("USER")));
        setLanguageSettings(
          JSON.parse(getItemFromLocalStorage("LANGUAGE_SETTINGS"))
        );
        setStartUpPopupSettings(
          JSON.parse(getItemFromLocalStorage("POPUP_SETTINGS"))
        );
      } else {
        const response = await getRequest("/api/secure/site/site-settings");
        if (
          response?.result?.status === 200 ||
          response?.result?.status === 201
        ) {
          const {
            settings,
            siteLogo,
            referralSettings,
            languageSettings,
            startUpPopupSettings,
          } = response?.result?.data?.siteSettings[0];
          setSiteSettings(settings);
          setStartUpPopupSettings(startUpPopupSettings);
          setLanguageSettings(languageSettings);
          setLogo(siteLogo);
          setItemInLocalStorage("SITE_SETTINGS", JSON.stringify(settings));
          setItemInLocalStorage(
            "REFERRAL_SETTINGS",
            JSON.stringify(referralSettings)
          );
          setItemInLocalStorage(
            "LANGUAGE_SETTINGS",
            JSON.stringify(languageSettings)
          );
          setItemInLocalStorage(
            "POPUP_SETTINGS",
            JSON.stringify(startUpPopupSettings)
          );
          setItemInLocalStorage("SITE_LOGO", siteLogo);
        }
      }
    } catch (error) {
      console.log("Get Settigns APi error", error.message);
    } finally {
      setTimeout(() => {
        setPageLoader(false);
      }, 1000);
    }
  };
  useEffect(() => {
    initializeSite();
    const role = getItemFromLocalStorage("ROLE");
    const token = getItemFromLocalStorage("TOKEN");

    if (role && token) {
      setUser(JSON.parse(getItemFromLocalStorage("USER")));
    } else {
      // Define regular expressions to match the desired routes
      const uploadRoutesRegex = /^\/[^/]+\/upload/;
      const publishedRouteRegex = /^\/[^/]+\/published/;
      const underModerationRouteRegex =
        /^\/[^/]+\/[^/]+\/under-moderation\/[^/]+/;

      if (
        route.pathname === "/account" ||
        uploadRoutesRegex.test(route.pathname) ||
        publishedRouteRegex.test(route.pathname) ||
        underModerationRouteRegex.test(route.pathname)
      ) {
        navigate("/login");
      }
    }
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    window.addEventListener("contextmenu", handleContextMenu);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // Define the additional props you want to pass to children
  const childProps = {
    settings: siteSettings,
    languagesettings: languageSettings,
    logo,
    user,
    startUpPopupSettings,
    // Add any other props you want to pass here
  };

  // Use React.Children.map to pass props to children
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, childProps);
    }
    return child;
  });
  return (
    <>
      {PageLoader && !siteSettings && !logo && !languageSettings ? (
        <Loader loading={PageLoader} />
      ) : (
        <>
          <Header
            settings={siteSettings}
            languagesettings={languageSettings}
            startUpPopupSettings={startUpPopupSettings}
            logo={logo}
            user={user}
          />
          <main id="main">
            <LazyLoadComponent>{childrenWithProps}</LazyLoadComponent>

            <div className="whatsAppCover">
              <a href="https://wa.link/45n5v1">
                <Image
                  fluid
                  src={PublicImages.WhatsAppIcon_2}
                  height={100}
                  width={100}
                  alt=""
                />
                <span>Tell Us</span>
              </a>
            </div>
          </main>
          <Footer
            settings={siteSettings}
            languagesettings={languageSettings}
            startUpPopupSettings={startUpPopupSettings}
            logo={logo}
            user={user}
          />
        </>
      )}
    </>
  );
};

export default Layout;

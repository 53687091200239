import React, { useEffect, useState } from "react";
import Layout from "../../layouts/public/Layout";
import { Image } from "react-bootstrap";

import { getRequest, uploadURL } from "../../helper/api";
import Loader from "../../components/Loader";

function AboutUs() {
  const [isLoading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [image, setImage] = useState(null);

  const getPageByName = async (pageName) => {
    try {
      const response = await getRequest("/api/secure/page/about-us", "", {
        pageName,
      });
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        const aboutUsData = response?.result?.data?.page;
        setContent(aboutUsData?.content);
        setImage(uploadURL + aboutUsData?.featuredImage);
      }
    } catch (error) {
      console.log("Get Page API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPageByName("About Us");
  }, []);

  return (
    <Layout>
      <main id="aboutus_main">
        <section className="banner">
          <div className="container">
            {isLoading ? (
              <Loader loading={isLoading}/>
            ) : (
              <>
                <div className="justify-content-center align-item-center text-center">
                  <h1>ABOUT US</h1>
                  <Image fluid src={image} alt="" />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              </>
            )}
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default AboutUs;

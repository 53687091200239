import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Layout from "../../layouts/public/Layout";
import { uploadURL, getRequest } from "./../../helper/api";
import Loader from "../../components/Loader";

const Team = () => {
  const [isLoading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState(null);
  const [isFlipped, setIsFlipped] = useState({});

  const handleMouseEnter = (index) => {
    setIsFlipped((prev) => ({ ...prev, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setIsFlipped((prev) => ({ ...prev, [index]: false }));
  };

  const getPageByName = async (pageName) => {
    try {
      const response = await getRequest("/api/secure/page/team", "", {
        pageName,
      });
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        const teamData = response?.result?.data?.page;
        setTeamData(teamData?.teamMembers);
        // Initialize flip state for each card to false
        setIsFlipped(
          Object.fromEntries(teamData?.teamMembers.map((_, i) => [i, false]))
        );
      }
    } catch (error) {
      console.log("Get Page API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPageByName("Teams");
  }, []);

  return (
    <Layout>
      <main id="main">
        <section className="teams p-5">
          <div className="mb-5">
            <p className="teamheading">TEAM Womanii</p>
          </div>
          <Container className="mt-3">
            {isLoading ? (
              <Loader loading={isLoading} />
            ) : (
              <>
                <Row>
                  {teamData ? (
                    teamData.map(
                      ({ image, name, designation, description }, index) => (
                        <Col lg={4} md={4} xs={12} key={index} className="mb-5">
                          <div
                            className="card-container"
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                          >
                            <div
                              className={`card ${
                                isFlipped[index] ? "flipped" : ""
                              }`}
                            >
                              <div className="card-face">
                                <Card.Img
                                  src={uploadURL + image}
                                  className="card-img-top img-fluid"
                                  alt="team-member-image"
                                />
                                <Card.Body className="text-center">
                                  <Card.Title>{name}</Card.Title>
                                  <Card.Text>{designation}</Card.Text>
                                </Card.Body>
                              </div>
                              <div className="card-face card-back position-absolute">
                                <div className="card-overlay">
                                  <p>{description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )
                    )
                  ) : (
                    <Col xs={12} className="text-center">
                      <p>No Team members added yet!</p>
                    </Col>
                  )}
                </Row>
                {/* <div className="text-center pt-4">
                  <Button className="btn-default">Load More</Button>
                </div> */}
              </>
            )}
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default Team;

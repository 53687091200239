import React, { useEffect, useMemo, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Layout from "../../layouts/public/Layout";

import { getRequest, postRequestForm, uploadURL } from "../../helper/api";
import { getItemFromLocalStorage } from "../../helper/helper";

import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import ImageCard from "../../components/public/Pages/ImageCard";
import PublicImages from "../../constants/public/images";

function TrendingPosts() {
  const [recent, setRecent] = useState(true);
  const [popular, setPopular] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allDataLength, setAllDataLength] = useState(0);
  const [latestPosts, setLatestPosts] = useState([]);
  const [latestPostsPage, setLatestPostsPage] = useState(1);
  const [latestPostLoading, setLatestPostLoading] = useState(false);
  const [latestPostEnded, setLatestPostEnded] = useState(true);
  const [latestError, setLatestError] = useState("");
  const [isBookmark, setIsBookmark] = useState({});
  const [isLiked, setIsLiked] = useState({});

  const handleBookmarkPost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/bookmark-post`,
        token,
        {
          postIdToToggle: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsBookmark((prev) => {
          let isBookmarked = false;
          const newState = { ...prev };
          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isBookmarked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isBookmarked = true;
          }
          if (isBookmarked) {
            toast.success("Post has been Bookmarked!", {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(
              `Post has been ${
                isBookmarked ? "Bookmarked!" : "Un-Bookmarked!"
              }!`,
              {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              }
            );
          }
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleLikePost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/like-post`,
        token,
        {
          postId: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsLiked((prev) => {
          const newState = { ...prev };
          let isLiked = false;

          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isLiked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isLiked = true;
          }

          // Update the like count in the corresponding post data
          setAllData((prevData) =>
            prevData.map((item) =>
              item._id === postId
                ? {
                    ...item,
                    likeCount: isLiked
                      ? item.likeCount + 1
                      : item.likeCount - 1,
                  }
                : item
            )
          );
          if (isLiked) {
            toast.success(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getLatestPosts = async (
    // latestPost,
    page = 1,
    recent = true,
    popular = false
  ) => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/post/latest-posts`,
        "",
        {
          recent: false,
          popular: true,
          page,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        setLatestError("");
        setLatestPosts(result?.data);
        if (page === 1) {
          setAllData(result?.data?.latestPosts);
          // Inside the function where you load more data
          setAllDataLength(result?.data?.latestPosts);
        } else {
          setAllData((prevData) => [...prevData, ...result?.data?.latestPosts]);
          // Inside the function where you load more data
          setAllDataLength(allData.length);
        }
        if (result?.data.currentPage === result?.data.totalPages) {
          setLatestPostEnded(false);
        }
        setLatestPostsPage((prevPage) => prevPage + 1);
      } else if (error?.response?.status === 400) {
        setLatestError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLatestPostLoading(false);
    }
  };

  const getUserLikedAndBookmarkedPosts = useMemo(() => {
    return async () => {
      try {
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result, error } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            const { userPrefrences } = result?.data;
            const { likedPost, bookmarks } = userPrefrences;
            setIsLiked((prev) => ({
              ...prev,
              ...likedPost.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
            setIsBookmark((prev) => ({
              ...prev,
              ...bookmarks.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
          }
        } else {
          setIsLiked({});
          setIsBookmark({});
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
        setIsLiked({});
        setIsBookmark({});
      } finally {
      }
    };
  }, []);
  useEffect(() => {
    getUserLikedAndBookmarkedPosts();
  }, [getUserLikedAndBookmarkedPosts]);
  useEffect(() => {
    setLatestPostsPage(1);
    setAllData([]);
    getLatestPosts(1, recent, popular);
  }, []);

  return (
    <Layout>
      <main id="main">
        <section className="creators_sec bg-white pb-0">
          <Container>
            <h1>
              <img
                src={PublicImages.TrendingPost}
                className="img-fluid"
                alt=""
              />
              Trending Posts
            </h1>
          </Container>
        </section>
        <section className="listing_sec my-5">
          <div className="container">
            {latestPostLoading ? (
              <Loader loading={true} />
            ) : (
              <div>
                {latestError ? (
                  latestError
                ) : (
                  <InfiniteScroll
                    dataLength={allDataLength}
                    next={() => {
                      getLatestPosts(
                        // latestPost,
                        latestPostsPage,
                        recent,
                        popular
                      );
                    }}
                    inverse={false}
                    hasMore={latestPostEnded}
                    loader={<Loader loading={true} />}
                    scrollThreshold={0.9}
                    endMessage={
                      <p
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <b>Yay! You've seen it all!</b>
                      </p>
                    }
                  >
                    <Row className="m-0">
                      {/* formatString(product)?.includes("Blog")? */}
                      {allData?.map((item, index) => (
                        <ImageCard
                          image={uploadURL + item?.featuredImage}
                          authorImage={
                            item.userId?.profileImage
                              ? uploadURL + item.userId.profileImage
                              : ""
                          }
                          subCatName={`${item.subCategoryId?.name}`}
                          productSlug={`${item?.productId?.slug}`}
                          catSlug={`${item?.categoryId?.slug}`}
                          subCatSlug={`${item.subCategoryId?.slug}`}
                          authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                          views={item.viewCount}
                          comments={item.comments}
                          likes={item.likeCount}
                          title={item.title}
                          date={item.createdAt}
                          username={item.userId.username}
                          link={`/${item?.productId?.slug}/${item?.categoryId?.slug}/${item.slug}`}
                          bookmarkHandler={() => handleBookmarkPost(item._id)}
                          isBookmarked={isBookmark[item._id]}
                          likeHandler={() => handleLikePost(item._id)}
                          isLiked={isLiked[item._id]}
                          isWinner={item.isWinner}
                          challengeLink={`/${item.productId?.slug}/challenges/${item.challengeId?.slug}`}
                          isStarmarked={item.isStarmarked}
                        />
                      ))}
                    </Row>
                  </InfiniteScroll>
                )}
              </div>
            )}
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default TrendingPosts;

import React, { useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArrowDown,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../helper/api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import ReactSelect from "react-select";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-date-range";
import { getItemFromLocalStorage } from "../../../helper/helper";
import { AsyncPaginate } from "react-select-async-paginate";

const Categories = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [categories, setCategories] = useState([]);
  const [statusOptions, setStatusOptions] = useState([
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCategories, setTotalCategories] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [productType, setProductType] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getAllCategories = async (
    page = 1,
    limit = 10,
    search = "",
    status = "",
    productType = "",
    startDate = "",
    endDate = ""
  ) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      // console.log("token", token);
      // var params = props.location.search.slice(5);
      const response = await getRequest(
        `/api/secure/category/get-all-categories`,
        token,
        {
          limit: limit,
          page: page,
          search: search,
          status: status,
          startDate: startDate,
          endDate: endDate,
          productType,
        }
      );
      // getKitGroup(kitResponse.result.data.kits);
      setTableHead([
        {
          name: "Category Name",
          sortable: true,
          selector: (row) => row.name,
        },
        {
          name: "Category Slug",
          sortable: true,
          selector: (row) => row.slug,
        },
        {
          name: "Product Type",
          sortable: true,
          selector: (row) => row.masterProductId?.name,
        },
        {
          name: "Category Status",
          sortable: true,
          selector: (row) => row.status,
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editCategory(row.slug, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                className="del_btn ms-1"
                size="sm"
                variant="danger"
                onClick={() => deleteCategory(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        setTotalCategories(response?.result.data?.totalCategories);
        setCategories(response?.result?.data?.categories);
        setTableData(response?.result?.data?.categories);
      } else {
        setTotalCategories(0);
        setCategories([]);
        setTableData([]);
      }
    } catch (error) {
      console.log("Get All Categories Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllCategories(
      page,
      perPage,
      searchItem,
      statusFilter,
      productType ? productType?._id : "",
      startDate,
      endDate
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    getAllCategories(
      1,
      newPerPage,
      searchItem,
      statusFilter,
      productType ? productType?._id : "",
      startDate,
      endDate
    );
  };

  const handleStatusFilter = (selectedOption) => {
    const statusValue = selectedOption?.value;
    setStatusFilter(statusValue);
    setCurrentPage(1);
    getAllCategories(
      1,
      perPage,
      searchItem,
      statusValue,
      productType ? productType?._id : "",
      startDate,
      endDate
    );
  };

  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);
    setCurrentPage(1);
    getAllCategories(
      1,
      perPage,
      searchValue,
      statusFilter,
      productType ? productType?._id : "",
      startDate,
      endDate
    );
  };

  const editCategory = async (categorySlug) => {
    navigate(`/editCategory`, {
      state: { categorySlug },
    });
  };

  const deleteCategory = async (categoryId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/category/delete-category/${categoryId}`,
        token
      );
      if (result?.status === 200) {
        toast.success("Category Deleted", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        toast.error(
          `${
            error?.response?.data?.message
          }: ${error?.response?.data?.childCategoryNames.join(", ")}`,
          {
            position: "top-center", theme: "colored",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error) {
      console.log("Deleting Category Error", error);
    } finally {
      getAllCategories();
      setPending(true);
    }
  };

  const csvData = useMemo(() => {
    return tableData?.map((category) => ({
      id: category._id,
      slug: category.slug,
      Name: category.name,
      product_type: category.masterProductId?.name,
      status: category.status,
      isDeleted: category.isDeleted ? "Yes" : "No",
      created_at: moment(category.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);

  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    setCurrentPage(1);

    getAllCategories(
      1,
      perPage,
      searchItem,
      statusFilter,
      productType ? productType?._id : "",
      startDate,
      endDate
    );

    setIsDropdownOpen(false);
  };

  const productTypeChange = (selectedOption) => {
    setProductType(selectedOption);
    setCurrentPage(1);
    getAllCategories(
      1,
      perPage,
      searchItem,
      statusFilter,
      selectedOption?._id,
      startDate,
      endDate
    );
  };
  const fetchProducts = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/product/get-all-products`,
        token,
        {
          page: page,
          search: search,
          status: "active",
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, products } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: products,
            hasMore: false,
          };
        } else {
          return {
            options: products,
            hasMore: products?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Products Error", error);
    }
  };
  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <Layout>
      <section id="Categories_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col>
              <FormControl
                type="text"
                placeholder="Search Categories"
                className="me-2"
                value={searchItem}
                onChange={handleSearchCategory}
              />
            </Col>
            <Col>
              <Form.Group>
                <ReactSelect
                  placeholder="Status"
                  onChange={handleStatusFilter}
                  options={statusOptions}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <AsyncPaginate
                  value={productType ? productType : ""}
                  loadOptions={fetchProducts}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                  onChange={productTypeChange}
                  placeholder="Select Product"
                  isClearable
                  required
                  loadingMessage={() => "Loading..."}
                  noOptionsMessage={() => "No Product Found"}
                  additional={{
                    page: 1,
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Dropdown
                show={isDropdownOpen}
                onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                autoClose={"outside"}
                className="w-100"
              >
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setStartDate();
                        setEndDate();
                        getAllCategories();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {tableData && (
              <Col>
                <CSVLink
                  data={csvData}
                  filename={"categories.csv"}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </CSVLink>
              </Col>
            )}
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalCategories} // Assuming you have a total count in your API response
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Categories;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { getRequest, putRequest, uploadURL } from "../../../helper/api";
import Loader from "../../../components/Loader";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { getItemFromLocalStorage } from "../../../helper/helper";

const EditSubCategory = () => {
  let subCategorySlug = "";
  const location = useLocation();
  const navigate = useNavigate();
  if (location?.state?.subCategorySlug === "") {
    navigate("/Products");
  } else {
    subCategorySlug = location?.state?.subCategorySlug;
  }
  const [name, setName] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [wordLimit, setWordLimit] = useState("");
  const [adminCategory, setAdminCategory] = useState(false);
  const [featuredCategory, setFeaturedCategory] = useState(false);
  const [status, setStatus] = useState();
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(true);
  const getAllCategories = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/category/get-active-categories`,
        token
      );

      const mappedOptions = response?.result?.data?.categories.map(
        (option) => ({
          value: option._id,
          label: `${option.name} (${option.masterProductId.name})`,
        })
      );
      setCategories(mappedOptions);
    } catch (error) {
      console.log("Get All Categories Error", error);
    } finally {
      setLoading(false);
    }
  };
  const categoryChange = (selectedOption) => {
    setCategory(selectedOption);
  };
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const editSubCategoryHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await putRequest(
        "/api/secure/category/update-sub-category",
        token,
        {
          slug: subCategorySlug,
          name,
          parentCategoryId:
            typeof category === "object" ? category.value : category,
          status: typeof status === "object" ? status.value : status,
          metaTitle,
          metaDescription,
          wordLimit,
          adminCategory,
          isFeatured: featuredCategory,
        }
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        toast.success("Sub Category Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/subCategories");
      }
    } catch (error) {
      console.log("Create Sub Category APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const getCategoryBySlug = async (categorySlug) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");

      const response = await getRequest(
        `/api/secure/category/get-category`,
        token,
        {
          slug: categorySlug,
        }
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        const {
          name,
          adminCategory,
          status,
          parentCategoryId,
          wordLimit,
          metaTitle,
          metaDescription,
          isFeatured,
        } = response?.result?.data?.category;
        setName(name);
        setStatus(status);
        setCategory(parentCategoryId);
        setWordLimit(wordLimit);
        setMetaTitle(metaTitle);
        setAdminCategory(adminCategory);
        setMetaDescription(metaDescription);
        setFeaturedCategory(isFeatured);
      }
    } catch (error) {
      console.log("Get Sub Category By Slug APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const getLabelByValue = (statusOptions, value) => {
    const option = statusOptions?.find((option) => option.value === value);
    return option?.label;
  };
  useEffect(() => {
    console.log("subCategorySlug", subCategorySlug);
    getAllCategories();
    getCategoryBySlug(subCategorySlug);
  }, [subCategorySlug, location]);
  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={editSubCategoryHandler}
            >
              <Row>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="Name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Word Limit</Form.Label>
                    <Form.Control
                      name="wordLimit"
                      type="number"
                      placeholder="Word Limit"
                      required
                      value={wordLimit}
                      onChange={(e) => setWordLimit(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      name="metaTitle"
                      type="text"
                      placeholder="Meta Title"
                      required
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      name="metaDescription"
                      type="text"
                      placeholder="Meta Description"
                      required
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                {status && (
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Category</Form.Label>
                      <ReactSelect
                        placeholder="Select Category"
                        onChange={categoryChange}
                        options={categories}
                        value={
                          typeof category === "object"
                            ? category
                            : category
                            ? {
                                value: category,
                                label: getLabelByValue(categories, category),
                              }
                            : ""
                        }
                        isClearable
                      />
                    </Form.Group>
                  </Col>
                )}

                {status && (
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <ReactSelect
                        placeholder="Select Status"
                        onChange={statusChange}
                        options={statusOptions}
                        value={
                          typeof status === "object"
                            ? status
                            : status
                            ? {
                                value: status,
                                label: getLabelByValue(statusOptions, status),
                              }
                            : ""
                        }
                        required
                      />
                    </Form.Group>
                  </Col>
                )}
                <Col xl={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Admin Category</Form.Label>
                    <div className="form-check">
                      <Form.Check
                        type="checkbox"
                        name="adminCategory"
                        id="adminCategory"
                        label="Admin Category"
                        checked={adminCategory} // Use the checked prop to bind the state to the checkbox
                        onChange={() => {
                          setAdminCategory(
                            (prevAdminCategory) => !prevAdminCategory
                          ); // Toggle the state
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Featured Category</Form.Label>
                    <div className="form-check">
                      <Form.Check
                        type="checkbox"
                        name="featuredCategory"
                        id="featuredCategory"
                        label="Featured Category"
                        checked={featuredCategory} // Use the checked prop to bind the state to the checkbox
                        onChange={() => {
                          setFeaturedCategory(
                            (prevFeaturedCategory) => !prevFeaturedCategory
                          ); // Toggle the state
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col xl={12} sm={12} className="text-center my-5">
                  <Button type="submit">Update</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default EditSubCategory;

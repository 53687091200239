import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import PublicImages from "../../constants/public/images";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { getRequest, postRequestForm } from "../../helper/api";
import Loader from "../../components/Loader";
import { getItemFromLocalStorage } from "../../helper/helper";
function WithdrawalRequest({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [ReferralSetting, setReferralSetting] = useState(null);
  const [error, setError] = useState([]);
  const withdrawlHandler = async (values) => {
    setLoading(true);
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const userId = getItemFromLocalStorage("USER");
      console.log({
        userId: JSON.parse(userId)._id,
        amount: values.withdrawlValue,
        availableBalance:
          Number(wallet?.balance) - Number(values.withdrawlValue),
        bankAccount: values.withdrawlDetails,
      });
      const res = await postRequestForm(
        `/api/secure/wallet/withdrawal-request`,
        token,
        {
          userId: JSON.parse(userId)._id,
          amount: values.withdrawlValue,
          availableBalance:
            Number(wallet?.balance) - Number(values.withdrawlValue),
          bankAccount: values.withdrawlDetails,
        }
      );
      if (res.result.status === 200 || res.result.status === 201) {
        setIsSuccess(true);
      }
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
      getWallet();
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    }
  };
  const withdrawlSchema = Yup.object().shape({
    withdrawlDetails: Yup.string().required("Required"),
    withdrawlValue: Yup.string().required("Required"),
  });
  const getBankAccountsOfUser = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const user = getItemFromLocalStorage("USER");
      const res = await getRequest(
        `/api/secure/bankAccount/get-bank-accounts-of-user`,
        token,
        {
          userId: JSON.parse(user)._id,
        }
      );
      if (res.result.status === 200 || res.result.status === 201) {
        const { bankAccounts } = res?.result?.data;
        setBankAccounts(bankAccounts);
      } else {
        setError(["Please set the payment details. "]);
      }
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };
  const getWallet = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const referralSettings = getItemFromLocalStorage("REFERRAL_SETTINGS");
      const user = getItemFromLocalStorage("USER");
      const res = await getRequest(
        `/api/secure/wallet/get-wallet-of-user`,
        token,
        {
          userId: JSON.parse(user)._id,
        }
      );
      if (res.result.status === 200 || res.result.status === 201) {
        const { wallet } = res?.result?.data;
        setWallet(wallet);
        setReferralSetting(JSON.parse(referralSettings));
      }
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getBankAccountsOfUser();
      getWallet();
    }
  }, [currentlyActive, actualComponent]);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Container>
          <div className="withdraw">
            <h6 className="text-center">WITHDRAWAL REQUEST</h6>
            <div className="top_withdraw">
              {wallet && (
                <Row className="pt-4 justify-content-center align-items-center">
                  <Col lg={5} md={5} xs={12} className="coin">
                    <a href="#" className="align-self-center ">
                      <img
                        src={PublicImages.coin_1}
                        className="img-fluid me-2 mb-1"
                        alt=""
                      />
                      {wallet?.balance}
                    </a>
                  </Col>
                  <Col
                    lg={5}
                    md={5}
                    xs={12}
                    className="justify-content-end d-flex available"
                  >
                    <p className="m-0 align-self-center">
                      <span>|</span> Available
                    </p>
                  </Col>
                </Row>
              )}
            </div>
            <div className="mid_withdraw">
              <Formik
                initialValues={{
                  withdrawlDetails: "",
                  withdrawlValue: "1",
                }}
                validationSchema={withdrawlSchema}
                onSubmit={withdrawlHandler}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                      <Col lg={10} className="p-0 mb-3">
                        <Form.Group className="">
                          <Form.Label>
                            <i className="far fa-rupee-sign"></i> Withdrawal
                            Account
                          </Form.Label>
                          {bankAccounts.length ? (
                            <Form.Select
                              aria-label="withdrawlDetails"
                              name="withdrawlDetails"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="w-100"
                              defaultValue={values.withdrawlDetails}
                              required
                            >
                              <option></option>
                              {bankAccounts.map(
                                ({ _id, type, bankName, accountNumber }) => {
                                  return (
                                    <option key={_id} value={_id}>
                                      {type === "bankTransfer"
                                        ? `${bankName.toUpperCase()} (${accountNumber})`
                                        : `${type.toUpperCase()} (${accountNumber})`}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                          ) : (
                            <Alert variant="danger">
                              Please add the bank accounts to widthdraw the
                              amount.
                            </Alert>
                          )}
                          <ErrorMessage name="withdrawlDetails" />
                        </Form.Group>
                      </Col>
                      <Col lg={10} className="p-0">
                        <Row className="justify-content-center">
                          <Col lg={6}>
                            <Form.Label>
                              <i className="far fa-rupee-sign"></i> Withdrawal
                              Coins
                            </Form.Label>
                          </Col>
                          <Col lg={6} className="align-self-center">
                            <p className="m-0 text-end">Value in Rupees</p>
                          </Col>
                        </Row>
                        <Row className="justify-content-center g-0 mb-2">
                          <Col lg={9} className="align-self-center">
                            <Form.Group className=" p-0 dry">
                              <Form.Control
                                type="number"
                                placeholder="Enter Coins"
                                name="withdrawlValue"
                                max={wallet?.balance}
                                min={1}
                                maxLength={wallet?.balance}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={values.withdrawlValue}
                                required
                                disabled={
                                  wallet?.balance >=
                                  ReferralSetting?.withdrawalLimitMinimum
                                    ? false
                                    : true
                                }
                              />
                            </Form.Group>
                          </Col>
                          {ReferralSetting && (
                            <Col lg={3} className="align-self-center">
                              <div className="input-group erase">
                                <Form.Control
                                  type="text"
                                  placeholder={`₹ ${
                                    (values.withdrawlValue *
                                    ReferralSetting?.perCoinValue)?.toFixed(2)
                                  }`}
                                  disabled
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                        {wallet?.balance <=
                              ReferralSetting?.withdrawalLimitMinimum && (
                          <Alert variant="danger">
                            You can not withdraw until your wallet balance in
                            greater than{" "}
                            {ReferralSetting?.withdrawalLimitMinimum} because,
                            our minimum withdrawal limit is{" "}
                            {ReferralSetting?.withdrawalLimitMinimum}
                          </Alert>
                        )}
                      </Col>
                      <Col lg={10} className="mt-3 p-0">
                        {isSuccess && (
                          <Alert variant={"success"}>Request Sent!</Alert>
                        )}
                      </Col>
                      <Col lg={10} className="p-0">
                        {wallet && (
                          <div className="pt-4 mt-3">
                            <p className="w-100">
                              Available Balance:{" "}
                              <b>
                                {wallet?.balance}{" "}
                                {wallet?.balance > 1 ? "Coins" : "Coin"}
                              </b>
                            </p>
                          </div>
                        )}
                        <div id="notice">
                          <p className="w-100">
                            After applying to withdraw the amount, the
                            processing time for payments will be within a month.
                          </p>
                        </div>
                        <div className="text-center">
                          <Button
                            disabled={
                              wallet?.balance >=
                              ReferralSetting?.withdrawalLimitMinimum
                                ? false
                                : true
                            }
                            className="btn-default"
                            type="submit"
                          >
                            WITHDRAW
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default WithdrawalRequest;

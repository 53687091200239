import { Container, Table } from "react-bootstrap";
import { getRequest } from "../../helper/api";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import moment from "moment";
import * as _ from "underscore";
import { getItemFromLocalStorage } from "../../helper/helper";

function WalletHistory({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const getTransactions = async () => {
    setLoading(true);
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const user = getItemFromLocalStorage("USER");
      const res = await getRequest(
        `/api/secure/wallet/get-wallet-transaction-of-user`,
        token,
        {
          userId: JSON.parse(user)._id,
        }
      );
      if (res.result.status === 200 || res.result.status === 201) {
        const { transactions } = res?.result?.data;
        setTransactions(transactions);
      }
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getTransactions();
    }
  }, [currentlyActive, actualComponent]);
  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Container>
          <div className="history">
            <h4 className="mb-5">WALLET HISTORY</h4>
            <div className="table-responsive">
              {transactions.length !== 0 ? (
                <Table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Transaction Details</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Available Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions &&
                      _.sortBy(transactions, "total")
                        .reverse()
                        .map(
                          (
                            {
                              _id,
                              referenceNumber,
                              amount,
                              type,
                              createdAt,
                              availableBalance,
                              reason,
                            },
                            key
                          ) => {
                            var momentDate = moment(createdAt);

                            // Format the date as "D MMMM YYYY" (e.g., "9 June 2023")
                            var formattedDate =
                              momentDate.format("D MMMM YYYY");

                            // Format the time as "h:mm A" (e.g., "7:43 PM")
                            var formattedTime = momentDate.format("h:mm A");
                            return (
                              <tr key={key}>
                                <td>
                                  <p className="m-0">
                                    {formattedDate}
                                    <br />
                                    <span>{formattedTime}</span>
                                  </p>
                                </td>
                                <td className="td">
                                  <div className="d-flex align-items-center">
                                    <div className="">
                                      <i class="fal fa-rupee-sign"></i>
                                    </div>
                                    <div className="">
                                      <a
                                        style={{
                                          color:
                                            type === "Incoming"
                                              ? "#15cf74"
                                              : "#dd0000",
                                        }}
                                        href="#"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        {type === "Incoming"
                                          ? reason === "referral"
                                            ? "Earn by Referral"
                                            : reason === "challenge"
                                            ? "Earn by Challenges"
                                            : reason === "badge"
                                            ? "Earn by Badges"
                                            : ""
                                          : reason === "referral"
                                          ? "Remove by Referral"
                                          : reason === "challenge"
                                          ? "Remove by Challenges"
                                          : reason === "badge"
                                          ? "Remove by Badges"
                                          : ""}
                                      </a>
                                      <p>
                                        {/* A/C NO: 0000000000000 <br /> */}
                                        Transaction ID: {_id}
                                        {referenceNumber && (
                                          <>
                                            <br />
                                            Reference Number: {referenceNumber}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                    style={{
                                      color:
                                        type === "Incoming"
                                          ? "#15cf74"
                                          : "#dd0000",
                                    }}
                                  >
                                    {type === "Incoming" ? "+" : "-"}₹{amount}
                                  </a>
                                </td>
                                <td>
                                  <p className="text-center">
                                    ₹{availableBalance}
                                  </p>
                                </td>
                              </tr>
                            );
                          }
                        )}
                  </tbody>
                </Table>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <p className=" text-black-50">No Transaction Available</p>
                </div>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default WalletHistory;

import {
  faFileArrowDown,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import * as _ from "underscore";
import { getRequest } from "../../../helper/api";
import Layout from "../../../layouts/admin/Layout";

import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-date-range";
import ReactSelect from "react-select";
import Loader from "../../../components/Loader";
import {
  cronScheduleToDate,
  getItemFromLocalStorage,
} from "../../../helper/helper";
moment.tz.setDefault("Asia/Kolkata");

const LiveStreamings = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "pending", label: "Pending" },
    { value: "deactive", label: "Deactive" },
  ];
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [statusFilter, setStatusFilter] = useState("");

  const getAllLiveStreaming = async (
    page = 1,
    limit = 10,
    search = "",
    status = "",
    startDate = "",
    endDate = ""
  ) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/liveStreaming/get-live-streamings`,
        token,
        {
          limit: limit,
          page: page,
          search: search,
          status: status,
          startDate: startDate,
          endDate: endDate,
        }
      );
      setTableData(response?.result?.data?.liveStreamings);
      setTotalRows(response?.result?.data?.totalLiveStreamings);
      setTableHead([
        {
          name: "Title",
          sortable: true,
          selector: (row) => row.title,
        },
        {
          name: "Status",
          selector: (row) => row.status,
        },
        {
          name: "Schedule",
          selector: (row) =>
          row.schedule
              ? moment(row.schedule)?.format("D-M-Y h:mm A")
              : moment(row.createdAt).format("D-M-Y  h:mm A"),
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() =>
                  editLiveStreaming(row._id, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </>
          ),
        },
      ]);
    } catch (error) {
      console.log("Get All Live Streamings Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllLiveStreaming(
      page,
      perPage,
      searchItem,
      statusFilter,
      startDate,
      endDate
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    getAllLiveStreaming(
      1,
      newPerPage,
      searchItem,
      statusFilter,
      startDate,
      endDate
    );
  };

  const handleStatusFilter = (selectedOption) => {
    const statusValue = selectedOption?.value;
    setStatusFilter(statusValue);
    setCurrentPage(1);
    getAllLiveStreaming(
      1,
      perPage,
      searchItem,
      statusValue,
      startDate,
      endDate
    );
  };

  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);
    setCurrentPage(1);
    getAllLiveStreaming(
      1,
      perPage,
      searchValue,
      statusFilter,
      startDate,
      endDate
    );
  };

  const csvData = useMemo(() => {
    return tableData?.map((liveStreaming) => ({
      id: liveStreaming._id,
      name: liveStreaming.title,
      status: liveStreaming.status,
      created_at: moment(liveStreaming.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);

  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;

    getAllLiveStreaming(
      1,
      perPage,
      searchItem,
      statusFilter,
      startDate,
      endDate
    );
  };

  const editLiveStreaming = async (liveStreamingId) => {
    navigate(`/editLiveStreaming`, {
      state: { liveStreamingId },
    });
  };

  useEffect(() => {
    getAllLiveStreaming(currentPage, perPage);
  }, [currentPage, perPage]);

  return (
    <Layout>
      <section id="LiveStreamings_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col lg={3} md={6} xs={12}>
              <FormControl
                type="text"
                placeholder="Search Live Streamings"
                className="me-2"
                value={searchItem}
                onChange={handleSearchCategory}
              />
            </Col>
            <Col lg={2} md={6} xs={12}>
              <Form.Group>
                <ReactSelect
                  placeholder="Status"
                  onChange={handleStatusFilter}
                  options={statusOptions}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12}>
              <Dropdown autoClose={"outside"} className="w-100">
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setStartDate(new Date());
                        setEndDate(new Date());
                        getAllLiveStreaming();
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {tableData && (
              <Col lg={1} md={6} xs={12}>
                <CSVLink
                  data={csvData}
                  filename={"LiveStreaming.csv"}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </CSVLink>
              </Col>
            )}
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default LiveStreamings;

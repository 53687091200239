import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Layout from "../../../../layouts/admin/Layout";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import {
  getRequest,
  postRequestForm,
  putRequest,
} from "../../../../helper/api";
import { getItemFromLocalStorage } from "../../../../helper/helper";
export default function GenerateShortStoryCardPreview() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    product,
    title,
    category,
    article,
    subCategory,
    user,
    status,
    featuredImage,
    hashtags,
  } = state || {};
  // Verifying all state properties
  useEffect(() => {
    if (
      !product ||
      !title ||
      !category ||
      !article ||
      !subCategory ||
      !user ||
      !status ||
      !featuredImage
    ) {
      console.error("Missing state information", { product, ...state });
      navigate(-1);
    } else {
      console.log("All state information is present", { product, ...state });
    }
  }, [product, state, navigate]);

  const [loading, setLoading] = useState(false);

  const formatString = (input) => {
    return input
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  // return a promise that resolves with a File instance
  function urltoFile(url, filename = "image.png", mimeType = "image/png") {
    if (url.startsWith("data:")) {
      var arr = url.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], filename, { type: mime || mimeType });
      return Promise.resolve(file);
    }
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }
  const addShortStoryHandler = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append("article", article);
      formData.append("productId", product && product._id);
      formData.append("categoryId", category && category._id);
      formData.append("subCategoryId", subCategory?._id);
      formData.append("userId", user && user._id);
      formData.append("hashtagArray", JSON.stringify(hashtags));
      const blob = await urltoFile(featuredImage);
      formData.append("featuredImage", blob);
      const token = getItemFromLocalStorage("TOKEN");
      console.log("formData", Object.fromEntries(formData));

      const { result, error } = await postRequestForm(
        "/api/secure/post/create-post-for-admin",
        token,
        formData
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success("Post Created", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/Posts");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <section className="select_background py-5">
        <Container>
          <h1 className="text-center">Preview</h1>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Row className="pt-3 justify-content-center">
              <Col lg={7} md={7} xs={12} className="text-center">
                <div className="d-flex flex-column my-3">
                  <div className="d-flex justify-content-center">
                    <div className="flex-grow-1 justify-content-start d-flex">
                      <h5>
                        <b>Title:</b>
                      </h5>
                    </div>
                    <div className="flex-grow-1 justify-content-end d-flex">
                      <p>{title}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="flex-grow-1 justify-content-start d-flex">
                      <h5>
                        <b>Product:</b>
                      </h5>
                    </div>
                    <div className="flex-grow-1 justify-content-end d-flex">
                      <p>{product?.name}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="flex-grow-1 justify-content-start d-flex">
                      <h5>
                        <b>Category:</b>
                      </h5>
                    </div>
                    <div className="flex-grow-1 justify-content-end d-flex">
                      <p>{category?.name}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="flex-grow-1 justify-content-start d-flex">
                      <h5>
                        <b>Sub Category:</b>
                      </h5>
                    </div>
                    <div className="flex-grow-1 justify-content-end d-flex">
                      <p>{subCategory?.name}</p>
                    </div>
                  </div>
                </div>
                <Image src={state?.featuredImage} fluid />
              </Col>
              <Col lg={6} md={6} xs={12} className="mt-5">
                <Link
                  to={`/addPost/generate-card`}
                  state={{
                    title,
                    article,
                    category,
                    product,
                    subCategory,
                    user,
                    status,
                    hashtags,
                    featuredImage,
                  }}
                  className="btn-default-3"
                >
                  Back
                </Link>
              </Col>
              <Col lg={6} md={6} xs={12} className="text-end mt-5">
                <Button
                  onClick={addShortStoryHandler}
                  className="btn-default-2 "
                >
                  Submit
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </Layout>
  );
}

import toggler from "../../assets/img/toggler.png";
import blog from "../../assets/img/blog.png";
import images from "../../assets/img/images.png";
import audios from "../../assets/img/audios.png";
import videos from "../../assets/img/videos.png";
import stories from "../../assets/img/stories.png";
import StarmarkGold from "../../assets/img/Star_icon.png";

const AdminImages = {
  toggler,
  blog,
  images,
  audios,
  videos,
  stories,
  StarmarkGold,
};
export default AdminImages;

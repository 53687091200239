import { convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Image, Ratio } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";
import { toast } from "react-toastify";
import { getRequest, postRequestForm } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Layout from "../../../layouts/admin/Layout";
import { useDropzone } from "react-dropzone";

import draftToHtml from "draftjs-to-html";
import Loader from "../../../components/Loader";
import DateTimePicker from "react-datetime-picker";
import { useNavigate } from "react-router";
import PublicImages from "../../../constants/public/images";
import { AsyncPaginate } from "react-select-async-paginate";

const SendEmailNotification = () => {
  const navigate = useNavigate();
  const [featuredFile, setFeaturedFile] = useState([]);
  const [subject, setSubject] = useState("");
  const [editor, setEditor] = useState();
  const [topicOfInterests, setTopicOfInterests] = useState();
  const [topicOfInterest, setTopicOfInterest] = useState();
  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const [pending, setPending] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    accept: {
      "image/*": [],
      "video/mp4": [".mp4", ".MP4"],
      "audio/*": [],
      "application/pdf": [".pdf"],
      // "application/msword": [".doc", ".docx"],
    },
    onDrop: (acceptedFiles) => {
      setFeaturedFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const onChangeEditorHandle = (editorState) => {
    setEditor(editorState);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setPending(true);

      // Prepare the data to be sent
      const formData = new FormData();
      if (subject) {
        formData.append("subject", subject);
      }
      if (editor?.getCurrentContent()) {
        formData.append(
          "message",
          draftToHtml(convertToRaw(editor.getCurrentContent()))
        );
      } else {
        formData.append("message", "");
      }
      if (featuredFile && featuredFile?.length > 0) {
        featuredFile.map((file) => {
          formData.append("attachments", file);
        });
      }
      if (startDate) {
        formData.append("schedule", startDate.toISOString());
      }
      if (user || topicOfInterest) {
        if (topicOfInterest?.length) {
          topicOfInterest.forEach((topicOfInterest) => {
            formData.append("topicOfInterests[]", topicOfInterest?.value);
          });
        }
        if (user?.length) {
          user.forEach((user) => {
            formData.append("recipients[]", user?.email);
          });
        }
      } else {
        toast.error("Please select atleast one user or topic of interest", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        "/api/secure/emailNotification/compose-email",
        token,
        formData
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        toast.success("Email Notification Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/EmailNotifications");
      }
    } catch (error) {
      console.log("Create Email Notification APi error", error.message);
    } finally {
      setPending(false);
    }
  };

  const fetchUsers = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/user/get-all`, token, {
        page: page,
        search: search,
      });
      if (response?.result?.status === 200) {
        const currentPage = response?.result?.data?.currentPage;
        const totalPages = response?.result?.data?.totalPages;

        if (currentPage === totalPages) {
          return {
            options: response?.result?.data?.users,
            hasMore: false,
          };
        } else {
          return {
            options: response?.result?.data?.users,
            hasMore: response?.result?.data?.users?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      }
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };
  const getAllTopicOfIntrests = async (query) => {
    try {
      // setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/topicOfInterest/get-all-topic-of-interest`,
        token,
        {
          search: query,
        }
      );

      const mappedOptions = response?.result?.data?.topicOfInterests?.map(
        (option) => ({
          value: option._id,
          label: option.name,
          // label: `${option.name} (${option.parentCategoryId?.name}) {${option.parentCategoryId?.masterProductId?.name}}`,
        })
      );

      setTopicOfInterests(mappedOptions);
    } catch (error) {
      console.log("Get All Topic Of Interest Error", error);
    } finally {
      // setPending(false);
    }
  };

  const handleChangeUsers = (selectedOptions) => {
    setUser(selectedOptions);
  };
  const handleChangeTopicOfInterests = (selectedOptions) => {
    setTopicOfInterest(selectedOptions);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    getAllTopicOfIntrests();
  }, []);

  return (
    <Layout>
      <Container>
        {pending ? (
          <Loader loading={pending} />
        ) : (
          <div className="row mt-5">
            <div className="col-md-12">
              <h2 className="text-center mb-5">Compose Email</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="subject">
                  <Form.Label>Email Subject</Form.Label>
                  <Form.Control
                    type="text"
                    value={subject}
                    required
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                  <Form.Label>Email Message</Form.Label>

                  <Editor
                    editorState={editor}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={onChangeEditorHandle}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Date To Send</Form.Label>
                  <br />
                  <DateTimePicker
                    onChange={handleStartDate}
                    value={startDate}
                    format="d-MM-y h:mm:ss a"
                    minDate={new Date()}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="userList">
                  <Form.Label>Users</Form.Label>
                  {/* <Select
                    options={users}
                    isMulti
                    onChange={handleChangeUsers}
                    onInputChange={(query) => {
                      getAllUsers(query);
                    }}
                  /> */}
                  <AsyncPaginate
                    value={user ? user : ""}
                    isMulti
                    loadOptions={fetchUsers}
                    getOptionLabel={(e) =>
                      `${e.firstName} ${e.lastName} (${e.email})`
                    }
                    getOptionValue={(e) => e.email}
                    onChange={handleChangeUsers}
                    placeholder="Select Users"
                    isClearable
                    loadingMessage={() => "Loading..."}
                    noOptionsMessage={() => "No User Found"}
                    additional={{
                      page: 1,
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="userList">
                  <Form.Label>Topic Of Intrests</Form.Label>
                  <Select
                    options={topicOfInterests}
                    isMulti
                    onChange={handleChangeTopicOfInterests}
                    onInputChange={(query) => {
                      getAllTopicOfIntrests(query);
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Add Attachments (optional)</Form.Label>
                  {/* <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                      Please upload .MP4 format for video, .MP3 format for
                      audio, .PDF format for DOCS/Attachments and any type for
                      an image.
                    </p>
                  </div> */}
                  {featuredFile.length == 0 && (
                    <div className="mt-1 dot">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <div className="d-flex">
                          <div className="p-2">
                            <img
                              src={PublicImages.backup}
                              className="img-fluid"
                              alt=""
                            />
                            <input {...getInputProps()} />
                          </div>
                          <div className="p-2">
                            <h5>Drop a file here or click to upload</h5>
                            <p>
                              Please upload .MP4 format for video, .MP3 format
                              for audio, .PDF format for DOCS/Attachments and
                              any type for an image.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="attachmetsPreview">
                    {featuredFile.map((file, key) => {
                      if (file.hasOwnProperty("data_url")) {
                        const fileExtension = file?.data_url?.split(".").pop(); // Get the file extension
                        if (fileExtension === "mp4") {
                          return (
                            <Ratio aspectRatio="16x9" key={key}>
                              <video controls>
                                <source
                                  src={file.data_url}
                                  type="video/mp4" // Hardcoded video type
                                />
                              </video>
                            </Ratio>
                          );
                        } else if (fileExtension === "mp3") {
                          return (
                            <audio controls key={key}>
                              <source
                                src={file.data_url}
                                key={key}
                                type="audio/mp3" // Hardcoded audio type
                              />
                            </audio>
                          );
                        } else if (fileExtension === "pdf") {
                          return (
                            <embed
                              src={file.data_url}
                              type="application/pdf"
                              key={key}
                              width="100%"
                              height="200"
                            />
                          );
                        } else {
                          return (
                            <img
                              key={key}
                              src={file.data_url}
                              className="img-fluid"
                              height="200"
                            />
                          );
                        }
                      } else {
                        if (file.type.startsWith("video/")) {
                          return (
                            <div key={key}>
                              <Button
                                variant="danger"
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                              >
                                Remove
                              </Button>
                              <Ratio aspectRatio="16x9">
                                <video controls height="200">
                                  <source src={file.preview} type={file.type} />
                                </video>
                              </Ratio>
                            </div>
                          );
                        } else if (file.type.startsWith("audio/")) {
                          return (
                            <div key={key}>
                              <Button
                                variant="danger"
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                              >
                                Remove
                              </Button>
                              <audio controls>
                                <source src={file.preview} type={file.type} />
                              </audio>
                            </div>
                          );
                        } else if (file.type === "application/pdf") {
                          return (
                            <div key={key}>
                              <Button
                                variant="danger"
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                              >
                                Remove
                              </Button>
                              <embed
                                src={file.preview}
                                type="application/pdf"
                                width="100%"
                                height="200"
                              />
                            </div>
                          );
                        } else if (file.type === "application/msword") {
                          // Handle DOC/DOCX files
                          return (
                            <div key={key}>
                              <Button
                                variant="danger"
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                              >
                                Remove
                              </Button>

                              <a
                                href={file.preview}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            </div>
                          );
                        } else {
                          return (
                            <div key={key}>
                              <Button
                                variant="danger"
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                              >
                                Remove
                              </Button>

                              <img
                                src={file.preview}
                                className="img-fluid"
                                height="200"
                                key={key}
                                // onLoad={() => {
                                //   URL.revokeObjectURL(file.preview);
                                // }}
                              />
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                </Form.Group>
                <div className="text-center my-5">
                  <Button variant="primary" type="submit">
                    Send Email
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default SendEmailNotification;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { getRequest, postRequestForm } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Layout from "../../../layouts/admin/Layout";

const AddSubCategory = () => {
  const [name, setName] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [wordLimit, setWordLimit] = useState("");
  const [adminCategory, setAdminCategory] = useState(false);
  const [featuredCategory, setFeaturedCategory] = useState(false);
  const [status, setStatus] = useState();
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()
  const getAllCategories = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/category/get-active-categories`,
        token
      );

      const mappedOptions = response?.result?.data?.categories.map(
        (option) => ({
          value: option._id,
          label: `${option.name} (${option.masterProductId.name})`,
        })
      );
      setCategories(mappedOptions);
    } catch (error) {
      console.log("Get All Categories Error", error);
    } finally {
      setLoading(false);
    }
  };

  const categoryChange = (selectedOption) => {
    setCategory(selectedOption);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const addSubCategoryHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        "/api/secure/category/create-sub-category",
        token,
        {
          name,
          parentCategoryId:
            typeof category === "object" ? category.value : category,
          status: typeof status === "object" ? status.value : status,
          metaTitle,
          metaDescription,
          wordLimit,
          adminCategory,
          isFeatured:featuredCategory,
        }
      );

      if (response?.result?.status === 200 || response?.result?.status === 201) {
        toast.success("Sub Category Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/subCategories");
      }
    } catch (error) {
      console.log("Create Sub Category APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllCategories();
  }, []);
  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={addSubCategoryHandler}
            >
              <Row>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="Name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Word Limit</Form.Label>
                    <Form.Control
                      name="wordLimit"
                      type="number"
                      placeholder="Word Limit"
                      required
                      value={wordLimit}
                      onChange={(e) => setWordLimit(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      name="metaTitle"
                      type="text"
                      placeholder="Meta Title"
                      required
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      name="metaDescription"
                      type="text"
                      placeholder="Meta Description"
                      required
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <ReactSelect
                      placeholder="Select Category"
                      onChange={categoryChange}
                      options={categories}
                      value={category ? category : ""}
                      isClearable
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <ReactSelect
                      placeholder="Select Status"
                      onChange={statusChange}
                      options={statusOptions}
                      value={status ? status : ""}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Admin Category</Form.Label>
                    <div className="form-check">
                      <Form.Check
                        type="checkbox"
                        name="adminCategory"
                        id="adminCategory"
                        label="Admin Category"
                        checked={adminCategory} // Use the checked prop to bind the state to the checkbox
                        onChange={() => {
                          setAdminCategory(
                            (prevAdminCategory) => !prevAdminCategory
                          ); // Toggle the state
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group>
                    <Form.Label>Featured Category</Form.Label>
                    <div className="form-check">
                      <Form.Check
                        type="checkbox"
                        name="featuredCategory"
                        id="featuredCategory"
                        label="Featured Category"
                        checked={featuredCategory} // Use the checked prop to bind the state to the checkbox
                        onChange={() => {
                          setFeaturedCategory(
                            (prevFeaturedCategory) => !prevFeaturedCategory
                          ); // Toggle the state
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col xl={12} sm={12} className="text-center my-5">
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default AddSubCategory;

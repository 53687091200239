import { Container, Row, Col, Form } from "react-bootstrap";
import PublicImages from "../../constants/public/images";
import { useState, useEffect } from "react";
import { getItemFromLocalStorage } from "../../helper/helper";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { getRequest, putRequest } from "../../helper/api";

function AccountSettings({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(false);
  const [tagMe, setTagMe] = useState(false);
  const [notification, setNotification] = useState(false);
  const saveUserPrefrences = async (tagMe, notification) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const user = JSON.parse(getItemFromLocalStorage("USER"));
      const { result } = await putRequest(
        `/api/secure/userPreferences/${user._id}`,
        token,
        {
          tagMe,
          notification,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        toast.success("Security Setting Saved", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Update User Prefrences APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const getUserPrefrences = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const user = JSON.parse(getItemFromLocalStorage("USER"));
      const { result } = await getRequest(
        `/api/secure/userPreferences/`,
        token,
        {
          userId: user._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { tagMe, allowNotification } = result?.data?.userPrefrences;
        console.log("tagMe, allowNotification",tagMe, allowNotification);
        setTagMe(tagMe);
        setNotification(allowNotification);
      }
    } catch (error) {
      console.log("Get User Prefrences APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   saveUserPrefrences();
  // }, [tagMe, notification]);

  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getUserPrefrences();
    }
  }, [currentlyActive, actualComponent]);
  return (
    <Container>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          <Row className="mb-2 align-items-center">
            <Col lg={7}>
              <div className="d-flex gap-3 align-items-center form-check form-switch ps-0">
                <img src={PublicImages.alternate_email} alt="" />
                <div>
                  <p className="m-0">Anyone can tag me</p>
                  <span className="allow">
                    Allow/disallow anyone on Womanii to tag you
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={5} className="d-flex justify-content-end">
              <div className="form-check form-switch">
                <Form.Check
                  type="switch"
                  id="flexSwitchCheckChecked"
                  className="color"
                  checked={tagMe} // Use the checked prop to bind the state to the checkbox
                  onChange={() => {
                    setTagMe((prevTagMe) => !prevTagMe); // Toggle the state
                    saveUserPrefrences(!tagMe, notification);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="pt-2 top align-items-center">
            <Col lg={7}>
              <div className="d-flex gap-3 align-items-center form-check form-switch ps-0">
                <img src={PublicImages.notifications} alt="" />
                <div>
                  <p className="m-0">Notification</p>
                  <span className="allow">Allow/disallow notification</span>
                </div>
              </div>
            </Col>
            <Col lg={5} className="d-flex justify-content-end">
              <div className="form-check form-switch">
                <Form.Check
                  type="switch"
                  id="flexSwitchCheckChecked"
                  className="color"
                  checked={notification} // Use the checked prop to bind the state to the checkbox
                  onChange={() => {
                    setNotification((prevNotification) => !prevNotification); // Toggle the state
                    saveUserPrefrences(tagMe, !notification);
                  }}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default AccountSettings;

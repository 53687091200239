import { io } from "socket.io-client";
import { getItemFromLocalStorage } from "./helper";
import { baseURL } from "./api";

let socket = null; // Variable to store the socket instance
let isConnected = false; // Flag to track connection status

// Function to establish a new connection
const connectToSocket = async () => {
  try {
    // Check if the user is already connected
    if (!isConnected) {
      const token = await getItemFromLocalStorage("TOKEN");
      if (token) {
        // Establish a new connection
        socket = io(baseURL, {
          transports: ["websocket"],
          auth: {
            token,
          },
        });

        // Event handler for successful connection
        socket.on("connect", () => {
          isConnected = true; // Update the connection status
          console.log("Connected to server");
        });

        // Event handler for connection error
        socket.on("connect_error", (error) => {
          console.error("Connection error:", error?.message);
        });
      }
    }
  } catch (error) {
    console.error("Error while connecting to socket:", error);
  }
};

// Export the socket instance
export { socket, connectToSocket };

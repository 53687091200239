import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  Breadcrumb,
  Col,
  Container,
  Nav,
  Row,
  Tab,
  Card,
  Image,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import ImageCard from "../../components/public/Pages/ImageCard";
import { getRequest, postRequestForm, uploadURL } from "../../helper/api";
import { getItemFromLocalStorage } from "../../helper/helper";
import Layout from "../../layouts/public/Layout";
import PublicImages from "../../constants/public/images";
import { Swiper, SwiperSlide } from "swiper/react";
import Blog from "./../../components/public/Pages/Blog";
import Story from "./../../components/public/Pages/Story";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import * as ChallengesInfiniteScroll from "react-infinite-scroller";
import { TRUE } from "sass";
export default function Category() {
  const scrollParentRef = useRef();
  const navigate = useNavigate();
  const { product, category } = useParams();
  if (!product) {
    navigate(-1);
  }
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subcategory = queryParams.get("subcategory");
  const [loading, setLoading] = useState(false);
  const [categoryPosts, setCategoryPosts] = useState([]);
  const [subCategoryPosts, setSubCategoryPosts] = useState([]);
  const [allData, setAllData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [allDataLength, setAllDataLength] = useState(0);
  const [subCategoryDataLength, setSubCategoryDataLength] = useState(0);
  const [categoryPostLoading, setCategoryPostLoading] = useState(true);
  const [categoryPostEnded, setCategoryPostEnded] = useState(true);
  const [subCategoryPostLoading, setSubCategoryPostLoading] = useState(true);
  const [subCategoryPostEnded, setSubCategoryPostEnded] = useState(true);
  const [categoryError, setCategoryError] = useState();
  const [subCategoryError, setSubCategoryError] = useState();
  const [categoryPostsPage, setCategoryPostsPage] = useState(1);
  const [subCategoryPostsPage, setSubCategoryPostsPage] = useState(1);
  const [recent, setRecent] = useState(true);
  const [popular, setPopular] = useState(false);
  const [activeTab, setActiveTab] = useState("all"); // Default active tab
  const [subCategories, setSubCategories] = useState([]);
  const [challesges, setChallesges] = useState([]);
  const [isBookmark, setIsBookmark] = useState({});
  const [isLiked, setIsLiked] = useState({});
  const [following, setFollowing] = useState({});
  const [challengesLoading, setChallengesLoading] = useState(true);
  const [challengesError, setChallengesError] = useState();
  const [challengesLength, setChallenglesLength] = useState(0);
  const [challengesPage, setChallengesPage] = useState(1);
  const [challengesEnded, setChallengesEnded] = useState(true);
  const [challengesData, setChallengesData] = useState([]);

  const loadChallenges = async (product, page = 1) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/challenge/get-challenges-by-product-type`,
        token,
        {
          productSlug: product,
          page,
          limit: 10,
        }
      );
      // if (res.result.status === 200 || res.result.status === 201) {
      //   setChallesges(res.result.data.challenges);
      // }

      if (result?.status === 200 || result?.status === 201) {
        setChallengesError("");
        console.log("result?.data.challenges", result?.data.challenges);
        if (page === 1) {
          setChallengesData(result?.data.challenges);
          // Inside the function where you load more data
          setChallenglesLength(result?.data.challenges);
        } else {
          setChallengesData((prevData) => [
            ...prevData,
            ...result?.data.challenges,
          ]);
          // Inside the function where you load more data
          setChallenglesLength(allData.length);
        }
        if (result?.data.currentPage === result?.data.totalPages) {
          setChallengesEnded(false);
        }
        setChallengesPage((prevPage) => prevPage + 1);
      } else if (error?.response?.status === 400) {
        setChallengesError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChallengesLoading(false);
    }
  };
  const loadSubCategory = async (product, category) => {
    try {
      setLoading(true);
      const user = getItemFromLocalStorage("USER");
      let userId = "";
      if (user) {
        userId = JSON.parse(user)._id;
      }
      const { result, error } = await getRequest(
        `/api/secure/category/get-all-sub-categories-user-topic-of-interest-based`,
        "",
        {
          productSlug: product,
          categorySlug: category,
          userId,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        setSubCategories(result?.data?.subCategories);
        loadChallenges(product, 1);
      } else if (error?.response?.status === 400) {
        navigate("/404");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const loadPostsByCategory = async (
    product,
    category,
    page = 1,
    recent = true,
    popular = false
  ) => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/post/posts-by-category`,
        "",
        {
          productSlug: product,
          categorySlug: category,
          recent,
          popular,
          page,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        setCategoryError("");
        setCategoryPosts(result?.data);
        if (page === 1) {
          setAllData(result?.data.posts);
          // Inside the function where you load more data
          setAllDataLength(result?.data.posts);
        } else {
          setAllData((prevData) => [...prevData, ...result?.data.posts]);
          // Inside the function where you load more data
          setAllDataLength(allData.length);
        }
        if (result?.data.currentPage === result?.data.totalPages) {
          setCategoryPostEnded(false);
        }
        setCategoryPostsPage((prevPage) => prevPage + 1);
      } else if (error?.response?.status === 400) {
        setCategoryError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCategoryPostLoading(false);
    }
  };
  const loadPostsBySubCategory = async (
    subCategory,
    page = 1,
    recent = true,
    popular = false
  ) => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/post/posts-by-sub-category`,
        "",
        {
          subCategorySlug: subCategory,
          recent,
          popular,
          page,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        setSubCategoryError("");
        setSubCategoryPosts(result?.data);
        if (page === 1) {
          setSubCategoryData(result?.data.posts);
          // Inside the function where you load more data
          setSubCategoryDataLength(result?.data.posts);
        } else {
          setSubCategoryData((prevData) => [
            ...prevData,
            ...result?.data.posts,
          ]);
          // Inside the function where you load more data
          setSubCategoryDataLength(subCategoryData.length);
        }
        if (result?.data.currentPage === result?.data.totalPages) {
          setSubCategoryPostEnded(false);
        }
        setSubCategoryPostsPage((prevPage) => prevPage + 1);
      } else if (error?.response?.status === 400) {
        setSubCategoryError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubCategoryPostLoading(false);
    }
  };
  const handleBookmarkPost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/bookmark-post`,
        token,
        {
          postIdToToggle: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsBookmark((prev) => {
          let isBookmarked = false;
          const newState = { ...prev };
          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isBookmarked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isBookmarked = true;
          }
          if (isBookmarked) {
            toast.success("Post has been Bookmarked!", {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(
              `Post has been ${
                isBookmarked ? "Bookmarked!" : "Un-Bookmarked!"
              }!`,
              {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              }
            );
          }
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleLikePost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/like-post`,
        token,
        {
          postId: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsLiked((prev) => {
          const newState = { ...prev };
          let isLiked = false;

          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isLiked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isLiked = true;
          }

          // Update the like count in the corresponding post data
          setAllData((prevData) =>
            prevData.map((item) =>
              item._id === postId
                ? {
                    ...item,
                    likeCount: isLiked
                      ? item.likeCount + 1
                      : item.likeCount - 1,
                  }
                : item
            )
          );
          if (isLiked) {
            toast.success(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleTabSelect = (subCategory) => {
    setActiveTab(subCategory);
  };
  const sortByHandler = (changer) => {
    if (changer) {
      setRecent((prevRecent) => !prevRecent);
      setPopular((prevPopular) => !prevPopular);
    } else {
      setRecent((prevRecent) => !prevRecent);
      setPopular((prevPopular) => !prevPopular);
    }
  };
  const formatString = (input) => {
    return input
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  useEffect(() => {
    loadSubCategory(product, category);
    // loadPostsByCategory(category);
  }, [product, category]);
  useEffect(() => {
    setCategoryPostsPage(1);
    setCategoryPostLoading(true);
    setCategoryPostEnded(true);
    setAllData([]);
    setSubCategoryPostEnded(true);
    setSubCategoryPostsPage(1);
    setSubCategoryPostLoading(true);
    setSubCategoryData([]);
    setChallengesLoading(true);
    setChallengesPage(1);
    setChallengesEnded(true);
    setChallengesData([]);
    if (subcategory) {
      if (subcategory !== "all" && subcategory !== "challenges") {
        setActiveTab(subcategory);
        loadPostsBySubCategory(subcategory, recent, popular);
      } else if (subcategory === "challenges") {
        setActiveTab("challenges");
        loadChallenges(product, 1);
      } else {
        console.log("subcategory", "all");
        setActiveTab("all");
        loadPostsByCategory(product, category, 1, recent, popular);
      }
    } else {
      setActiveTab("all");
      loadPostsByCategory(product, category, 1, recent, popular);
    }
  }, [subcategory, recent, popular]);

  const followUserHandler = async (userId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/follow-user`,
        token,
        {
          userIdToFollow: userId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setFollowing((prev) => {
          const newState = { ...prev };
          newState[userId] = true;
          toast.success("User has been added to Followings!", {
            position: "top-center",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
        setFollowing({});
      }

      console.log(result);
    } catch (error) {
      console.log(error);
      setFollowing({});
    }
  };
  const unfollowUserHandler = async (userId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/unfollow-user`,
        token,
        {
          userIdToUnfollow: userId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setFollowing((prev) => {
          const newState = { ...prev };

          if (newState[userId]) {
            delete newState[userId];
          }
          toast.error("User has been removed from Followings!", {
            position: "top-center",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const getUserLikedAndBookmarkedPosts = useMemo(() => {
    return async () => {
      try {
        setLoading(true);
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result, error } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            const { userPrefrences } = result?.data;
            const { likedPost, bookmarks, following } = userPrefrences;
            setIsLiked((prev) => ({
              ...prev,
              ...likedPost.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
            setIsBookmark((prev) => ({
              ...prev,
              ...bookmarks.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
            setFollowing((prev) => ({
              ...prev,
              ...following.reduce(
                (acc, userId) => ({ ...acc, [userId]: true }),
                {}
              ),
            }));
          }
        } else {
          setIsLiked({});
          setIsBookmark({});
          setFollowing({});
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
        setIsLiked({});
        setIsBookmark({});
        setFollowing({});
      } finally {
        setLoading(false); // Ensure isLoading is set to false
      }
    };
  }, []);
  useEffect(() => {
    getUserLikedAndBookmarkedPosts();
  }, [getUserLikedAndBookmarkedPosts]);
  return (
    <Layout>
      <section id="listing_main">
        <div className="listing_sec" ref={scrollParentRef}>
          <Container>
            {loading ? (
              <Loader loading={loading} />
            ) : (
              <>
                <Tab.Container
                  id="myTab"
                  activeKey={activeTab}
                  onSelect={handleTabSelect}
                >
                  <Nav variant="tabs" as="ul">
                    <Nav.Item as="li">
                      <Nav.Link
                        as={Link}
                        eventKey={"all"}
                        className="btn"
                        to={`/${product}/${category}/?subcategory=all`}
                      >
                        All
                      </Nav.Link>
                    </Nav.Item>
                    {subCategories.map(({ _id, name, slug }, index) => (
                      <Nav.Item key={`${slug}-${index}`} as="li">
                        <Nav.Link
                          as={Link}
                          className="btn"
                          eventKey={slug}
                          to={`/${product}/${category}/?subcategory=${slug}`}
                        >
                          {name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                    <Nav.Item as="li">
                      <Nav.Link
                        as={Link}
                        className="btn"
                        eventKey={"challenges"}
                        to={`/${product}/challenges`}
                      >
                        Challenges
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <hr />
                  {activeTab !== "challenges" && (
                    <>
                      <div className="left-text">
                        <Breadcrumb>
                          <Link className="breadcrumb-item" to="/">
                            Home
                          </Link>
                          <Link className="breadcrumb-item" to={`/${product}`}>
                            {formatString(product)}
                          </Link>
                          {/* {activeTab !== "all" && activeTab !== "challenges" ? (
                            <Link
                              className="breadcrumb-item"
                              to={`/${product}/${category}`}
                            >
                              {category.toLocaleUpperCase()}
                            </Link>
                          ) : (
                            
                          )} */}
                          <Breadcrumb.Item active>{category}</Breadcrumb.Item>
                          {/* {activeTab !== "all" &&
                            activeTab !== "challenges" && (
                              <Breadcrumb.Item active>
                                {formatString(activeTab)}
                              </Breadcrumb.Item>
                            )} */}
                        </Breadcrumb>
                      </div>
                      <div className="mid-tab">
                        <Row>
                          <Col lg={6} md={6} xs={12}>
                            <Link
                              to={`/${product}/upload`}
                              className="btn btn-default text-decoration-none text-capitalize"
                            >
                              {product === "videos" ||
                              product === "audio-station" ||
                              product === "image-corner"
                                ? `Upload Your ${formatString(product)}`
                                : `Write Your ${formatString(product)}`}
                            </Link>
                          </Col>
                          <Col
                            lg={6}
                            md={6}
                            xs={12}
                            className="d-flex justify-content-end align-items-center"
                          >
                            <div className="sortByTogglerCover">
                              <p>Sort by:</p>
                              <div className="sortByToggler">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    sortByHandler(true);
                                  }}
                                  className={`${recent ? "active" : ""}`}
                                >
                                  RECENT
                                </a>
                                <span className="seperator"></span>
                                <a
                                  href="#"
                                  className={`${popular ? "active" : ""}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    sortByHandler(false);
                                  }}
                                >
                                  POPULAR
                                </a>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  <Tab.Content className="my-5">
                    <Tab.Pane eventKey={"all"}>
                      {categoryPostLoading ? (
                        <Loader loading={true} />
                      ) : (
                        <div>
                          {categoryError ? (
                            categoryError
                          ) : (
                            <InfiniteScroll
                              dataLength={allDataLength}
                              next={() => {
                                loadPostsByCategory(
                                  product,
                                  category,
                                  categoryPostsPage,
                                  recent,
                                  popular
                                );
                              }}
                              inverse={false}
                              hasMore={categoryPostEnded}
                              loader={<Loader loading={true} />}
                              scrollThreshold={0.9}
                              endMessage={
                                <p style={{ justifyContent: "center" }}>
                                  <b>Yay! You've seen it all!</b>
                                </p>
                              }
                            >
                              <Row className="m-0">
                                {formatString(product)?.includes("Blog")
                                  ? allData.map((item, index) => (
                                      <Blog
                                        key={index}
                                        blogImage={
                                          uploadURL + item?.featuredImage
                                        }
                                        authorImage={
                                          item.userId?.profileImage
                                            ? uploadURL +
                                              item.userId.profileImage
                                            : ""
                                        }
                                        cat={`${item.subCategoryId?.name}`}
                                        authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                                        description={item.article
                                          .replace(/<[^>]*>/g, "")
                                          ?.substring(0, 100)}
                                        views={item.viewCount}
                                        comments={item.comments}
                                        likes={item.likeCount}
                                        title={item.title}
                                        date={item.createdAt}
                                        username={item.userId.username}
                                        link={`${product}/${category}/${item.slug}`}
                                        bookmarkHandler={() =>
                                          handleBookmarkPost(item._id)
                                        }
                                        isBookmarked={isBookmark[item._id]}
                                        likeHandler={() =>
                                          handleLikePost(item._id)
                                        }
                                        isLiked={isLiked[item._id]}
                                        isWinner={item.isWinner}
                                        challengeLink={`${product}/challenges/${item.challengeId?.slug}`}
                                        isStarmarked={item.isStarmarked}
                                      />
                                    ))
                                  : formatString(product)?.includes("Stories")
                                  ? allData.map((item, index) => (
                                      <Story
                                        key={index}
                                        authorName={`${item.userId.firstName}`}
                                        image={uploadURL + item?.featuredImage}
                                        username={item.userId.username}
                                        authorImage={
                                          item.userId?.profileImage
                                            ? uploadURL +
                                              item.userId.profileImage
                                            : ""
                                        }
                                        link={`${product}/${category}/${item.slug}`}
                                        isLiked={isLiked[item._id]}
                                        bookmarkHandler={() =>
                                          handleBookmarkPost(item._id)
                                        }
                                        title={item.title}
                                        comments={item.comments}
                                        likes={item.likeCount}
                                        isBookmarked={isBookmark[item._id]}
                                        isFollowed={
                                          following[item?.userId?._id]
                                        }
                                        likeHandler={() =>
                                          handleLikePost(item._id)
                                        }
                                        followUserHandler={() =>
                                          followUserHandler(item?.userId?._id)
                                        }
                                        unfollowUserHandler={() =>
                                          unfollowUserHandler(item?.userId?._id)
                                        }
                                        isWinner={item.isWinner}
                                        challengeLink={`${product}/challenges/${item.challengeId?.slug}`}
                                        isStarmarked={item.isStarmarked}
                                      />
                                    ))
                                  : allData.map((item, index) => (
                                      <ImageCard
                                        image={uploadURL + item?.featuredImage}
                                        authorImage={
                                          item.userId?.profileImage
                                            ? uploadURL +
                                              item.userId.profileImage
                                            : ""
                                        }
                                        subCatName={`${item.subCategoryId?.name}`}
                                        productSlug={`${item?.productId?.slug}`}
                                        catSlug={`${item?.categoryId?.slug}`}
                                        subCatSlug={`${item.subCategoryId?.slug}`}
                                        authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                                        views={item.viewCount}
                                        comments={item.comments}
                                        likes={item.likeCount}
                                        title={item.title}
                                        date={item.createdAt}
                                        username={item.userId.username}
                                        link={`/${product}/${category}/${item.slug}`}
                                        bookmarkHandler={() =>
                                          handleBookmarkPost(item._id)
                                        }
                                        isBookmarked={isBookmark[item._id]}
                                        likeHandler={() =>
                                          handleLikePost(item._id)
                                        }
                                        isLiked={isLiked[item._id]}
                                        isWinner={item.isWinner}
                                        challengeLink={`${product}/challenges/${item.challengeId?.slug}`}
                                        isStarmarked={item.isStarmarked}
                                      />
                                    ))}
                              </Row>
                            </InfiniteScroll>
                          )}
                        </div>
                      )}
                    </Tab.Pane>
                    {subCategories.map(({ slug }, index) => (
                      <Tab.Pane key={`${slug}-${index}`} eventKey={slug}>
                        {subCategoryPostLoading ? (
                          <Loader loading={true} />
                        ) : (
                          <div>
                            {subCategoryError ? (
                              subCategoryError
                            ) : (
                              <InfiniteScroll
                                dataLength={subCategoryDataLength}
                                next={() => {
                                  loadPostsBySubCategory(
                                    slug,
                                    subCategoryPostsPage,
                                    recent,
                                    popular
                                  );
                                }}
                                inverse={false}
                                hasMore={subCategoryPostEnded}
                                loader={<Loader loading={true} />}
                                scrollThreshold={0.9}
                                endMessage={
                                  <p style={{ justifyContent: "center" }}>
                                    <b>Yay! You've seen it all!</b>
                                  </p>
                                }
                              >
                                <Row className="m-0">
                                  {formatString(product)?.includes("Blog")
                                    ? subCategoryData.map((item, index) => (
                                        <Blog
                                          key={index}
                                          blogImage={
                                            uploadURL + item?.featuredImage
                                          }
                                          authorImage={
                                            item.userId?.profileImage
                                              ? uploadURL +
                                                item.userId.profileImage
                                              : ""
                                          }
                                          cat={`${item.subCategoryId?.name}`}
                                          authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                                          description={item.article
                                            .replace(/<[^>]*>/g, "")
                                            ?.substring(0, 100)}
                                          views={item.viewCount}
                                          comments={item.comments}
                                          likes={item.likeCount}
                                          title={item.title}
                                          date={item.createdAt}
                                          username={item.userId.username}
                                          link={`${product}/${category}/${item.slug}`}
                                          bookmarkHandler={() =>
                                            handleBookmarkPost(item._id)
                                          }
                                          isBookmarked={isBookmark[item._id]}
                                          likeHandler={() =>
                                            handleLikePost(item._id)
                                          }
                                          isLiked={isLiked[item._id]}
                                          isWinner={item.isWinner}
                                          challengeLink={`${product}/challenges/${item.challengeId?.slug}`}
                                          isStarmarked={item.isStarmarked}
                                        />
                                      ))
                                    : formatString(product)?.includes("Stories")
                                    ? subCategoryData.map((item, index) => (
                                        <Story
                                          key={index}
                                          authorName={`${item.userId.firstName}`}
                                          image={
                                            uploadURL + item?.featuredImage
                                          }
                                          username={item.userId.username}
                                          authorImage={
                                            item.userId?.profileImage
                                              ? uploadURL +
                                                item.userId.profileImage
                                              : ""
                                          }
                                          link={`${product}/${category}/${item.slug}`}
                                          isLiked={isLiked[item._id]}
                                          bookmarkHandler={() =>
                                            handleBookmarkPost(item._id)
                                          }
                                          title={item.title}
                                          comments={item.comments}
                                          likes={item.likeCount}
                                          isBookmarked={isBookmark[item._id]}
                                          isFollowed={
                                            following[item?.userId?._id]
                                          }
                                          likeHandler={() =>
                                            handleLikePost(item._id)
                                          }
                                          followUserHandler={() =>
                                            followUserHandler(item?.userId?._id)
                                          }
                                          unfollowUserHandler={() =>
                                            unfollowUserHandler(
                                              item?.userId?._id
                                            )
                                          }
                                          isWinner={item.isWinner}
                                          challengeLink={`${product}/challenges/${item.challengeId?.slug}`}
                                          isStarmarked={item.isStarmarked}
                                        />
                                      ))
                                    : subCategoryData.map((item, index) => (
                                        <ImageCard
                                          image={
                                            uploadURL + item?.featuredImage
                                          }
                                          authorImage={
                                            item.userId?.profileImage
                                              ? uploadURL +
                                                item.userId.profileImage
                                              : ""
                                          }
                                          subCatName={`${item.subCategoryId?.name}`}
                                          productSlug={`${item?.productId?.slug}`}
                                          catSlug={`${item?.categoryId?.slug}`}
                                          subCatSlug={`${item.subCategoryId?.slug}`}
                                          authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                                          views={item.viewCount}
                                          comments={item.comments}
                                          likes={item.likeCount}
                                          title={item.title}
                                          date={item.createdAt}
                                          username={item.userId.username}
                                          link={`/${product}/${category}/${item.slug}`}
                                          bookmarkHandler={() =>
                                            handleBookmarkPost(item._id)
                                          }
                                          isBookmarked={isBookmark[item._id]}
                                          likeHandler={() =>
                                            handleLikePost(item._id)
                                          }
                                          isLiked={isLiked[item._id]}
                                          isWinner={item.isWinner}
                                          challengeLink={`${product}/challenges/${item.challengeId?.slug}`}
                                          isStarmarked={item.isStarmarked}
                                        />
                                      ))}
                                </Row>
                              </InfiniteScroll>
                            )}
                          </div>
                        )}
                      </Tab.Pane>
                    ))}
                    <Tab.Pane eventKey={"challenges"}>
                      <section className="popular_sec bg-white">
                        <Container fluid>
                          {" "}
                          {challengesLoading ? (
                            <Loader loading={true} />
                          ) : (
                            <div>
                              {challengesError ? (
                                challengesError
                              ) : (
                                <ChallengesInfiniteScroll
                                  pageStart={1}
                                  loadMore={() =>
                                    loadChallenges(product, challengesPage)
                                  }
                                  hasMore={challengesEnded}
                                  loader={<Loader loading={true} />}
                                  useWindow={false}
                                  getScrollParent={() =>
                                    scrollParentRef.current
                                  }
                                >
                                  <Row className="m-0">
                                    {challengesData.map(
                                      (
                                        { slug, photo, name, challengeEndDate },
                                        index
                                      ) => (
                                        <Col lg={3} md={6} xs={12} key={index}>
                                          <Card className="card_style_2 border-0 pt-1">
                                            <Link
                                              reloadDocument={true}
                                              to={`/${product}/challenges/${slug}`}
                                              className="text-decoration-none text-dark d-block  w-100"
                                              style={{
                                                cursor: "pointer",
                                                position: "relative",
                                              }}
                                            >
                                              <LazyLoadImage
                                                effect="blur"
                                                src={uploadURL + photo}
                                                className="img-fluid w-100 challengeImage"
                                                wrapperClassName="d-block"
                                                alt=""
                                              />
                                              {challengeEndDate &&
                                                moment(new Date()).isBefore(
                                                  challengeEndDate
                                                ) && (
                                                  <span className="badge badge-primary challenge_live">
                                                    <FontAwesomeIcon
                                                      icon={faCircle}
                                                      color="#fff"
                                                      className="pe-2"
                                                    />
                                                    Live
                                                  </span>
                                                )}

                                              <Card.Body className="text-center">
                                                <Card.Title>
                                                  <p className="text-center forChallenge">
                                                    {name}
                                                  </p>
                                                </Card.Title>
                                              </Card.Body>
                                            </Link>
                                          </Card>
                                        </Col>
                                      )
                                    )}
                                  </Row>
                                </ChallengesInfiniteScroll>
                              )}
                            </div>
                          )}
                        </Container>
                      </section>

                      {/* <section className="popular_sec bg-white">
                        <Container fluid>
                          <h1>
                            <img
                              src={PublicImages.pages}
                              className="img-fluid"
                              alt=""
                            />
                            Challenges
                          </h1>
                          {challesges.length > 0 ? (
                            <Swiper
                              navigation={true} // Show navigation arrows
                              spaceBetween={20} // Space between slides
                              slidesPerView={4} // Number of slides to show
                              loop={true} // Enable loop
                              loopAdditionalSlides={1} // Number of additional slides to loop (1 for circular)
                              autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                              }} // Enable autoplay
                            >
                              {challesges.map(
                                ({ slug, photo, name }, index) => (
                                  <SwiperSlide key={index}>
                                    <Card className="card_style_2 ">
                                      <Link
                                        to={`/${product}/challenges/${slug}`}
                                        className="text-decoration-none text-dark"
                                      >
                                        <Image
                                          src={uploadURL + photo}
                                          fluid
                                          alt=""
                                        />
                                        <Card.Body className="text-center">
                                          <Card.Title>
                                            <p>{name}</p>
                                          </Card.Title>
                                        </Card.Body>
                                      </Link>
                                    </Card>
                                  </SwiperSlide>
                                )
                              )}
                            </Swiper>
                          ) : (
                            <Row>
                              <Col className="text-center" xs={12}>
                                <p>No Challeges Added Yet!</p>
                              </Col>
                            </Row>
                          )}
                        </Container>
                      </section> */}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </>
            )}
          </Container>
        </div>
      </section>
    </Layout>
  );
}

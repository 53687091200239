import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { getRequest, postRequestForm } from "../../helper/api";
import Loader from "../../components/Loader";
import { getItemFromLocalStorage } from "../../helper/helper";

function TopicTab({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(true);
  const [activeButtons, setActiveButtons] = useState([]);
  const [data, setData] = useState([]);

  const updateTopic = async (id) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const user = JSON.parse(getItemFromLocalStorage("USER"));
      console.log(id);
      const { result } = await postRequestForm(
        `/api/secure/userTopicOfInterest/update-user-topic-of-interest`,
        token,
        {
          userId: user._id,
          topicOfInterestId: id,
        }
      );
      if (result?.status === 200) {
        getUserTOI();
      }
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };
  const handleButtonClick = (buttonObject = { name: "", _id: "" }) => {
    // Check if the button is already active
    if (activeButtons.some((item) => item.name === buttonObject.name)) {
      // If active, remove it from the activeButtons array
      setActiveButtons(
        activeButtons.filter((item) => item.name !== buttonObject.name)
      );
    } else {
      // If inactive, add it to the activeButtons array
      setActiveButtons([...activeButtons, buttonObject]);
    }
    updateTopic(buttonObject._id);
    // getTopicOfInterest();
  };
  const getTransactions = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result } = await getRequest(
        `/api/secure/topicOfInterest/get-all-topic-of-interest`,
        token
      );
      if (result.status === 200) {
        const { topicOfInterests } = result?.data;
        setData(topicOfInterests);
      }
    } catch (err) {
      console.log(`Error of Getting topic of interest`, err.message);
    } finally {
      setLoading(false);
    }
  };
  const getUserTOI = async () => {
    setLoading(true);
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result } = await getRequest(
        `/api/secure/userTopicOfInterest/get-user-topic-of-interest`,
        token
      );
      if (result.status === 200) {
        const { userTopicOfInterest } = result?.data;
        let activeButton = [];
        userTopicOfInterest.topicOfInterestIds.map(({ name, _id }) => {
          activeButton.push({ name, _id });
        });
        setActiveButtons(activeButton);
      }
    } catch (err) {
      console.log(`Error of Getting topic of interest`, err.message);
    } finally {
      setLoading(false);
    }
  };
  // const getTopicOfInterest = async () => {
  //   try {
  //     const token = getItemFromLocalStorage("TOKEN");
  //   const { result } = await getRequest(
  //     `/api/secure/userTopicOfInterest/get-categories-from-user-topic-of-interest`,
  //     token
  //   );
  //   if (result.status === 200) {
  //     console.log(result?.data);

  //   }
  //   } catch (error) {
  //     console.log(error?.message);
  //   }

  // };

  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getTransactions();
      // getTopicOfInterest();
      getUserTOI();
    }
  }, [currentlyActive, actualComponent]);
  return (
    <div className="topic">
      {loading ? (
        <Loader loading={loading} />
      ) : (
        data &&
        data.length > 0 &&
        data.map(({ name, _id }, index) => {
          return (
            <Button
              key={index}
              onClick={() => handleButtonClick({ name, _id })}
              className={`me-1 mb-1 ${
                activeButtons.some((item) => item.name === name) ? "active" : ""
              }`}
            >
              {name}
            </Button>
          );
        })
      )}
    </div>
  );
}

export default TopicTab;

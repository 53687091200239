import {
  faBan,
  faCheck,
  faFileArrowDown,
  faPencilAlt,
  faSignInAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequestForm } from "../../../helper/api";
import Layout from "./../../../layouts/admin/Layout";

import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-date-range";
import ReactSelect from "react-select";
import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";
import {
  getFilterHistory,
  updateFilterHistory,
} from "../../../helper/filterHistory";
import { socket } from "../../../helper/socket";
import ExportProgressComponent from "../../../components/admin/ExportProgressComponent";

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(true);
  const [showSwal, setShowSwal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [users, setUsers] = useState([]);
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "blocked", label: "Blocked" },
  ];
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "user", label: "User" },
  ];

  const getAllUsers = async (
    page = 1,
    limit = 10,
    search = "",
    status = "",
    startDate = "",
    endDate = "",
    role = ""
  ) => {
    try {
      setCurrentPage(page);
      setPerPage(limit);
      setSearchItem(search);
      setStatusFilter(status);
      setRoleFilter(role);
      setStartDate(startDate);
      setEndDate(endDate);
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/user/get-all`, token, {
        limit: limit,
        page: page,
        search: search,
        status: status ? status?.value : "",
        startDate: startDate,
        endDate: endDate,
        role: role ? role?.value : "",
      });

      setUsers(response?.result?.data?.users);
      setTableData(response?.result?.data?.users);
      setTotalUsers(response?.result?.data?.totalUsers);
      setTableHead([
        {
          name: "Username",
          sortable: true,
          selector: (row) => row.username,
        },
        {
          name: "Email",
          sortable: true,
          selector: (row) => row.email,
        },
        {
          name: "Status",
          sortable: true,
          cell: (row, index, column, id) => (
            <>
              {row?.status === "active" && (
                <span className="badge bg-success">Active</span>
              )}
              {row?.status === "blocked" && (
                <span className="badge bg-danger">Blocked</span>
              )}
            </>
          ),
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          // button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="btn-default me-1 d-none"
                size="sm"
                variant="primary"
                onClick={() => loginUser(row._id, row.username)}
              >
                <FontAwesomeIcon icon={faSignInAlt} />
              </Button>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editUser(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              {row?.status === "blocked" && (
                <Button
                  className="del_btn ms-1"
                  size="sm"
                  variant="success"
                  onClick={() => unBlockUser(row._id, row, index, column, id)}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              )}
              {row?.status !== "blocked" && (
                <Button
                  className="del_btn ms-1"
                  size="sm"
                  variant="danger"
                  onClick={() => blockUser(row._id, row, index, column, id)}
                >
                  <FontAwesomeIcon icon={faBan} />
                </Button>
              )}
              <Button
                className="del_btn ms-2"
                size="sm"
                variant="danger"
                onClick={() => deleteUser(row._id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </>
          ),
        },
      ]);
    } catch (error) {
      console.log("Get All Users Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    updateFilterHistory(
      {
        page,
        limit: perPage,
        searchItem,
        status: statusFilter,
        startDate,
        endDate,
        role: roleFilter,
      },
      "Users",
      navigate
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    updateFilterHistory(
      {
        page: 1,
        limit: newPerPage,
        searchItem,
        status: statusFilter,
        startDate,
        endDate,
        role: roleFilter,
      },
      "Users",
      navigate
    );
  };

  const handleStatusFilter = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: perPage,
        searchItem,
        status: selectedOption,
        startDate,
        endDate,
        role: roleFilter,
      },
      "Users",
      navigate
    );
  };

  const handleRoleFilter = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: perPage,
        searchItem,
        status: statusFilter,
        startDate,
        endDate,
        role: selectedOption,
      },
      "Users",
      navigate
    );
  };

  const handleSearchUser = (e) => {
    const searchValue = e.target.value;
    updateFilterHistory(
      {
        page: 1,
        limit: perPage,
        searchItem: searchValue,
        status: statusFilter,
        startDate,
        endDate,
        role: roleFilter,
      },
      "Users",
      navigate
    );
  };

  const csvData = useMemo(() => {
    return tableData?.map((user) => ({
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      email: user.email,
      phone: user.phone,
      address: user.address,
      city: user.city,
      state: user.state,
      country: user.country,
      zip: user.zip,
      isVerified: user.isVerified ? "Yes" : "No",
      isDeleted: user.isDeleted ? "Yes" : "No",
      created_at: moment(user.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);

  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const handleSelect = (ranges) => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    updateFilterHistory(
      {
        page: 1,
        limit: perPage,
        searchItem,
        status: statusFilter,
        startDate,
        endDate,
        role: roleFilter,
      },
      "Users",
      navigate
    );
  };

  const editUser = async (userId) => {
    navigate(`/editUser`, {
      state: { userId },
    });
  };

  const blockUser = async (userId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        `/api/secure/user/block-user`,
        token,
        { userId }
      );

      if (response.result.status === 200) {
        toast.success("Member Blocked", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        updateFilterHistory(
          {
            page: currentPage,
            limit: perPage,
            searchItem,
            status: statusFilter,
            startDate,
            endDate,
            role: roleFilter,
          },
          "Users",
          navigate
        );
      }
    } catch (error) {
      console.log("Get Site Setting Error", error);
    } finally {
      setPending(false);
    }
  };
  const deleteUser = async (userId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        `/api/secure/user/delete-user`,
        token,
        { userId }
      );

      if (response.result.status === 200) {
        toast.success("Member Deleted!", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        updateFilterHistory(
          {
            page: currentPage,
            limit: perPage,
            searchItem,
            status: statusFilter,
            startDate,
            endDate,
            role: roleFilter,
          },
          "Users",
          navigate
        );
      }
    } catch (error) {
      console.log("Get Site Setting Error", error);
    } finally {
      setPending(false);
    }
  };
  const unBlockUser = async (userId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        `/api/secure/user/unblock-user`,
        token,
        { userId }
      );

      if (response.result.status === 200) {
        toast.success("Member Re-Activated", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        updateFilterHistory(
          {
            page: currentPage,
            limit: perPage,
            searchItem,
            status: statusFilter,
            startDate,
            endDate,
            role: roleFilter,
          },
          "Users",
          navigate
        );
      }
    } catch (error) {
      console.log("Get Site Setting Error", error);
    } finally {
      setPending(false);
    }
  };
  const loginUser = async (userId, username) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/auth/loginAsUser`,
        token,
        { userId }
      );

      if (
        result?.status === 200 ||
        result?.status === 201 ||
        result?.status === 304
      ) {
        toast.success(`Logged in as ${username}`, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.log("Login As User", result?.data);
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Login As User", error);
    } finally {
      setPending(false);
    }
  };
  const exportUsers = async () => {
    try {
      setShowSwal(true);
      const token = getItemFromLocalStorage("TOKEN");

      const retryExport = async (retries = 3) => {
        for (let attempt = 1; attempt <= retries; attempt++) {
          console.log(`Attempt ${attempt} to export users...`);

          const { result, error } = await getRequest(
            `/api/secure/user/export`,
            token,
            {
              search: searchItem,
              status: statusFilter ? statusFilter?.value : "",
              startDate: startDate,
              endDate: endDate,
              role: roleFilter ? roleFilter?.value : "",
              socketId: socket?.id,
            }
          );

          if (
            result?.status === 200 ||
            result?.status === 201 ||
            result?.status === 304
          ) {
            console.log("Export Result", result?.data);
            return;
          } else if (error?.response?.status === 400) {
            if (
              error?.response?.data?.message === "Invalid socket ID" &&
              attempt < retries
            ) {
              console.log("Retrying due to invalid socket ID...");
              await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay before retry
            } else {
              toast.error(error?.response?.data?.message, {
                position: "top-center", theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              });
              return;
            }
          } else {
            throw new Error(error?.response?.data?.message || "Unknown error");
          }
        }
      };

      await retryExport();
    } catch (error) {
      console.log("Export Error", error);
    } finally {
      setShowSwal(false);
    }
  };

  useEffect(() => {
    // Parse query parameters from URL on component mount
    getFilterHistory(location, getAllUsers);
  }, [location]);

  return (
    <Layout>
      <section id="users_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col lg={3} md={6} xs={12}>
              <FormControl
                type="text"
                placeholder="Search Users"
                className="me-2"
                value={searchItem}
                onChange={handleSearchUser}
              />
            </Col>
            <Col lg={2} md={6} xs={12}>
              <Form.Group>
                <ReactSelect
                  placeholder="Status"
                  onChange={handleStatusFilter}
                  options={statusOptions}
                  required
                  isClearable
                  value={statusFilter ? statusFilter : ""}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} xs={12}>
              <Form.Group>
                <ReactSelect
                  placeholder="Role"
                  onChange={handleRoleFilter}
                  options={roleOptions}
                  required
                  isClearable
                  value={roleFilter ? roleFilter : ""}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12}>
              <Dropdown autoClose={"outside"} className="w-100">
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        updateFilterHistory(
                          {
                            page: 1,
                            limit: 10,
                            searchItem,
                            status: statusFilter,
                            startDate: "",
                            endDate: "",
                            role: roleFilter,
                          },
                          "Users",
                          navigate
                        );
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {tableData && (
              <Col lg={1} md={6} xs={12}>
                {/* <CSVLink
                  data={csvData}
                  filename={"users.csv"}
                  className="btn btn-success"
                >
                </CSVLink> */}
                <Button variant="info" onClick={() => exportUsers()}>
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </Button>
              </Col>
            )}
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalUsers}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          )}
          {showSwal && <ExportProgressComponent />}
        </Container>
      </section>
    </Layout>
  );
};

export default Users;

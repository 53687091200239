import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { postRequestForm } from "../../helper/api";
import Layout from "../../layouts/auth/Layout";
import Loader from "./../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function SetNewPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [_id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match") // Use Yup.ref to reference the 'password' field
      .required("Confirm Password is required"),
  });

  const resetPasswordHandler = async (values) => {
    try {
      setLoading(true);
      const {result,error} = await postRequestForm("/api/auth/reset-password", "", {
        _id: _id,
        password: values.password,
      });

      if (result?.status === 200) {
        toast.success(result?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate(`/login`);
      } else if(error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Create Profile API error", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (location?.state?._id !== "") {
      setId(location?.state?._id);
    } else {
      navigate("/login");
    }
  }, [location, navigate]);

  return (
    <Layout>
      <section className="forget-password">
        <Container>
          <Row className=" bod">
            <Col lg={6} md={12} xs={12} className="d-none d-lg-block">
              <Image
                fluid
                src="https://generation-sessions.s3.amazonaws.com/2aeb18d3f01e5505fad2b96ebbb01e64/img/frame.svg"
                alt="Frame"
              />
            </Col>
            <Col
              lg={6}
              md={12}
              xs={12}
              className="align-self-center text-center d-flex flex-column align-items-center"
            >
              {loading ? (
                <Loader loading={loading} />
              ) : (
                <Formik
                  initialValues={{
                    password,
                    confirmPassword,
                  }}
                  validationSchema={resetPasswordSchema}
                  onSubmit={resetPasswordHandler}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <h1 className="forget">Enter New Password</h1>
                      <p>Please enter new password to log into your account.</p>
                      <InputGroup className="mb-3 w-100" size="lg">
                        <Form.Control
                          type={isShowPassword ? "text" : "password"}
                          placeholder="New Password*"
                          style={{ borderRight: "none" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="password"
                          autoComplete="none"
                          defaultValue={values.password}
                        />
                        <InputGroup.Text
                          style={{
                            background: "#fff",
                            borderLeft: "none",
                          }}
                          onClick={() => setIsShowPassword(!isShowPassword)}
                        >
                          <FontAwesomeIcon
                            icon={isShowPassword ? faEyeSlash : faEye}
                            size="lg"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <InputGroup className="mb-3 w-100" size="lg">
                        <Form.Control
                          type={isShowPassword ? "text" : "password"}
                          placeholder="Confirm Password*"
                          style={{ borderRight: "none" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="confirmPassword"
                          autoComplete="none"
                          defaultValue={values.confirmPassword}
                        />
                        <InputGroup.Text
                          style={{
                            background: "#fff",
                            borderLeft: "none",
                          }}
                          onClick={() => setIsShowPassword(!isShowPassword)}
                        >
                          <FontAwesomeIcon
                            icon={isShowPassword ? faEyeSlash : faEye}
                            size="lg"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <div id="status">
                        <div
                          className={`alert ${(errors.password && touched.password) ||
                              (errors.confirmPassword && touched.confirmPassword)
                              ? ""
                              : "d-none"
                            } ${errors ? "alert-danger" : "alert-success"}`}
                          role="alert"
                        >
                          {errors.confirmPassword && touched.confirmPassword
                            ? errors.confirmPassword
                            : errors.password && touched.password
                              ? errors.password
                              : ""}
                        </div>
                      </div>
                      <InputGroup className="reset  w-100">
                        <button className="btn-default w-100">
                          SET PASSWORD
                        </button>
                      </InputGroup>
                    </form>
                  )}
                </Formik>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default SetNewPassword;

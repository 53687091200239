import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { getRequest, putRequest } from "./../../../helper/api";

import { faAdd, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { getItemFromLocalStorage, setItemInLocalStorage } from "./../../../helper/helper";

function LanguageSettings({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(true);
  const [languageSettings, setLanguageSettings] = useState([]);

  const updateLanguageSettingsHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const {result,error} = await putRequest(
        "/api/secure/site/update-language-settings",
        token,
        {
          siteId: "007",
          languageSettings,
        }
      );
      if (
        result?.status === 200 ||
        result?.status === 201
      ) {
        const { languageSettings } = result?.data;
        toast.success("Language Settings Updated", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setItemInLocalStorage(
          "LANGUAGE_SETTINGS",
          JSON.stringify(languageSettings)
        );
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Update Language Settings API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getLanguageSettings = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        "/api/secure/site/get-language-settings",
        token,
        {
          siteId: "007",
        }
      );
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        const { languageSettings } = response?.result?.data;
        setLanguageSettings(languageSettings);
      }
    } catch (error) {
      console.log("Get Language Settings API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const addRowTable = () => {
    const addLanguageSetting = {
      key: "",
      value: "",
    };
    setLanguageSettings([...languageSettings, addLanguageSetting]);
  };

  const removeRowTable = (index) => {
    const languageSettingRow = [...languageSettings];
    languageSettingRow.splice(index, 1);
    setLanguageSettings(languageSettingRow);
  };

  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const languageSetting = [...languageSettings];
    languageSetting[i][name] = value;
    setLanguageSettings(languageSetting);
  };

  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getLanguageSettings();
    }
  }, [currentlyActive, actualComponent]);

  return (
    <section className="py-5">
      <Container>
        {!loading ? (
          <Form
            className="form_add_user"
            encType="multipart/form-data"
            method="post"
            onSubmit={updateLanguageSettingsHandler}
          >
            <Row>
              <Col sm={12}>
                <Form.Label>
                  <b>Language Settings</b>
                </Form.Label>
                <Table id="languageSettingsTable" responsive striped>
                  <tbody>
                    {languageSettings?.map((rowsData, index) => (
                      <tr key={index}>
                        <td>
                          <Table className="bg-white m-0">
                            <tbody>
                              <tr>
                                <td>
                                  <Form.Label>
                                    <b>Key</b>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="key"
                                    defaultValue={rowsData.key}
                                    onChange={(event) =>
                                      onValUpdate(index, event)
                                    }
                                    required
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Form.Label>
                                    <b>Value</b>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="value"
                                    defaultValue={rowsData.value}
                                    onChange={(event) =>
                                      onValUpdate(index, event)
                                    }
                                    required
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          <Button
                            className="btn-danger"
                            onClick={() => removeRowTable(index)}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="text-end" colSpan={2}>
                        <Button className="btn-primary" onClick={addRowTable}>
                          <FontAwesomeIcon icon={faAdd} />
                        </Button>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Col>
              <Col xl={12} sm={12} className="text-center mt-4">
                <Button type="submit">Save</Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader loading={loading} />
        )}
      </Container>
    </section>
  );
}

export default LanguageSettings;

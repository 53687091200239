import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Image,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as _ from "underscore";
import { getRequest, putRequest } from "../../../helper/api";

import ReactSelect from "react-select";
import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";
import AdminImages from "./../../../constants/admin/images";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import PublicImages from "../../../constants/public/images";

const PostByChallenges = ({ challengeId, isChallengeLive }) => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [latestPosts, setLatestPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [winnerOptions, setWinnerOptions] = useState([
    { value: "all", label: "All" },
    { value: "winners", label: "Winners" },
    { value: "starmark", label: "Starmark" },
  ]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [winnerFilter, setWinnerFilter] = useState("");

  const getAllPosts = async (
    page = 1,
    limit = 10,
    search = "",
    status = "",
    startDate = "",
    endDate = ""
  ) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/challenge/get-challenge-posts`,
        token,
        {
          limit: limit,
          page: page,
          search: search,
          getWinners: status,
          challengeId: challengeId,
        }
      );
      setLatestPosts(response?.result?.data?.posts);
      setTableData(response?.result?.data?.posts);
      setTotalRows(response?.result?.data?.competitorCount);
      setTableHead([
        {
          name: "Title",
          sortable: true,
          selector: (row) => row.title,
        },
        {
          name: "Sub Category Name",
          selector: (row) => row.subCategoryId?.name,
        },
        {
          name: "Category Name",
          selector: (row) => row.categoryId.slug,
        },
        {
          name: "Product Type",
          selector: (row) => row.productId.name,
        },
        {
          name: "User",
          selector: (row) => `${row.userId.firstName} ${row.userId.lastName}`,
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="del_btn ms-1"
                size="sm"
                variant=""
                onClick={() => chooseStarMark(row._id, row.challengeId)}
              >
                <img
                  src={AdminImages.StarmarkGold}
                  alt=""
                  height={25}
                  width={25}
                />
              </Button>
              {!isChallengeLive && (
                <Button
                  className="del_btn ms-1"
                  size="sm"
                  variant=""
                  onClick={() => chooseWinner(row._id, row.challengeId)}
                >
                  <img
                    src={PublicImages.Trophy}
                    alt=""
                    height={25}
                    width={25}
                  />
                </Button>
              )}
              <Link
                to={`/${row.productId.slug}/${row.categoryId?.slug}/${row.slug}`}
                state={{ isModeration: !row.isApproved }}
                target="_BLANK"
                className="btn btn-sm text-light btn-info view_btn ms-1"
              >
                <FontAwesomeIcon icon={faEye} />
              </Link>
            </>
          ),
        },
      ]);
    } catch (error) {
      console.log("Get Moderations Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllPosts(page, perPage, searchItem, winnerFilter, startDate, endDate);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    getAllPosts(1, newPerPage, searchItem, winnerFilter, startDate, endDate);
  };

  const handleWinnerFilter = (selectedOption) => {
    const statusValue = selectedOption?.value;
    setWinnerFilter(statusValue);
    setCurrentPage(1);
    getAllPosts(1, perPage, searchItem, statusValue, startDate, endDate);
  };

  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);
    setCurrentPage(1);
    getAllPosts(1, perPage, searchValue, winnerFilter, startDate, endDate);
  };

  const chooseWinner = async (postId, challengeId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        `/api/secure/challenge/choose-winner`,
        token,
        {
          postId,
          challengeId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success(result?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("winner Post Error", error);
    } finally {
      getAllPosts();
      setPending(false);
    }
  };

  const chooseStarMark = async (postId, challengeId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        `/api/secure/challenge/star-mark`,
        token,
        {
          postId,
          challengeId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success(result?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("winner Post Error", error);
    } finally {
      getAllPosts();
      setPending(false);
    }
  };

  useEffect(() => {
    getAllPosts(currentPage, perPage);
    if (!isChallengeLive) {
      setWinnerFilter({
        value: "starmark",
        label: "Starmark",
      });
    }
  }, [currentPage, perPage, challengeId, isChallengeLive]);

  return (
    <section id="Posts_list" className="pt-5">
      <Container>
        <Row className="mb-3">
          <Col lg={6} md={6} xs={12}>
            <FormControl
              type="text"
              placeholder="Search Posts"
              className="me-2"
              value={searchItem}
              onChange={handleSearchCategory}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Form.Group>
              <ReactSelect
                placeholder="Filter By Winner"
                onChange={handleWinnerFilter}
                options={winnerOptions}
                required
                isClearable
              />
            </Form.Group>
          </Col>
        </Row>
        {pending ? (
          <Loader loading={pending} />
        ) : (
          <DataTable
            columns={tableHead}
            data={_.sortBy(tableData, "total").reverse()}
            style={{
              borderRadius: "10px",
            }}
            pagination
            paginationServer
            paginationPerPage={perPage}
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressPending={pending}
          />
        )}
      </Container>
    </section>
  );
};

export default PostByChallenges;

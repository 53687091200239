import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { uploadURL } from "../../../helper/api";

export default function MenuPop({ show, handleClose, products }) {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    setModalShow(show);
  }, [show]);
  return (
    <Modal centered show={modalShow} id="menu-modal">
      <Modal.Header
        className="justify-content-center position-relative"
        style={{
          borderRadius: "25px 25px 0px 0px",
          background: "#E5097D",
        }}
      >
        <h5
          className="mb-0"
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Editors-Note",
            fontSize: "36px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            textTransform: "uppercase",
          }}
        >
          Create
        </h5>
        <button
          type="button"
          className="btn-close position-absolute"
          style={{
            right: "15px",
            top: "15px",
            filter: "invert(1)",
            opacity: "0.7",
          }}
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            handleClose(!modalShow);
          }}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className="p-0">
          <Row>
            {products.map(({ slug, featuredImage, name }, index) => (
              <Col
                lg={
                  (products.length - 1) % 2 === 0 &&
                  index === products.length - 1
                    ? 12
                    : 6
                }
                md={
                  (products.length - 1) % 2 === 0 &&
                  index === products.length - 1
                    ? 12
                    : 6
                }
                key={index}
              >
                <Card>
                  <Card.Body>
                    <div className="card-logo">
                      <div className="card-svg">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/${slug}/upload`, { replace: true });
                            handleClose(!modalShow);
                            navigate(0)
                          }}
                        >
                          <Image
                            fluid
                            src={uploadURL + featuredImage}
                            alt={name}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="card-title">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/${slug}/upload`, { replace: true });
                          handleClose(!modalShow);
                          navigate(0)
                        }}
                      >
                        {slug === "videos" ||
                        slug === "audio-station" ||
                        slug === "image-corner"
                          ? `Upload Your ${name}`
                          : `Write Your ${name}`}
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

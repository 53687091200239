import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import Layout from "../../layouts/public/Layout";
import { uploadURL, getRequest } from "./../../helper/api";
import Loader from "../../components/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getItemFromLocalStorage } from "../../helper/helper";
import DataTable from "react-data-table-component";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const PublishedPosts = () => {
  const navigate = useNavigate();
  const { product } = useParams();
  if (!product) {
    navigate(-1);
  }
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const getAllPostsByProduct = async (page, perPage, product) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const user = getItemFromLocalStorage("USER");
      const userId = JSON.parse(user)?._id;
      const response = await getRequest(
        `/api/secure/post/posts-by-product`,
        token,
        {
          page: page,
          limit: perPage,
          productSlug: product,
          userId,
        }
      );
      setTableHead([
        {
          name: `${formatString(product)} Title`,
          sortable: true,
          selector: (row) => row.title,
        },
        {
          name: `${formatString(product)} Category`,
          selector: (row) => `${row.categoryId?.name}`,
        },
        {
          name: `${formatString(product)} Sub Category`,
          selector: (row) => `${row.subCategoryId?.name}`,
        },
        {
          name: "Status",
          selector: (row) => (row.isApproved ? "Published" : "Pending"),
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              {/* <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editPost(row.slug, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button> */}
              <Link
                to={`/${row.productId.slug}/${row.categoryId?.slug}/${row.slug}`}
                target="_BLANK"
                className="btn btn-sm text-light btn-info view_btn ms-1"
              >
                <FontAwesomeIcon icon={faEye} />
              </Link>
              {/* <Button
                className="del_btn ms-1 d-none"
                size="sm"
                variant="danger"
                onClick={() => deletePost(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button> */}
            </>
          ),
        },
      ]);
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        setTableData(response?.result?.data?.posts);
        setTotalRows(response?.result?.data?.totalPosts);
      } else {
        setTableData([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.log("Get Moderations Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllPostsByProduct(page, perPage, product);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    getAllPostsByProduct(1, newPerPage, product);
  };
  const formatString = (input) => {
    if (input === "image-corner") {
      return input
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
        .replace(" Corner", "");
    } else {
      return input
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
  };
  useEffect(() => {
    getAllPostsByProduct(1, 10, product);
  }, [product]);

  return (
    <Layout>
      <main id="main">
        <section className="publish text-light py-5">
          <Container>
            <h1 className="mb-5 text-center text-dark">
              <b>
                {formatString(product)}{" "}
                {formatString(product)?.includes("Blogs") ||
                formatString(product)?.includes("Audio") ||
                formatString(product)?.includes("Stories")
                  ? "Published"
                  : "Published"}
              </b>
            </h1>
            {pending ? (
              <Loader loading={pending} />
            ) : (
              <DataTable
                columns={tableHead}
                data={tableData}
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                }}
                pagination
                paginationServer
                paginationPerPage={perPage}
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={pending}
              />
            )}
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default PublishedPosts;

import { faMinus, faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { getRequest, postRequestForm } from "../../../helper/api";
import {
  cronScheduleToDate,
  getItemFromLocalStorage,
} from "../../../helper/helper";
import Layout from "../../../layouts/admin/Layout";

const EditLiveStreaming = () => {
  let liveStreamingId = "";
  const location = useLocation();
  const navigate = useNavigate();
  if (location?.state?.liveStreamingId === "") {
    navigate("/liveStreamings");
  } else {
    liveStreamingId = location?.state?.liveStreamingId;
  }
  const [status, setStatus] = useState();
  const [title, setTitle] = useState();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date().toLocaleString("en-US")
  );
  const [description, setDescription] = useState();
  const [expertDetails, setExpertDetails] = useState([
    {
      expertName: "",
      expertDescription: "",
      expertOrganization: "",
    },
  ]);
  const [anchorDetails, setAnchorDetails] = useState([
    {
      anchorName: "",
      anchorDescription: "",
    },
  ]);
  const [videoId, setVideoId] = useState("");

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
    { value: "scheduled", label: "Scheduled" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const editLiveStreamingHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      // console.log("startDate", startDate);
      const { result, error } = await postRequestForm(
        `/api/secure/liveStreaming/udpate-live-streaming/${liveStreamingId}`,
        token,
        {
          title,
          description,
          status: typeof status === "object" ? status?.value : status,
          schedule:
            typeof status === "object"
              ? status?.value === "scheduled"
                ? startDate instanceof Date
                  ? startDate.toISOString()
                  : startDate
                : new Date().toISOString()
              : status === "scheduled"
              ? startDate instanceof Date
                ? startDate.toISOString()
                : startDate
              : new Date().toISOString(),

          expertDetails,
          anchorDetails,
          videoId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success("Live Streaming Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/liveStreamings");
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        // navigate("/liveStreamings");
      }
    } catch (error) {
      console.log("Create Live Streaming APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleStartDate = (date) => {
    setStartDate(
      date
    );
  };
  const getLiveStreamById = async (liveStreamingId) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/liveStreaming/get-live-streaming`,
        token,
        {
          liveStreamingId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        const { liveStreaming } = result?.data;
        const {
          description,
          expertDetails,
          anchorDetails,
          schedule,
          status,
          title,
          videoId,
        } = liveStreaming;

        setTitle(title);
        setDescription(description);
        setStatus(status);
        setStartDate(
          new Date(schedule).toLocaleString("en-US")
        );
        setExpertDetails(expertDetails);
        setAnchorDetails(anchorDetails);
        setVideoId(videoId);
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/liveStreamings");
      }
    } catch (error) {
      console.log("Get Live Streaming APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  // Function to add a new row to the table
  const addRowTable = (tableData, setTableData) => {
    const addRow = {
      expertName: "",
      expertDescription: "",
      expertOrganization: "",
    };
    setTableData([...tableData, addRow]);
  };

  // Function to remove a row from the table based on the index
  const removeRowTable = (index, tableData, setTableData) => {
    const tableRow = [...tableData];
    tableRow.splice(index, 1);
    setTableData(tableRow);
  };

  // Function to update the value of a specific field in a row
  const onValUpdate = (i, event, tableData, setTableData) => {
    const { name, value } = event.target;
    const table = [...tableData];
    table[i][name] = value;
    setTableData(table);
  };

  // Inside your component, use these functions with the respective state variables
  // For expertDetails
  const handleExpertAddRow = () => {
    addRowTable(expertDetails, setExpertDetails);
  };

  const handleExpertRemoveRow = (index) => {
    removeRowTable(index, expertDetails, setExpertDetails);
  };

  const handleExpertValUpdate = (i, event) => {
    onValUpdate(i, event, expertDetails, setExpertDetails);
  };

  // For anchorDetails
  const handleAnchorAddRow = () => {
    addRowTable(anchorDetails, setAnchorDetails);
  };

  const handleAnchorRemoveRow = (index) => {
    removeRowTable(index, anchorDetails, setAnchorDetails);
  };

  const handleAnchorValUpdate = (i, event) => {
    onValUpdate(i, event, anchorDetails, setAnchorDetails);
  };

  const getLabelByValue = (options, value) => {
    const option = options?.find((option) => option.value === value);
    return option?.label;
  };
  useEffect(() => {
    getLiveStreamById(liveStreamingId);
  }, [liveStreamingId]);

  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={editLiveStreamingHandler}
            >
              <Row>
                <Col xl={12} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Youtube Video ID</Form.Label>
                    <Form.Control
                      name="videoID"
                      type="text"
                      placeholder="Youtube Video ID"
                      required
                      value={videoId}
                      onChange={(e) => setVideoId(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xl={
                    status
                      ? typeof status === "object"
                        ? status?.value === "scheduled"
                          ? 4
                          : 6
                        : status === "scheduled"
                        ? 4
                        : 6
                      : 6
                  }
                  sm={12}
                >
                  <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      name="title"
                      type="text"
                      placeholder="Title"
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xl={
                    status
                      ? typeof status === "object"
                        ? status?.value === "scheduled"
                          ? 4
                          : 6
                        : status === "scheduled"
                        ? 4
                        : 6
                      : 6
                  }
                  sm={12}
                >
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <ReactSelect
                      placeholder="Select Status"
                      onChange={statusChange}
                      options={statusOptions}
                      value={
                        typeof status === "object"
                          ? status
                          : status
                          ? {
                              value: status,
                              label: getLabelByValue(statusOptions, status),
                            }
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
                {status &&
                (typeof status === "object"
                  ? status?.value === "scheduled"
                  : status === "scheduled") ? (
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Date to Go Live</Form.Label>
                      <DateTimePicker
                        onChange={handleStartDate}
                        value={new Date(startDate)}
                        format="d-MM-y h:mm:ss a"
                        // minDate={new Date()}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  <></>
                )}
                <Col xl={12} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Description"
                      required
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Label>
                    <b>Expert Details</b>
                  </Form.Label>
                  <Table id="faqsTable" responsive striped>
                    <tbody>
                      {expertDetails.map((rowsData, index) => (
                        <tr key={index}>
                          <td>
                            <Table className="bg-white m-0">
                              <tbody>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Expert Name</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="expertName"
                                      defaultValue={rowsData.expertName}
                                      onChange={(event) =>
                                        handleExpertValUpdate(index, event)
                                      }
                                      required
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Expert Description</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="expertDescription"
                                      defaultValue={rowsData.expertDescription}
                                      onChange={(event) =>
                                        handleExpertValUpdate(index, event)
                                      }
                                      required
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Expert Organization</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="expertOrganization"
                                      defaultValue={rowsData.expertOrganization}
                                      onChange={(event) =>
                                        handleExpertValUpdate(index, event)
                                      }
                                      required
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                          <td>
                            <Button
                              className="btn-danger"
                              onClick={() => handleExpertRemoveRow(index)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="text-start">Add Expert Detail</td>
                        <td className="text-end">
                          <Button
                            className="btn-primary"
                            onClick={() => {
                              handleExpertAddRow();
                            }}
                          >
                            <FontAwesomeIcon icon={faAdd} />
                          </Button>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
                <Col sm={12}>
                  <Form.Label>
                    <b>Anchor Details</b>
                  </Form.Label>
                  <Table id="faqsTable" responsive striped>
                    <tbody>
                      {anchorDetails.map((rowsData, index) => (
                        <tr key={index}>
                          <td>
                            <Table className="bg-white m-0">
                              <tbody>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Anchor Name</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="anchorName"
                                      defaultValue={rowsData.anchorName}
                                      onChange={(event) =>
                                        handleAnchorValUpdate(index, event)
                                      }
                                      required
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Anchor Description</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="anchorDescription"
                                      defaultValue={rowsData.anchorDescription}
                                      onChange={(event) =>
                                        handleAnchorValUpdate(index, event)
                                      }
                                      required
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                          <td>
                            <Button
                              className="btn-danger"
                              onClick={() =>
                                handleAnchorRemoveRow(index, anchorDetails)
                              }
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="text-start">Add Anchor Detail</td>
                        <td className="text-end">
                          <Button
                            className="btn-primary"
                            onClick={() => {
                              handleAnchorAddRow();
                            }}
                          >
                            <FontAwesomeIcon icon={faAdd} />
                          </Button>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Update</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default EditLiveStreaming;

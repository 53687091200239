import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { getRequest, postRequestForm } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Layout from "../../../layouts/admin/Layout";

const AddCategory = () => {
  const [name, setName] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const [images, setImages] = React.useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [productTypes, setProductTypes] = useState();
  const [productType, setProductType] = useState();
  const [loading, setLoading] = useState(true);

  const getAllProductTypes = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/product/get-all-active-products`,
        token
      );

      const mappedOptions = response?.result?.data?.products.map((option) => ({
        value: option._id,
        label: option.name,
      }));
      setProductTypes(mappedOptions);
    } catch (error) {
      console.log("Get All Product Types Error", error);
    } finally {
      setLoading(false);
    }
  };

  const productTypeChange = (selectedOption) => {
    setProductType(selectedOption);
  };

  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setFeaturedImage(imageList[addUpdateIndex].file);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const addCategoryHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("masterProductId", productType.value);
      formData.append("metaTitle", metaTitle);
      formData.append("metaDescription", metaDescription);

      formData.append(
        "status",
        typeof status === "object" ? status.value : status
      );
      if (featuredImage) {
        formData.append("featuredImage", featuredImage);
      }
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        "/api/secure/category/create-category",
        token,
        formData
      );

      if (response?.result?.status === 200 || response?.result?.status === 201) {
        toast.success("Category Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/categories");
      }
    } catch (error) {
      console.log("Create Category APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllProductTypes();
  }, []);
  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={addCategoryHandler}
            >
              <Row>
                <Col sm={12} className="text-center">
                  <Form.Label>Add Category Icon</Form.Label>
                  <ImageUploading
                    value={images}
                    onChange={onChangeImage}
                    dataURLKey="data_url"
                    maxNumber={1}
                    maxFileSize={1000000}
                    acceptType={["jpeg", "jpg", "gif", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div className="upload__image-wrapper mx-auto mb-5">
                          <button
                            type="button"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            {/* <i className="fal fa-plus"></i> */}
                          </button>
                          {imageList
                            ? imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img src={image["data_url"]} alt="" />
                                  <div className="image-item__btn-wrapper">
                                    <button
                                      type="button"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <FontAwesomeIcon icon={faPencilAlt} />
                                    </button>
                                  </div>
                                </div>
                              ))
                            : ""}
                        </div>
                        {errors && (
                          <div
                            id="imageUploadingErrors"
                            className="text-center"
                          >
                            {errors.maxFileSize && (
                              <Alert className="my-1" variant="danger">
                                Selected file size exceed 1MB
                              </Alert>
                            )}
                            {errors.acceptType && (
                              <Alert className="my-1" variant="danger">
                                Your selected file type is not allow
                              </Alert>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </ImageUploading>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="Name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                {/* <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Word Limit</Form.Label>
                    <Form.Control
                      name="wordLimit"
                      type="number"
                      placeholder="Word Limit"
                      required
                      value={wordLimit}
                      onChange={(e) => setWordLimit(e.target.value)}
                    />
                  </Form.Group>
                </Col> */}
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      name="metaTitle"
                      type="text"
                      placeholder="Meta Title"
                      required
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      name="metaDescription"
                      type="text"
                      placeholder="Meta Description"
                      required
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Product Type</Form.Label>
                    <ReactSelect
                      placeholder="Select Product Type"
                      onChange={productTypeChange}
                      options={productTypes}
                      value={productType ? productType : ""}
                      required
                      isClearable
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <ReactSelect
                      placeholder="Select Status"
                      onChange={statusChange}
                      options={statusOptions}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col xl={12} sm={12} className="text-center my-5">
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default AddCategory;

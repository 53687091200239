import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import PublicImages from "../../../constants/public/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  MailruIcon,
  MailruShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Link } from "react-router-dom";
import { baseURL } from "../../../helper/api";
import { toast } from "react-toastify";

export default function Share({
  show,
  handleClose,
  referralCode,
  username,
  isReferral = false,
}) {
  // Site URL
  const siteUrl = isReferral
    ? `${window.location.origin}/sign-up/?referralCode=${referralCode}`
    : `${window.location.origin}/profile/${username}`;

  const handleCopyText = (e) => {
    e.preventDefault();
    try {
      const textToCopyElement = document.getElementById("textToCopy");

      if (textToCopyElement) {
        const textToCopy = textToCopyElement.innerText;
        const textArea = document.createElement("textarea");
        textArea.value = `${window.location.origin}/sign-up/?referralCode=${textToCopy}`;

        // Append the textarea to the document
        document.body.appendChild(textArea);

        // Select the text in the textarea
        textArea.select();

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the textarea from the document
        document.body.removeChild(textArea);
      }
    } catch (error) {
      console.error("Error copying text:", error);
    } finally {
      handleClose();
      toast.success("Referral Link Copied!", {
        position: "top-center", theme: "colored",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Share URL
  const shareUrl = siteUrl;

  return (
    <Modal
      show={show} // Set this to control when the modal is shown
      backdrop="static"
      keyboard={false}
      centered
      id="share-modal"
    >
      <Modal.Header>
        <Button
          type="button"
          className="btn-close"
          onClick={handleClose}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-title text-center">
          <h1>Invite Friends to Follow you</h1>
        </div>
        <div className="earn">
          <Row>
            <div className="d-flex">
              <div className="align-self-center">
                <p className="m-0">Your unique code</p>
                {referralCode && (
                  <h6 id="textToCopy" className="text-center">
                    {referralCode}
                  </h6>
                )}
              </div>
              <div className="bordr h-100">
                <span>|</span>
                <a href="#" onClick={handleCopyText}>
                  <img
                    src={PublicImages.file_copy}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </Row>{" "}
        </div>
        <div className="main">
          <div className="text text-center position-relative">
            <h2>OR</h2>
            <div className="position-absolute top-50 border border-1 border-top-0 border-right-0 border-left-0 border-black w-75"></div>
          </div>
        </div>
      </Modal.Body>
      <div className="icon-text">
        <h3 className="text-center">Share Invite Link Via</h3>
        <div className="icons d-flex justify-content-center align-items-center">
          <FacebookShareButton
            url={shareUrl}
            className="facebook-share-button me-1"
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            className="twitter-share-button me-1"
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton
            url={shareUrl}
            className="linkedin-share-button me-1"
          >
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <WhatsappShareButton
            url={shareUrl}
            className="whatsapp-share-button me-1"
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <EmailShareButton url={shareUrl} className="email-share-button">
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </div>
      </div>
    </Modal>
  );
}

import React from "react";

import SiteSettings from "../constants/SiteSettings";
import { useDynamicTitle, useDynamicViewport } from "./documentSettings";
import Home from "./../views/public/Home";
import AboutUs from "./../views/public/AboutUs";
import LatestPosts from "./../views/public/LatestPosts";
import SetNewPassword from "./../views/public/SetNewPassword";
import OTP from "./../views/public/OTP";
import ForgetPassword from "./../views/public/ForgetPassword";
import SignUp from "./../views/public/SignUp";
import Login from "./../views/public/Login";

import ContactUs from "../views/public/ContactUs";
import FAQs from "./../views/public/FAQs";
import PrivacyAndPolicy from "./../views/public/PrivacyAndPolicy";
import TermsAndConditions from "../views/public/TermsAndConditions";
import Dashboard from "./../views/admin/Dashboard";
import Privacy from "./../views/admin/Pages/Privacy";
import Terms from "./../views/admin/Pages/Terms";
import * as FaqsManagement from "./../views/admin/Pages/Faqs";
import Users from "./../views/admin/Users/Users";
import AddUsers from "./../views/admin/Users/AddUsers";
import EditUser from "./../views/admin/Users/EditUser";
import * as AboutUsManagement from "./../views/admin/Pages/AboutUs";
import Team from "../views/public/Team";
import Advertisement from "../views/public/Advertisement";
import * as AdvetisementManagement from "../views/admin/Pages/Advertisement";
import Error404 from "../views/Error404";
import Settings from "./../views/admin/Settings";
import Account from "../views/account";
import Languages from "../views/admin/Languages/Languages";
import AddLanguage from "../views/admin/Languages/AddLanguage";
import EditLanguage from "../views/admin/Languages/EditLanguage";
import Teams from "../views/admin/Pages/Teams";
import Category from "../views/public/Category";
import Single from "../views/public/Single";
import Product from "../views/public/Product";
import Categories from "./../views/admin/Categories/Categories";
import SubCategories from "./../views/admin/Categories/SubCategories";
import EditCategory from "../views/admin/Categories/EditCategory";
import AddCategory from "./../views/admin/Categories/AddCategory";
import AddProduct from "../views/admin/Products/AddProduct";
import EditProduct from "../views/admin/Products/EditProduct";
import Products from "../views/admin/Products/Products";
import AddSubCategory from "../views/admin/Categories/AddSubCategory";
import EditSubCategory from "../views/admin/Categories/EditSubCategory";
import Sliders from "../views/admin/Slider/Sliders";
import EditSlider from "../views/admin/Slider/EditSlider";
import AddSlider from "./../views/admin/Slider/AddSlider";
import SubSliders from "../views/admin/SubSlider/SubSliders";
import EditSubSlider from "../views/admin/SubSlider/EditSubSlider";
import AddSubSlider from "../views/admin/SubSlider/AddSubSlider";
import TopicOfInterests from "../views/admin/TopicOfInterest/TopicOfInterests";
import EditTopicOfInterest from "./../views/admin/TopicOfInterest/editTopicOfInterest";
import AddTopicOfInterest from "./../views/admin/TopicOfInterest/AddTopicOfInterest";
import Posts from "./../views/admin/Posts/Posts";
import EditPost from "./../views/admin/Posts/EditPost";
import AddPost from "./../views/admin/Posts/AddPost";
import Upload from "../views/public/Upload";
import UserProfile from "../views/account/UserProfile";
import AllChallenges from "../views/admin/Challenges/AllChallenges";
import EditChallenges from "../views/admin/Challenges/EditChallenges";
import AddChallenge from "../views/admin/Challenges/AddChallenge";
import SendEmailNotification from "../views/admin/Emails/SendEmailNotification";
import Withdrawals from "../views/admin/Withdrawals/Withdrawals";
import LiveStreamings from "../views/admin/LiveStreaming/LiveStreamings";
import EditLiveStreaming from "../views/admin/LiveStreaming/EditLiveStreaming";
import AddLiveStreaming from "../views/admin/LiveStreaming/AddLiveStreaming";
import Badges from "../views/admin/Badges/Badges";
import EditBadge from "../views/admin/Badges/EditBadge";
import AddBadge from "../views/admin/Badges/AddBadge";
import EditReportAnswer from "../views/admin/ReportAnswers/EditReportAnswer";
import AddReportAnswer from "../views/admin/ReportAnswers/AddReportAnswer";
import ReportAnswers from "../views/admin/ReportAnswers/ReportAnswers";
import ReportedPosts from "../views/admin/Posts/ReportedPosts";
import EditEmailNotification from "../views/admin/Emails/EditEmailNotification";
import EmailNotifications from "../views/admin/Emails/EmailNotifications";
import UnderModeration from "../views/public/UnderModeration";
import PublishedPosts from "../views/public/PublishedPosts";
import TopContributors from "../views/public/TopContributors";
import GenerateCard from "./../views/public/GenerateCard";
import Backgrounds from "./../views/admin/Backgrounds/Backgrounds";
import EditBackground from "./../views/admin/Backgrounds/EditBackground";
import AddBackground from "./../views/admin/Backgrounds/AddBackground";
import GenerateCardPreview from "./../views/public/GenerateCardPreview";
import Comments from "../views/admin/Comments/Comments";
import EditComment from "../views/admin/Comments/EditComment";
import ReportedComments from "../views/admin/Comments/ReportedComments";
import EditReportedComment from "../views/admin/Comments/EditReportedComment";
import EditReportedPost from "../views/admin/Posts/EditReportedPost";
import Challenge from "../views/public/Challenge";
import TrendingPosts from "../views/public/TrendingPosts";
import * as PublicLiveStreamings from "../views/public/LiveStreamings";
import Live from "../views/public/Live";
import PostListings from "../views/public/PostListings";
import Challenges from "../views/public/Challenges";
import GenerateShortStoryCard from "../views/admin/Posts/ShortStory/GenerateShortStoryCard";
import GenerateShortStoryCardPreview from '../views/admin/Posts/ShortStory/GenerateShortStoryCardPreview';

function _error() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Error 404`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Error404 />;
}
function _home() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Home`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Home />;
}
function _login() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Login`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Login />;
}
function _signUp() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Sign Up`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <SignUp />;
}

function _forgetPassword() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Forget Password`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ForgetPassword />;
}
function _otp() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | OTP`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <OTP />;
}
function _setNewPassword() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Set New Password`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <SetNewPassword />;
}
function _aboutUs() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | About Us`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AboutUs />;
}
function _latestPosts() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Latest Posts`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <LatestPosts />;
}
function _trendingPosts() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Trending Posts`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <TrendingPosts />;
}
function _contactUs() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Contact Us`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ContactUs />;
}
function _faqs() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | FAQs`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <FAQs />;
}
function _privacyPolicy() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Privacy & Policy`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <PrivacyAndPolicy />;
}
function _termsAndConditions() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Terms & Conditions`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <TermsAndConditions />;
}
function _Dashboard() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Dashboard`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Dashboard />;
}
function _advertisementManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Advetisement`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AdvetisementManagement.default />;
}
function _AboutUsManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | About Us`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AboutUsManagement.default />;
}
function _teams() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | About Us`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Teams />;
}
function _FaqsManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | FAQs`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <FaqsManagement.default />;
}
function _TermsManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Terms & Conditions`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Terms />;
}
function _PrivacyManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Privacy & Policy`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Privacy />;
}
function _UsersManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | List all Users`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Users />;
}
function _topContributors() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Top Contributors`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <TopContributors />;
}

function _addUserManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Create New User`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddUsers />;
}
function _editUserManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit User`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditUser />;
}

function _teamSection() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Team`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Team />;
}
function _advertisement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Advertisement`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Advertisement />;
}
function _underModeration() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Under Moderation`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <UnderModeration />;
}
function _publishedPosts() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Published Posts`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <PublishedPosts />;
}
// _Settings
function _Settings() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Settings`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Settings />;
}
function _account() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Account`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Account />;
}

function _LanguageManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | List all Languages`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Languages />;
}
function _addLanguageManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Create New Language`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddLanguage />;
}
function _editLanguageManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Language`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditLanguage />;
}
function _product() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Product`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Product />;
}
function _category() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Category`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Category />;
}
function _single() {
  // useDynamicTitle(`${SiteSettings.SITE_TITLE} | Single`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Single />;
}
function _upload() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Upload`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Upload />;
}
function _uploadGenerateCard() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Generate Card`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <GenerateCard />;
}
function _uploadGenerateCardPreview() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Generated Card Preview`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <GenerateCardPreview />;
}
function _Categories() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | List all Categories`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Categories />;
}
function _subCategory() {
  useDynamicTitle(
    `${SiteSettings.SITE_TITLE} | Admin | List all Sub Categories`
  );
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <SubCategories />;
}
function _editCategory() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Category`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditCategory />;
}
function _addCategory() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Category`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddCategory />;
}
function _editSubCategory() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Category`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditSubCategory />;
}
function _addSubCategory() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Category`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddSubCategory />;
}
function _Products() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Products`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Products />;
}
function _editProduct() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Product`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditProduct />;
}
function _addProduct() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Product`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddProduct />;
}
function _Sliders() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Sliders`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Sliders />;
}
function _editSlider() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Slider`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditSlider />;
}
function _addSlider() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Slider`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddSlider />;
}
function _SubSliders() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | All Sub Sliders`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <SubSliders />;
}
function _editSubSlider() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Sub Slider`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditSubSlider />;
}
function _addSubSlider() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Sub Slider`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddSubSlider />;
}
function _TopicOfInterests() {
  useDynamicTitle(
    `${SiteSettings.SITE_TITLE} | Admin | All Topic Of Interests`
  );
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <TopicOfInterests />;
}
function _editTopicOfInterest() {
  useDynamicTitle(
    `${SiteSettings.SITE_TITLE} | Admin | Edit Topic Of Interest`
  );
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditTopicOfInterest />;
}
function _addTopicOfInterest() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Topic Of Interest`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddTopicOfInterest />;
}
function _Posts() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Moderations`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Posts />;
}
function _editPost() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Post`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditPost />;
}
function _addPost() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Post`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddPost />;
}
function _shortShortCard() {
  useDynamicTitle(
    `${SiteSettings.SITE_TITLE} | Admin | Create Short Story Card`
  );
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <GenerateShortStoryCard />;
}
function _shortShortCardPreview(){
  useDynamicTitle(
    `${SiteSettings.SITE_TITLE} | Admin | Create Short Story Card`
  );
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <GenerateShortStoryCardPreview />;
}
function _ReportedPosts() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Report Post`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ReportedPosts />;
}
function _userProfile() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Profile`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <UserProfile />;
}
function _AllChallengesManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | All Challenges`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AllChallenges />;
}

function _EditChallengeManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Challenge`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditChallenges />;
}
function _addChallengeManagement() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Challenge`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddChallenge />;
}

function _EmailNotifications() {
  useDynamicTitle(
    `${SiteSettings.SITE_TITLE} | Admin | All Email Notifications`
  );
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EmailNotifications />;
}

function _SendEmailNotification() {
  useDynamicTitle(
    `${SiteSettings.SITE_TITLE} | Admin | Send Email Notification`
  );
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <SendEmailNotification />;
}
function _EditEmailNotification() {
  useDynamicTitle(
    `${SiteSettings.SITE_TITLE} | Admin | Edit Email Notification`
  );
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditEmailNotification />;
}

function _Widthdrawals() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Withdrawals`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Withdrawals />;
}

function _liveStreamings() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | All Live Streamings`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <LiveStreamings />;
}
function _editLiveStreaming() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Create Live Streaming`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditLiveStreaming />;
}
function _addLiveStreaming() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Live Streaming`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddLiveStreaming />;
}

function _Badges() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | All Badges`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Badges />;
}
function _editBadge() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Badge`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditBadge />;
}
function _addBadge() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Badge`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddBadge />;
}

function _ReportAnswers() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | All Report Answers`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ReportAnswers />;
}
function _editReportAnswer() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Report Answer`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditReportAnswer />;
}
function _addReportAnswer() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Report Answer`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddReportAnswer />;
}
function _Backgrounds() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | All Backgrounds`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Backgrounds />;
}
function _editBackground() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Background`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditBackground />;
}
function _addBackground() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Add Background`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <AddBackground />;
}
function _comments() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | All Comments`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Comments />;
}
function _editComments() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Comment`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditComment />;
}
function _ReportedComments() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Reported Comments`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <ReportedComments />;
}
function _editReportedComment() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Reported Comment`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditReportedComment />;
}
function _editReportedPost() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Admin | Edit Reported Comment`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <EditReportedPost />;
}
function _challenge() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Challenge`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Challenge />;
}
function _challenges() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Challenges`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Challenges />;
}
function _publicLiveStreamings() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Live`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <PublicLiveStreamings.default />;
}
function _live() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Live`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <Live />;
}
function _postListing() {
  useDynamicTitle(`${SiteSettings.SITE_TITLE} | Posts`);
  useDynamicViewport("width=device-width, initial-scale=1, maximum-scale=1");
  return <PostListings />;
}

var routes = [
  {
    path: "*",
    name: "404",
    icon: "",
    component: _error,
    layout: "",
    show: "no",
  },
  {
    path: "/404",
    name: "405",
    icon: "",
    component: _error,
    layout: "",
    show: "no",
  },
  {
    path: "/profile/:username",
    name: "UserProfile",
    icon: "",
    component: _userProfile,
    layout: "",
    show: "no",
  },
  {
    path: "/",
    name: "Home",
    icon: "",
    component: _home,
    layout: "",
    show: "no",
  },
  {
    path: "/live-streamings",
    name: "Live Streamings",
    icon: "",
    component: _publicLiveStreamings,
    layout: "",
    show: "no",
  },
  {
    path: "/live-streamings/:liveStream",
    name: "Live Streaming",
    icon: "",
    component: _live,
    layout: "",
    show: "no",
  },
  {
    path: "/:product",
    name: "Product",
    icon: "",
    component: _product,
    layout: "",
    show: "no",
  },
  {
    path: "/:product/upload",
    name: "Upload",
    icon: "",
    component: _upload,
    layout: "",
    show: "no",
  },
  {
    path: "/:product/listings",
    name: "Post Listing",
    icon: "",
    component: _postListing,
    layout: "",
    show: "no",
  },
  {
    path: "/:product/challenges",
    name: "Upload",
    icon: "",
    component: _challenges,
    layout: "",
    show: "no",
  },
  {
    path: "/:product/challenges/:challenge",
    name: "Upload",
    icon: "",
    component: _challenge,
    layout: "",
    show: "no",
  },
  {
    path: "/:product/upload/generate-card",
    name: "Upload",
    icon: "",
    component: _uploadGenerateCard,
    layout: "",
    show: "no",
  },
  {
    path: "/:product/upload/generate-card/preview",
    name: "Upload",
    icon: "",
    component: _uploadGenerateCardPreview,
    layout: "",
    show: "no",
  },
  {
    path: "/:product/published",
    name: "Under Moderation",
    icon: "",
    component: _publishedPosts,
    layout: "",
    show: "no",
  },
  {
    path: "/:product/:category",
    name: "Category",
    icon: "",
    component: _category,
    layout: "",
    show: "no",
  },
  {
    path: "/:product/:category/:post",
    name: "Post",
    icon: "",
    component: _single,
    layout: "",
    show: "no",
  },
  {
    path: "/:product/:category/under-moderation/:post",
    name: "Under Moderation",
    icon: "",
    component: _underModeration,
    layout: "",
    show: "no",
  },
  {
    path: "/top-contributors",
    name: "Top Contributors",
    icon: "",
    component: _topContributors,
    layout: "",
    show: "no",
  },
  {
    path: "/account",
    name: "Account",
    icon: "",
    component: _account,
    layout: "ADMIN",
    show: "no",
  },
  // {
  //   path: "/upload",
  //   name: "Upload",
  //   icon: "",
  //   component: _upload,
  //   layout: "",
  //   show: "no",
  // },
  //AUTHENTICATION ROUTES
  {
    path: "/login",
    name: "Login",
    icon: "",
    component: _login,
    layout: "",
    show: "no",
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    icon: "",
    component: _signUp,
    layout: "",
    show: "no",
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    icon: "",
    component: _forgetPassword,
    layout: "",
    show: "no",
  },
  {
    path: "/otp",
    name: "OTP",
    icon: "",
    component: _otp,
    layout: "",
    show: "no",
  },
  {
    path: "/set-new-password",
    name: "Set New Password",
    icon: "",
    component: _setNewPassword,
    layout: "",
    show: "no",
  },
  // PUBLIC PAGES ROUTES
  {
    path: "/about-us",
    name: "About Us",
    icon: "",
    component: _aboutUs,
    layout: "",
    show: "no",
  },
  {
    path: "/latest-posts",
    name: "Latest Posts",
    icon: "",
    component: _latestPosts,
    layout: "",
    show: "no",
  },
  {
    path: "/trending-posts",
    name: "Trending Posts",
    icon: "",
    component: _trendingPosts,
    layout: "",
    show: "no",
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    icon: "",
    component: _contactUs,
    layout: "",
    show: "no",
  },
  {
    path: "/faqs",
    name: "Faqs",
    icon: "",
    component: _faqs,
    layout: "",
    show: "no",
  },
  {
    path: "/privacy-and-policy",
    name: "Privacy & Policy",
    icon: "",
    component: _privacyPolicy,
    layout: "",
    show: "no",
  },
  {
    path: "/terms-and-conditions",
    name: "Terms & Conditions",
    icon: "",
    component: _termsAndConditions,
    layout: "",
    show: "no",
  },
  {
    path: "/team",
    name: "Team",
    icon: "",
    component: _teamSection,
    layout: "",
    show: "no",
  },
  {
    path: "/advertisement",
    name: "Advertisement",
    icon: "",
    component: _advertisement,
    layout: "",
    show: "no",
  },
  // {
  //   path: "/under-moderation",
  //   name: "Under Moderation",
  //   icon: "",
  //   component: _underModeration,
  //   layout: "",
  //   show: "no",
  // },
  // ADMIN ROUTES
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "",
    component: _Dashboard,
    layout: "ADMIN",
    show: "yes",
  },
  {
    path: "",
    name: "Pages",
    icon: "",
    component: "",
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/AboutUs",
        name: "About Us",
        icon: "",
        component: _AboutUsManagement,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/teams",
        name: "Team",
        icon: "",
        component: _teams,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/AdvertisementManagement",
        name: "Advertisement",
        icon: "",
        component: _advertisementManagement,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/privacy",
        name: "Privacy & Policy",
        icon: "",
        component: _PrivacyManagement,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/faqsManagement",
        name: "FAQs",
        icon: "",
        component: _FaqsManagement,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/terms",
        name: "Terms & Conditions",
        icon: "",
        component: _TermsManagement,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Users",
    icon: "",
    component: _UsersManagement,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Users",
        name: "All Users",
        icon: "",
        component: _UsersManagement,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editUser",
        name: "Edit Users",
        icon: "",
        component: _editUserManagement,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addUser",
        name: "Create User",
        icon: "",
        component: _addUserManagement,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Languages",
    icon: "",
    component: _LanguageManagement,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Languages",
        name: "All Languages",
        icon: "",
        component: _LanguageManagement,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editLanguage",
        name: "Edit Language",
        icon: "",
        component: _editLanguageManagement,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addLanguage",
        name: "Create Language",
        icon: "",
        component: _addLanguageManagement,
        layout: "ADMIN",
        show: "no",
      },
    ],
  },
  {
    path: "",
    name: "Comments",
    icon: "",
    component: _comments,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Comments",
        name: "All Comments",
        icon: "",
        component: _comments,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editComment",
        name: "Edit Comments",
        icon: "",
        component: _editComments,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/ReportedComments",
        name: "Reported Comments",
        icon: "",
        component: _ReportedComments,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editReportedComment",
        name: "Edit Reported Comment",
        icon: "",
        component: _editReportedComment,
        layout: "ADMIN",
        show: "no",
      },
    ],
  },
  {
    path: "",
    name: "Short Stories Images",
    icon: "",
    component: _Backgrounds,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Backgrounds",
        name: "All Short Stories Images",
        icon: "",
        component: _Backgrounds,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editBackground",
        name: "Edit Short Stories Image",
        icon: "",
        component: _editBackground,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addBackground",
        name: "Create Short Stories Image",
        icon: "",
        component: _addBackground,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Categories",
    icon: "",
    component: _Categories,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Categories",
        name: "All Categories",
        icon: "",
        component: _Categories,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/subCategories",
        name: "All Sub Categories",
        icon: "",
        component: _subCategory,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editCategory",
        name: "Edit Category",
        icon: "",
        component: _editCategory,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/editSubCategory",
        name: "Edit Sub Category",
        icon: "",
        component: _editSubCategory,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addCategory",
        name: "Create Category",
        icon: "",
        component: _addCategory,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/addSubCategory",
        name: "Create Sub Category",
        icon: "",
        component: _addSubCategory,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Products",
    icon: "",
    component: _Products,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Products",
        name: "All Products",
        icon: "",
        component: _Products,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editProduct",
        name: "Edit Product",
        icon: "",
        component: _editProduct,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addProduct",
        name: "Create Product",
        icon: "",
        component: _addProduct,
        layout: "ADMIN",
        show: "no",
      },
    ],
  },
  {
    path: "",
    name: "Challenges",
    icon: "",
    component: _AllChallengesManagement,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Challenges",
        name: "All Challenges",
        icon: "",
        component: _AllChallengesManagement,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editChallenge",
        name: "Edit Challenge",
        icon: "",
        component: _EditChallengeManagement,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addChallenge",
        name: "Create Challenge",
        icon: "",
        component: _addChallengeManagement,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Email Notifications",
    icon: "",
    component: _EmailNotifications,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/EmailNotifications",
        name: "All Email Notifications",
        icon: "",
        component: _EmailNotifications,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/SendEmailNotification",
        name: "Send Email Notification",
        icon: "",
        component: _SendEmailNotification,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/EditEmailNotification",
        name: "Edit Email Notification",
        icon: "",
        component: _EditEmailNotification,
        layout: "ADMIN",
        show: "no",
      },
    ],
  },
  {
    path: "",
    name: "Sliders",
    icon: "",
    component: _Sliders,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Sliders",
        name: "All Sliders",
        icon: "",
        component: _Sliders,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editSlider",
        name: "Edit Slider",
        icon: "",
        component: _editSlider,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addSlider",
        name: "Create Slider",
        icon: "",
        component: _addSlider,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Advertisements",
    icon: "",
    component: _SubSliders,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/SubSliders",
        name: "All Advertisements",
        icon: "",
        component: _SubSliders,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editSubSlider",
        name: "Edit Advertisement",
        icon: "",
        component: _editSubSlider,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addSubSlider",
        name: "Create Advertisement",
        icon: "",
        component: _addSubSlider,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Topic Of Interests",
    icon: "",
    component: _TopicOfInterests,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/topicOfInterests",
        name: "All Topic Of Interests",
        icon: "",
        component: _TopicOfInterests,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editTopicOfInterest",
        name: "Edit Topic Of Interest",
        icon: "",
        component: _editTopicOfInterest,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addTopicOfInterest",
        name: "Create Topic Of Interest",
        icon: "",
        component: _addTopicOfInterest,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Live Streamings",
    icon: "",
    component: _liveStreamings,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/liveStreamings",
        name: "All Live Streamings",
        icon: "",
        component: _liveStreamings,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editLiveStreaming",
        name: "Edit Live Streaming",
        icon: "",
        component: _editLiveStreaming,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addLiveStreaming",
        name: "Create Live Streaming",
        icon: "",
        component: _addLiveStreaming,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Report Answers",
    icon: "",
    component: _ReportAnswers,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/ReportAnswers",
        name: "All Report Answers",
        icon: "",
        component: _ReportAnswers,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editReportAnswer",
        name: "Edit Report Answer",
        icon: "",
        component: _editReportAnswer,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addReportAnswer",
        name: "Create Report Answer",
        icon: "",
        component: _addReportAnswer,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Badges",
    icon: "",
    component: _Badges,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Badges",
        name: "All Badges",
        icon: "",
        component: _Badges,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editBadge",
        name: "Edit Badge",
        icon: "",
        component: _editBadge,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addBadge",
        name: "Create Badge",
        icon: "",
        component: _addBadge,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "",
    name: "Posts",
    icon: "",
    component: _Posts,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Posts",
        name: "Moderations",
        icon: "",
        component: _Posts,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editPost",
        name: "Edit Post",
        icon: "",
        component: _editPost,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addPost",
        name: "Create Post",
        icon: "",
        component: _addPost,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/addPost/generate-card",
        name: "Create Short Story Card",
        icon: "",
        component: _shortShortCard,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/addPost/generate-card/preview",
        name: "Create Short Story Card Preview",
        icon: "",
        component: _shortShortCardPreview,
        layout: "ADMIN",
        show: "no",
      },
      {
        path: "/ReportedPosts",
        name: "Reported Post",
        icon: "",
        component: _ReportedPosts,
        layout: "ADMIN",
        show: "yes",
      },
      {
        path: "/editReportedPost",
        name: "Edit Reported Post",
        icon: "",
        component: _editReportedPost,
        layout: "ADMIN",
        show: "no",
      },
    ],
  },
  {
    path: "",
    name: "Widthdrawals",
    icon: "",
    component: _Widthdrawals,
    layout: "ADMIN",
    show: "yes",
    subMenu: [
      {
        path: "/Widthdrawals",
        name: "All Widthdrawals",
        icon: "",
        component: _Widthdrawals,
        layout: "ADMIN",
        show: "yes",
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "",
    component: _Settings,
    layout: "ADMIN",
    show: "yes",
  },
];
export default routes;

import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getRequest, uploadURL } from "../../../helper/api";
import Layout from "../../../layouts/admin/Layout";

import ReactSelect from "react-select";
import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";

const Backgrounds = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(true);
  const [tableData, setTableData] = useState([]);
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [
    totalShortStoriesBackgroundsCount,
    setTotalShortStoriesBackgroundsCount,
  ] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const getAllBackgrounds = async (page = 1, limit = 10, status = "") => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/shortStoriesBackground/backgrounds`,
        token,
        {
          limit: limit,
          page: page,
          status: status,
        }
      );
      setTableHead([
        {
          name: "Image",
          sortable: true,
          cell: (row, index, column, id) => (
            <>
              <Image
                src={uploadURL + row.image}
                fluid
                height={50}
                width={50}
                roundedCircle
                style={{
                  height: "50px",
                  width: "50px",
                  objectFit: "cover",
                }}
              />
            </>
          ),
        },
        {
          name: "Status",
          sortable: true,
          cell: (row, index, column, id) => (
            <>
              {row?.status === "active" && (
                <span className="badge bg-success">
                  {row?.status.capitalize()}
                </span>
              )}
              {(row?.status === "deactive" || row?.status === "rejected") && (
                <span className="badge bg-danger">
                  {row?.status.capitalize()}
                </span>
              )}
            </>
          ),
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editBackground(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </>
          ),
        },
      ]);
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        setTotalShortStoriesBackgroundsCount(
          response?.result.data?.totalShortStoriesBackgroundsCount
        );
        setTableData(response?.result?.data?.backgrounds);
      } else {
        setTotalShortStoriesBackgroundsCount(0);
        setTableData([]);
      }
    } catch (error) {
      console.log("Get All Backgrounds Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllBackgrounds(page, perPage, statusFilter);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    getAllBackgrounds(1, newPerPage, statusFilter);
  };

  const handleStatusFilter = (selectedOption) => {
    const statusValue = selectedOption?.value;
    setStatusFilter(statusValue);
    setCurrentPage(1);
    getAllBackgrounds(1, perPage, statusValue);
  };

  const editBackground = async (backgroundId) => {
    navigate(`/editBackground`, {
      state: { backgroundId },
    });
  };

  useEffect(() => {
    getAllBackgrounds();
  }, []);

  return (
    <Layout>
      <section id="Backgrounds_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <ReactSelect
                  placeholder="Status"
                  onChange={handleStatusFilter}
                  options={statusOptions}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalShortStoriesBackgroundsCount} // Assuming you have a total count in your API response
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Backgrounds;

import React, { useEffect, useState } from "react";
import Layout from "../../layouts/public/Layout";
import PublicImages from "../../constants/public/images";
import AudioPlayer from "react-h5-audio-player";

import { faBookmark as notBookmarked } from "@fortawesome/free-regular-svg-icons";
import {
  faBookmark as Bookmarked,
  faPaperPlane,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartFilled } from "@fortawesome/free-solid-svg-icons";

import ReactPlayer from "react-player/lazy";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Card,
  Image,
  Dropdown,
  Nav,
  Ratio,
} from "react-bootstrap";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faBoltLightning,
  faCopy,
  faEllipsisV,
  faEye,
  faShare,
  faShareAlt,
  faShareNodes,
  faSquareShareNodes,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentAlt,
  faHeart,
  faShareSquare,
} from "@fortawesome/free-regular-svg-icons";
import {
  faFacebook,
  faFacebookF,
  faLinkedinIn,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { getItemFromLocalStorage } from "../../helper/helper";
import {
  getRequest,
  postRequest,
  postRequestForm,
  uploadURL,
} from "../../helper/api";
import moment from "moment";
import Loader from "../../components/Loader";
import ImageCard from "../../components/public/Pages/ImageCard";
import { toast } from "react-toastify";
import Comments from "../../components/public/Pages/Comments";
import { Button, Comment, Form, Header } from "semantic-ui-react";
import { socket } from "../../helper/socket";
import ReportPopUp from "../../components/public/PopUps/ReportPopUp";
import { useMemo } from "react";
import AdminImages from "../../constants/admin/images";

export default function Single() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isModeration } = state || false;
  const { product, category, post } = useParams();
  if (!product || !category || !post) {
    navigate(-1);
  }
  const [loading, setLoading] = useState(true);
  const [previousPostdata, setPreviousPostData] = useState(null);
  const [postdata, setPostData] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [nextPostdata, setNextPostData] = useState(null);
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [content, setContent] = useState("");
  const [showReportPostModal, setShowReportPostModal] = useState(false);
  const [showReportCommentModal, setShowReportCommentModal] = useState(false);
  const [reportCommentId, setReportCommentId] = useState("");
  const [isLiked, setIsLiked] = useState({});
  const [isBookmark, setIsBookmark] = useState({});
  const [hashtags, setHashtags] = useState([]);
  const [sendingComment, setSendingComment] = useState(false);

  const getSinglePost = async (product, category, post, isModeration) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/post/single-post`,
        token,
        {
          productSlug: product,
          categorySlug: category,
          postSlug: post,
          isModeration: isModeration ? true : false,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        const { post, nextPost, previousPost, relatedPost, hashtags } =
          result?.data;
        setPreviousPostData(previousPost);
        setPostData(post);
        setNextPostData(nextPost);
        setRelatedPosts(relatedPost);
        getComments(post._id);
        setHashtags(hashtags);
      } else if (error?.response?.status === 400) {
        setError(error?.response?.data?.message);
        if (error?.response?.data?.message === "No post available") {
          navigate("/404");
        }
      }
    } catch (error) {
      console.log("Get Single Post Error", error);
    } finally {
      setLoading(false);
    }
  };

  const getCurrentUrl = () => {
    const url = window.location.href;
    // const url = "https://niosse.com" + location.pathname;
    return url;
  };

  const copyToClipboard = () => {
    const currentUrl = getCurrentUrl();

    const tempInput = document.createElement("input");
    tempInput.value = currentUrl;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");

    document.body.removeChild(tempInput);

    toast.success("Copied to Clipboard!", {
      position: "top-center", theme: "colored",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  const getComments = async (postId) => {
    try {
      setCommentsLoading(true);
      const { result, error } = await getRequest(
        `/api/secure/comment/get-all-comments-by-post`,
        "",
        {
          postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setComments(result?.data?.comments);
      } else if (error?.response?.status === 400) {
        setError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log("Get Comments Error", error);
    } finally {
      setCommentsLoading(false);
    }
  };

  const postComment = async (postId, content) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequest(
        `/api/secure/comment/post-comment`,
        token,
        {
          postId,
          content,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success("Comment Posted!", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        setError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log("Post Comment Error", error);
    } finally {
      setContent("");
      setSendingComment(false);
    }
  };

  const postViewCount = async (postId) => {
    try {
      const { result, error } = await postRequest(
        `/api/secure/userPreferences/view-post`,
        "",
        {
          postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        console.log("View Count Posted");
      } else if (error?.response?.status === 400) {
        setError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log("Post View Count Error", error);
    } finally {
    }
  };

  const postShareCount = async (postId) => {
    try {
      const { result, error } = await postRequest(
        `/api/secure/userPreferences/share-post`,
        "",
        {
          postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        console.log("Share Count Posted");
      } else if (error?.response?.status === 400) {
        setError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log("Post Share Count Error", error);
    } finally {
    }
  };

  const handleLikePost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/like-post`,
        token,
        {
          postId: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsLiked((prev) => {
          const newState = { ...prev };
          let isLiked = false;

          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isLiked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isLiked = true;
          }

          // Update the like count in the corresponding post data
          setPostData((prevData) =>
            prevData._id === postId
              ? {
                  ...prevData,
                  likeCount: isLiked
                    ? prevData.likeCount + 1
                    : prevData.likeCount - 1,
                }
              : prevData
          );

          if (isLiked) {
            toast.success(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center", theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center", theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }

          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBookmarkPost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/bookmark-post`,
        token,
        {
          postIdToToggle: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsBookmark((prev) => {
          let isBookmarked = false;
          const newState = { ...prev };
          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isBookmarked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isBookmarked = true;
          }
          if (isBookmarked) {
            toast.success(`Post has been Bookmarked!`, {
              position: "top-center", theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(
              `Post has been ${
                isBookmarked ? "Bookmarked!" : "Un-Bookmarked!"
              }!`,
              {
                position: "top-center", theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              }
            );
          }
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const formatString = (input) => {
    return input
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const toggleReportPostModal = () => {
    setShowReportPostModal(true);
  };
  const handleReportComment = (commentId) => {
    setReportCommentId(commentId);
    setShowReportCommentModal(true);
  };

  const getUserLikedAndBookmarkedPosts = useMemo(() => {
    return async () => {
      try {
        setLoading(true);
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result, error } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            const { userPrefrences } = result?.data;
            const { likedPost, bookmarks } = userPrefrences;
            setIsLiked((prev) => ({
              ...prev,
              ...likedPost.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
            setIsBookmark((prev) => ({
              ...prev,
              ...bookmarks.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
          }
        } else {
          setIsLiked({});
          setIsBookmark({});
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
        setIsLiked({});
        setIsBookmark({});
      } finally {
        setLoading(false); // Ensure isLoading is set to false
      }
    };
  }, []);
  useEffect(() => {
    getUserLikedAndBookmarkedPosts();
  }, [getUserLikedAndBookmarkedPosts]);

  useEffect(() => {
    setComments([]);
    console.log("socket", socket);
    function newComment(value) {
      setComments((previous) => [...previous, value]);
      console.log(value);
    }
    if (socket) {
      socket.on("newComment", newComment);
    }

    return () => {
      if (socket) {
        socket.off("newComment", newComment);
      }
    };
  }, []);

  useEffect(() => {
    if (postdata) {
      postViewCount(postdata._id);
    }
  }, [postdata]);

  useEffect(() => {
    getSinglePost(product, category, post, isModeration);
    const user = getItemFromLocalStorage("USER");
    if (user) {
      setUser(JSON.parse(user));
    }
  }, [product, category, post, isModeration]);

  return (
    <Layout>
      {!postdata || loading ? (
        <Loader loading={true} />
      ) : (
        <>
          <section className="blog_2">
            <Container>
              <div className="sec_contant text-center">
                <h2 className="hin text-capitalize mb-3">{postdata.title}</h2>
              </div>
              <div className="date text-center d-flex justify-content-center flex-column flex-sm-row">
                <p className="m-0">
                  <span>
                    {postdata.viewCount} <FontAwesomeIcon icon={faEye} />
                  </span>
                  {"  "}
                  <a href="#commentSection">
                    {postdata.comments.length}{" "}
                    <FontAwesomeIcon icon={faCommentAlt} />
                  </a>
                  {"  "}
                  <span>
                    {postdata.likeCount} <FontAwesomeIcon icon={faHeart} />
                  </span>
                  <span className="space d-none d-sm-inline">|</span>
                </p>
                <p className="m-0">
                  {moment(postdata.createdAt).format("D-M-Y h:mm:ss A")}
                  <span className="space d-none d-sm-inline">|</span>
                </p>
                <p>
                  By {"  "}
                  <Link
                    // className="nav-link"
                    to={`/profile/${postdata.userId.username}`}
                  >
                    {postdata.userId.firstName} {postdata.userId.lastName}
                  </Link>
                </p>
              </div>
            </Container>
          </section>

          {!formatString(product)?.includes("Audio") &&
            !formatString(product)?.includes("Video") && (
              <section className="bannar singlePostBanner">
                <Container>
                  <Row className="justify-content-center">
                    <Col className="text-center position-relative">
                      <Image
                        fluid
                        src={uploadURL + postdata.featuredImage}
                        className="w-100 postBannerImage"
                        alt={postdata.title}
                      />
                      {postdata?.isStarmarked && (
                        <Link
                          to={`/${product}/challenges/${postdata?.challengeId?.slug}`}
                          className="btn-default-2 addToBookmarkButton starmarkIcon"
                          style={{
                            right: postdata?.isWinner ? 70 : 20,
                          }}
                        >
                          <Image
                            src={AdminImages.StarmarkGold}
                            height={30}
                            width={30}
                            className="img-fluid"
                          />
                        </Link>
                      )}
                      {postdata?.isWinner && (
                        <Link
                          to={`/${product}/challenges/${postdata?.challengeId?.slug}`}
                          className="btn-default-2 addToBookmarkButton winnerIcon"
                          style={{
                            right: 20,
                          }}
                        >
                          <Image
                            src={PublicImages.Trophy}
                            height={30}
                            width={30}
                            className="img-fluid"
                          />
                        </Link>
                      )}
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

          {formatString(product)?.includes("Video") && (
            <section className="bannar singlePostBanner">
              <Container>
                <Row className="justify-content-center">
                  <Col className="text-center position-relative">
                    {/* <Image
                      fluid
                      src={uploadURL + postdata.featuredImage}
                      className="w-100"
                      alt={postdata.title}
                    /> */}

                    {postdata?.isStarmarked && (
                      <Link
                        to={`/${product}/challenges/${postdata?.challengeId?.slug}`}
                        className="btn-default-2 addToBookmarkButton starmarkIcon"
                        style={{
                          right: postdata?.isWinner ? 70 : 20,
                        }}
                      >
                        <Image
                          src={AdminImages.StarmarkGold}
                          height={30}
                          width={30}
                          className="img-fluid"
                        />
                      </Link>
                    )}
                    {postdata?.isWinner && (
                      <Link
                        to={`/${product}/challenges/${postdata?.challengeId?.slug}`}
                        className="btn-default-2 addToBookmarkButton winnerIcon"
                        style={{
                          right: 20,
                        }}
                      >
                        <Image
                          src={PublicImages.Trophy}
                          height={30}
                          width={30}
                          className="img-fluid"
                        />
                      </Link>
                    )}
                    <Ratio aspectRatio="16x9">
                      {/* <video controls className="w-100">
                        <source src={uploadURL + postdata.featuredFile} />
                      </video> */}
                      <ReactPlayer
                        url={uploadURL + postdata.featuredFile}
                        controls
                        playing
                        light={uploadURL + postdata.featuredImage}
                        config={{
                          file: { attributes: { controlsList: "nodownload" } },
                        }}
                        // Disable right click
                        onContextMenu={(e) => e.preventDefault()}
                        width="100%"
                        height="100%"
                        showPreview={true}
                      />
                    </Ratio>
                  </Col>
                </Row>
              </Container>
            </section>
          )}

          {formatString(product)?.includes("Audio") && (
            <section className="bannar singlePostBanner">
              <Container>
                <Row className="justify-content-center">
                  <Col className="text-center position-relative">
                    {postdata?.isStarmarked && (
                      <Link
                        to={`/${product}/challenges/${postdata?.challengeId?.slug}`}
                        className="btn-default-2 addToBookmarkButton starmarkIcon"
                        style={{
                          right: postdata?.isWinner ? 70 : 20,
                        }}
                      >
                        <Image
                          src={AdminImages.StarmarkGold}
                          height={30}
                          width={30}
                          className="img-fluid"
                        />
                      </Link>
                    )}
                    {postdata?.isWinner && (
                      <Link
                        to={`/${product}/challenges/${postdata?.challengeId?.slug}`}
                        className="btn-default-2 addToBookmarkButton winnerIcon"
                        style={{
                          right: 20,
                        }}
                      >
                        <Image
                          src={PublicImages.Trophy}
                          height={30}
                          width={30}
                          className="img-fluid"
                        />
                      </Link>
                    )}
                    <Image
                      fluid
                      src={uploadURL + postdata.featuredImage}
                      className="w-100"
                      alt={postdata.title}
                    />
                    <AudioPlayer
                      autoPlay={false}
                      src={uploadURL + postdata.featuredFile}
                      onPlay={(e) => console.log("onPlay")}
                      // other props here
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}

          <section className="mid">
            <Container>
              <Row className="align-items-center">
                <Col
                  lg={4}
                  md={4}
                  xs={12}
                  className="gap-2  d-flex justify-content-center justify-content-md-start mb-3 mb-md-0"
                >
                  <span>
                    <FontAwesomeIcon icon={faEye} /> {postdata.viewCount}
                  </span>
                  <span
                    className="likes"
                    onClick={() => handleLikePost(postdata._id)}
                    style={{ cursor: "pointer" }}
                  >
                    {console.log(
                      "isLiked",
                      isLiked,
                      postdata._id,
                      typeof isLiked === "Boolean",
                      typeof isLiked
                    )}
                    {isLiked[postdata._id] ? (
                      <FontAwesomeIcon icon={faHeartFilled} color="#e5097d" />
                    ) : (
                      <FontAwesomeIcon icon={faHeart} color="#000" />
                    )}{" "}
                    {postdata.likeCount}
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faShare} /> {postdata.shareCount}
                  </span>
                </Col>
                <Col lg={8} md={8} xs={12} className="ecs">
                  <ul className="nav justify-content-end">
                    <li className="nav-item">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                          getCurrentUrl()
                        )}`}
                        onClick={() => postShareCount(postdata._id)}
                        target="_blank"
                        className="nav-link facebook"
                      >
                        <FontAwesomeIcon
                          icon={faFacebookF}
                          onClick={getCurrentUrl}
                        />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                          getCurrentUrl()
                        )}`}
                        onClick={() => postShareCount(postdata._id)}
                        target="_blank"
                        className="nav-link twitter"
                      >
                        <FontAwesomeIcon icon={faXTwitter} />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                          getCurrentUrl()
                        )}`}
                        onClick={() => postShareCount(postdata._id)}
                        target="_blank"
                        className="nav-link linkedin"
                      >
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                          getCurrentUrl()
                        )}`}
                        onClick={() => postShareCount(postdata._id)}
                        target="_blank"
                        className="nav-link whatsapp"
                      >
                        <FontAwesomeIcon icon={faWhatsapp} />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link share"
                        onClick={() => postShareCount(postdata._id)}
                      >
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={copyToClipboard}
                        />
                      </a>
                    </li>
                    <li className="nav-item">
                      <Button
                        className="nav-link share"
                        onClick={() => handleBookmarkPost(postdata._id)}
                      >
                        {isBookmark ? (
                          <FontAwesomeIcon icon={Bookmarked} />
                        ) : (
                          <FontAwesomeIcon icon={notBookmarked} />
                        )}
                      </Button>
                    </li>

                    <li className="nav-item">
                      <Dropdown renderMenuOnMount align="end">
                        <Dropdown.Toggle id="dropdown-basic">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="end">
                          <Dropdown.Item
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleReportPostModal();
                            }}
                          >
                            Report
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </section>

          {!formatString(product)?.includes("Stories") && postdata.article && (
            <section className="mid2">
              <div className="container">
                <div
                  dangerouslySetInnerHTML={{
                    __html: postdata.article,
                  }}
                />
              </div>
            </section>
          )}
          {/* Disclaimer */}
          <section className={`mid3 mt-2 ${!postdata.article && "pt-0"}`}>
            <div className="container">
              <div className="bol text-center">
                <h4>
                  Disclaimer: The opinions expressed in this post are the
                  author's personal views. It is not necessary that these
                  opinions or views reflect womanii.com's perspectives. Any
                  mistakes or inaccuracies are the author's own, and Womanii
                  holds no responsibility or liability for them.
                </h4>
              </div>
            </div>
          </section>

          {hashtags?.length > 0 && (
            <section className="mid4">
              <Container className="justify-content-start">
                <div className="hashTag d-flex gap-2 align-items-center justify-content-start">
                  <h5 className="m-0">Tags:</h5>
                  <Nav as={"ul"} className="gap-2">
                    {hashtags.map((item, index) => (
                      <Nav.Item key={index} as={"li"}>
                        <Link
                          className="nav-link btn rounded-pill btn-default-3 w-auto px-3 py-1 h-auto"
                          to={`/trending-posts?tag=${item.hashtag}`}
                        >
                          {item.hashtag}
                        </Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Container>
            </section>
          )}
          {/* ----------------------comment section---------------------- */}
          {/* Next/Previous Posts */}
          {(previousPostdata || nextPostdata) && (
            <section className="nextPeriviousPostsSection mb-3">
              <Container>
                <Row>
                  {previousPostdata && (
                    <Col
                      lg={!nextPostdata ? 12 : 6}
                      md={!nextPostdata ? 12 : 6}
                      sm={!nextPostdata ? 12 : 6}
                    >
                      <div className="previousPostCover">
                        <Link
                          to={`/${previousPostdata?.productId?.slug}/${previousPostdata?.categoryId?.slug}/${previousPostdata.slug}`}
                          className="btn btn-default-2 mb-3 w-auto h-auto"
                        >
                          <FontAwesomeIcon icon={faArrowLeftLong} /> Previous
                        </Link>
                        <div className="previousPost">
                          <div className="img-cover">
                            <Link
                              to={`/${previousPostdata?.productId?.slug}/${previousPostdata?.categoryId?.slug}/${previousPostdata.slug}`}
                            >
                              <Image
                                src={uploadURL + previousPostdata.featuredImage}
                                alt=""
                                fluid
                                className="w-100"
                              />
                            </Link>
                          </div>
                          <h3>
                            <Link
                              to={`/${previousPostdata?.productId?.slug}/${previousPostdata?.categoryId?.slug}/${previousPostdata.slug}`}
                            >
                              {previousPostdata.title}
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </Col>
                  )}
                  {nextPostdata && (
                    <Col
                      lg={!previousPostdata ? 12 : 6}
                      md={!previousPostdata ? 12 : 6}
                      sm={!previousPostdata ? 12 : 6}
                      className="bl"
                    >
                      <div className="nextPostCover">
                        <Link
                          to={`/${nextPostdata?.productId?.slug}/${nextPostdata?.categoryId?.slug}/${nextPostdata.slug}`}
                          className="btn btn-default-2 mb-3 w-auto h-auto"
                        >
                          Next <FontAwesomeIcon icon={faArrowRightLong} />
                        </Link>
                        <div className="nextPost">
                          <h3>
                            <Link
                              to={`/${nextPostdata?.productId?.slug}/${nextPostdata?.categoryId?.slug}/${nextPostdata.slug}`}
                            >
                              {nextPostdata.title}
                            </Link>
                          </h3>
                          <div className="img-cover">
                            <Link
                              to={`/${nextPostdata?.productId?.slug}/${nextPostdata?.categoryId?.slug}/${nextPostdata.slug}`}
                            >
                              <Image
                                src={uploadURL + nextPostdata.featuredImage}
                                alt=""
                                fluid
                                className="w-100"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Container>
            </section>
          )}
          {/* ----------------------comment section---------------------- */}
          <section id="commentSection">
            {comments.length > 0 && (
              <section className="mid7" id="commentSection">
                <Container>
                  <Comment.Group className="mw-100" threaded size="large">
                    <Header as="h3" dividing>
                      Comments
                    </Header>
                    {commentsLoading ? (
                      <Loader loading={commentsLoading} />
                    ) : (
                      comments?.map((item, index) => (
                        <Comments
                          {...item}
                          key={index}
                          onClickReportButton={handleReportComment}
                        />
                      ))
                    )}
                  </Comment.Group>
                </Container>
              </section>
            )}

            {getItemFromLocalStorage("TOKEN") && (
              <section className="commentForm">
                <Container>
                  <h5 className="mb-3">Post a Comment</h5>
                  <div className="commentFormCover">
                    <div
                      className="d-flex gap-2 gap-md-4  align-items-center flex-grow-1  commentFormInputCover"
                      style={{
                        background: "#EAF1F3",
                      }}
                    >
                      {user?.profileImage ? (
                        <Image
                          src={uploadURL + user?.profileImage}
                          fluid
                          alt=""
                          height={40}
                          width={40}
                          className="m-0"
                        />
                      ) : (
                        <></>
                      )}
                      <InputGroup>
                        <FormControl
                          type="text"
                          placeholder="Write Here"
                          onChange={(e) => {
                            setContent(e.target.value);
                          }}
                          value={content}
                          style={{
                            background: "transparent",
                          }}
                        />
                      </InputGroup>
                      <Button
                        type="button"
                        className="commentFormSubmit bg-transparent border-0 p-0 me-2"
                        style={{
                          fontSize: "22px",
                        }}
                        disabled={sendingComment}
                        onClick={() => {
                          setSendingComment(true);
                          postComment(postdata._id, content);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={sendingComment ? faSpinner : faPaperPlane}
                          className={sendingComment ? "fa-spin" : ""}
                          color="#e32975"
                        />
                      </Button>
                    </div>
                  </div>
                </Container>
              </section>
            )}
          </section>

          {/* ----------comment section end---------------------- */}

          {/* Related Posts */}
          {relatedPosts?.length > 0 && (
            <section className="listing_sec bg-treitory">
              <Container>
                <h1>
                  <Image src={PublicImages.PopularPost} fluid alt="" />
                  Related {product}
                </h1>
                <Row>
                  {relatedPosts.map((item) => (
                    <ImageCard
                      image={uploadURL + item?.featuredImage}
                      authorImage={
                        item.userId?.profileImage
                          ? uploadURL + item.userId.profileImage
                          : ""
                      }
                      subCatName={`${item.subCategoryId?.name}`}
                      productSlug={`${item?.productId?.slug}`}
                      catSlug={`${item?.categoryId?.slug}`}
                      subCatSlug={`${item.subCategoryId?.slug}`}
                      authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                      views={item.viewCount}
                      comments={item.comments}
                      likes={item.likeCount}
                      title={item.title}
                      date={item.createdAt}
                      username={item.userId.username}
                      link={`/${product}/${category}/${item.slug}`}
                      size={3}
                      sizeMD={6}
                    />
                  ))}
                </Row>
              </Container>
            </section>
          )}
          <ReportPopUp
            userId={user?._id}
            postId={postdata?._id}
            show={showReportPostModal}
            handleCloseModal={() => setShowReportPostModal(false)}
          />
          <ReportPopUp
            userId={user?._id}
            commentId={reportCommentId}
            show={showReportCommentModal}
            handleCloseModal={() => setShowReportCommentModal(false)}
          />
        </>
      )}
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import Layout from "../../layouts/public/Layout";
import PublicImages from "../../constants/public/images";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { postRequestForm } from "../../helper/api";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "../../components/Loader";

function ContactUs() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState(null);
  const [PageLoader, setPageLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(null);

  const validateContactForm = Yup.object().shape({
    fullname: Yup.string().required("Full name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleContactSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await postRequestForm(
        "/api/secure/page/send-contact-query",
        "",
        values
      );
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        setSuccess(response?.result?.data?.data);
      } else {
        setError("Something went wrong");
      }
    } catch (error) {
      console.log("Get Page API error", error.message);
    } finally {
      setLoading(false);
    }
  };
  // const loadSiteData =  () => {
  //   try {
  //     if (localStorage.getItem("SITE_SETTINGS")) {
  //       setSettings(JSON.parse(localStorage.getItem("SITE_SETTINGS")));
  //     }
  //   } catch (error) {
  //     console.log("Get site setting error", error.message);
  //   } finally {
  //     setPageLoader(false);
  //   }
  // };
  // const loadSiteData = () => {
  //   try {
  //       const setting = localStorage.getItem("SITE_SETTINGS")
  //       const loadedSettings = JSON.parse(setting);
  //       console.log("Loaded Settings:", loadedSettings);
  //       setSettings(loadedSettings);
      
  //   } catch (error) {
  //     console.log("Get site setting error", error.message);
  //   } finally {
  //     console.log("loading loading loading");
  //     setPageLoader(false);
  //   }
  // };

  const ContactPage=({settings})=>{

    return (
      <>
        <section className="sec1">
          <div className="container">
            <div className="text-center">
              <h1>CONTACT US</h1>
            </div>
          </div>
        </section>
        <section className="sec2">
          <Container>
            {settings && <iframe
              src={`${settings.contact_us_iframe}`}
              width="100%"
              height="450"
              style={{
                border: 0,
              }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>}
          </Container>
        </section>
        {settings && <section className="sec3">
          <Container fluid>
            <Row>
              <Col lg={6} md={7} xs={12}>
                <div className="pt-5">
                  <h1>ADDRESS</h1>
                </div>
                <div className="states gb row">
                  <div className="col-lg-2 col-md-3 col-sm-2">
                    <div>
                      <a href="">
                        <img
                          src={PublicImages.pin_drop}
                          className="img-fluid w-37"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-7">
                    <div>
                      <p>{settings.address}</p>
                    </div>
                  </div>
                </div>
                <div className="states row bg">
                  <div className="col-lg-2  col-md-3 col-sm-2">
                    <div>
                      <a href="">
                        <img
                          src={PublicImages.call}
                          className="img-fluid w-37"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-m-7">
                    <div>
                      <p>{settings.phone}</p>
                    </div>
                  </div>
                </div>
                <div className="states row bg">
                  <div className="col-lg-2  col-md-3 col-sm-2">
                    <div>
                      <a href="">
                        <img
                          src={PublicImages.mail}
                          className="img-fluid w-37"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-sm-7">
                    <div>
                      <p>{settings.email}</p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={6} md={7} xs={12}>
                <div className="pt-5">
                  <h1>WRITE TO US</h1>
                </div>
                {loading ? (
                  <Loader loading={loading} />
                ) : (
                  <Formik
                    initialValues={{
                      fullname,
                      email,
                      subject,
                      message,
                    }}
                    validationSchema={validateContactForm}
                    onSubmit={handleContactSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="inputs">
                          <InputGroup className="mb-3 w-100" size="lg">
                            <Form.Control
                              type="text"
                              placeholder="Full name"
                              onChange={handleChange("fullname")}
                              handleBlur={handleBlur}
                              name="fullName"
                              defaultValue={values.fullname}
                            />
                          </InputGroup>
                          <InputGroup className="mb-3 w-100" size="lg">
                            <Form.Control
                              type="email"
                              placeholder="Email"
                              onChange={handleChange("email")}
                              handleBlur={handleBlur}
                              name="email"
                              defaultValue={values.email}
                            />
                          </InputGroup>
                          <InputGroup className="mb-3 w-100" size="lg">
                            <Form.Control
                              type="text"
                              placeholder="Subject"
                              onChange={handleChange("subject")}
                              handleBlur={handleBlur}
                              name="subject"
                              defaultValue={values.subject}
                            />
                          </InputGroup>
                          <InputGroup className="mb-3 w-100" size="lg">
                            <Form.Control
                              as="textarea"
                              type="text"
                              placeholder="Message"
                              onChange={handleChange("message")}
                              handleBlur={handleBlur}
                              name="message"
                              defaultValue={values.message}
                            />
                          </InputGroup>
                        </div>
                        {/* {<div id="status">
                    <div
                      className={`alert ${success
                        ? ""
                        : error ? "" : "d-none"
                        } ${success ? "alert-success" : "alert-danger"}`}
                      role="alert"
                    >
                      {success
                        ? success
                        : error
                      }
                    </div>
                  </div>} */}
                        <div id="status">
                          {console.log("errors", errors)}
                          {console.log("touch", touched)}

                          <div
                            className={`alert ${
                              (errors.email ||
                                errors.fullname ||
                                errors.message ||
                                errors.subject) &&
                              (touched.email ||
                                touched.fullname ||
                                touched.message ||
                                touched.subject)
                                ? ""
                                : "d-none"
                            } ${errors ? "alert-danger" : "alert-success"}`}
                            role="alert"
                          >
                            {errors.email && touched.email
                              ? errors.email
                              : errors.message && touched.message
                              ? errors.message
                              : errors.fullname && touched.fullname
                              ? errors.fullname
                              : errors.subject && touched.subject
                              ? errors.subject
                              : ""}
                          </div>
                          {success || error ? (
                            <div
                              className={`alert ${
                                success || error ? "" : "d-none"
                              } ${error ? "alert-danger" : "alert-success"}`}
                              role="alert"
                            >
                              {success ? success : error}
                            </div>
                          ) : null}
                        </div>
                        <InputGroup
                          className="  d-flex
                      justify-content-center"
                        >
                          <button className="btn-default">
                            SEND A MESSAGE
                          </button>
                        </InputGroup>
                      </Form>
                    )}
                  </Formik>
                )}
              </Col>
            </Row>
          </Container>
        </section>}
      </>
    );
  }

  

  return (
    <Layout>
      <ContactPage settings={settings} />
    </Layout>
  );
}

export default ContactUs;

import React from "react";
import { Button, Container, FormControl } from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../helper/api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";

const TopicOfInterests = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [topicOfInterest, setTopicOfInterest] = useState([]);

  const getAllTopicOfInterest = async () => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/topicOfInterest/get-all-topic-of-interest`,
        token
      );
      setTopicOfInterest(response?.result?.data?.topicOfInterests);
      setTableData(response?.result?.data?.topicOfInterests);
      setTableHead([
        {
          name: "Name",
          sortable: true,
          selector: (row) => row.name,
        },
        {
          name: "Category",
          sortable: true,
          selector: (row) => row.categoryId?.name,
        },
        {
          name: "Status",
          sortable: true,
          selector: (row) => row.status,
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() =>
                  editTopicOfInterest(row._id, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                className="del_btn ms-1"
                size="sm"
                variant="danger"
                onClick={() => TopicOfInterest(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);

      setPending(false);
    } catch (error) {
      console.log("Get All Topic Of Interest Error", error);
    }
  };

  const searchTopicOfInterest = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);

    if (searchValue === "") {
      setTableData(topicOfInterest);
    } else {
      const searchData = topicOfInterest.filter((val) =>
        val.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setTableData(searchData);
    }
  };

  const editTopicOfInterest = async (topicOfInterestId) => {
    navigate(`/editTopicOfInterest`, {
      state: { topicOfInterestId },
    });
  };

  const TopicOfInterest = async (topicOfInterestId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        `/api/secure/topicOfInterest/delete-topic-of-interest`,
        token,
        {
          topicOfInterestId,
        }
      );

      if (response.result.status === 200) {
        toast.success("Topic Of Interest Deleted", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Deleting Topic Of Interest Error", error);
    } finally {
      getAllTopicOfInterest();
      setPending(true);
    }
  };

  useEffect(() => {
    getAllTopicOfInterest();
  }, []);

  return (
    <Layout>
      <section id="TopicOfInterest_list" className="pt-5">
        <Container>
          <div className="d-flex">
            <FormControl
              type="text"
              placeholder="Search TopicOfInterest"
              className="me-2"
              value={searchItem}
              onChange={searchTopicOfInterest}
            />
          </div>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={_.sortBy(tableData, "total").reverse()}
              style={{
                borderRadius: "10px",
              }}
              pagination
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default TopicOfInterests;

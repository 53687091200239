import React, { useEffect, useState } from "react";
import Layout from "../../layouts/public/Layout";
import { Row } from "react-bootstrap";

import { getRequest, uploadURL } from "../../helper/api";
import Loader from "../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import ImageCard from "../../components/public/Pages/ImageCard";
import { useParams } from "react-router-dom";

const PostListings = () => {
  const { product } = useParams();
  const [allData, setAllData] = useState([]);
  const [allDataLength, setAllDataLength] = useState(0);
  const [latestPostsPage, setLatestPostsPage] = useState(1);
  const [latestPostLoading, setLatestPostLoading] = useState(false);
  const [latestPostEnded, setLatestPostEnded] = useState(true);
  const [latestError, setLatestError] = useState("");

  const getPostsByProductType = async (
    // latestPost,
    page = 1,
    product
  ) => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/post/posts-by-product`,
        "",
        {
          page,
          productSlug: product,
          limit: 9,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        setLatestError("");
        if (page === 1) {
          setAllData(result?.data?.posts);
          // Inside the function where you load more data
          setAllDataLength(result?.data?.posts);
        } else {
          setAllData((prevData) => [...prevData, ...result?.data?.posts]);
          // Inside the function where you load more data
          setAllDataLength(allData.length);
        }
        if (result?.data.currentPage === result?.data.totalPages) {
          setLatestPostEnded(false);
        }
        setLatestPostsPage((prevPage) => prevPage + 1);
      } else if (error?.response?.status === 400) {
        setLatestError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLatestPostLoading(false);
    }
  };

  useEffect(() => {
    setAllData([]);
    setAllDataLength(0);
    setLatestPostsPage(1);
    setLatestPostLoading(false);
    setLatestPostEnded(true);
    setLatestError("");
    getPostsByProductType(1, product);
  }, [product]);

  return (
    <Layout>
      <main id="main">
        <section className="listing_sec my-5">
          <div className="container">
            {latestPostLoading ? (
              <Loader loading={true} />
            ) : (
              <div>
                {latestError ? (
                  latestError
                ) : (
                  <InfiniteScroll
                    dataLength={allDataLength}
                    next={() => {
                      getPostsByProductType(
                        // latestPost,
                        latestPostsPage,
                        product
                      );
                    }}
                    inverse={false}
                    hasMore={latestPostEnded}
                    loader={<Loader loading={true} />}
                    scrollThreshold={0.9}
                    endMessage={
                      <p
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <b>Yay! You've seen it all!</b>
                      </p>
                    }
                  >
                    <Row className="m-0">
                      {allData?.map((item, index) => (
                        <ImageCard
                          image={uploadURL + item?.featuredImage}
                          authorImage={
                            item.userId?.profileImage
                              ? uploadURL + item.userId.profileImage
                              : ""
                          }
                          subCatName={`${item.subCategoryId?.name}`}
                          productSlug={`${item?.productId?.slug}`}
                          catSlug={`${item?.categoryId?.slug}`}
                          subCatSlug={`${item.subCategoryId?.slug}`}
                          authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                          views={item.viewCount}
                          comments={item.comments}
                          likes={item.likeCount}
                          title={item.title}
                          date={item.createdAt}
                          username={item.userId.username}
                          link={`/${item?.productId?.slug}/${item?.categoryId?.slug}/${item.slug}`}
                          isWinner={item.isWinner}
                          challengeLink={`/${item.productId?.slug}/challenges/${item.challengeId?.slug}`}
                          isStarmarked={item.isStarmarked}
                        />
                      ))}
                    </Row>
                  </InfiniteScroll>
                )}
              </div>
            )}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default PostListings;

import { faEye, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { getRequest } from "../../../helper/api";
import Layout from "../../../layouts/admin/Layout";

import { DateRangePicker } from "react-date-range";
import ReactSelect from "react-select";
import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";
import CommentAction from "./EditReportedComment";

const ReportedComments = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  // const statusOptions = [
  //   { value: "active", label: "Active" },
  //   { value: "deactive", label: "Deactive" },
  // ];
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const statusFilter = "reported";
  // const [statusFilter, setStatusFilter] = useState("");
  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const [posts, setPosts] = useState();
  const [post, setPost] = useState();
  const getAllComments = async (
    page = 1,
    limit = 10,
    search = "",
    status = "reported",
    startDate = "",
    endDate = "",
    post = "",
    user = ""
  ) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/comment/get-all-comments`,
        token,
        {
          page: page,
          limit: limit,
          search: search,
          status: status,
          startDate: startDate,
          endDate: endDate,
          postId: post,
          userId: user,
        }
      );
      setTableHead([
        {
          name: "Post",
          selector: (row) => row.postId?.title,
        },
        {
          name: "User",
          selector: (row) => `${row.userId?.firstName} ${row.userId?.lastName}`,
        },
        {
          name: "Reported By",
          selector: (row) =>
            `${row?.report?.userId?.firstName} ${row?.report?.userId?.lastName}`,
        },
        {
          name: "Replies",
          selector: (row) => row.childCommentIds?.length,
        },
        {
          name: "Status",
          selector: (row) => row.status,
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          // button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editReportedComment(row)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Link
                to={`/${row.postId.productId.slug}/${row.postId.categoryId?.slug}/${row.postId.slug}`}
                target="_BLANK"
                className="btn btn-sm text-light btn-info view_btn ms-1"
              >
                <FontAwesomeIcon icon={faEye} />
              </Link>
            </>
          ),
        },
      ]);
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        setTableData(response?.result?.data?.comments);
        setTotalRows(response?.result?.data?.totalComments);
      } else {
        setTableData([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.log("Get Moderations Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllComments(
      page,
      perPage,
      searchItem,
      statusFilter,
      startDate,
      endDate,
      post ? post?.value : "",
      user ? user?.value : ""
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    getAllComments(
      1,
      newPerPage,
      searchItem,
      statusFilter,
      startDate,
      endDate,
      post ? post?.value : "",
      user ? user?.value : ""
    );
  };

  const handleSearchFilter = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);
    setCurrentPage(1);
    getAllComments(
      1,
      perPage,
      searchValue,
      statusFilter,
      startDate,
      endDate,
      post ? post?.value : "",
      user ? user?.value : ""
    );
  };

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;

    getAllComments(
      1,
      perPage,
      searchItem,
      statusFilter,
      startDate,
      endDate,
      post ? post?.value : "",
      user ? user?.value : ""
    );
  };

  const handlePostFilter = (selectedOption) => {
    setPost(selectedOption);
    console.log(selectedOption);
    getAllComments(
      1,
      perPage,
      searchItem,
      statusFilter,
      startDate,
      endDate,
      selectedOption?.value,
      user ? user?.value : ""
    );
  };
  const handleUserFilter = (selectedOption) => {
    setUser(selectedOption);
    getAllComments(
      1,
      perPage,
      searchItem,
      statusFilter,
      startDate,
      endDate,
      post ? post?.value : "",
      selectedOption?.value
    );
  };

  const getAllPosts = async (query) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/post/all-posts`, token, {
        search: query,
        status: "active",
      });

      const mappedOptions = response?.result?.data?.posts?.map((option) => ({
        value: option._id,
        label: `${option.title}`,
      }));

      setPosts(mappedOptions);
    } catch (error) {
      console.log("Get All Sub Badges Error", error);
    } finally {
      //   setPending(false);
    }
  };

  const getAllUsers = async () => {
    try {
      //   setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/user/get-all`, token);

      const mappedOptions = response?.result?.data?.users.map((option) => ({
        value: option._id,
        label: `${option.firstName} ${option.lastName} (${option.email})`,
      }));
      setUsers(mappedOptions);
    } catch (error) {
      console.log("Get All Users Error", error);
    } finally {
      //   setPending(false);
    }
  };
  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const editReportedComment = (row) => {
    const reportId = row?.report?._id;
    const commentId = row?._id;
    navigate(`/editReportedComment`, {
      state: { reportId, commentId },
    });
  };
  useEffect(() => {
    getAllPosts();
    getAllUsers();
    getAllComments(currentPage, perPage);
  }, [currentPage, perPage]);

  return (
    <Layout>
      <section id="Posts_list" className="py-5">
        <Container>
          <Row className="mb-3">
            <Col lg={3} md={6} xs={12} className="mt-3">
              <FormControl
                type="text"
                placeholder="Search Posts"
                className="me-2"
                value={searchItem}
                onChange={handleSearchFilter}
              />
            </Col>

            <Col lg={3} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Post"
                  onChange={handlePostFilter}
                  options={posts}
                  value={post ? post : ""}
                  required
                  isClearable
                  onInputChange={(query) => {
                    getAllPosts(query);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Select User"
                  onChange={handleUserFilter}
                  options={users}
                  value={user ? user : ""}
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Dropdown autoClose={"outside"} className="w-100">
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setStartDate();
                        setEndDate();
                        getAllComments();
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default ReportedComments;

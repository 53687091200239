import OTPInput, { ResendOTP } from "otp-input-react";
import { postRequestForm } from "./../../helper/api";
import React, { useEffect, useState } from "react";
import Layout from "../../layouts/auth/Layout";
import {
  Button,
  Col,
  Image,
  InputGroup,
  Row,
  Container,
} from "react-bootstrap";
import PublicImages from "../../constants/public/images";

import { useNavigate, useLocation } from "react-router-dom";
import Loader from "./../../components/Loader";
import { setItemInLocalStorage } from "../../helper/helper";
import { toast } from "react-toastify";

export const EnterOtpScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOTP] = useState("");
  const [OTPTime, setOTPTime] = useState(120);
  const [OTPMsg, setOTPMsg] = useState("");
  const [_id, setId] = useState("");
  const [route, setRoute] = useState("");
  const [loading, setLoading] = useState(true);

  const renderButton = (buttonProps) => {
    return (
      <Button className="btn-resendOTP" type="button" {...buttonProps}>
        Resend
      </Button>
    );
  };
  const renderTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    // Create the formatted string
    const formattedTime = `${minutes > 0 ? `${minutes} minute` : ""}${
      minutes > 0 ? "s" : ""
    } ${minutes > 0 ? `and` : ""} ${seconds > 0 ? `${seconds} second` : ""}${
      seconds > 0 ? "s" : ""
    } ${(seconds || minutes) && "left"}`;

    return <>{formattedTime && <p className="m-0 ">{formattedTime}</p>}</>;
  };
  const resendOTP = async () => {
    try {
      setLoading(true);
      const { result, error } = await postRequestForm(
        `/api/auth/resendOTP`,
        "",
        {
          _id: _id,
        }
      );
      if (result?.status === 200) {
        setOTPMsg(
          "OTP has been sent to you email address. Please make sure to check spam folder."
        );
        setTimeout(() => {
          setOTPMsg("");
        }, 3000);
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.log(`Error of Sending OTP again`, err.message);
    } finally {
      setLoading(false);
      setOTPTime(120);
    }
  };
  const otpHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { result, error } = await postRequestForm(
        `/api/auth/verifyOTP`,
        "",
        {
          _id: _id,
          otp: otp,
        }
      );
      if (result?.status === 200) {
        const { user, token } = result?.data;
        if (route !== "set-new-password") {
          setItemInLocalStorage("TOKEN", token);
          setItemInLocalStorage("ROLE", user.role);
          setItemInLocalStorage("USER", JSON.stringify(user, null, 2));
        }
        if (route === "dashboard") {
          window.location.assign("/dashboard");
        } else {
          navigate(`/${route}`, {
            state: { _id: user._id },
          });
        }
      } else if (error?.response?.status === 400) {
        setOTP("");
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.log(`Error of OTP`, err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log("location", location);
    if (location?.state?._id === "" || location?.state === null) {
      navigate("/login");
    } else {
      setId(location?.state?._id);
    }
    if (location?.state?.pathToGo === "") {
      setRoute("dashboard");
    } else {
      setRoute(location?.state?.pathToGo);
    }
    setLoading(false);
  }, [location, navigate]);

  return (
    <Layout>
      <section className="forget-password">
        <Container>
          <Row className="bod p-6 align-items-center">
            <Col lg={6} md={12} xs={12} className="text-center d-none d-lg-block">
              <Image fluid src={PublicImages.image3} alt="" />
            </Col>
            <Col
              lg={6}
              md={12}
              xs={12}
              className="align-self-center text-center d-flex flex-column align-items-center"
            >
              {loading ? (
                <Loader loading={loading} />
              ) : (
                <form onSubmit={otpHandler}>
                  <h1 className="forget text-center">Enter OTP Number</h1>
                  <p className="access text-center">
                    Please Enter Your OTP To Login Into Your Account
                  </p>

                  <OTPInput
                    value={otp}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    disabled={false}
                    otpType={"number"}
                    className=""
                    // inputStyles={{
                    //   height: "67.58px",
                    //   width: "72.5px",
                    //   border: "1px solid rgba(167, 167, 167, 1)",
                    //   borderRadius: "5px",
                    // }}
                  />

                  <p className="access text-center mt-3">Did Not Get Code?</p>

                  <ResendOTP
                    maxTime={OTPTime}
                    renderButton={renderButton}
                    renderTime={renderTime}
                    onResendClick={resendOTP}
                    className="otp-resend justify-content-center align-items-center flex-column flex-md-row mb-3 align-items-md-baseline"
                  />
                  {OTPMsg ? (
                    <div className="my-3">
                      <div className="alert alert-success" role="alert">
                        {OTPMsg}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <InputGroup className="reset  w-100">
                    <Button type="submit" className="btn-default w-100">
                      VERIFY OTP
                    </Button>
                  </InputGroup>
                </form>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default EnterOtpScreen;

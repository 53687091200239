import React, { useState } from "react";
import { Container, Row, Col, Form, Tab, Nav, Button } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import BankTransfer from "./BankTranfer";
import PtmAndUpi from "./PTM-UPI";

function PaymentRequest() {
  const [activeTab, setActiveTab] = useState("bank-transfer"); // Default active tab
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };
  const [setting, setSetting] = useState([
    {
      key: "ptm-upi",
      title: "UPI/ Paytm ID",
      Component: PtmAndUpi,
    },
    {
      key: "bank-transfer",
      title: "Bank Transfer",
      Component: BankTransfer,
    },
  ]);
  return (
    <div>
      <Tab.Container
        id="profile-tab-pane"
        activeKey={activeTab}
        onSelect={handleTabSelect}
      >
        <Nav as={Row} className="justify-content-center payment p-0">
          <h1>PAYMENT DETAILS</h1>
          {setting.map(({ key, title }, index) => (
            <Nav.Item
              key={`${key}-${index}`}
              as={Col}
              className="text-center col-lg-5"
            >
              <div className="button">
                <Nav.Link eventKey={key} className="">
                  {title}
                </Nav.Link>
              </div>
            </Nav.Item>
          ))}
        </Nav>

        <Col>
          <Tab.Content>
            {setting.map(({ key, Component }, index) => (
              <Tab.Pane key={`${key}-${index}`} eventKey={key}>
                <Component currentlyActive={activeTab} actualComponent={key} />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Tab.Container>
    </div>
  );
}

export default PaymentRequest;

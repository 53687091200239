import React from "react";
import { useTimer } from "react-timer-hook";

function ChallengeTimer({ expiryTimestamp, onExpire = () => {} }) {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: onExpire,
  });

  const timerContainerStyle = {
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  };

  const timerStyle = {
    fontSize: `clamp(2rem, 4vw, 4rem)`, // Using clamp for responsive font size
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e5097d",
    padding: `clamp(10px, 2vw, 20px)`, // Using clamp for responsive padding
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const digitStyle = {
    minWidth: "2rem",
    margin: "0 0rem",
    background: "#333",
    color: "#fff",
    borderRadius: "4px",
    padding: "0.5rem",
    display: "inline-flex",
    flexDirection: "column",
    lineHeight: "normal",
  };

  const digitStyleText = {
    color: "#fff",
    borderRadius: "4px",
    fontSize: `clamp(11px, 1.5vw, 14px)`, // Using clamp for responsive font size
    textTransform: "uppercase",
    fontWeight: "700",
    lineHeight: "normal",
  };

  return (
    <div style={timerContainerStyle}>
      <div style={timerStyle}>
        <span style={digitStyle}>
          {days}
          <span style={digitStyleText}>Days</span>
        </span>
        <span>:</span>
        <span style={digitStyle}>
          {hours}
          <span style={digitStyleText}>Hours</span>
        </span>
        <span>:</span>
        <span style={digitStyle}>
          {minutes}
          <span style={digitStyleText}>Minutes</span>
        </span>
        <span>:</span>
        <span style={digitStyle}>
          {seconds}
          <span style={digitStyleText}>Seconds</span>
        </span>
      </div>
    </div>
  );
}

export default ChallengeTimer;

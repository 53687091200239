import React, { useEffect, useState, useMemo, useRef } from "react";
import Layout from "../../layouts/public/Layout";
import PublicImages from "../../constants/public/images";
import AudioPlayer from "react-h5-audio-player";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Card,
  Image,
  Dropdown,
  Ratio,
  Offcanvas,
  Button,
} from "react-bootstrap";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faBoltLightning,
  faCircle,
  faCopy,
  faEllipsisV,
  faEye,
  faShare,
  faShareAlt,
  faShareNodes,
  faSquareShareNodes,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentAlt,
  faHeart,
  faShareSquare,
} from "@fortawesome/free-regular-svg-icons";
import {
  faFacebook,
  faFacebookF,
  faLinkedinIn,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { getItemFromLocalStorage } from "../../helper/helper";
import { getRequest, postRequestForm, uploadURL } from "../../helper/api";
import moment from "moment";
import Loader from "../../components/Loader";
import ImageCard from "../../components/public/Pages/ImageCard";
import { toast } from "react-toastify";
import Comments from "../../components/public/Pages/Comments";
import { socket } from "../../helper/socket";
import ReportPopUp from "../../components/public/PopUps/ReportPopUp";
import ReactPlayer from "react-player";
import ChallengeTimer from "../../components/public/Pages/ChallengeTimer";

export default function Challenge() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { product, challenge } = useParams();
  const [loading, setLoading] = useState(true);
  const [islive, setIsLive] = useState(true);
  const [starmarkPosts, setStarmarkPosts] = useState([]);
  const [winnerPosts, setWinnerPosts] = useState([]);
  const [challengeData, setChallengeData] = useState({});

  const [challengePostsLoading, setChallengePostsLoading] = useState(true);
  const [challengePostError, setChallengePostError] = useState();
  const [challengePostLength, setChallengePostLength] = useState(0);
  const [challengePostPage, setChallengePostPage] = useState(1);
  const [challengePostEnded, setChallengePostEnded] = useState(true);
  const [challengePosts, setChallengePosts] = useState([]);
  const [isBookmark, setIsBookmark] = useState({});
  const [isLiked, setIsLiked] = useState({});
  const [showOffcanvas, setShowOffcanvas] = useState(true);
  const observedElementRef = useRef(null); // Ref for the div to be observed

  // Show/Hide Offcanvas
  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  useEffect(() => {
    const handleObserver = () => {
      console.log("handleObserver is working");
      const observer = new IntersectionObserver(
        ([entry]) => {
          console.log("entry.isIntersecting", entry.isIntersecting);
          if (entry.isIntersecting) {
            // Element is in view
            handleClose(); // Hide the offcanvas
          } else {
            // Element is out of view
            handleShow(); // Show the offcanvas
          }
        },
        {
          root: null, // Use the viewport as the container
          threshold: 0.1, // Trigger when 10% of the element is visible
        }
      );

      if (observedElementRef.current) {
        observer.observe(observedElementRef.current);
      }

      // Cleanup the observer on component unmount
      return () => {
        if (observedElementRef.current) {
          observer.unobserve(observedElementRef.current);
        }
      };
    };

    // Wait for the page to fully load before setting up the observer
    if (document.readyState === "complete") {
      handleObserver();
    } else {
      window.addEventListener("load", handleObserver);
    }

    // Cleanup the event listener
    return () => {
      window.removeEventListener("load", handleObserver);
    };
  }, [navigate, state]);

  if (challenge === undefined) {
    navigate("/404");
  }
  const getPostListofChallenge = async (type, challengeId, page = 1) => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/challenge/get-challenge-posts`,
        "",
        {
          challengeId,
          getWinners: type,
          page: type === "all" ? challengePostPage : page,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        if (type === "all") {
          setChallengePostError("");
          if (challengePostPage === 1) {
            setChallengePosts(result?.data.posts);
            // Inside the function where you load more data
            setChallengePostLength(result?.data.posts);
          } else {
            setChallengePosts((prevData) => [
              ...prevData,
              ...result?.data.posts,
            ]);
            // Inside the function where you load more data
            setChallengePostLength(challengePosts.length);
          }
          if (result?.data.currentPage === result?.data.totalPages) {
            setChallengePostEnded(false);
          }
          setChallengePostPage((prevPage) => prevPage + 1);
        } else {
          setWinnerPosts(result?.data?.posts);
        }
      } else if (error?.response?.status === 400) {
        setChallengePostError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChallengePostsLoading(false);
    }
  };
  const getChallengeBySlug = async (slug) => {
    try {
      setLoading(true);
      const { result, error } = await getRequest(
        `/api/secure/challenge/get-challenge-by-slug`,
        "",
        {
          challengeSlug: slug,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { challenge } = result?.data;
        setChallengeData(challenge);
        if (challenge?.competitorIds?.length) {
          getPostListofChallenge("all", challenge?._id);
        }
        if (challenge?.winnerIds?.length) {
          getPostListofChallenge("winners", challenge?._id);
        }
        if (challenge.challengeEndDate) {
          if (
            !moment(challenge.challengeEndDate).isSameOrAfter(
              moment(new Date()).format("YYYY-MM-DD")
            )
          ) {
            setIsLive(false);
          }
        }
      } else if (error?.response?.status === 400) {
        navigate("/404");
      }
    } catch (error) {
      navigate("/404");
    } finally {
      setLoading(false);
    }
  };
  const formatString = (input) => {
    if (input === "image-corner") {
      return input
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
        .replace(" Corner", "");
    } else {
      return input
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
  };
  const handleBookmarkPost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/bookmark-post`,
        token,
        {
          postIdToToggle: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsBookmark((prev) => {
          let isBookmarked = false;
          const newState = { ...prev };
          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isBookmarked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isBookmarked = true;
          }
          if (isBookmarked) {
            toast.success("Post has been Bookmarked!", {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(
              `Post has been ${
                isBookmarked ? "Bookmarked!" : "Un-Bookmarked!"
              }!`,
              {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              }
            );
          }
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleLikePost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/like-post`,
        token,
        {
          postId: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsLiked((prev) => {
          const newState = { ...prev };
          let isLiked = false;

          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isLiked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isLiked = true;
          }

          // Update the like count in the corresponding post data
          setChallengePosts((prevData) =>
            prevData.map((item) =>
              item._id === postId
                ? {
                    ...item,
                    likeCount: isLiked
                      ? item.likeCount + 1
                      : item.likeCount - 1,
                  }
                : item
            )
          );
          // Update the like count in the corresponding post data
          setWinnerPosts((prevData) =>
            prevData.map((item) =>
              item._id === postId
                ? {
                    ...item,
                    likeCount: isLiked
                      ? item.likeCount + 1
                      : item.likeCount - 1,
                  }
                : item
            )
          );
          if (isLiked) {
            toast.success(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getChallengeBySlug(challenge);
  }, [challenge]);
  const getUserLikedAndBookmarkedPosts = useMemo(() => {
    return async () => {
      try {
        setLoading(true);
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result, error } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            const { userPrefrences } = result?.data;
            const { likedPost, bookmarks } = userPrefrences;
            setIsLiked((prev) => ({
              ...prev,
              ...likedPost.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
            setIsBookmark((prev) => ({
              ...prev,
              ...bookmarks.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
          }
        } else {
          setIsLiked({});
          setIsBookmark({});
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
        setIsLiked({});
        setIsBookmark({});
      } finally {
        setLoading(false); // Ensure isLoading is set to false
      }
    };
  }, []);
  useEffect(() => {
    getUserLikedAndBookmarkedPosts();
  }, [getUserLikedAndBookmarkedPosts]);
  return (
    <Layout>
      {!challengeData || loading ? (
        <Loader loading={true} />
      ) : (
        <>
          <section className="blog_2">
            <Container>
              <div className="sec_contant text-center">
                <h2 className="hin text-capitalize">{challengeData.name}</h2>
              </div>
              <div className="date text-center d-flex justify-content-center">
                <p>
                  {moment(challengeData.challengeStartDate).format("D-M-Y")}
                  {" - "}
                  {moment(challengeData.challengeEndDate).format("D-M-Y")}
                </p>
              </div>
            </Container>
          </section>

          {formatString(product)?.includes("Audio") && (
            <section className="bannar singlePostBanner">
              <Container>
                <Row className="justify-content-center">
                  <Col
                    className="text-center"
                    style={{
                      position: "relative",
                    }}
                  >
                    <Image
                      fluid
                      src={uploadURL + challengeData.photo}
                      className="w-100 postBannerImage"
                      alt={challengeData.title}
                    />
                    {islive && (
                      <span
                        style={{
                          backgroundColor: "#e5097d",
                          marginBottom: "5px",
                          borderRadius: "5px",
                          fontSize: "15px",
                          padding: "5px 10px",
                          color: "white",
                          position: "absolute",
                          top: "0",
                          right: "10px",
                          textTransform: "uppercase",
                        }}
                        className="badge badge-primary"
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          color="#fff"
                          className={`pe-2 fa-beat Blink`}
                        />
                        Live
                      </span>
                    )}
                    {/* <audio className="w-100 my-5" controls>
                      <source src={uploadURL + challengeData.videoFile} />
                    </audio> */}
                    {challengeData?.videoFile && (
                      <AudioPlayer
                        autoPlay={false}
                        src={uploadURL + challengeData.videoFile}
                        onPlay={(e) => console.log("onPlay")}
                        // other props here
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}
          {formatString(product)?.includes("Video") && (
            <section className="bannar singlePostBanner">
              <Container>
                <Row className="justify-content-center">
                  <Col
                    className="text-center"
                    style={{
                      position: "relative",
                    }}
                  >
                    {console.log("islive", islive)}
                    <Ratio aspectRatio="16x9">
                      <ReactPlayer
                        url={uploadURL + challengeData.videoFile}
                        controls
                        playing
                        light={uploadURL + challengeData.photo}
                        config={{
                          file: { attributes: { controlsList: "nodownload" } },
                        }}
                        // Disable right click
                        onContextMenu={(e) => e.preventDefault()}
                        width="100%"
                        height="100%"
                        showPreview={true}
                      />
                    </Ratio>
                    {islive && (
                      <span
                        style={{
                          backgroundColor: "#e5097d",
                          marginBottom: "5px",
                          borderRadius: "5px",
                          fontSize: "15px",
                          padding: "5px 10px",
                          color: "white",
                          position: "absolute",
                          top: "0",
                          right: "15px",
                          textTransform: "uppercase",
                        }}
                        className="badge badge-primary"
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          color="#fff"
                          className={`pe-2 fa-beat Blink`}
                        />
                        Live
                      </span>
                    )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}
          {!challengeData.videoFile && (
            <section className="bannar singlePostBanner">
              <Container>
                <Row className="justify-content-center">
                  <Col
                    className="text-center"
                    style={{
                      position: "relative",
                    }}
                  >
                    <Image
                      fluid
                      src={uploadURL + challengeData.photo}
                      className="w-100 postBannerImage"
                      alt={challengeData.title}
                    />
                    {islive && (
                      <span
                        style={{
                          backgroundColor: "#e5097d",
                          marginBottom: "5px",
                          borderRadius: "5px",
                          fontSize: "15px",
                          padding: "5px 10px",
                          color: "white",
                          position: "absolute",
                          top: "0",
                          right: "15px",
                          textTransform: "uppercase",
                        }}
                        className="badge badge-primary"
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          color="#fff"
                          className={`pe-2 fa-beat Blink`}
                        />
                        Live
                      </span>
                    )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}

          {!formatString(product)?.includes("Video") &&
            !formatString(product)?.includes("Audio") && (
              <section className="mid2 mb-5">
                <div className="container">
                  <h2 className="hin text-capitalize">
                    Challenge Description:{" "}
                  </h2>
                  <hr />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: challengeData.description,
                    }}
                  />
                </div>
              </section>
            )}

          {challengeData.rule && (
            <section className="mid2 mb-5">
              <div className="container">
                <h2 className="hin text-capitalize">Challenge Rules: </h2>
                <hr />
                <div
                  dangerouslySetInnerHTML={{
                    __html: challengeData.rule,
                  }}
                />
              </div>
            </section>
          )}

          {moment(challengeData.challengeEndDate).isAfter(
            moment(new Date())
          ) && (
            <section className="mid2 mb-5">
              <div className="container">
                <h2 className="hin text-capitalize">Challenge End On: </h2>
                <hr />
                <ChallengeTimer
                  expiryTimestamp={new Date(challengeData.challengeEndDate)}
                  onExpire={() => {
                    setIsLive(false);
                  }}
                />
              </div>
            </section>
          )}

          {challengeData?.productId?.status === "active" && (
            <>
              <section className="mid4">
                <Container>
                  <Button
                    type="button"
                    className="btn-default"
                    // disabled={!islive}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/${challengeData.productId.slug}/upload`, {
                        state: {
                          challengeSlug: challengeData.slug,
                        },
                        replace: false,
                      });
                    }}
                  >
                    {challengeData.productId.slug === "videos" ||
                    challengeData.productId.slug === "audio-station" ||
                    challengeData.productId.slug === "image-corner"
                      ? `Upload Your ${formatString(
                          challengeData.productId.slug
                        )}`
                      : `Write Your ${formatString(
                          challengeData.productId.slug
                        )}`}
                  </Button>
                </Container>
              </section>
              <Offcanvas
                show={showOffcanvas}
                scroll={true}
                backdrop={false}
                placement="bottom"
              >
                <Offcanvas.Body>
                  <Container>
                    <Row className="align-items-center">
                      <Col lg={9}>
                        <section className="blog_2 p-0 bg-white">
                          <div className="sec_contant text-center text-md-start">
                            <h2 className="hin text-capitalize d-flex align-items-center gap-3">
                              {challengeData.name}

                              {islive && (
                                <span
                                  style={{
                                    backgroundColor: "#e5097d",
                                    marginLeft: "5px",
                                    borderRadius: "5px",
                                    fontSize: "15px",
                                    padding: "5px 10px",
                                    color: "white",
                                    textTransform: "uppercase",
                                  }}
                                  className="badge badge-primary"
                                >
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )}
                            </h2>
                          </div>
                          <div className="date justify-content-md-start d-flex justify-content-center">
                            <p>
                              {moment(challengeData.challengeStartDate).format(
                                "D-M-Y"
                              )}
                              {" - "}
                              {moment(challengeData.challengeEndDate).format(
                                "D-M-Y"
                              )}
                            </p>
                          </div>
                        </section>
                      </Col>
                      <Col lg={3} sm={12} className="text-center text-md-end">
                        <section className="mid4 p-0">
                          <Button
                            type="button"
                            className="btn-default"
                            // disabled={!islive}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/${challengeData.productId.slug}/upload`,
                                {
                                  state: {
                                    challengeSlug: challengeData.slug,
                                  },
                                  replace: false,
                                }
                              );
                            }}
                          >
                            {challengeData.productId.slug === "videos" ||
                            challengeData.productId.slug === "audio-station" ||
                            challengeData.productId.slug === "image-corner"
                              ? `Upload Your ${formatString(
                                  challengeData.productId.slug
                                )}`
                              : `Write Your ${formatString(
                                  challengeData.productId.slug
                                )}`}
                          </Button>
                        </section>
                      </Col>
                    </Row>
                  </Container>
                </Offcanvas.Body>
              </Offcanvas>
            </>
          )}
          {challengeData?.competitorIds?.length > 0 && (
            <section className="listing_sec bg-treitory pb-4">
              <Container>
                <h1>
                  <Image src={PublicImages.PopularPost} fluid alt="" />
                  Challenge Posts
                </h1>

                {challengePostsLoading ? (
                  <Loader loading={true} />
                ) : (
                  <div>
                    {challengePostError ? (
                      challengePostError
                    ) : (
                      <InfiniteScroll
                        dataLength={challengePostLength}
                        next={() => {
                          getPostListofChallenge("all", challengeData?._id);
                        }}
                        inverse={false}
                        hasMore={challengePostEnded}
                        loader={<Loader loading={true} />}
                        scrollThreshold={0.9}
                        endMessage={
                          <p
                            style={{
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            <b>Yay! You've seen it all!</b>
                          </p>
                        }
                      >
                        <Row className="m-0">
                          {challengePosts.map((item, index) => (
                            <ImageCard
                              image={uploadURL + item?.featuredImage}
                              authorImage={
                                item.userId?.profileImage
                                  ? uploadURL + item.userId.profileImage
                                  : ""
                              }
                              subCatName={`${item.subCategoryId?.name}`}
                              productSlug={`${item?.productId?.slug}`}
                              catSlug={`${item?.categoryId?.slug}`}
                              subCatSlug={`${item.subCategoryId?.slug}`}
                              authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                              views={item.viewCount}
                              comments={item.comments}
                              likes={item.likeCount}
                              title={item.title}
                              date={item.createdAt}
                              username={item.userId.username}
                              link={`/${product}/${item.categoryId.slug}/${item.slug}`}
                              size={3}
                              bookmarkHandler={() =>
                                handleBookmarkPost(item._id)
                              }
                              isBookmarked={isBookmark[item._id]}
                              likeHandler={() => handleLikePost(item._id)}
                              isLiked={isLiked[item._id]}
                              sizeMD={6}
                              isWinner={item.isWinner}
                              challengeLink={`${product}/challenges/${item.challengeId?.slug}`}
                              isStarmarked={item.isStarmarked}
                            />
                          ))}
                        </Row>
                      </InfiniteScroll>
                    )}
                  </div>
                )}
              </Container>
            </section>
          )}
          {challengeData?.winnerIds?.length > 0 && (
            <section className="listing_sec">
              <Container>
                <h1>
                  <Image src={PublicImages.PopularPost} fluid alt="" />
                  Winner Post
                </h1>
                <Row>
                  {winnerPosts.map((item) => (
                    <ImageCard
                      image={uploadURL + item?.featuredImage}
                      authorImage={
                        item.userId?.profileImage
                          ? uploadURL + item.userId.profileImage
                          : ""
                      }
                      subCatName={`${item.subCategoryId?.name}`}
                      productSlug={`${item?.productId?.slug}`}
                      catSlug={`${item?.categoryId?.slug}`}
                      subCatSlug={`${item.subCategoryId?.slug}`}
                      authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                      views={item.viewCount}
                      comments={item.comments}
                      likes={item.likeCount}
                      title={item.title}
                      date={item.createdAt}
                      username={item.userId.username}
                      link={`/${product}/${item.categoryId.slug}/${item.slug}`}
                      size={3}
                      bookmarkHandler={() => handleBookmarkPost(item._id)}
                      isBookmarked={isBookmark[item._id]}
                      likeHandler={() => handleLikePost(item._id)}
                      isLiked={isLiked[item._id]}
                      sizeMD={6}
                      isWinner={item.isWinner}
                      challengeLink={`${product}/challenges/${item.challenge}`}
                      isStarmarked={item.isStarmarked}
                    />
                  ))}
                </Row>
              </Container>
            </section>
          )}
        </>
      )}
      <div ref={observedElementRef}></div>
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { SketchPicker } from "react-color";

function TextSettings({
  currentlyActive,
  actualComponent,
  activeSettings = [
    { value: "left", property: "textAlign" },
    { value: "left", property: "textAlignLast" },
    { value: "", property: "textTransform" },
    { value: "#fff", property: "color" },
    { value: "flex-start", property: "justifyContent" },
  ],
  onSettingsChange = () => {},
  isLoading = () => {},
}) {
  const [color, setColor] = useState("#fff");
  const handleButtonClick = (value, property) => {
    // Call the callback function to update the active setting in the parent component
    onSettingsChange({ value, property });
  };
  useEffect(() => {
    isLoading(false);
  }, []);

  return (
    <section>
      <h4>
        <b>Text Transformation</b>
      </h4>
      <ButtonGroup className="my-2" aria-label="First group">
        {["upperCase", "lowerCase", "capitalize"].map((setting) => (
          <Button
            key={setting}
            variant="secondary"
            onClick={() => handleButtonClick(setting, "textTransform")}
            active={activeSettings.some(
              (item) =>
                item.value === setting && item.property === "textTransform"
            )}
          >
            {setting.charAt(0).toUpperCase() +
              setting
                .slice(1)
                .replace(/([A-Z])/g, " $1")
                .trim()}
          </Button>
        ))}
      </ButtonGroup>
      <h4 className="mt-3">
        <b>Text Horizontal Alignment</b>
      </h4>
      <ButtonGroup className="my-2" aria-label="Second group">
        {["left", "center", "right", "justify"].map((setting) => (
          <Button
            key={setting}
            variant="secondary"
            onClick={() => {
              handleButtonClick(setting, "textAlign");
              handleButtonClick(setting, "textAlignLast");
            }}
            active={activeSettings.some(
              (item) => item.value === setting && item.property === "textAlign"
            )}
          >
            {setting.charAt(0).toUpperCase() + setting.slice(1)}
          </Button>
        ))}
      </ButtonGroup>
      <h4 className="mt-3">
        <b>Text Vertical Alignment</b>
      </h4>
      <ButtonGroup className="my-2" aria-label="Third group">
        {["top", "middle", "bottom"].map((setting) => (
          <Button
            key={setting}
            variant="secondary"
            onClick={() => {
              let justifyContentValue = "";
              if (setting === "top") justifyContentValue = "flex-start";
              else if (setting === "middle") justifyContentValue = "center";
              else if (setting === "bottom") justifyContentValue = "flex-end";
              handleButtonClick(justifyContentValue, "justifyContent");
            }}
            active={activeSettings.some(
              (item) =>
                item.value ===
                  (setting === "top"
                    ? "flex-start"
                    : setting === "middle"
                    ? "center"
                    : "flex-end") && item.property === "justifyContent"
            )}
          >
            {setting.charAt(0).toUpperCase() + setting.slice(1)}
          </Button>
        ))}
      </ButtonGroup>
      <h4 className="mt-3">
        <b>Text Color</b>
      </h4>
      <SketchPicker
        color={color}
        onChangeComplete={(color) => {
          setColor(color);
          handleButtonClick(color?.hex, "color");
        }}
      />
    </section>
  );
}

export default TextSettings;

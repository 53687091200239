import React, { useEffect, useState } from "react";
import { Dropdown, Image, Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SiteSettings from "./../../constants/SiteSettings";
import routes from "./../../helper/routes";
import Loader from "../Loader";
import { getItemFromLocalStorage } from "../../helper/helper";
import { getRequest } from "../../helper/api";
export const Header = ({ loader }) => {
  const route = useLocation();
  const getAdminRoutesChild = (routes) => {
    return routes.map((prop, key) => {
      if (prop.show === "yes") {
        if (prop?.subMenu) {
          let match = false;
          prop?.subMenu.map((prop) => {
            if (route.pathname === prop.path) {
              match = true;
            }
          });
          return (
            <NavDropdown
              key={key}
              renderMenuOnMount
              as="li"
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
              // active={match}
            >
              {getAdminRoutesChild(prop.subMenu)}
            </NavDropdown>
          );
        } else {
          return (
            <Nav.Item as="li" key={key}>
              <Link
                className={`nav-link ${
                  route.pathname === prop.path ? "active" : ""
                }`}
                to={prop.path}
              >
                {prop.name}
              </Link>
            </Nav.Item>
          );
        }
      }
    });
  };
  const getAdminRoutes = (routes, user) => {
    return routes.map((prop, key) => {
      if (
        prop.show === "yes" &&
        prop?.layout === "ADMIN" &&
        user !== null &&
        (user.permissions.includes(prop?.name) ||
          user.permissions.includes("Super Admin"))
      ) {
        if (prop?.subMenu) {
          let match = false;
          prop?.subMenu.map((prop) => {
            if (route.pathname === prop.path) {
              match = true;
            }
          });

          return match ? (
            <Dropdown
              key={key}
              as="li"
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
              autoClose={false}
              show={match ? true : ""}
            >
              <Dropdown.Toggle
                className={`nav-link dropdown-toggle ${match ? "active" : ""}`}
                type="button"
                id={`basic-nav-dropdown-${key}`}
                as="a"
              >
                {prop.name}
              </Dropdown.Toggle>
              <Dropdown.Menu renderOnMount as="ul">
                {getAdminRoutesChild(prop.subMenu)}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Dropdown
              key={key}
              as="li"
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
              autoClose={false}
            >
              <Dropdown.Toggle
                className={`nav-link dropdown-toggle ${match ? "active" : ""}`}
                type="button"
                id={`basic-nav-dropdown-${key}`}
                as="a"
              >
                {prop.name}
              </Dropdown.Toggle>
              <Dropdown.Menu renderOnMount as="ul">
                {getAdminRoutesChild(prop.subMenu)}
              </Dropdown.Menu>
            </Dropdown>
          );
        } else {
          return (
            <Nav.Item as="li" key={key}>
              <Link
                className={`nav-link ${
                  route.pathname === prop.path ? "active" : ""
                }`}
                to={prop.path}
              >
                {prop.name}
              </Link>
            </Nav.Item>
          );
        }
      }
    });
  };
  const [user, setUser] = useState(null); // Maintain the user state
  const [isLoading, setIsLoading] = useState(true); // Maintain the loading state

  const getUser = async () => {
    try {
      const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
      if (storedUser && storedUser?.username) {
        const { result, error } = await getRequest(
          `/api/secure/user/profile/${storedUser?.username}`
        );
        if (result?.status === 200 || result?.status === 201) {
          setUser(result?.data?.user);
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error("Error while fetching user:", error);
      setUser(null);
    } finally {
      setIsLoading(false); // Ensure isLoading is set to false
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  return isLoading ? (
    <Loader loading={isLoading} />
  ) : (
    <>
      <div
        className="sidebar"
        style={{
          display: !loader ? "flex" : "none",
        }}
      >
        <div className="logo-details">
          <Link to="/">
            <Image
              fluid
              src={SiteSettings.SITE_LOGO}
              alt=""
              style={{ cursor: "pointer" }}
            />
          </Link>
        </div>
        <Nav id="main_menu" as="ul" className="me-auto flex-column">
          {getAdminRoutes(routes, user)}
        </Nav>
        <p className="copyright_para">{SiteSettings.Copyright_text}</p>
      </div>
    </>
  );
};

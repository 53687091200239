import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { getRequest, postRequest, putRequest } from "./../../../helper/api";
import Layout from "./../../../layouts/admin/Layout";

import { faAdd, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import ReactSelect from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { getItemFromLocalStorage } from "../../../helper/helper";
const EditReportAnswer = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState([""]);
  const [title, setTitle] = useState();
  const [status, setStatus] = useState("");
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];

  let reportAnswerId = "";
  const location = useLocation();
  if (location?.state?.reportAnswerId === "") {
    navigate("/ReportAnswers");
  } else {
    reportAnswerId = location?.state?.reportAnswerId;
  }

  const editReportAnswerHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await putRequest(
        `/api/secure/reportAnswer/update-report-answer/${reportAnswerId}`,
        token,
        {
          title,
          answers,
          status: typeof status === "object" ? status.value : status,
        }
      );
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        toast.success("Report Answer Updated", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
      navigate("/ReportAnswers");
    } catch (error) {
      console.log("Update Report Answer APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const addRowTable = () => {
    const addFaq = "";
    setAnswers([...answers, addFaq]);
  };
  const removeRowTable = (index) => {
    const faqRow = [...answers];
    faqRow.splice(index, 1);
    setAnswers(faqRow);
  };
  const onValUpdate = (i, event) => {
    const { value } = event.target;
    const answer = [...answers];
    answer[i] = value;
    setAnswers(answer);
  };
  const handleStatusFilter = (selectedOption) => {
    setStatus(selectedOption);
  };
  const getReportAnswerById = async (reportAnswerId) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/reportAnswer/get-report-answer`,
        token,
        {
          reportAnswerId,
        }
      );
      const { title, status, answers } = response?.result?.data?.reportAnswer;
      setTitle(title);
      setStatus(status);
      setAnswers(answers);
    } catch (error) {
      console.log("Get Report Answer By Id Error", error);
    } finally {
      setLoading(false);
    }
  };
  const getLabelByValue = (statusOptions, value) => {
    const option = statusOptions?.find((option) => option.value === value);
    return option?.label;
  };
  useEffect(() => {
    getReportAnswerById(reportAnswerId);
  }, [reportAnswerId]);
  return (
    <Layout>
      <section className="py-5">
        <Container>
          {!loading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={editReportAnswerHandler}
            >
              <Row>
                <Col sm={12} className="mb-3">
                  <Form.Label>
                    <b>Title</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Col>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <ReactSelect
                      placeholder="Status"
                      onChange={handleStatusFilter}
                      options={statusOptions}
                      required
                      isClearable
                      value={
                        typeof status === "object"
                          ? status
                          : status
                          ? {
                              value: status,
                              label: getLabelByValue(statusOptions, status),
                            }
                          : ""
                      }
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Label>
                    <b>Answers</b>
                  </Form.Label>
                  <Table id="faqsTable" responsive striped>
                    <tbody>
                      {answers.map((answer, index) => (
                        <tr key={index}>
                          <td>
                            <Table className="bg-white m-0">
                              <tbody>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Answer</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      defaultValue={answer}
                                      onChange={(event) =>
                                        onValUpdate(index, event)
                                      }
                                      required
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                          <td>
                            <Button
                              className="btn-danger"
                              onClick={() => removeRowTable(index)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="text-end" colSpan={2}>
                          <Button className="btn-primary" onClick={addRowTable}>
                            <FontAwesomeIcon icon={faAdd} />
                          </Button>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
                <Col xl={12} sm={12} className="text-center mt-4">
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Loader loading={loading} />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default EditReportAnswer;

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { getRequest } from "../../../helper/api";

function LangPop({ show = false, handleLanguage = () => {}, initialLanguage }) {
  const [language, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    handleLanguage(selectedLanguage);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleConfirm = () => {
    // You can perform actions based on the selected language here.
    // For example, update the user's language preference.
    // Then, close the modal.
    handleClose();
  };
  const loadLanguage = async () => {
    try {
      const res = await getRequest("/api/public/language/get-languages-name");
      if (res.result.status === 200 || res.result.status === 201) {
        // console.log(res.result.data);
        setLanguages(["English", ...res.result.data.languageNames]);
      }
    } catch (e) {
      console.log("Error", e.message);
    }
  };

  useEffect(() => {
    loadLanguage();
    setShowModal(show);
    setSelectedLanguage(initialLanguage);
  }, [show, initialLanguage]);

  useEffect(() => {
    setSelectedLanguage(initialLanguage);
  }, [initialLanguage]);
  return (
    <Modal show={showModal} centered onHide={handleClose} id="language-modal">
      <Modal.Header></Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Form>
          {language.map((item, index) => (
            <Form.Check
              type="radio"
              name="language"
              className="d-flex align-items-center gap-2"
              id="flexRadioDefault1"
              label={item}
              value={item}
              checked={selectedLanguage === item}
              onChange={handleLanguageChange}
              key={index}
            />
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100 flex-column-reverse flex-md-row">
          <Col lg={6} md={6} xs={12} >
            <Button className="btn-default-3 w-100 d-block h-auto px-3 py-3" onClick={handleClose}>
              Cancel
            </Button>
          </Col>
          <Col lg={6} md={6} xs={12} className="mb-3 m-md-0 m-lg-0">
            <Button className="btn-default-2 w-100 d-block h-auto px-3 py-3" onClick={handleConfirm}>
              OK
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default LangPop;

import React, { useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEye,
  faFileArrowDown,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../helper/api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import ReactSelect from "react-select";
import { DateRangePicker } from "react-date-range";
import { CSVLink } from "react-csv";
import { getItemFromLocalStorage } from "../../../helper/helper";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  getFilterHistory,
  updateFilterHistory,
} from "../../../helper/filterHistory";

const Posts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [statusOptions, setStatusOptions] = useState([
    { value: "active", label: "Active" },
    { value: "pending", label: "Pending" },
    { value: "rejected", label: "Rejected" },
    { value: "deactive", label: "Deactive" },
  ]);
  const [totalRows, setTotalRows] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [isWinner, setIsWinner] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState("");
  const [subCategory, setSubCategory] = useState();
  const [challenge, setChallenge] = useState("");
  const [user, setUser] = useState();

  const winnerOptions = [
    { value: "", label: "All" },
    { value: true, label: "Winners Only" },
  ];

  const fetchUsers = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/user/get-all`, token, {
        page: page,
        search: search,
      });

      const currentPage = response?.result?.data?.currentPage;
      const totalPages = response?.result?.data?.totalPages;

      if (currentPage === totalPages) {
        return {
          options: response?.result?.data?.users,
          hasMore: false,
        };
      } else {
        return {
          options: response?.result?.data?.users,
          hasMore: response?.result?.data?.users?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };
  const fetchSubCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-all-sub-categories`,
        token,
        {
          page: page,
          search: search,
          status: "active",
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };

  const fetchChallenges = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/challenge/get-all-challenges`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          productId: subCategory && subCategory?.masterProduct?._id,
          categoryId: subCategory && subCategory?.parentCategory?._id,
          subCategoryId: subCategory && subCategory?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, challenges } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: challenges,
            hasMore: false,
          };
        } else {
          return {
            options: challenges,
            hasMore: challenges?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Challenges Error", error);
    }
  };
  const getAllPosts = async (
    page = 1,
    limit = 10,
    search = "",
    subCategory = "",
    status = "",
    startDate = "",
    endDate = "",
    user = "",
    isWinner = "",
    challenge = ""
  ) => {
    try {
      setCurrentPage(page);
      setPerPage(limit);
      setSearch(search);
      setStatus(status);
      setStartDate(startDate);
      setEndDate(endDate);
      setUser(user);
      setIsWinner(isWinner);
      setSubCategory(subCategory);
      setChallenge(challenge);
      if (challenge) {
        const { subCategoryId, categoryId, productId } = challenge;
        setSubCategory({
          _id: subCategoryId?._id,
          name: `${subCategoryId?.name}`,
          parentCategory: categoryId,
          masterProduct: productId,
        });
      }
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/post/all-posts`,
        token,
        {
          page: page,
          limit: limit,
          search: search,
          subCategory: subCategory ? subCategory?._id : "",
          status: status ? status?.value : "",
          startDate: startDate,
          endDate: endDate,
          user: user ? user?._id : "",
          isWinner: isWinner ? isWinner?.value : "",
          challenge: challenge ? challenge?._id : "",
        }
      );
      setTableHead([
        {
          name: "Title",
          sortable: true,
          selector: (row) => row.title,
        },
        {
          name: "Product Type",
          selector: (row) => `${row.productId?.name} (${row.productId?.slug})`,
        },
        {
          name: "Category",
          selector: (row) =>
            `${row.categoryId?.name} (${row.categoryId?.slug})`,
        },
        {
          name: "Sub Category",
          selector: (row) =>
            `${row.subCategoryId?.name} (${row.subCategoryId?.slug})`,
        },
        {
          name: "User",
          selector: (row) => `${row.userId?.firstName} ${row.userId?.lastName}`,
        },
        {
          name: "Status",
          sortable: true,
          cell: (row, index, column, id) => (
            <>
              {row?.status === "active" && (
                <span className="badge bg-success">
                  {row?.status.capitalize()}
                </span>
              )}
              {(row?.status === "deactive" || row?.status === "rejected") && (
                <span className="badge bg-danger">
                  {row?.status.capitalize()}
                </span>
              )}
            </>
          ),
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-YYYY h:mm:ss A"),
        },
        {
          name: "Actions",
          // button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              {!row.isApproved && (
                <Button
                  className="edit_btn me-1"
                  size="sm"
                  variant="success"
                  onClick={() => approvePost(row.slug, row, index, column, id)}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              )}
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editPost(row.slug, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Link
                to={`/${row.productId.slug}/${row.categoryId?.slug}/${row.slug}`}
                state={{ isModeration: !row.isApproved }}
                target="_BLANK"
                className="btn btn-sm text-light btn-info view_btn ms-1"
              >
                <FontAwesomeIcon icon={faEye} />
              </Link>
            </>
          ),
        },
      ]);
      if (result?.status === 200 || result?.status === 201) {
        setTableData(result?.data?.posts);
        setTotalRows(result?.data?.totalPosts);
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Get Moderations Error", error);
    } finally {
      setPending(false);
    }
  };

  const csvData = useMemo(() => {
    return tableData?.map((user) => ({
      id: user._id,
      name: user.name,
      slug: user.slug,
      parentCategory: user.parentCategoryId?.name,
      productType: user.parentCategoryId?.masterProductId?.name,
      adminCategory: user.adminCategory ? "Yes" : "No",
      isDeleted: user.isDeleted ? "Yes" : "No",
      created_at: moment(user.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);

  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const editPost = async (postSlug) => {
    navigate(`/editPost`, {
      state: { postSlug },
    });
  };

  const approvePost = async (postSlug) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/post/approve-post/`,
        token,
        {
          postSlug,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success("Post Approved", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setTableData((prevData) =>
          prevData.map((item) =>
            item.slug === postSlug
              ? {
                  ...item,
                  status: "active",
                  isApproved: true,
                }
              : item
          )
        );
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Deleting Post Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    updateFilterHistory(
      {
        page: page,
        limit: perPage,
        search,
        subCategory,
        status,
        startDate,
        endDate,
        user,
        isWinner,
        challenge,
      },
      "Posts",
      navigate
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    updateFilterHistory(
      {
        page: currentPage,
        limit: newPerPage,
        search,
        subCategory,
        status,
        startDate,
        endDate,
        user,
        isWinner,
        challenge,
      },
      "Posts",
      navigate
    );
  };
  const handleStatusFilter = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        search,
        subCategory,
        status: selectedOption,
        startDate,
        endDate,
        user,
        isWinner,
        challenge,
      },
      "Posts",
      navigate
    );
  };
  const handleWinnerFilter = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        search,
        subCategory,
        status,
        startDate,
        endDate,
        user,
        isWinner: selectedOption,
        challenge,
      },
      "Posts",
      navigate
    );
  };
  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        search: searchValue,
        subCategory,
        status,
        startDate,
        endDate,
        user,
        isWinner,
        challenge,
      },
      "Posts",
      navigate
    );
  };
  const handleSelect = (ranges) => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        search,
        subCategory,
        status,
        startDate,
        endDate,
        user,
        isWinner,
        challenge,
      },
      "Posts",
      navigate
    );
  };
  const handleSubCategoryChange = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        search,
        subCategory: selectedOption,
        status,
        startDate,
        endDate,
        user,
        isWinner,
        challenge,
      },
      "Posts",
      navigate
    );
  };
  const handleUserChange = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        search,
        subCategory,
        status,
        startDate,
        endDate,
        user: selectedOption,
        isWinner,
        challenge,
      },
      "Posts",
      navigate
    );
  };
  const hangleChallengeChange = (selectedOption) => {
    delete selectedOption?.slug;
    delete selectedOption?.slugGenerated;
    delete selectedOption?.photo;
    delete selectedOption?.videoFile;
    delete selectedOption?.description;
    delete selectedOption?.rule;
    delete selectedOption?.challengeStartDate;
    delete selectedOption?.challengeEndDate;
    delete selectedOption?.status;
    delete selectedOption?.cronId;
    delete selectedOption?.competitorCount;
    delete selectedOption?.coins;
    delete selectedOption?.winnerCount;
    delete selectedOption?.starMarkCount;
    delete selectedOption?.isEnded;
    let subCat = null;
    if (
      selectedOption?.subCategoryId ||
      selectedOption?.categoryId ||
      selectedOption?.productId
    ) {
      const { subCategoryId, categoryId, productId } = selectedOption;
      subCat = {
        ...subCategoryId,
        parentCategory: categoryId,
        masterProduct: productId,
      };
    }
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        search,
        subCategory: subCat,
        status,
        startDate,
        endDate,
        user,
        isWinner,
        challenge: selectedOption,
      },
      "Posts",
      navigate
    );
  };
  useEffect(() => {
    getFilterHistory(location, getAllPosts);
  }, [location]);

  return (
    <Layout>
      <section id="Posts_list" className="py-5">
        <Container>
          <Row className="mb-3">
            <Col lg={3} md={6} xs={12} className="mt-3">
              <FormControl
                type="text"
                placeholder="Search Posts"
                className="me-2"
                value={search}
                onChange={handleSearchCategory}
              />
            </Col>
            <Col lg={2} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Status"
                  onChange={handleStatusFilter}
                  options={statusOptions}
                  required
                  isClearable
                  value={status ? status : ""}
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Select"
                  onChange={handleWinnerFilter}
                  options={winnerOptions}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Form.Group>
                <AsyncPaginate
                  value={subCategory ? subCategory : ""}
                  loadOptions={fetchSubCategories}
                  getOptionLabel={(option) =>
                    `${option.name} (${option?.parentCategory?.name}) {${option?.masterProduct?.name}}`
                  }
                  getOptionValue={(option) => option._id}
                  onChange={handleSubCategoryChange}
                  placeholder="Select Sub Category"
                  isClearable
                  loadingMessage={() => "Loading..."}
                  noOptionsMessage={() => "No Sub Category Found"}
                  additional={{
                    page: 1,
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Form.Group>
                <AsyncPaginate
                  value={user ? user : ""}
                  loadOptions={fetchUsers}
                  getOptionLabel={(e) =>
                    `${e.firstName} ${e.lastName} (${e.email})`
                  }
                  getOptionValue={(e) => e._id}
                  onChange={handleUserChange}
                  placeholder="Select User"
                  isClearable
                  loadingMessage={() => "Loading..."}
                  noOptionsMessage={() => "No User Found"}
                  additional={{
                    page: 1,
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Form.Group>
                <AsyncPaginate
                  value={challenge ? challenge : ""}
                  loadOptions={fetchChallenges}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                  onChange={hangleChallengeChange}
                  placeholder="Select Challenge"
                  isClearable
                  required
                  loadingMessage={() => "Loading..."}
                  noOptionsMessage={() => "No Challenge Found"}
                  additional={{
                    page: 1,
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Dropdown autoClose={"outside"} className="w-100">
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        updateFilterHistory(
                          {
                            page: 1,
                            limit: 10,
                            search,
                            subCategory,
                            status,
                            startDate: "",
                            endDate: "",
                            user,
                            isWinner,
                          },
                          "Posts",
                          navigate
                        );
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {tableData && (
              <Col lg={1} md={6} xs={12} className="mt-3">
                <CSVLink
                  data={csvData}
                  filename={"categories.csv"}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </CSVLink>
              </Col>
            )}
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Posts;

Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";
import { getItemFromLocalStorage } from "../../helper/helper";
import Loader from "./../../components/Loader";
import { putRequest } from "./../../helper/api";

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isShowCurrentPassword, setIsShowCurrentPassword] = useState(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const passwordSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current Password is Required"),
    newPassword: Yup.string()
      .required("New Password is Required")
      .min(6, "New Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is Required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const passwordHandler = async (values) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        `/api/secure/user/change-password`,
        token,
        {
          oldPassword: values.currentPassword,
          newPassword: values.newPassword,
        }
      );
      if (result?.status === 200) {
        setIsSuccess(true);
      } else if (error?.response?.status === 400) {
        setIsSuccess(false);
        alert(error?.response?.data?.message);
      }
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    }
  };
  return (
    <div id="change-password">
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Container>
          <div className="change">
            <div>
              <h3 className="text-center">RESET ACCOUNT PASSWORD</h3>
              <p className="text-center">Enter a new password for update</p>
            </div>
            <Formik
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={passwordSchema}
              onSubmit={passwordHandler}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-2">
                        <Form.Label
                          className={
                            errors.currentPassword && touched.currentPassword
                              ? "form-label-error"
                              : null
                          }
                        >
                          Current Password
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className={
                              errors.currentPassword && touched.currentPassword
                                ? "form-control-error"
                                : null
                            }
                            style={{ borderRight: "none" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type={isShowCurrentPassword ? "text" : "password"}
                            placeholder="*********"
                            name="currentPassword"
                          />
                          <InputGroup.Text
                            style={{
                              background: "#fff",
                              borderLeft: "none",
                            }}
                            onClick={() =>
                              setIsShowCurrentPassword(
                                (prevState) => !prevState
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={isShowCurrentPassword ? faEyeSlash : faEye}
                              size="lg"
                            />
                          </InputGroup.Text>
                        </InputGroup>
                        {errors.currentPassword && touched.currentPassword && (
                          <div className="error-message">
                            {errors.currentPassword}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}></Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          className={
                            errors.newPassword && touched.newPassword
                              ? "form-label-error"
                              : null
                          }
                        >
                          New Password
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className={
                              errors.newPassword && touched.newPassword
                                ? "form-control-error"
                                : null
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ borderRight: "none" }}
                            type={isShowNewPassword ? "text" : "password"}
                            placeholder="*********"
                            name="newPassword"
                          />
                          <InputGroup.Text
                            style={{
                              background: "#fff",
                              borderLeft: "none",
                            }}
                            onClick={() =>
                              setIsShowNewPassword((prevState) => !prevState)
                            }
                          >
                            <FontAwesomeIcon
                              icon={isShowNewPassword ? faEyeSlash : faEye}
                              size="lg"
                            />
                          </InputGroup.Text>
                        </InputGroup>
                        {errors.newPassword && touched.newPassword && (
                          <div className="error-message">
                            {errors.newPassword}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          className={
                            errors.confirmPassword && touched.confirmPassword
                              ? "form-label-error"
                              : null
                          }
                        >
                          Confirm Password
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className={
                              errors.confirmPassword && touched.confirmPassword
                                ? "form-control-error"
                                : null
                            }
                            onChange={handleChange}
                            style={{ borderRight: "none" }}
                            onBlur={handleBlur}
                            type={isShowConfirmPassword ? "text" : "password"}
                            placeholder="*********"
                            name="confirmPassword"
                          />
                          <InputGroup.Text
                            style={{
                              background: "#fff",
                              borderLeft: "none",
                            }}
                            onClick={() =>
                              setIsShowConfirmPassword(
                                (prevState) => !prevState
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={isShowConfirmPassword ? faEyeSlash : faEye}
                              size="lg"
                            />
                          </InputGroup.Text>
                        </InputGroup>
                        {errors.confirmPassword && touched.confirmPassword && (
                          <div className="error-message">
                            {errors.confirmPassword}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      {isSuccess && (
                        <Alert variant={"success"}>Password Updated</Alert>
                      )}
                    </Col>
                  </Row>

                  <div className="text-center">
                    <Button className="btn-default" type="submit">
                      RESET MY PASSWORD
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Container>
      )}
    </div>
  );
}

export default ResetPassword;

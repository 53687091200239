import React, { useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArrowDown,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../helper/api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import ReactSelect from "react-select";
import { DateRangePicker } from "react-date-range";
import { CSVLink } from "react-csv";
import { getItemFromLocalStorage } from "../../../helper/helper";
import { AsyncPaginate } from "react-select-async-paginate";

const SubCategories = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [statusOptions, setStatusOptions] = useState([
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCategories, setTotalCategories] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState();
  const getAllCategories = async (query = "") => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/category/get-all-categories`,
        token,
        {
          search: query,
          status: "active",
        }
      );

      const mappedOptions = response?.result?.data?.categories.map(
        (option) => ({
          value: option._id,
          label: `${option.name} (${option.masterProductId.name})`,
        })
      );
      setCategories(mappedOptions);
    } catch (error) {
      console.log("Get All Categories Error", error);
    } finally {
      setPending(false);
    }
  };
  const fetchCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-all-categories`,
        token,
        {
          page: page,
          search: search,
          status: "active",
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };
  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption);
    getAllSubCategories(
      1,
      perPage,
      searchItem,
      statusFilter,
      selectedOption ? selectedOption?._id : "",
      startDate,
      endDate
    );
  };
  const categoryChange = (selectedOption) => {
    setCategory(selectedOption);
    getAllSubCategories(
      1,
      perPage,
      searchItem,
      statusFilter,
      selectedOption?.value,
      startDate,
      endDate
    );
  };

  const getAllSubCategories = async (
    page = 1,
    limit = 10,
    search = "",
    status = "",
    category = "",
    startDate = "",
    endDate = ""
  ) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/category/get-all-sub-categories`,
        token,
        {
          limit: limit,
          page: page,
          search: search,
          status: status,
          startDate: startDate,
          endDate: endDate,
          category,
        }
      );
      setTableHead([
        {
          name: "Category Name",
          sortable: true,
          selector: (row) => row.name,
        },
        {
          name: "Category Slug",
          sortable: true,
          selector: (row) => row.slug,
        },
        {
          name: "Parent Category",
          sortable: true,
          selector: (row) => row.parentCategory?.name,
        },
        {
          name: "Product Type",
          sortable: true,
          selector: (row) => row.masterProduct?.name,
        },
        {
          name: "Admin Category",
          sortable: true,
          selector: (row) => (row.adminCategory ? "Yes" : "No"),
        },
        {
          name: "Featured Category",
          sortable: true,
          selector: (row) => (row.isFeatured ? "Yes" : "No"),
        },
        {
          name: "Status",
          sortable: true,
          selector: (row) => row.status,
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editCategory(row.slug, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                className="del_btn ms-1"
                size="sm"
                variant="danger"
                onClick={() => deleteCategory(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        setSubCategories(response?.result?.data?.categories);
        setTableData(response?.result?.data?.categories);
        setTotalCategories(response?.result?.data?.totalCategories);
      } else {
        setSubCategories([]);
        setTableData([]);
        setTotalCategories(0);
      }
      setPending(false);
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllSubCategories(
      page,
      perPage,
      searchItem,
      statusFilter,
      category ? category?._id : "",
      startDate,
      endDate
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    getAllSubCategories(
      1,
      newPerPage,
      searchItem,
      statusFilter,
      category ? category?._id : "",
      startDate,
      endDate
    );
  };

  const handleStatusFilter = (selectedOption) => {
    const statusValue = selectedOption?.value;
    setStatusFilter(statusValue);
    setCurrentPage(1);
    getAllSubCategories(
      1,
      perPage,
      searchItem,
      statusValue,
      category ? category?._id : "",
      startDate,
      endDate
    );
  };

  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);
    setCurrentPage(1);
    getAllSubCategories(
      1,
      perPage,
      searchValue,
      statusFilter,
      category ? category?._id : "",
      startDate,
      endDate
    );
  };

  const csvData = useMemo(() => {
    return tableData?.map((subCatgory) => ({
      id: subCatgory._id,
      name: subCatgory.name,
      slug: subCatgory.slug,
      parent_category: subCatgory.parentCategoryId?.name,
      product_type: subCatgory.parentCategoryId?.masterProductId?.name,
      status: subCatgory.status,
      isDeleted: subCatgory.isDeleted ? "Yes" : "No",
      created_at: moment(subCatgory.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);

  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;

    getAllSubCategories(
      1,
      perPage,
      searchItem,
      statusFilter,
      category ? category?._id : "",
      startDate,
      endDate
    );
    setIsDropdownOpen(false);
  };

  const editCategory = async (subCategorySlug) => {
    navigate(`/editSubCategory`, {
      state: { subCategorySlug },
    });
  };

  const deleteCategory = async (categoryId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/category/delete-sub-category/${categoryId}`,
        token
      );

      if (result?.status === 200) {
        toast.success("Sub Category Deleted", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Deleting Sub Category Error", error);
    } finally {
      getAllSubCategories();
      setPending(true);
    }
  };

  useEffect(() => {
    // getAllCategories();
    getAllSubCategories();
  }, []);

  return (
    <Layout>
      <section id="SubCategories_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col>
              <FormControl
                type="text"
                placeholder="Search Sub Categories"
                className="me-2"
                value={searchItem}
                onChange={handleSearchCategory}
              />
            </Col>
            <Col>
              <Form.Group>
                <ReactSelect
                  placeholder="Status"
                  onChange={handleStatusFilter}
                  options={statusOptions}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                {/* <ReactSelect
                  placeholder="Select Category"
                  onChange={categoryChange}
                  options={categories}
                  value={category ? category : ""}
                  onInputChange={(query) => {
                    getAllCategories(query);
                  }}
                  isClearable
                /> */}
                <AsyncPaginate
                  value={category ? category : ""}
                  loadOptions={fetchCategories}
                  getOptionLabel={(option) =>
                    `${option.name} (${option.masterProductId.name})`
                  }
                  getOptionValue={(option) => option._id}
                  onChange={handleCategoryChange}
                  placeholder="Select Category"
                  isClearable
                  loadingMessage={() => "Loading..."}
                  noOptionsMessage={() => "No Category Found"}
                  additional={{
                    page: 1,
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Dropdown
                show={isDropdownOpen}
                onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                autoClose={"outside"}
                className="w-100"
              >
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setStartDate();
                        setEndDate();
                        getAllSubCategories();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {tableData && (
              <Col>
                <CSVLink
                  data={csvData}
                  filename={"posts.csv"}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </CSVLink>
              </Col>
            )}
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalCategories}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default SubCategories;

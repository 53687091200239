import React from "react";
import { Button, Container, FormControl, Image } from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm, uploadURL } from "../../../helper/api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";

const SubSliders = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [subSlider, setSubSlider] = useState([]);

  const getAllSubSlider = async () => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/subSlider/get-all-sub-slider`,
        token
      );
      setSubSlider(response?.result?.data?.sliders);
      setTableData(response?.result?.data?.sliders);
      setTableHead([
        {
          name: "Name",
          sortable: true,
          selector: (row) => row?.name,
        },
        {
          name: "Featured Image",
          sortable: true,
          cell: (row, index, column, id) => (
            <>
              <Image
                src={uploadURL + row.featuredImage}
                fluid
                height={50}
                width={50}
                roundedCircle
                style={{
                  height: "50px",
                  width: "50px",
                  objectFit: "cover",
                }}
              />
            </>
          ),
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Status",
          sortable: true,
          cell: (row, index, column, id) => (
            <>
              {row?.status === "active" && (
                <span className="badge bg-success">
                  {row?.status.capitalize()}
                </span>
              )}
              {(row?.status === "deactive" || row?.status === "rejected") && (
                <span className="badge bg-danger">
                  {row?.status.capitalize()}
                </span>
              )}
            </>
          ),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editSubSlider(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                className="del_btn ms-1"
                size="sm"
                variant="danger"
                onClick={() => deleteSubSlider(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);
    } catch (error) {
      console.log("Get All Sub Slider Error", error);
    } finally {
      setPending(false);
    }
  };

  const searchSubSlider = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);

    if (searchValue === "") {
      setTableData(subSlider); // Show all categories when the search input is empty
    } else {
      const searchData = subSlider.filter((val) =>
        val.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setTableData(searchData);
    }
  };

  const editSubSlider = async (subSliderId) => {
    navigate(`/editSubSlider`, {
      state: { subSliderId },
    });
  };

  const deleteSubSlider = async (subSliderId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        `/api/secure/subSlider/delete-sub-slider/`,
        token,
        {
          subSliderId,
        }
      );

      if (response.result.status === 200) {
        toast.success("Sub Slider Deleted", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Deleting Product Error", error);
    } finally {
      getAllSubSlider();
      setPending(false);
    }
  };

  useEffect(() => {
    getAllSubSlider();
  }, []);

  return (
    <Layout>
      <section id="Slider_list" className="pt-5">
        <Container>
          <div className="d-flex">
            <FormControl
              type="text"
              placeholder="Search Sub Slider"
              className="me-2"
              value={searchItem}
              onChange={searchSubSlider}
            />
          </div>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={_.sortBy(tableData, "total").reverse()}
              style={{
                borderRadius: "10px",
              }}
              pagination
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default SubSliders;

import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { getItemFromLocalStorage } from "../../helper/helper";
import Layout from "../../layouts/public/Layout";
import { getRequest } from "./../../helper/api";
import PublicImages from "../../constants/public/images";

const UnderModeration = () => {
  const navigate = useNavigate();
  const { product, category, post } = useParams();
  if (!product || !category || !post) {
    navigate(-1);
  }
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const getSinglePost = async (product, category, post) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/post/single-post`,
        token,
        {
          productSlug: product,
          categorySlug: category,
          postSlug: post,
          isModeration:true
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        const { post } = result?.data;
        if (!post.isApproved) {
          setMessage(
            "Your post has been received and will be moderated within 48 hours."
          );
        }
      } else if (error?.response?.status === 400) {
        setMessage(error?.response?.data?.message);
      }
    } catch (error) {
      console.log("Get Single Post Error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSinglePost(product, category, post);
  }, [product, category, post]);

  return (
    <Layout>
      <main id="main">
        <section className="publish">
          <Container className="mt-3">
            {loading ? (
              <Loader loading={loading} />
            ) : (
              <Row className="justify-content-center text-center flex-row">
                <Col lg={12} className="text-center">
                  <Image src={PublicImages.go} fluid alt="" />
                </Col>
                <Col lg={12}>
                  {message ? (
                    <p>{message}</p>
                  ) : (
                    <p>
                      This post has been approved. Please click on a link below
                      to view the Post!
                    </p>
                  )}

                  {message === "" ? (
                    <Link
                      to={`/${product}/${category}/${post}`}
                      className="text-decoration-none btn-default-2"
                    >
                      View Post
                    </Link>
                  ) : (
                    <Link
                      to={`/${product}/published`}
                      className="text-decoration-none btn-default-2"
                    >
                      Okay
                    </Link>
                  )}
                </Col>
              </Row>
            )}
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default UnderModeration;

import { Container, Row, Col, Button } from "react-bootstrap";
import PublicImages from "../../constants/public/images";
import { useEffect } from "react";
import { getRequest } from "./../../helper/api";
import { useState } from "react";
import Loader from "../../components/Loader";
import Share from "../../components/public/PopUps/Share";
import { getItemFromLocalStorage } from "../../helper/helper";

function ReferAndEarn({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(true);
  const [shareActive, setShareActive] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [username, setUsername] = useState("");
  const getProfile = async () => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const user = getItemFromLocalStorage("USER");
      const res = await getRequest(`/api/secure/user/profile`, token, {
        _id: JSON.parse(user)._id,
      });
      const response = res?.result?.data?.user;
      setReferralCode(response?.referralCode);
      setUsername(response?.username);
    } catch (err) {
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };
  const handleCopyText = (e) => {
    e.preventDefault();
    try {
      const textToCopyElement = document.getElementById("textToCopy");

      if (textToCopyElement) {
        const textToCopy = textToCopyElement.innerText;
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        // Append the textarea to the document
        document.body.appendChild(textArea);

        // Select the text in the textarea
        textArea.select();

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the textarea from the document
        document.body.removeChild(textArea);
      }
    } catch (error) {
      console.error("Error copying text:", error);
    }
  };

  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getProfile();
    }
  }, [currentlyActive, actualComponent]);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Container>
          <div
            className="earn"
            style={{
              backgroundImage: `url('${PublicImages.image_60}')`,
            }}
          >
            <h5>REFER AND EARN</h5>
            <p>
              Introducing WOMANII, the premier platform for female creators to
              share their thoughts, stories, videos, and podcasts with the
              world. And that's not all – we have an exhilarating Refer and Earn
              program exclusively designed for our female users! Here's how it
              works: Invite your friends, fellow creators, or anyone passionate
              about writing, video production, or podcasting to join WOMANII.
              When they sign up using your unique referral code or link, both
              you and your friend will unlock incredible rewards in the form of
              smiling coins. But it doesn't stop there! As your referral count
              rises, showcase your influence and expertise within the vibrant
              WOMANII community.
            </p>
            <p>
              Ready to embark on an incredible journey of creativity,
              connection, and rewards? Join WOMANII today and start referring
              your fellow female creators to unleash your full potential while
              enjoying fantastic benefits every step of the way. Together, let's
              empower women to make their mark and create a thriving community
              of inspired creators!
            </p>
            <Row>
              <Col lg={7} className="align-self-end">
                <div className="">
                  <div className="d-flex">
                    <div className="align-self-center">
                      <p className="m-0">Your unique code</p>
                      {referralCode && (
                        <h6 id="textToCopy" className="text-center">
                          {referralCode}
                        </h6>
                      )}
                    </div>
                    <div className="bordr">
                      <span>|</span>
                      <a href="#" onClick={handleCopyText}>
                        <img
                          src={PublicImages.file_copy}
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <ul>
                  <li>Step 1: You share the referral code with your friend</li>
                  <li>
                    Step 2: Your friend signs up using the referral code or link
                  </li>
                  <li>
                    Step 3: Once your friend successfully completes their
                    inaugural story, video, or podcast, both of you will receive
                    a reward of 10 Smile Coins each. This reward will be
                    credited within 24 hours of the completion of the
                    transaction. Additionally, you have the opportunity to
                    convert these Smile Coins into actual earnings.
                  </li>
                </ul>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                className="btn-default"
                onClick={() => {
                  setShareActive(true);
                }}
              >
                <i className="far fa-share-alt"></i> SHARE WITH FRIENDS
              </Button>
            </div>
          </div>
          <Share
            show={shareActive}
            handleClose={() => {
              setShareActive(false);
            }}
            referralCode={referralCode}
            username={username}
            isReferral={true}
          />
        </Container>
      )}
    </>
  );
}

export default ReferAndEarn;

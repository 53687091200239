import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { getRequest, postRequestForm } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";
import { toast } from "react-toastify";

export default function ReportPopUp({
  show = false,
  postId = "",
  userId,
  commentId = "",
  handleCloseModal = () => {},
}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    handleCloseModal(false);
  };

  const handleSubmitForPost = async (e) => {
    e.preventDefault();
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/report/register-report-for-post`,
        token,
        {
          postId,
          userId,
          reason: selectedOption === "Any Other" ? otherReason : selectedOption,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        toast.success("Post Reported Successfully", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Error", error.message);
    } finally {
      handleClose();
    }
  };
  const handleSubmitForComment = async (e) => {
    e.preventDefault();
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await postRequestForm(
        `/api/secure/report/register-report-for-comment`,
        token,
        {
          commentId,
          userId,
          reason: selectedOption === "Any Other" ? otherReason : selectedOption,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        toast.success("Comment Reported Successfully", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Error", error.message);
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={showModal}
      centered
      id="report-modal"
    >
      <Modal.Header>
        <div className="col-9 modal-title">
          <div className="d-flex flex-column align-items-center">
            <h5>REPORTING THIS</h5>
            <span className="title-line"></span>
          </div>
        </div>
        <div className="col-3 modal-close-button">
          <Button
            variant="close"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          ></Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-radio-heading">
          <h5>Inappropriate because</h5>
        </div>
        <div className="modal-radio">
          <Form
            onSubmit={postId ? handleSubmitForPost : handleSubmitForComment}
          >
            <Form.Check
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              label="It hurts religious feelings"
              onChange={() => setSelectedOption("It hurts religious feelings")}
            />
            <Form.Check
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              label="Its abusive"
              onChange={() => setSelectedOption("Its abusive")}
            />
            <Form.Check
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault3"
              label="Its Inappropriate"
              onChange={() => setSelectedOption("Its Inappropriate")}
            />
            <Form.Check
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault4"
              label="Its Spam"
              onChange={() => setSelectedOption("Its Spam")}
            />
            <Form.Check
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault5"
              label="Any Other"
              onChange={() => setSelectedOption("Any Other")}
            />
            {selectedOption === "Any Other" && (
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter the reason"
                className="mt-3"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
              />
            )}
            <Button className="btn btn-default w-100 mt-3" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import Loader from "../../../components/Loader";
import { putRequest, getRequest } from "../../../helper/api";
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "../../../helper/helper";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const EmailTemplateSettings = ({ currentlyActive, actualComponent }) => {
  const [loading, setLoading] = useState(false);
  const [welcomeEmail, setWelcomeEmail] = useState(EditorState.createEmpty());
  const [postApprovalEmail, setPostApprovalEmail] = useState(
    EditorState.createEmpty()
  );
  const [welcomeEmailSubject, setWelcomeEmailSubject] = useState("");
  const [postApprovalEmailSubject, setPostApprovalEmailSubject] = useState("");

  const onChangeWelcomeEmailHandle = (editorState) => {
    setWelcomeEmail(editorState);
  };

  const onChangePostApprovalEmailHandle = (editorState) => {
    setPostApprovalEmail(editorState);
  };
  function converEditorToHtml(editor) {
    const html = draftToHtml(convertToRaw(editor.getCurrentContent()));
    return html;
  }
  const updateStatrupPopupSettings = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        "/api/secure/site/update-email-templates",
        token,
        {
          siteId: "007",
          emailTemplates: [
            {
              name: "welcomeEmail",
              subject: welcomeEmailSubject,
              body: converEditorToHtml(welcomeEmail),
            },
            {
              name: "postApprovalEmail",
              subject: postApprovalEmailSubject,
              body: converEditorToHtml(postApprovalEmail),
            },
          ],
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        toast.success("Email Template Settings Updated Successfully", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getEmailTemplates = async () => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        "/api/secure/site/get-email-templates",
        token,
        {
          siteId: "007",
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { emailTemplates } = result?.data;
        const welcomeEmail = emailTemplates?.find(
          (email) => email?.name === "welcomeEmail"
        );

        const postApprovalEmail = emailTemplates?.find(
          (email) => email?.name === "postApprovalEmail"
        );
        if (welcomeEmail?.body) {
          const welcomeEmailContentBlock = htmlToDraft(welcomeEmail?.body);
          const contentState = ContentState.createFromBlockArray(
            welcomeEmailContentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setWelcomeEmail(editorState);
        } else {
          setWelcomeEmail(EditorState.createEmpty());
        }
        setWelcomeEmailSubject(
          welcomeEmail?.subject ? welcomeEmail?.subject : ""
        );

        if (postApprovalEmail?.body) {
          const postApprovalEmailContentBlock = htmlToDraft(
            postApprovalEmail?.body
          );
          const contentState = ContentState.createFromBlockArray(
            postApprovalEmailContentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setPostApprovalEmail(editorState);
        } else {
          setPostApprovalEmail(EditorState.createEmpty());
        }
        setPostApprovalEmailSubject(
          postApprovalEmail?.subject ? postApprovalEmail?.subject : ""
        );
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getEmailTemplates();
    }
  }, [currentlyActive, actualComponent]);
  return (
    <section className="py-5">
      <Container>
        {!loading ? (
          <Form
            className="form_add_user"
            encType="multipart/form-data"
            method="post"
            onSubmit={updateStatrupPopupSettings}
          >
            <Row>
              <Col sm={12} className="mb-2">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Subject"
                  value={welcomeEmailSubject}
                  onChange={(e) => setWelcomeEmailSubject(e.target.value)}
                />
              </Col>
              <Col sm={12} className="mb-5">
                <Form.Label>Welcome Email</Form.Label>
                <Editor
                  editorState={welcomeEmail}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onEditorStateChange={onChangeWelcomeEmailHandle}
                />
              </Col>
              <Col sm={12} className="mb-2">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Subject"
                  value={postApprovalEmailSubject}
                  onChange={(e) => setPostApprovalEmailSubject(e.target.value)}
                />
              </Col>
              <Col sm={12} className="mb-5">
                <Form.Label>Post Approval Email</Form.Label>
                <Editor
                  editorState={postApprovalEmail}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onEditorStateChange={onChangePostApprovalEmailHandle}
                />
              </Col>
              <Col xl={12} sm={12} className="text-center mt-4">
                <Button type="submit">Save</Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader loading={loading} />
        )}
      </Container>
    </section>
  );
};

export default EmailTemplateSettings;

import React, { useEffect, useState } from "react";
import { getRequest, uploadURL } from "../../helper/api";
import SkeletonLoader from "../SkeletonLoader";

const UserBadges = ({ badges }) => {
  const [loading, setLoading] = useState(true);
  const [badgeList, setBadgeList] = useState([]);
  const getUserBadges = async (userBadges) => {
    try {
      setLoading(true);
      const { result, error } = await getRequest(
        `/api/secure/badge/get-user-badge`,
        "",
        {
          userBadges,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { badges } = result?.data;
        setBadgeList(badges);
      } else if (error?.response?.status === 400) {
        setBadgeList([]);
      }
    } catch (error) {
      console.log("GET USER BADGES ERROR => ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUserBadges(badges);
  }, [badges]);

  return (
    <div>
      {loading ? (
        <SkeletonLoader
          loading={loading}
          height="50px"
          width="50px"
          count={badges?.length}
          gap="10px"
          isVertical={false}
        />
      ) : (
        badgeList?.map((badge, index) => (
          <img
            key={index}
            src={uploadURL + badge?.featuredFile}
            alt={badge?.name}
            style={{
              width: "50px",
              height: "50px",
              objectFit:"contain",
              marginRight: "10px",
              borderRadius: "50%",
            }}
          />
        ))
      )}
    </div>
  );
};

export default UserBadges;

import React, { useEffect, useState } from "react";
import Layout from "../../layouts/public/Layout";
import { getRequest, uploadURL, postRequestForm } from "../../helper/api";
import {
  Container,
  Form,
  Image,
  Button,
  Row,
  InputGroup,
  Col,
} from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import Loader from "../../components/Loader";
function Advertisement() {
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [image, setImage] = useState(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const validateContactForm = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    investmentAmount: Yup.string().required("Investment Amount is required"),
    contactPerson: Yup.string().required("Contact Person is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
  });

  const getPageByName = async (pageName) => {
    try {
      const response = await getRequest("/api/secure/page/advertisement", "", {
        pageName,
      });
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        const advertiseData = response?.result?.data?.page;
        setContent(advertiseData?.content);
        setImage(uploadURL + advertiseData?.featuredImage);
      }
    } catch (error) {
      console.log("Get Page API error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContactSubmit = async (values) => {
    try {
      setLoading(true);
      const {
        companyName,
        contactPerson,
        email,
        investmentAmount,
        phoneNumber,
      } = values;
      const response = await postRequestForm(
        "/api/secure/page/send-advertise-query",
        "",
        {
          companyName,
          contactPerson,
          email,
          investAmount: investmentAmount,
          phone: phoneNumber,
        }
      );
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        console.log("response", response);
        setSuccess(response?.result?.data?.data);
        setError(null); // Clear any previous error
      } else {
        setError("Something went wrong");
        setSuccess(""); // Clear success message on error
      }
    } catch (error) {
      console.log("Get Page API error", error.message);
      setError("Something went wrong");
      setSuccess(""); // Clear success message on error
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPageByName("Advertisement");
  }, []);

  return (
    <Layout>
      <section className="banner2">
        <Container>
          <h1>ADVERTISEMENT</h1>
          {isLoading ? (
            <Loader loading={isLoading} />
          ) : (
            <>
              <Image src={image} alt="" className="w-100" fluid />
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            </>
          )}
        </Container>
      </section>
      <section className="info_1">
        <Container>
          <div className="back">
            <h1>Investment Form:</h1>
            {loading ? (
              <Loader loading={loading} />
            ) : (
              <Formik
                initialValues={{
                  companyName,
                  email,
                  investmentAmount,
                  contactPerson,
                  phoneNumber,
                }}
                validationSchema={validateContactForm}
                onSubmit={handleContactSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6} md={6} xs={12}>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            name="companyName"
                            placeholder="Company Name:"
                            onChange={handleChange("companyName")}
                            onBlur={handleBlur("companyName")}
                            defaultValue={values.companyName}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email:"
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            defaultValue={values.email}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            name="investmentAmount"
                            placeholder="Investment Amount (USD):"
                            onChange={handleChange("investmentAmount")}
                            onBlur={handleBlur("investmentAmount")}
                            defaultValue={values.investmentAmount}
                          />
                        </InputGroup>
                      </Col>
                      <Col lg={6} md={6} xs={12}>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            name="contactPerson"
                            placeholder="Contact Person Name:"
                            onChange={handleChange("contactPerson")}
                            onBlur={handleBlur("contactPerson")}
                            defaultValue={values.contactPerson}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="tel"
                            name="phoneNumber"
                            placeholder="Phone Number:"
                            onChange={handleChange("phoneNumber")}
                            onBlur={handleBlur}
                            defaultValue={values.phoneNumber}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <div>
                      <p>
                        By investing in WOMANII, you are not only supporting
                        female empowerment but also gaining exposure to a
                        dynamic and influential community of women. Our team
                        will reach out to discuss the investment opportunities
                        and develop a partnership that aligns with your brand
                        objectives.
                      </p>
                      <p>
                        Thank you for considering an investment in WOMANII.
                        Together, let's inspire, educate, and uplift women
                        around the world!
                      </p>
                    </div>
                    {touched.companyName && errors.companyName ? (
                      <div className="alert alert-danger">
                        {errors.companyName}
                      </div>
                    ) : null}
                    {touched.email && errors.email ? (
                      <div className="alert alert-danger">{errors.email}</div>
                    ) : null}
                    {touched.investmentAmount && errors.investmentAmount ? (
                      <div className="alert alert-danger">
                        {errors.investmentAmount}
                      </div>
                    ) : null}
                    {touched.contactPerson && errors.contactPerson ? (
                      <div className="alert alert-danger">
                        {errors.contactPerson}
                      </div>
                    ) : null}
                    {touched.phoneNumber && errors.phoneNumber ? (
                      <div className="alert alert-danger">
                        {errors.phoneNumber}
                      </div>
                    ) : null}
                    {success || error ? (
                      <div
                        className={`alert ${success || error ? "" : "d-none"} ${
                          error ? "alert-danger" : "alert-success"
                        }`}
                        role="alert"
                      >
                        {success ? success : error}
                      </div>
                    ) : null}

                    <InputGroup
                      className="  d-flex
                        justify-content-center"
                    >
                      <button className="btn-default">SEND A MESSAGE</button>
                    </InputGroup>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </Container>
      </section>
    </Layout>
  );
}
export default Advertisement;

import React, { useState, useEffect } from "react";
import Layout from "./../../../layouts/admin/Layout";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { getRequest, putRequest, uploadURL } from "./../../../helper/api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMinus } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/Loader";
import ImageUploading from "react-images-uploading";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { getItemFromLocalStorage } from "../../../helper/helper";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Teams = () => {
  const [isLoading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [images, setImages] = useState([]);
  const [page, setPage] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]); // For uploaded images

  const updatePageHandler = async (e) => {
    e.preventDefault();

    try {
      const token = getItemFromLocalStorage("TOKEN");
      const formData = new FormData();
      formData.append("pageName", "Teams");
      // Handle image uploads
      if (uploadedImages) {
        teamMembers.map((teamMember, index) => {
          if (typeof teamMember.image === "number") {
            formData.append(
              `images_${teamMember.image}`,
              uploadedImages[teamMember.image].file
            );
          }
        });
      }

      // Set teamMembers directly in formData
      formData.append("teamMembers", JSON.stringify(teamMembers));
      console.log("formData", Object.fromEntries(formData));

      const response = await putRequest(
        "/api/secure/page/update-teams",
        token,
        formData
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        toast.success("Teams Page Updated", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Update Page API error", error.message);
    }
  };

  const getPageByName = async (pageName) => {
    try {
      const response = await getRequest("/api/secure/page/team", "", {
        pageName,
      });
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        setPage(response?.result?.data?.page);
        const imagesArray = response?.result?.data?.page?.teamMembers.map(
          (member, index) => {
            return {
              data_url: uploadURL + member.image,
            };
          }
        );
        setTeamMembers(response?.result?.data?.page?.teamMembers);
        setImages(imagesArray);
        setLoading(false);
      }
    } catch (error) {
      console.log("Get Page API error", error.message);
    }
  };

  const addRowTable = () => {
    const addMember = {
      image: "",
      name: "",
      designation: "",
      description: "",
    };
    const addImage = {
      data_url: "",
    };
    setTeamMembers([...teamMembers, addMember]);
    setImages([...images, addImage]);
  };

  const removeRowTable = (index) => {
    const memberRow = [...teamMembers];
    memberRow.splice(index, 1);
    setTeamMembers(memberRow);
    const imagesRow = [...images];
    imagesRow.splice(index, 1);
    setImages(imagesRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const member = [...teamMembers];
    member[i][name] = value;
    setTeamMembers(member);
  };
  const onImageChange = async (i, imageList) => {
    const changeIndex = Math.floor(Math.random() * 10);
    console.log("i", i);
    const image = [...images];
    image[i] = imageList[i];
    setImages(image);
    const member = [...teamMembers];
    member[i].image = changeIndex;
    console.log("member", member);
    setTeamMembers(member);
    const updatedImages = [...uploadedImages];
    updatedImages[changeIndex] = imageList[i];
    console.log("updatedImages", updatedImages);
    setUploadedImages(updatedImages);
  };
  // const onDragEnd = (result) => {
  //   console.log("result",result)
  //   if (!result.destination) return;
  //   const reorderedMembers = Array.from(teamMembers);
  //   const [reorderedItem] = reorderedMembers.splice(result.source.index, 1);
  //   reorderedMembers.splice(result.destination.index, 0, reorderedItem);
  //   setTeamMembers(reorderedMembers);
  // };
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    if (source.index === destination.index) return;

    // Update team members order
    const reorderedMembers = Array.from(teamMembers);
    const [reorderedItem] = reorderedMembers.splice(source.index, 1);
    reorderedMembers.splice(destination.index, 0, reorderedItem);
    setTeamMembers(reorderedMembers);

    // Update images order
    const reorderedImages = Array.from(images);
    const [reorderedImage] = reorderedImages.splice(source.index, 1);
    reorderedImages.splice(destination.index, 0, reorderedImage);
    setImages(reorderedImages);
  };

  useEffect(() => {
    getPageByName("Teams");
  }, []);

  return (
    <Layout>
      <section className="py-5">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updatePageHandler}
            >
              <Row>
                <Col sm={12}>
                  {/* Team Members Table */}
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Table id="teamMembersTable" responsive striped>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Designation</th>
                          <th>Description</th>
                          <th>Image</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <Droppable droppableId="team-members">
                        {(provided) => (
                          <tbody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {teamMembers.map((member, index) => (
                              <Draggable
                                key={index}
                                draggableId={`member-${index}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      background: snapshot.isDragging
                                        ? "lightgreen"
                                        : "white",
                                    }}
                                  >
                                    <td>
                                      <Form.Control
                                        type="text"
                                        name="name"
                                        value={member.name}
                                        onChange={(event) =>
                                          onValUpdate(index, event)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Form.Control
                                        type="text"
                                        name="designation"
                                        value={member.designation}
                                        onChange={(event) =>
                                          onValUpdate(index, event)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="description"
                                        value={member.description}
                                        onChange={(event) =>
                                          onValUpdate(index, event)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <td>
                                        {/* Use ImageUploading component to handle image changes */}
                                        <ImageUploading
                                          value={[images[index]]}
                                          onChange={(imageList) =>
                                            onImageChange(index, imageList)
                                          }
                                          dataURLKey="data_url"
                                          maxNumber={1}
                                          maxFileSize={1000000}
                                          acceptType={[
                                            "jpeg",
                                            "jpg",
                                            "gif",
                                            "png",
                                          ]}
                                        >
                                          {({
                                            imageList,
                                            onImageUpload,
                                            onImageUpdate,
                                            isDragging,
                                            dragProps,
                                            errors,
                                          }) => (
                                            <>
                                              <div className="upload__image-wrapper mx-auto mb-5">
                                                <button
                                                  type="button"
                                                  style={
                                                    isDragging
                                                      ? { color: "red" }
                                                      : undefined
                                                  }
                                                  onClick={onImageUpload}
                                                  {...dragProps}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faPlus}
                                                  />
                                                </button>
                                                {imageList.length > 0 && (
                                                  <div className="image-item">
                                                    {imageList[0]?.data_url && (
                                                      <img
                                                        src={
                                                          imageList[0]?.data_url
                                                        }
                                                        alt={`Team Member ${
                                                          index + 1
                                                        }`}
                                                      />
                                                    )}
                                                    <div className="image-item__btn-wrapper">
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          onImageUpdate(index)
                                                        }
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={
                                                            imageList[0]
                                                              ?.data_url
                                                              ? faPencilAlt
                                                              : faPlus
                                                          }
                                                        />
                                                      </button>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                              {errors && (
                                                <div id="imageUploadingErrors">
                                                  {errors.maxFileSize && (
                                                    <Alert
                                                      className="my-1"
                                                      variant="danger"
                                                    >
                                                      Selected file size exceed
                                                      1MB
                                                    </Alert>
                                                  )}
                                                  {errors.acceptType && (
                                                    <Alert
                                                      className="my-1"
                                                      variant="danger"
                                                    >
                                                      Your selected file type is
                                                      not allow
                                                    </Alert>
                                                  )}
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </ImageUploading>
                                      </td>
                                    </td>
                                    <td>
                                      <Button
                                        className="btn-danger"
                                        onClick={() => removeRowTable(index)}
                                      >
                                        <FontAwesomeIcon icon={faMinus} />
                                      </Button>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                      <tfoot>
                        <tr>
                          <td className="text-end" colSpan={5}>
                            <Button
                              className="btn-primary"
                              onClick={addRowTable}
                            >
                              <FontAwesomeIcon icon={faAdd} />
                            </Button>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </DragDropContext>
                </Col>
                <Col xl={12} sm={12} className="text-center mt-4">
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Loader loading={isLoading} />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Teams;

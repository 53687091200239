import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { getRequest, putRequest } from "../../../helper/api";
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "../../../helper/helper";

function StatrupPopupSettings({ currentlyActive, actualComponent }) {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [editor, setEditor] = useState();
  const [popupCondition, setPopupCondition] = useState();
  const [oneTime, setOneTime] = useState();

  const oneTimeOptions = [
    { value: "One Time", label: "One Time" },
    { value: "Always", label: "Always" },
  ];

  const oneTimeChange = (selectedOption) => {
    setOneTime(selectedOption);
  };

  const onChangeEditorHandle = (editorState) => {
    setEditor(editorState);
    console.log("editorState", editorState);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];

  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const updateStatrupPopupSettings = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      let isOneTime = true;
      if (typeof oneTime === "object") {
        isOneTime = oneTime.value === "One Time" ? true : false;
      } else {
        isOneTime = oneTime === "One Time" ? true : false;
      }
      const { result, error } = await putRequest(
        "/api/secure/site/update-startup-popup-settings",
        token,
        {
          siteId: "007",
          name,
          description: draftToHtml(convertToRaw(editor.getCurrentContent())),
          status: typeof status === "object" ? status.value : status,
          isOneTime: isOneTime,
          popupCondition:
            typeof popupCondition === "object"
              ? popupCondition.value
              : popupCondition,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { startUpPopupSettings } = result?.data;
        toast.success("Statrup Popup Settings Updated", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setItemInLocalStorage(
          "POPUP_SETTINGS",
          JSON.stringify(startUpPopupSettings)
        );
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Update Statrup Popup Settings API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getLabelByValue = (options, value) => {
    const option = options?.find((option) => option.value === value);
    return option?.label;
  };
  const getStatrupPopupSettings = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        "/api/secure/site/get-startup-popup-settings",
        token,
        {
          siteId: "007",
        }
      );
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        const { startUpPopupSettings } = response?.result?.data;
        const { name, description, status, isOneTime } = startUpPopupSettings;
        setName(name);
        setStatus(status);
        setPopupCondition(startUpPopupSettings.popupCondition);
        if (isOneTime) {
          setOneTime("One Time");
        } else {
          setOneTime("Always");
        }

        const contentBlock = htmlToDraft(description);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        }
      }
    } catch (error) {
      console.log("Get Statrup Popup Settings API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const popupConditionOptions = [
    { value: "allOverSite", label: "All Over Site" },
    { value: "homePage", label: "Home Page Only" },
  ];

  const popupConditionChange = (selectedOption) => {
    setPopupCondition(selectedOption);
  };

  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getStatrupPopupSettings();
    }
  }, [currentlyActive, actualComponent]);

  return (
    <section className="py-5">
      <Container>
        {!loading ? (
          <Form
            className="form_add_user"
            encType="multipart/form-data"
            method="post"
            onSubmit={updateStatrupPopupSettings}
          >
            <Row>
              <Col xl={6} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder="Name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl={6} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <ReactSelect
                    placeholder="Select Status"
                    onChange={statusChange}
                    options={statusOptions}
                    value={
                      typeof status === "object"
                        ? status
                        : status
                        ? {
                            value: status,
                            label: getLabelByValue(statusOptions, status),
                          }
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={6} className="mb-3">
                <Form.Label>Popup Condition</Form.Label>
                <ReactSelect
                  placeholder="Select Popup Condition"
                  onChange={popupConditionChange}
                  options={popupConditionOptions}
                  value={
                    typeof popupCondition === "object"
                      ? popupCondition
                      : popupCondition
                      ? {
                          value: popupCondition,
                          label: getLabelByValue(
                            popupConditionOptions,
                            popupCondition
                          ),
                        }
                      : ""
                  }
                />
              </Col>
              <Col xl={6} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Visibility</Form.Label>
                  <ReactSelect
                    placeholder="Select Visibility"
                    onChange={oneTimeChange}
                    options={oneTimeOptions}
                    value={
                      typeof oneTime === "object"
                        ? oneTime
                        : oneTime
                        ? {
                            value: oneTime,
                            label: getLabelByValue(oneTimeOptions, oneTime),
                          }
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={12} className="mb-5">
                <Form.Label>Description</Form.Label>
                <Editor
                  editorState={editor}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  onEditorStateChange={onChangeEditorHandle}
                />
              </Col>
              <Col xl={12} sm={12} className="text-center mt-4">
                <Button type="submit">Save</Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader loading={loading} />
        )}
      </Container>
    </section>
  );
}

export default StatrupPopupSettings;

import React, { useEffect, useState } from "react";
import Layout from "../../layouts/public/Layout";
import PublicImages from "../../constants/public/images";
import { Accordion, Image, Row,Col } from "react-bootstrap";
import Loader from "../../components/Loader";
import { getRequest } from "../../helper/api";

function FAQs() {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPageByName = async (pageName) => {
    try {
      const response = await getRequest("/api/secure/page/faqs", "", {
        pageName,
      });
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        setFaqs(response?.result?.data?.page?.questions);
      }
    } catch (error) {
      console.log("Get Page APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPageByName("FAQs");
  }, []);
  return (
    <Layout>
      {loading ? (
        <Loader loading={loading}/>
      ) : (
        <>
          <section className="faq">
            <div className="container">
              <div className="text-center">
                <h1>FAQ’s</h1>
              </div>
            </div>
          </section>
          <section className="faq-2">
            <div className="container">
              <Row>
                <Col lg={7} md={6} xs={12} className="">
                  {faqs ? (
                    <Accordion defaultActiveKey={["0"]} alwaysOpen>
                      {faqs.map((item, key) => (
                        <Accordion.Item eventKey={`${key}`}>
                          <Accordion.Header>{item.question}</Accordion.Header>
                          <Accordion.Body>{item.answer}</Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  ) : (
                    <Loader loading={loading}/>
                  )}
                </Col>
                <Col lg={5} md={6} xs={12} className="brd">
                  <h5>GET NEWSLETTER</h5>
                  <div className="text-center">
                    <div className="first">
                      <Image fluid src={PublicImages.drafts} alt="" />
                    </div>
                    <div className="heading">
                      <h3>
                        Subscribe To Our Daily <br />
                        Newsletter
                      </h3>
                    </div>
                    <div className="email">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email Address ..."
                        />
                      </div>
                    </div>
                    <div className="bnt">
                      <a href="#"> Subscribe </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </>
      )}
    </Layout>
  );
}

export default FAQs;

import React, { useEffect, useState } from "react";
import domtoimage from "dom-to-image";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Image,
  Ratio,
  Tab,
  Nav,
} from "react-bootstrap";
import Layout from "../../layouts/public/Layout";
import PublicImages from "../../constants/public/images";
import { useDropzone } from "react-dropzone";
import ImageUploading from "react-images-uploading";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";

import draftToHtml from "draftjs-to-html";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { getItemFromLocalStorage } from "../../helper/helper";
import { getRequest, postRequestForm } from "../../helper/api";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SelectBackground from "./SelectBackground";
import TextSettings from "./TextSettings";
export default function GenerateCard() {
  const { state, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get("Tab");
  const { product } = useParams();
  const navigate = useNavigate();
  if (!product || !state?.title || !state?.category || !state?.article) {
    navigate(-1);
  }
  const [featuredImage, setFeaturedImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [componentLoading, setComponentLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("site-settings"); // Default active tab
  const [activeSettings, setActiveSettings] = useState([
    { value: "", property: "backgroundImage" },
    { value: "left", property: "textAlign" },
    { value: "left", property: "textAlignLast" },
    { value: "", property: "textTransform" },
    { value: "#fff", property: "color" },
    { value: "flex-start", property: "justifyContent" },
  ]);
  const settingsData = [
    {
      key: "background",
      title: "Short Story Images",
      Component: SelectBackground,
    },
    {
      key: "text", // Corrected key
      title: "Text",
      Component: TextSettings,
    },
  ];

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    } else {
      setActiveTab("background");
    }
    console.log(product, state)
  }, [tab]);

  const formatString = (input) => {
    return input
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const addPostHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", state?.title);
      formData.append("subCategoryId", state?.category);
      if (featuredImage) {
        formData.append("featuredImage", featuredImage);
      } else {
        alert(`Error: Generating the Image for a ${formatString(product)}.`);
        return false;
      }
      formData.append("article", state?.article);
      const token = getItemFromLocalStorage("TOKEN");
      const user = getItemFromLocalStorage("USER");
      const userId = JSON.parse(user)?._id;
      formData.append("userId", userId);
      console.log("formData", Object.fromEntries(formData));
      const { result, error } = await postRequestForm(
        "/api/secure/post/create-post",
        token,
        formData
      );

      if (result?.status === 200 || result?.status === 201) {
        const { post, category } = result?.data;
        toast.success("Post Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        // navigate(`/${product}/`);
        if (post.isApproved) {
          navigate(`/${product}/published`);
        } else {
          navigate(
            `/${product}/${category?.parentCategoryId?.slug}/under-moderation/${post.slug}`
          );
        }
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Create Post APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSettingsChange = (newSetting) => {
    console.log("newSetting",newSetting);
    setActiveSettings((prevSettings) => {
      // Find the index of the object with the matching property
      const index = prevSettings.findIndex(
        (setting) => setting.property === newSetting.property
      );

      // If the property is found, update the specific object
      if (index !== -1) {
        return [
          ...prevSettings.slice(0, index), // elements before the updated element
          newSetting, // updated element
          ...prevSettings.slice(index + 1), // elements after the updated element
        ];
      } else {
        // If the property is not found, add the new setting to the array
        return [...prevSettings, newSetting];
      }
    });
  };
  const styleObject = activeSettings.reduce((style, setting) => {
    if (setting.property === "backgroundImage") {
      style.backgroundImage = `url(${setting.value})`;
    } else {
      style[setting.property] = setting.value;
    }

    return style;
  }, {});
  styleObject.height = "max-content";
  styleObject.backgroundSize = "cover";
  styleObject.backgroundRepeat = "no-repeat";
  styleObject.height = "345px";
  styleObject.width = "370px";
  styleObject.display = "flex";
  styleObject.flexDirection = "column";
  const handlePreviewClick = () => {
    domtoimage
      .toPng(document.getElementById("view"))
      .then(function (dataUrl) {
        navigate(`/${product}/upload/generate-card/preview`, {
          state: {
            title: state?.title,
            article: state?.article,
            category: state?.category,
            categoryLabel: state?.categoryLabel,
            challengeSlug: state?.challengeSlug,
            featuredImage: dataUrl,
          },
        });
      })
      .catch(function (error) {
        console.error("Oops, something went wrong!", error);
      });
  };
  return (
    <Layout>
      <section className="select_background py-5">
        <Container>
          <h1 className="text-center">Select {formatString(activeTab)}</h1>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Row className="pt-3">
              <Col lg={6} md={6} xs={12}>
                <div
                  id="view"
                  className="px-2 py-2"
                  style={styleObject}
                >
                  <h2>{state?.title}</h2>
                  <div
                    id="textView"
                    dangerouslySetInnerHTML={{ __html: state?.article }}
                  ></div>
                </div>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Tab.Container
                  id="settings-tabs"
                  activeKey={activeTab}
                  onSelect={handleTabSelect}
                >
                  <Nav
                    variant="tabs"
                    className="justify-content-center mb-3 border-0 gap-3"
                    as="ul"
                  >
                    {settingsData.map((setting) => (
                      <Nav.Item
                        key={setting.key}
                        as="li"
                        className="flex-grow-1 d-flex justify-content-center"
                      >
                        <Nav.Link
                          as={Link}
                          to={`/${product}/upload/generate-card?Tab=${setting.key}`}
                          state={{
                            title: state?.title,
                            article: state?.article,
                            category: state?.category,
                            categoryLabel: state?.categoryLabel,
                            challengeSlug: state?.challengeSlug,
                          }}
                          className="border-0"
                          eventKey={setting.key}
                        >
                          {setting.title}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content>
                    {settingsData.map(({ key, Component }, index) => (
                      <Tab.Pane key={`${key}-${index}`} eventKey={key}>
                        <Component
                          currentlyActive={activeTab}
                          actualComponent={key}
                          activeSettings={activeSettings}
                          onSettingsChange={handleSettingsChange}
                          isLoading={(loading)=>{
                            console.log("loading",loading)
                            setComponentLoading(loading)
                          }}
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </Col>
              {
                        console.log(state)}
              <Col lg={6} md={6} xs={12} className="mt-5">
                <Link
                  to={`/${product}/upload`}
                  state={{
                    title: state?.title,
                    article: state?.article,
                    category: state?.category,
                    categoryLabel: state?.categoryLabel,
                    challengeSlug: state?.challengeSlug,
                  }}
                  className="btn-default-3"
                >
                  Back
                </Link>
              </Col>
              <Col lg={6} md={6} xs={12} className="text-end mt-5">
                <Button
                  className="btn-default-2 "
                  disabled={!componentLoading}
                  onClick={() => {
                    setLoading(true);
                    handlePreviewClick();
                    setLoading(false);
                  }}
                >
                  Preview
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </Layout>
  );
}

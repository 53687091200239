import React from "react";
import Layout from "../layouts/public/Layout";
import PublicImages from "../constants/public/images";
import { Col, Container, Image, Row } from "react-bootstrap";

const Error404 = () => {
  return (
    <Layout>
      <section className="error">
        <Container>
          <Row className="justify-content-center text-center">
            <Col lg={6} md={6} xs={12}>
              <Image fluid src={PublicImages.frame} alt="" />
            </Col>
            <Col lg={12} md={12} xs={12} className="pt-5">
              <h1>404</h1>
              <h4>OOPS!-Page Not Found</h4>
              <p>We are working on it:)</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Error404;

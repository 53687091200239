import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as _ from "underscore";
import { getRequest, putRequest } from "../../../helper/api";

import Select from "react-select";
import ReactSelect from "react-select";
import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Layout from "../../../layouts/admin/Layout";

const EditReportedPost = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { reportId, postId } = state;
  if (reportId === "") {
    navigate("/ReportedPosts");
  }
  const [decision, setDecision] = useState("");
  const [reportAnswers, setReportAnswers] = useState([]);
  const [reportAnswer, setReportAnswer] = useState("");
  const [answers, setAnswers] = useState([]);
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const decisionOptions = [
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
  ];
  const [reportAnswerId, setReportAnswerId] = useState("");
  const [report, setReport] = useState({});

  const getLabelByValue = (options, value) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : "";
  };
  const getReportAnswers = async (search) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result } = await getRequest(
        `/api/secure/reportAnswer/get-report-answers`,
        token,
        {
          status: "active",
          search,
        }
      );
      if (result.status === 200) {
        setReportAnswers(
          result.data.reportAnswers.map((answer) => {
            return {
              value: answer._id,
              label: answer.title,
              answers: answer.answers,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const reportAnswerChange = (selectedReportAnswer) => {
    setReportAnswer(selectedReportAnswer);
    setReportAnswerId(selectedReportAnswer.value);
    setAnswers(
      selectedReportAnswer.answers.map((answer) => {
        return {
          value: answer,
          label: answer,
        };
      })
    );
  };
  const decisionChange = (selectedDecision) => {
    setDecision(selectedDecision);
  };
  const answerChange = (selectedDecision) => {
    setAnswer(selectedDecision);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        `/api/secure/report/report-decision-for-post`,
        token,
        {
          decision: typeof decision === "object" ? decision.value : decision,
          reportAnswerId,
          answer: typeof answer === "object" ? answer.value : answer,
          reportId,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        toast.success("Report Answer Updated Successfully", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/ReportedPosts");
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getReport = async (reportpostId) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/report/get-report/${reportpostId}`,
        token
      );
      if (result?.status === 200 || result?.status === 201) {
        const { decision, reportAnswerId, adminResponse } = result.data.report;
        setReport(result.data.report);
        setDecision(decision);
        setReportAnswer(reportAnswerId);
        setReportAnswerId(reportAnswerId);
        setAnswers(
          reportAnswers
            .find((answer) => answer.value === reportAnswerId)
            ?.answers.map((answer) => {
              return {
                value: answer,
                label: answer,
              };
            })
        );
        setAnswer(adminResponse);
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getReportAnswers();
    getReport(reportId);
  }, [reportId]);

  return (
    <Layout>
      <section id="Posts_list" className="pt-5">
        <Container>
          {loading ? (
            <Loader loading={true} />
          ) : (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={6} xs={12} className="mb-3">
                  <Form.Label>Decision</Form.Label>
                  <ReactSelect
                    placeholder="Select Decision"
                    onChange={decisionChange}
                    options={decisionOptions}
                    value={
                      typeof decision === "object"
                        ? decision
                        : decision
                        ? {
                            value: decision,
                            label: getLabelByValue(decisionOptions, decision),
                          }
                        : ""
                    }
                    required
                    isClearable
                  />
                </Col>
                <Col lg={6} xs={12} className="mb-3">
                  <Form.Label>Report Answers</Form.Label>
                  <ReactSelect
                    placeholder="Select Report Answer"
                    onChange={reportAnswerChange}
                    options={reportAnswers}
                    value={
                      typeof reportAnswer === "object"
                        ? reportAnswer
                        : reportAnswer
                        ? {
                            value: reportAnswer,
                            label: getLabelByValue(reportAnswers, reportAnswer),
                          }
                        : ""
                    }
                    required
                    isClearable
                    onInputChange={(query) => {
                      getReportAnswers(query);
                    }}
                  />
                </Col>
                {reportAnswerId && (
                  <Col lg={12} xs={12} className="mb-3">
                    <Form.Label>Answers</Form.Label>
                    <ReactSelect
                      placeholder="Answers"
                      onChange={answerChange}
                      options={answers}
                      value={
                        typeof answer === "object"
                          ? answer
                          : answer
                          ? {
                              value: answer,
                              label: getLabelByValue(answers, answer),
                            }
                          : ""
                      }
                      required
                      isClearable
                    />
                  </Col>
                )}
                <Col lg={12} xs={12} className="mb-3 text-center">
                  <Button type="submit" variant="primary">
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default EditReportedPost;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "./../../layouts/admin/Layout";
import { getRequest } from "../../helper/api";
import Loader from "../../components/Loader";
import { getItemFromLocalStorage } from "../../helper/helper";

const Dashboard = () => {
  const [pending, setPending] = useState(() => false);
  const [postInsights, setPostInsights] = useState(() => {});
  const [userInsights, setUserInsights] = useState(() => {});
  const [adminInsights, setAdminInsights] = useState(() => {});
  const [catInsights, setCatInsights] = useState(() => {});
  const [subCatInsights, setSubCatInsights] = useState(() => {});
  const [challengeInsights, setChallengeInsights] = useState(() => {});
  const [productInsights, setProductInsights] = useState(() => []);
  const [blogInsights, setBlogInsights] = useState(() => []);
  const [videoInsights, setVideoInsights] = useState(() => []);
  const [audioInsights, setAudioInsights] = useState(() => []);
  const [imageInsights, setImageInsights] = useState(() => []);
  const [shortStoryInsights, setShortStoryInsights] = useState(() => []);
  const [visitors, setVisitors] = useState(() => []);

  const token = useMemo(() => getItemFromLocalStorage("TOKEN"), []);

  const fetchData = useCallback(
    async (url, setter) => {
      try {
        setPending(true);
        const response = await getRequest(url, token);

        if (response.result.status === 200) {
          const { insights } = response.result.data;
          setter(insights);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setPending(false);
      }
    },
    [token]
  );

  const getPostInsights = useCallback(() => {
    fetchData("/api/secure/dashboard/get-post-insights", setPostInsights);
  }, [fetchData]);

  const getUserInsights = useCallback(() => {
    fetchData("/api/secure/dashboard/get-user-insights", setUserInsights);
  }, [fetchData]);

  const getAdminInsights = useCallback(() => {
    fetchData("/api/secure/dashboard/get-admin-insights", setAdminInsights);
  }, [fetchData]);

  const getCatInsights = useCallback(() => {
    fetchData("/api/secure/dashboard/get-category-insights", setCatInsights);
  }, [fetchData]);

  const getSubCatInsights = useCallback(() => {
    fetchData(
      "/api/secure/dashboard/get-sub-category-insights",
      setSubCatInsights
    );
  }, [fetchData]);

  const getChallengeInsights = useCallback(() => {
    fetchData(
      "/api/secure/dashboard/get-challenge-insights",
      setChallengeInsights
    );
  }, [fetchData]);

  const getProductInsights = useCallback(() => {
    fetchData("/api/secure/dashboard/get-product-insights", setProductInsights);
  }, [fetchData]);
  const getBlogInsights = useCallback(() => {
    fetchData("/api/secure/dashboard/get-blog-insights", setBlogInsights);
  }, [fetchData]);
  const getVideoInsights = useCallback(() => {
    fetchData("/api/secure/dashboard/get-video-insights", setVideoInsights);
  }, [fetchData]);
  const getAudioInsights = useCallback(() => {
    fetchData("/api/secure/dashboard/get-audio-insights", setAudioInsights);
  }, [fetchData]);
  const getImageInsights = useCallback(() => {
    fetchData(
      "/api/secure/dashboard/get-image-corner-insights",
      setImageInsights
    );
  }, [fetchData]);
  const getShortStoryInsights = useCallback(() => {
    fetchData(
      "/api/secure/dashboard/get-short-story-insights",
      setShortStoryInsights
    );
  }, [fetchData]);
  const getCounts = useCallback(() => {
    fetchData("/api/secure/visitors", setVisitors);
  }, [fetchData]);
  const formatCash = (number) => {
    return Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(number);
  };
  useEffect(() => {
    getPostInsights();
    getUserInsights();
    getAdminInsights();
    getCatInsights();
    getSubCatInsights();
    getChallengeInsights();
    getProductInsights();
    getBlogInsights();
    getVideoInsights();
    getAudioInsights();
    getImageInsights();
    getShortStoryInsights();
    getCounts();
  }, []);
  return (
    <Layout>
      <section className="articles">
        <Container>
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <Row className="justify-content-center ">
              {/* {productInsights &&
                productInsights.map(({ insight, name }, key) => (
                  <Col lg={6} md={6} xs={12} className="mb-3">
                    <div className="vlogs">
                      <div className="text-start ">
                        <h5>
                          <b>{name}</b>
                        </h5>

                        <div className="d-flex  flex-wrap">
                          <p className="m-0 para2">
                            Today : {insight?.today ? insight?.today : 0}
                            <span>|</span>
                          </p>
                          <p className="m-0 para">
                            Last Day : {insight?.lastDay ? insight?.lastDay : 0}
                            <span>|</span>
                          </p>
                          <p className="m-0 para2">
                            Last Week :{" "}
                            {insight?.lastWeek ? insight?.lastWeek : 0}
                            <span>|</span>
                          </p>
                          <p className="m-0 para3">
                            Last Month :{" "}
                            {insight?.lastMonth ? insight?.lastMonth : 0}
                          </p>
                        </div>
                      </div>
                      <div className="imageCover">
                        <h3 className="text-white m-0">{insight?.total}</h3>
                      </div>
                    </div>
                  </Col>
                ))} */}
              {productInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Products</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {productInsights?.today ? productInsights?.today : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {productInsights?.lastDay
                            ? productInsights?.lastDay
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {productInsights?.lastWeek
                            ? productInsights?.lastWeek
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {productInsights?.lastMonth
                            ? productInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">
                        {productInsights?.total}
                      </h3>
                    </div>
                  </div>
                </Col>
              )}
              {postInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Post</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {postInsights?.today ? postInsights?.today : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {postInsights?.lastDay ? postInsights?.lastDay : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {postInsights?.lastWeek ? postInsights?.lastWeek : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {postInsights?.lastMonth
                            ? postInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">{postInsights?.total}</h3>
                    </div>
                  </div>
                </Col>
              )}
              {userInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Users</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {userInsights?.today ? userInsights?.today : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {userInsights?.lastDay ? userInsights?.lastDay : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {userInsights?.lastWeek ? userInsights?.lastWeek : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {userInsights?.lastMonth
                            ? userInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">{userInsights?.total}</h3>
                    </div>
                  </div>
                </Col>
              )}
              {catInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Categories</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today : {catInsights?.today ? catInsights?.today : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {catInsights?.lastDay ? catInsights?.lastDay : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {catInsights?.lastWeek ? catInsights?.lastWeek : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {catInsights?.lastMonth ? catInsights?.lastMonth : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">{catInsights?.total}</h3>
                    </div>
                  </div>
                </Col>
              )}
              {subCatInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Sub Categories</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {subCatInsights?.today ? subCatInsights?.today : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {subCatInsights?.lastDay
                            ? subCatInsights?.lastDay
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {subCatInsights?.lastWeek
                            ? subCatInsights?.lastWeek
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {subCatInsights?.lastMonth
                            ? subCatInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">
                        {subCatInsights?.total}
                      </h3>
                    </div>
                  </div>
                </Col>
              )}
              {adminInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Admin</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {adminInsights?.today ? adminInsights?.today : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {adminInsights?.lastDay ? adminInsights?.lastDay : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {adminInsights?.lastWeek
                            ? adminInsights?.lastWeek
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {adminInsights?.lastMonth
                            ? adminInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">{adminInsights?.total}</h3>
                    </div>
                  </div>
                </Col>
              )}
              {challengeInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Challenge</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {challengeInsights?.today
                            ? challengeInsights?.today
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {challengeInsights?.lastDay
                            ? challengeInsights?.lastDay
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {challengeInsights?.lastWeek
                            ? challengeInsights?.lastWeek
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {challengeInsights?.lastMonth
                            ? challengeInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">
                        {challengeInsights?.total}
                      </h3>
                    </div>
                  </div>
                </Col>
              )}
              {blogInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Blogs</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {blogInsights?.today ? blogInsights?.today : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {blogInsights?.lastDay ? blogInsights?.lastDay : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {blogInsights?.lastWeek ? blogInsights?.lastWeek : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {blogInsights?.lastMonth
                            ? blogInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">{blogInsights?.total}</h3>
                    </div>
                  </div>
                </Col>
              )}
              {videoInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Videos</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {videoInsights?.today ? videoInsights?.today : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {videoInsights?.lastDay ? videoInsights?.lastDay : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {videoInsights?.lastWeek
                            ? videoInsights?.lastWeek
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {videoInsights?.lastMonth
                            ? videoInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">{videoInsights?.total}</h3>
                    </div>
                  </div>
                </Col>
              )}
              {audioInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Audios</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {audioInsights?.today ? audioInsights?.today : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {audioInsights?.lastDay ? audioInsights?.lastDay : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {audioInsights?.lastWeek
                            ? audioInsights?.lastWeek
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {audioInsights?.lastMonth
                            ? audioInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">{audioInsights?.total}</h3>
                    </div>
                  </div>
                </Col>
              )}
              {imageInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Image Corners</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {imageInsights?.today ? imageInsights?.today : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {imageInsights?.lastDay ? imageInsights?.lastDay : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {imageInsights?.lastWeek
                            ? imageInsights?.lastWeek
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {imageInsights?.lastMonth
                            ? imageInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">{imageInsights?.total}</h3>
                    </div>
                  </div>
                </Col>
              )}
              {shortStoryInsights && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Short Stories</b>
                      </h5>

                      <div className="d-flex  flex-wrap">
                        <p className="m-0 para2">
                          Today :{" "}
                          {shortStoryInsights?.today
                            ? shortStoryInsights?.today
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para">
                          Last Day :{" "}
                          {shortStoryInsights?.lastDay
                            ? shortStoryInsights?.lastDay
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para2">
                          Last Week :{" "}
                          {shortStoryInsights?.lastWeek
                            ? shortStoryInsights?.lastWeek
                            : 0}
                          <span>|</span>
                        </p>
                        <p className="m-0 para3">
                          Last Month :{" "}
                          {shortStoryInsights?.lastMonth
                            ? shortStoryInsights?.lastMonth
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="imageCover">
                      <h3 className="text-white m-0">
                        {shortStoryInsights?.total}
                      </h3>
                    </div>
                  </div>
                </Col>
              )}
              {visitors && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <div className="vlogs">
                    <div className="text-start ">
                      <h5>
                        <b>Visitors</b>
                      </h5>
                    </div>
                    <div className="">
                      <h3 className=" m-0">
                        {visitors?.count}
                      </h3>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Dashboard;

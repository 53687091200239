import { useRef, useEffect } from 'react'

export const useDynamicTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount])
}


export const useDynamicViewport = (viewportContent) => {
  useEffect(() => {
    const metaTag = document.querySelector('meta[name="viewport"]');

    if (metaTag) {
      const originalViewportContent = metaTag.getAttribute('content');
      metaTag.setAttribute('content', viewportContent);

      return () => {
        // Reset the viewport content to its original value when unmounting
        metaTag.setAttribute('content', originalViewportContent);
      };
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.name = 'viewport';
      newMetaTag.content = viewportContent;
      document.head.appendChild(newMetaTag);

      return () => {
        // Remove the newly added meta tag when unmounting
        document.head.removeChild(newMetaTag);
      };
    }
  }, [viewportContent]);
};


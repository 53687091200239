import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { postRequestForm } from "../../../helper/api";
import { useState } from "react";
import { getItemFromLocalStorage } from "../../../helper/helper";

const AddLanguage = () => {
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const addUserHandler = async (e) => {
    e.preventDefault();
    try {
      const token = getItemFromLocalStorage("TOKEN");

      const response = await postRequestForm(
        "/api/public/language/create-language",
        token,
        {
          name,
          key,
          data: [],
        }
      );

      if (response?.result?.status === 200 || response?.result?.status === 201) {
        toast.success("Language Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        window.location.assign("/Languages");
      }
    } catch (error) {
      console.log("Create Language APi error", error.message);
    }
  };
  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          <Form
            className="form_add_user"
            encType="multipart/form-data"
            method="post"
            onSubmit={addUserHandler}
          >
            <Row>
              <Col xl={6} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder="Name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xl={6} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Key</Form.Label>
                  <Form.Control
                    name="key"
                    type="text"
                    placeholder="Key"
                    value={key}
                    required
                    onChange={(e) => setKey(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xl={12} sm={12} className="text-center">
                <Button type="submit">Submit</Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </Layout>
  );
};

export default AddLanguage;

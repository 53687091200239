import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { getRequest, postRequestForm } from "../../../helper/api";
import Loader from "../../../components/Loader";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";
import { getItemFromLocalStorage } from "../../../helper/helper";
import { AsyncPaginate } from "react-select-async-paginate";

const AddTopicOfInterest = () => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState();
  const [subCategory, setSubCategory] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchSubCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-all-sub-categories`,
        token,
        {
          page: page,
          search: search,
          status: "active",
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };
  const subCategoryChange = (selectedOption) => {
    setSubCategory(selectedOption);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];

  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const topicOfInterestHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        "/api/secure/topicOfInterest/create-topic-of-interest",
        token,
        {
          name,
          categoryId:
            typeof subCategory === "object" ? subCategory._id : subCategory,
          status: typeof status === "object" ? status.value : status,
        }
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        toast.success("Topic Of Interest Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/topicOfInterests");
      }
    } catch (error) {
      console.log("Create Topic Of Interest APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={topicOfInterestHandler}
            >
              <Row>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="Name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Sub Category</Form.Label>
                    <AsyncPaginate
                      value={subCategory ? subCategory : ""}
                      loadOptions={fetchSubCategories}
                      getOptionLabel={(option) =>
                        `${option.name} (${option?.parentCategory?.name}) {${option?.masterProduct?.name}}`
                      }
                      getOptionValue={(option) => option._id}
                      onChange={subCategoryChange}
                      placeholder="Select Sub Category"
                      isClearable
                      loadingMessage={() => "Loading..."}
                      noOptionsMessage={() => "No Sub Category Found"}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <ReactSelect
                      placeholder="Select Status"
                      onChange={statusChange}
                      options={statusOptions}
                      value={status ? status : ""}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default AddTopicOfInterest;

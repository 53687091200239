import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import Loader from "../../../components/Loader";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { putRequest, uploadURL } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";

const WithdrawalExpandedRow = ({ withdrawal, updateWithdrawalData }) => {
  const bankTypeOptions = [
    { value: "NEFT", label: "NEFT" },
    { value: "RTGS", label: "RTGS" },
    { value: "IMPS", label: "IMPS" },
    { value: "UPI", label: "UPI" },
    { value: "PAYTM", label: "PAYTM" },
  ];
  const [loading, setLoading] = useState(true);
  const [isPending, setIsPending] = useState(true);
  const [paymentImage, setPaymentImage] = React.useState([]);
  const [selectedPaymentImage, setSelectedPaymentImage] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [transactionReferrenceNo, setTransactionReferrenceNo] = useState("");
  const [bankType, setBankType] = useState("");
  const [amount, setAmount] = useState("");
  const onChangeImage = async (imageList, addUpdateIndex) => {
    setPaymentImage(imageList);
    setSelectedPaymentImage(imageList[addUpdateIndex].file);
  };
  const handleBankTypeChange = (selectedOption) => {
    setBankType(selectedOption);
  };
  const loadWithdrawalData = (withdrawal) => {
    try {
      setLoading(true);
      if (
        withdrawal?.paymentPhoto !== undefined ||
        withdrawal?.paymentPhoto !== "" ||
        withdrawal?.paymentPhoto !== null
      ) {
        setPaymentImage([
          {
            data_url: uploadURL + withdrawal?.paymentPhoto,
          },
        ]);
      }
      setBankType(
        withdrawal?.payerBankType
          ? {
              label: withdrawal?.payerBankType,
              value: withdrawal?.payerBankType,
            }
          : ""
      );
      setTransactionId(withdrawal?.paymentId ? withdrawal?.paymentId : "");
      setTransactionReferrenceNo(
        withdrawal?.referenceNumber ? withdrawal?.referenceNumber : ""
      );
      setAmount(withdrawal?.paidAmmount ? withdrawal?.paidAmmount : "");
      setIsPending(withdrawal?.isPending);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  const payNowHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("paymentId", transactionId);
      formData.append("payerBankType", bankType?.value);
      formData.append("amount", amount);
      formData.append("paymentRefNo", transactionReferrenceNo);
      formData.append("type", "Outgoing");

      if (selectedPaymentImage) {
        formData.append("paymentPhoto", selectedPaymentImage);
        const token = getItemFromLocalStorage("TOKEN");
        const response = await putRequest(
          `/api/secure/wallet/withdrawals/${withdrawal?._id}`,
          token,
          formData
        );

        if (
          response?.result?.status === 200 ||
          response?.result?.status === 201
        ) {
          toast.success("Transaction Updated", {
            position: "top-center", theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          updateWithdrawalData();
        }
      } else {
        alert("Please Upload the Payment Image");
      }
    } catch (error) {
      console.log("Update Transaction APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadWithdrawalData(withdrawal);
  }, [withdrawal]);
  return (
    <Container fluid className="py-3">
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Form
          className="form_add_user"
          encType="multipart/form-data"
          method="post"
          onSubmit={payNowHandler}
        >
          <Row>
            <Col sm={12} className="text-center">
              <Form.Group className="mb-3">
                <Form.Label>Payment Image</Form.Label>
                <ImageUploading
                  value={paymentImage}
                  onChange={onChangeImage}
                  dataURLKey="data_url"
                  maxNumber={1}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    isDragging,
                    dragProps,
                  }) => (
                    <div className="upload__image-wrapper mx-auto mb-5">
                      <button
                        type="button"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {/* <i className="fal fa-plus"></i> */}
                      </button>
                      {imageList
                        ? imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image["data_url"]} alt="" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  type="button"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <FontAwesomeIcon icon={faPencilAlt} />
                                </button>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  )}
                </ImageUploading>
              </Form.Group>
            </Col>
            <Col xl={3} md={6} sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Bank Type</Form.Label>
                <ReactSelect
                  placeholder="Bank Type"
                  onChange={handleBankTypeChange}
                  options={bankTypeOptions}
                  value={bankType ? bankType : ""}
                  required
                  isClearable
                  readOnly={!isPending}
                />
              </Form.Group>
            </Col>
            <Col xl={3} md={6} sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Transaction Id</Form.Label>
                <Form.Control
                  name="transactionId"
                  type="number"
                  placeholder="Transaction Id"
                  required
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                  readOnly={!isPending}
                />
              </Form.Group>
            </Col>
            <Col xl={3} md={6} sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Transaction Referrence No.</Form.Label>
                <Form.Control
                  name="transactionReferrenceNo"
                  type="number"
                  placeholder="Transaction Referrence No."
                  required
                  value={transactionReferrenceNo}
                  onChange={(e) => setTransactionReferrenceNo(e.target.value)}
                  readOnly={!isPending}
                />
              </Form.Group>
            </Col>
            <Col xl={3} md={6} sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  name="amount"
                  type="number"
                  placeholder="Amount"
                  required
                  value={amount}
                  min={0}
                  max={withdrawal?.amount}
                  onChange={(e) => setAmount(e.target.value)}
                  readOnly={!isPending}
                />
              </Form.Group>
            </Col>

            <Col xl={12} sm={12} className="text-center">
              <Button disabled={!isPending} type="submit">
                Pay Now
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
};

export default WithdrawalExpandedRow;

import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { getRequest, putRequest, uploadURL } from "../../../helper/api";
import Loader from "../../../components/Loader";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { getItemFromLocalStorage } from "../../../helper/helper";

const EditBackground = () => {
  let backgroundId = "";
  const location = useLocation();
  const navigate = useNavigate();
  if (location?.state?.backgroundId === "") {
    navigate("/Backgrounds");
  } else {
    backgroundId = location?.state?.backgroundId;
  }
  const [status, setStatus] = useState();
  const [images, setImages] = React.useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [loading, setLoading] = useState(true);

  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setFeaturedImage(imageList[addUpdateIndex].file);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];

  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const updateBackgroundHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        "status",
        typeof status === "object" ? status.value : status
      );
      if (featuredImage) {
        formData.append("image", featuredImage);
      } else if (!images.some((file) => file.hasOwnProperty("data_url"))) {
        toast.error("Please select an image", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      const token = getItemFromLocalStorage("TOKEN");
      const response = await putRequest(
        `/api/secure/shortStoriesBackground/update-background/${backgroundId}`,
        token,
        formData
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        toast.success("Background Updated", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/Backgrounds");
      }
    } catch (error) {
      console.log("Update Background APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getBackgroundById = async (backgroundId) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");

      const response = await getRequest(
        `/api/secure/shortStoriesBackground/background`,
        token,
        {
          backgroundId,
        }
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        const { image, status } = response?.result?.data?.background;

        setStatus(status);

        if (
          image !== undefined ||
          image !== "" ||
          image !== null
        ) {
          setImages([
            {
              data_url: uploadURL + image,
            },
          ]);
        }
      }
    } catch (error) {
      console.log("Get Background By ID APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const getLabelByValue = (statusOptions, value) => {
    const option = statusOptions?.find((option) => option.value === value);
    return option?.label;
  };
  useEffect(() => {
    getBackgroundById(backgroundId);
  }, [backgroundId, location]);
  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={updateBackgroundHandler}
            >
              <Row>
                <Col sm={12} className="text-center">
                  <Form.Label>Update Background Image</Form.Label>
                  <ImageUploading
                    value={images}
                    onChange={onChangeImage}
                    dataURLKey="data_url"
                    maxNumber={1}
                    maxFileSize={1000000}
                    acceptType={["jpeg", "jpg", "gif", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div className="upload__image-wrapper mx-auto mb-5">
                          <button
                            type="button"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            {/* <i className="fal fa-plus"></i> */}
                          </button>
                          {imageList
                            ? imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img src={image["data_url"]} alt="" />
                                  <div className="image-item__btn-wrapper">
                                    <button
                                      type="button"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <FontAwesomeIcon icon={faPencilAlt} />
                                    </button>
                                  </div>
                                </div>
                              ))
                            : ""}
                        </div>
                        {errors && (
                          <div
                            id="imageUploadingErrors"
                            className="text-center"
                          >
                            {errors.maxFileSize && (
                              <Alert className="my-1" variant="danger">
                                Selected file size exceed 1MB
                              </Alert>
                            )}
                            {errors.acceptType && (
                              <Alert className="my-1" variant="danger">
                                Your selected file type is not allow
                              </Alert>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </ImageUploading>
                </Col>
                {status && (
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <ReactSelect
                        placeholder="Select Status"
                        onChange={statusChange}
                        options={statusOptions}
                        required
                        value={
                          typeof status === "object"
                            ? status
                            : status
                            ? {
                                value: status,
                                label: getLabelByValue(statusOptions, status),
                              }
                            : ""
                        }
                      />
                    </Form.Group>
                  </Col>
                )}

                <Col xl={12} sm={12} className="text-center my-5">
                  <Button type="submit">Update</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default EditBackground;

import PublicImages from "./public/images";
const SiteSettings = {
  __SITE_ID: "007",
  SITE_TITLE: "Womanii",
  SITE_LOGO: PublicImages.LOGO,
  FB_LINK: "#",
  TW_LINK: "#",
  INSTA_LINK: "#",
  YT_LINK: "#",
  APPSTORE_LINK: "#",
  PLAYSTORE_LINK: "#",
  Phone: "+1 (800) 123 4467",
  Email: "womanii2023@gmail.com",
  Address: "1625 Bagwell Avenue, Gainesville United States",
  ContactUSMaps: 
  `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3297.7230903035984!2d-83.87998658805908!3d34.255608872968715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5f3902e980acb%3A0xf87439c30f0e22c!2sBagwell%20Dr%2C%20Gainesville%2C%20GA%2030504%2C%20USA!5e0!3m2!1sen!2s!4v1693910168755!5m2!1sen!2s" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
  Site_LINK: "/",
  Copyright_text: `©${new Date().getFullYear()} Womanii.com ALL RIGHTS RESERVED.`,
};

export default SiteSettings;
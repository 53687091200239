import { useEffect, useState } from "react";
import { baseURL, getRequest, uploadURL } from "../../helper/api";
import { Col, Image, Container, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader";
import { getItemFromLocalStorage } from "../../helper/helper";

function SelectBackground({
  currentlyActive,
  actualComponent,
  activeSettings = [{ value: "", property: "backgroundImage" }],
  onSettingsChange = () => {},
  isLoading = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [backgroundImagesError, setBackgroundImagesError] = useState("");
  const [backgroundImagesPage, setBackgroundImagesPage] = useState(1);
  const [backgroundImagesLength, setBackgroundImagesLength] = useState(0);
  const [backgroundImagesEnded, setBackgroundImagesEnded] = useState(true);

  const getBackgroundImages = async (page) => {
    try {
      const token = await getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/shortStoriesBackground/all-backgrounds`,
        token,
        {
          page: page,
          limit: 6,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        setBackgroundImagesError("");
        if (page === 1) {
          setBackgroundImages(result?.data.backgrounds);
          // Inside the function where you load more data
          setBackgroundImagesLength(result?.data.backgrounds);
          if (result?.data?.backgrounds) {
            onSettingsChange({
              value: result?.data.backgrounds[0]?.image,
              property: "backgroundImage",
            });
            isLoading(false);
          }
        } else {
          setBackgroundImages((prevData) => [
            ...prevData,
            ...result?.data.backgrounds,
          ]);
          // Inside the function where you load more data
          setBackgroundImagesLength(backgroundImages.length);
        }
        if (result?.data.currentPage === result?.data.totalPages) {
          setBackgroundImagesEnded(false);
        }
        setBackgroundImagesPage((prevPage) => prevPage + 1);
      } else if (error?.response?.status === 400) {
        setBackgroundImagesError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      isLoading(true);
    }
  };

  const handleImageClick = (imageUrl) => {
    onSettingsChange({
      value: imageUrl,
      property: "backgroundImage",
    });
  };
  useEffect(() => {
    if (currentlyActive === actualComponent) {
      setLoading(true);
      setBackgroundImages([]);
      setBackgroundImagesError("");
      setBackgroundImagesPage(1);
      setBackgroundImagesLength(0);
      setBackgroundImagesEnded(true);
      getBackgroundImages(1);
    }
  }, [currentlyActive, actualComponent]);
  return (
    <section className="backgroundsToSelect">
      {loading ? (
        <Loader loading={true} />
      ) : (
        <div
          id="scrollableDiv"
          style={{
            height: 400,
            overflow: "auto",
          }}
        >
          {backgroundImagesError ? (
            backgroundImagesError
          ) : (
            <InfiniteScroll
              dataLength={backgroundImagesLength}
              next={() => {
                getBackgroundImages(backgroundImagesPage);
              }}
              inverse={false}
              hasMore={backgroundImagesEnded}
              loader={<Loader loading={true} />}
              scrollThreshold={0.9}
              endMessage={<></>}
              scrollableTarget="scrollableDiv"
            >
              <Row className="m-0">
                {backgroundImages.map(({ image, _id }, index) => (
                  <Col md={6} sm={6} xs={6}>
                    <Image
                      fluid
                      src={image}
                      onClick={() => handleImageClick(image)}
                      className={
                        activeSettings.some(
                          (item) =>
                            item.property === "backgroundImage" &&
                            item.value === image
                        )
                          ? "selected-image w-100"
                          : " w-100"
                      }
                      style={{
                        height: 150,
                        marginBottom: 20,
                        objectFit: "cover",
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          )}
        </div>
      )}
    </section>
  );
}

export default SelectBackground;

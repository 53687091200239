import domtoimage from "dom-to-image";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import { postRequestForm } from "../../../../helper/api";
import { getItemFromLocalStorage } from "../../../../helper/helper";
import SelectBackground from "./SelectBackground";
import TextSettings from "./TextSettings";
import Layout from "../../../../layouts/admin/Layout";
export default function GenerateShortStoryCard() {
  const { state, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get("Tab");
  const {
    product,
    title,
    category,
    article,
    subCategory,
    user,
    status,
    hashtags,
  } = state || {};

  const navigate = useNavigate();

  // Verifying all state properties
  useEffect(() => {
    if (
      !product ||
      !title ||
      !category ||
      !article ||
      !subCategory ||
      !user ||
      !status
    ) {
      console.error("Missing state information", { product, ...state });
      navigate(-1);
    } else {
      console.log("All state information is present", { product, ...state });
    }
  }, [product, state, navigate]);

  const [featuredImage, setFeaturedImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [componentLoading, setComponentLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("site-settings"); // Default active tab
  const [activeSettings, setActiveSettings] = useState([
    { value: "", property: "backgroundImage" },
    { value: "left", property: "textAlign" },
    { value: "left", property: "textAlignLast" },
    { value: "", property: "textTransform" },
    { value: "#fff", property: "color" },
    { value: "flex-start", property: "justifyContent" },
  ]);
  const settingsData = [
    {
      key: "background",
      title: "Short Story Images",
      Component: SelectBackground,
    },
    {
      key: "text", // Corrected key
      title: "Text",
      Component: TextSettings,
    },
  ];

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    } else {
      setActiveTab("background");
    }
    console.log(product, state);
  }, [tab]);

  const formatString = (input) => {
    return input
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleSettingsChange = (newSetting) => {
    console.log("newSetting", newSetting);
    setActiveSettings((prevSettings) => {
      // Find the index of the object with the matching property
      const index = prevSettings.findIndex(
        (setting) => setting.property === newSetting.property
      );

      // If the property is found, update the specific object
      if (index !== -1) {
        return [
          ...prevSettings.slice(0, index), // elements before the updated element
          newSetting, // updated element
          ...prevSettings.slice(index + 1), // elements after the updated element
        ];
      } else {
        // If the property is not found, add the new setting to the array
        return [...prevSettings, newSetting];
      }
    });
  };
  const styleObject = activeSettings.reduce((style, setting) => {
    if (setting.property === "backgroundImage") {
      style.backgroundImage = `url(${setting.value})`;
    } else {
      style[setting.property] = setting.value;
    }

    return style;
  }, {});
  styleObject.height = "max-content";
  styleObject.backgroundSize = "cover";
  styleObject.backgroundRepeat = "no-repeat";
  styleObject.height = "345px";
  styleObject.width = "370px";
  styleObject.display = "flex";
  styleObject.flexDirection = "column";
  const handlePreviewClick = () => {
    domtoimage
      .toPng(document.getElementById("view"))
      .then(function (dataUrl) {
        navigate(`/addPost/generate-card/preview`, {
          state: {
            title,
            article,
            category,
            product,
            subCategory,
            user,
            status,
            hashtags,
            featuredImage: dataUrl,
          },
        });
      })
      .catch(function (error) {
        console.error("Oops, something went wrong!", error);
      });
  };
  return (
    <Layout>
      <section className="select_background py-5">
        <Container fluid>
          <h1 className="text-center">Select {formatString(activeTab)}</h1>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Row className="pt-3">
              <Col lg={6} md={6} xs={12}>
                <div id="view" className="px-2 py-2" style={styleObject}>
                  <h2>{state?.title}</h2>
                  <div
                    id="textView"
                    dangerouslySetInnerHTML={{ __html: state?.article }}
                  ></div>
                </div>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Tab.Container
                  id="settings-tabs"
                  activeKey={activeTab}
                  onSelect={handleTabSelect}
                >
                  <Nav
                    variant="tabs"
                    className="justify-content-center mb-3 border-0 gap-3"
                    as="ul"
                  >
                    {settingsData.map((setting) => (
                      <Nav.Item
                        key={setting.key}
                        as="li"
                        className="flex-grow-1 d-flex justify-content-center"
                      >
                        <Nav.Link
                          as={Link}
                          to={`/addPost/generate-card?Tab=${setting.key}`}
                          state={{
                            title,
                            article,
                            category,
                            product,
                            subCategory,
                            user,
                            status,
                          }}
                          className="border-0"
                          eventKey={setting.key}
                        >
                          {setting.title}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content>
                    {settingsData.map(({ key, Component }, index) => (
                      <Tab.Pane key={`${key}-${index}`} eventKey={key}>
                        <Component
                          currentlyActive={activeTab}
                          actualComponent={key}
                          activeSettings={activeSettings}
                          onSettingsChange={handleSettingsChange}
                          isLoading={(loading) => {
                            console.log("loading", loading);
                            setComponentLoading(loading);
                          }}
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </Col>
              {console.log(state)}
              <Col lg={6} md={6} xs={12} className="mt-5">
                <Link
                  to={`/addPost/?product=${product?.slug}`}
                  state={{
                    title,
                    article,
                    category,
                    product,
                    subCategory,
                    user,
                    status,
                    hashtags,
                  }}
                  className="btn-default-3"
                >
                  Back
                </Link>
              </Col>
              <Col lg={6} md={6} xs={12} className="text-end mt-5">
                <Button
                  className="btn-default-2 "
                  disabled={!componentLoading}
                  onClick={() => {
                    setLoading(true);
                    handlePreviewClick();
                    setLoading(false);
                  }}
                >
                  Preview
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </Layout>
  );
}

import React from "react";
import { Button, Container, FormControl } from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../helper/api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";

const Languages = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [languages, setLanguages] = useState([]);

  const getAllLanguages = async () => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/public/language/get-languages`,
        token
      );
      setLanguages(response?.result?.data?.languages);
      setTableData(response?.result?.data?.languages);
      setTableHead([
        {
          name: "Sub Domain",
          sortable: true,
          selector: (row) => row.subDomain,
        },
        {
          name: "Name",
          sortable: true,
          selector: (row) => row.name,
        },
        {
          name: "Key",
          sortable: true,
          selector: (row) => row.key,
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() =>
                  editSingleLanguage(row._id, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                className="del_btn ms-1"
                size="sm"
                variant="danger"
                onClick={() =>
                  deleteSingleLanguage(row._id, row, index, column, id)
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);

      setPending(false);
    } catch (error) {
      console.log("Get All Languages Error", error);
    }
  };

  const searchLanguages = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);

    if (searchValue === "") {
      setTableData(languages); // Show all categories when the search input is empty
    } else {
      const searchData = languages.filter((val) =>
        val.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setTableData(searchData);
    }
  };

  const editSingleLanguage = async (languageId) => {
    navigate(`/editSingleLanguage`, {
      state: { languageId },
    });
  };

  const deleteSingleLanguage = async (languageId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        `/api/public/language/delete-language/${languageId}`,
        token
      );

      if (response.result.status === 200) {
        toast.success("Language Deleted", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        getAllLanguages();
      }
    } catch (error) {
      console.log("Deleting Language Error", error);
    }
  };

  useEffect(() => {
    getAllLanguages();
  }, []);

  return (
    <Layout>
      <section id="Languages_list" className="pt-5">
        <Container>
          <div className="d-flex">
            <FormControl
              type="text"
              placeholder="Search Languages"
              className="me-2"
              value={searchItem}
              onChange={searchLanguages}
            />
          </div>
          <br />
          <br />
          {!pending ? (
            <DataTable
              columns={tableHead}
              data={_.sortBy(tableData, "total").reverse()}
              style={{
                borderRadius: "10px",
              }}
              pagination
              progressPending={pending}
            />
          ) : (
            <Loader loading={pending} />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Languages;

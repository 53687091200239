import React, { useEffect, useMemo, useState } from "react";
import { Container, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import ImageCard from "../../../components/public/Pages/ImageCard";
import { getRequest, postRequestForm, uploadURL } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";

export default function UploadVideo({
  currentlyActive,
  actualComponent,
  user,
}) {
  const product = "videos";
  const [pending, setPending] = useState(true);
  const [allData, setAllData] = useState([]);
  const [allDataLength, setAllDataLength] = useState(0);
  const [postError, setPostsError] = useState("");
  const [postEnded, setPostEnded] = useState(true);
  const [posts, setPosts] = useState([]);
  const [postsPage, setPostsPage] = useState(1);
  const [isBookmark, setIsBookmark] = useState({});
  const [isLiked, setIsLiked] = useState({});

  const loadVideoPosts = async (product, page = 1, userId) => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/post/posts-by-product`,
        "",
        {
          page: page,
          productSlug: product,
          userId,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        setPostsError("");
        setPosts(result?.data);
        if (page === 1) {
          setAllData(result?.data.posts);
          // Inside the function where you load more data
          setAllDataLength(result?.data.posts);
        } else {
          setAllData((prevData) => [...prevData, ...result?.data.posts]);
          // Inside the function where you load more data
          setAllDataLength(allData.length);
        }
        if (result?.data.currentPage === result?.data.totalPages) {
          setPostEnded(false);
        }
        setPostsPage((prevPage) => prevPage + 1);
      } else if (error?.response?.status === 400) {
        setPostsError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPending(false);
    }
  };
  const handleBookmarkPost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/bookmark-post`,
        token,
        {
          postIdToToggle: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsBookmark((prev) => {
          let isBookmarked = false;
          const newState = { ...prev };
          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isBookmarked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isBookmarked = true;
          }
          if (isBookmarked) {
            toast.success("Post has been Bookmarked!", {
              position: "top-center", theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(
              `Post has been ${
                isBookmarked ? "Bookmarked!" : "Un-Bookmarked!"
              }!`,
              {
                position: "top-center", theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              }
            );
          }
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleLikePost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/like-post`,
        token,
        {
          postId: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsLiked((prev) => {
          const newState = { ...prev };
          let isLiked = false;

          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isLiked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isLiked = true;
          }

          // Update the like count in the corresponding post data
          setAllData((prevData) =>
            prevData.map((item) =>
              item._id === postId
                ? {
                    ...item,
                    likeCount: isLiked
                      ? item.likeCount + 1
                      : item.likeCount - 1,
                  }
                : item
            )
          );

          if(isLiked){
            toast.success(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center", theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }else{
            toast.error(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
              position: "top-center", theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }

          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const getUserLikedAndBookmarkedPosts = useMemo(() => {
    return async () => {
      try {
        setPending(true);
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result, error } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            const { userPrefrences } = result?.data;
            const { likedPost, bookmarks } = userPrefrences;
            setIsLiked((prev) => ({
              ...prev,
              ...likedPost.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
            setIsBookmark((prev) => ({
              ...prev,
              ...bookmarks.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
          }
        } else {
          setIsLiked({});
          setIsBookmark({});
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
        setIsLiked({});
        setIsBookmark({});
      } finally {
        setPending(false); // Ensure isLoading is set to false
      }
    };
  }, []);
  useEffect(() => {
    getUserLikedAndBookmarkedPosts();
  }, [getUserLikedAndBookmarkedPosts]);

  useEffect(() => {
    setPending(true);
    setAllData([]);
    setAllDataLength(0);
    setPostsError("");
    setPostEnded(true);
    setPosts([]);
    setPostsPage(1);
    if (currentlyActive === actualComponent) {
      loadVideoPosts(product, 1, user);
    }
  }, [currentlyActive, actualComponent, user]);

  return (
    <section className="mt-5 bg-white py-3">
      <Container>
        {pending ? (
          <Loader loading={true} />
        ) : (
          <div>
            {postError ? (
              postError
            ) : (
              <InfiniteScroll
                dataLength={allDataLength}
                next={() => {
                  loadVideoPosts(product, postsPage, user);
                }}
                inverse={false}
                hasMore={postEnded}
                loader={<Loader loading={true} />}
                scrollThreshold={0.9}
                endMessage={
                  <p style={{ justifyContent: "center", textAlign: "center" }}>
                    <b>Yay! You've seen it all!</b>
                  </p>
                }
              >
                <Row className="m-0">
                  {allData.map((item, index) => (
                    <ImageCard
                      key={index}
                      image={uploadURL + item?.featuredImage}
                      authorImage={
                        item.userId?.profileImage
                          ? uploadURL + item.userId.profileImage
                          : ""
                      }
                      subCatName={`${item.subCategoryId?.name}`}
                      productSlug={`${item?.productId?.slug}`}
                      catSlug={`${item?.categoryId?.slug}`}
                      subCatSlug={`${item.subCategoryId?.slug}`}
                      authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                      views={item.viewCount}
                      comments={item.comments}
                      likes={item.likeCount}
                      title={item.title}
                      date={item.createdAt}
                      username={item.userId.username}
                      link={`/${item.productId?.slug}/${item.categoryId?.slug}/${item.slug}`}
                      isBookmarked={isBookmark[item._id]}
                      likeHandler={() => handleLikePost(item._id)}
                      isLiked={isLiked[item._id]}
                      bookmarkHandler={() => handleBookmarkPost(item._id)}
                      isWinner={item.isWinner}
                      challengeLink={`/${item.productId?.slug}/challenges/${item.challengeId?.slug}`}
                      isStarmarked={item.isStarmarked}
                    />
                  ))}
                </Row>
              </InfiniteScroll>
            )}
          </div>
        )}
      </Container>
    </section>
  );
}

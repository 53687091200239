import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { DateRangePicker } from "react-date-range";
import ReactSelect from "react-select";
import * as _ from "underscore";
import Loader from "../../../components/Loader";
import { getRequest } from "../../../helper/api";
import Layout from "../../../layouts/admin/Layout";
import WithdrawalExpandedRow from "./WithdrawalExpandedRow";
import { getItemFromLocalStorage } from "../../../helper/helper";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  getFilterHistory,
  updateFilterHistory,
} from "../../../helper/filterHistory";
import { useLocation, useNavigate } from "react-router-dom";

const Withdrawals = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const statusOptions = [
    { value: "Incoming", label: "Details" },
    { value: "Outgoing", label: "Completed" },
    { value: "Pending", label: "Received" },
  ];
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [statusFilter, setStatusFilter] = useState({});
  const [user, setUser] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getAllCategories = async (
    page = 1,
    limit = 10,
    search = "",
    status = null,
    user = null,
    startDate = "",
    endDate = ""
  ) => {
    try {
      setCurrentPage(page);
      setPerPage(limit);
      setSearchItem(search);
      setStatusFilter(status);
      setUser(user);
      setStartDate(startDate);
      setEndDate(endDate);
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/wallet/get-transactions`,
        token,
        {
          limit: limit,
          page: page,
          search: search,
          status: status?.value,
          startDate: startDate,
          endDate: endDate,
          user: user?._id,
        }
      );
      setTableHead([
        {
          name: "Withdrawal ID",
          sortable: true,
          selector: (row) => row._id,
        },
        {
          name: "Type",
          sortable: true,
          cell: (row, index, column, id) => (
            <>
              {row.type === "Incoming" && (
                <span className="badge bg-secondary">Details</span>
              )}
              {row.type === "Pending" && (
                <span className="badge bg-info">Recived</span>
              )}
              {row.type === "Outgoing" && (
                <span className="badge bg-success">Completed</span>
              )}
            </>
          ),
        },
        {
          name: "Pending",
          sortable: true,
          selector: (row) => (row.isPending ? "Yes" : "No"),
        },
        {
          name: "Amount",
          sortable: true,
          selector: (row) => row.amount,
        },
        {
          name: "User",
          sortable: true,
          selector: (row) => `${row.userId?.firstName} ${row.userId?.lastName}`,
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
      ]);
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        setTotalTransactions(response?.result.data.totalTransactions);
        setTableData(response?.result?.data?.transactions);
      } else {
        setTotalTransactions(0);
        setTableData([]);
      }
    } catch (error) {
      console.log("Get All Transactions Error", error);
    } finally {
      setPending(false);
    }
  };
  const handlePageChange = (page) => {
    updateFilterHistory(
      {
        page: page,
        limit: perPage,
        searchItem: searchItem,
        status: statusFilter,
        user: user,
        startDate: startDate,
        endDate: endDate,
      },
      "Widthdrawals",
      navigate
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    updateFilterHistory(
      {
        page: 1,
        limit: newPerPage,
        searchItem,
        status: statusFilter,
        user,
        startDate,
        endDate,
      },
      "Widthdrawals",
      navigate
    );
  };
  const handleStatusFilter = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        searchItem,
        status: selectedOption,
        user,
        startDate,
        endDate,
      },
      "Widthdrawals",
      navigate
    );
  };
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        searchItem: searchValue,
        status: statusFilter,
        user,
        startDate,
        endDate,
      },
      "Widthdrawals",
      navigate
    );
  };
  const csvData = useMemo(() => {
    return tableData?.map((withdrawal) => ({
      ID: withdrawal._id,
      Type: withdrawal.type,
      Pending: withdrawal.isPending ? "Yes" : "No",
      Amount: withdrawal.amount,
      User: `${withdrawal.userId?.firstName} ${withdrawal.userId?.lastName}`,
      created_at: moment(withdrawal.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);
  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);
  const handleSelect = (ranges) => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        searchItem,
        status: statusFilter,
        user,
        startDate,
        endDate,
      },
      "Widthdrawals",
      navigate
    );

    setIsDropdownOpen(false);
  };
  const handleUserChange = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        searchItem,
        status: statusFilter,
        user: selectedOption,
        startDate,
        endDate,
      },
      "Widthdrawals",
      navigate
    );
  };

  const fetchUsers = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/user/get-all`, token, {
        page: page,
        search: search,
      });

      const currentPage = response?.result?.data?.currentPage;
      const totalPages = response?.result?.data?.totalPages;

      if (currentPage === totalPages) {
        return {
          options: response?.result?.data?.users,
          hasMore: false,
        };
      } else {
        return {
          options: response?.result?.data?.users,
          hasMore: response?.result?.data?.users?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };

  const updateWithdrawalData = async () => {
    updateFilterHistory(
      {
        page: currentPage,
        limit: perPage,
        searchItem: searchItem,
        status: statusFilter,
        user: user,
        startDate: startDate,
        endDate: endDate,
      },
      "Widthdrawals",
      navigate
    );
  };

  const renderExpandedRow = useCallback((row) => {
    const withdrawal = row?.data;
    console.log(withdrawal?.type, withdrawal?.reason);
    console.log(
      withdrawal?.type === "Incoming" || withdrawal?.reason !== "referral"
    );
    if (
      withdrawal?.type === "Incoming" ||
      (withdrawal?.reason !== "withdrawal" && withdrawal?.reason !== "referral")
    ) {
      return (
        <p className="text-center my-5">
          This transaction is {withdrawal?.type} via {withdrawal?.reason}.
        </p>
      );
    } else {
      return (
        <WithdrawalExpandedRow
          withdrawal={withdrawal}
          updateWithdrawalData={updateWithdrawalData}
        />
      );
    }
  }, []);

  useEffect(() => {
    // Parse query parameters from URL on component mount
    getFilterHistory(location, getAllCategories);
  }, [location]);

  return (
    <Layout>
      <section id="Categories_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col>
              <FormControl
                type="text"
                placeholder="Search Reference No."
                className="me-2"
                value={searchItem}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Form.Group>
                <ReactSelect
                  placeholder="Type"
                  onChange={handleStatusFilter}
                  options={statusOptions}
                  value={statusFilter ? statusFilter : ""}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <AsyncPaginate
                  value={user ? user : ""}
                  loadOptions={fetchUsers}
                  getOptionLabel={(e) =>
                    `${e.firstName} ${e.lastName} (${e.email})`
                  }
                  getOptionValue={(e) => e._id}
                  onChange={handleUserChange}
                  placeholder="Select User"
                  isClearable
                  loadingMessage={() => "Loading..."}
                  noOptionsMessage={() => "No User Found"}
                  additional={{
                    page: 1,
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Dropdown
                show={isDropdownOpen}
                onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                autoClose={"outside"}
                className="w-100"
              >
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        updateFilterHistory(
                          {
                            page: 1,
                            limit: 10,
                            searchItem,
                            statusFilter,
                            user,
                            startDate: "",
                            endDate: "",
                          },
                          "Widthdrawals",
                          navigate
                        );
                        setIsDropdownOpen(false);
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {tableData && (
              <Col>
                <CSVLink
                  data={csvData}
                  filename={"categories.csv"}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </CSVLink>
              </Col>
            )}
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalTransactions} // Assuming you have a total count in your API response
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
              expandableRows
              expandableRowsComponent={renderExpandedRow}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Withdrawals;

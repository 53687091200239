import React, { useEffect, useState } from "react";
import domtoimage from "dom-to-image";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Image,
  Ratio,
  Tab,
  Nav,
} from "react-bootstrap";
import Layout from "../../layouts/public/Layout";
import PublicImages from "../../constants/public/images";
import { useDropzone } from "react-dropzone";
import ImageUploading from "react-images-uploading";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";

import draftToHtml from "draftjs-to-html";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { getItemFromLocalStorage } from "../../helper/helper";
import { getRequest, postRequestForm, putRequest } from "../../helper/api";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SelectBackground from "./SelectBackground";
import TextSettings from "./TextSettings";
export default function GenerateCardPreview() {
  const { state } = useLocation();
  const { product } = useParams();
  const navigate = useNavigate();
  if (
    !product ||
    !state?.title ||
    !state?.category ||
    !state?.article ||
    !state?.featuredImage
  ) {
    navigate(-1);
  }
  const [loading, setLoading] = useState(false);
  const [challenge, setChallenge] = useState();

  const formatString = (input) => {
    return input
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  // return a promise that resolves with a File instance
  function urltoFile(url, filename = "image.png", mimeType = "image/png") {
    if (url.startsWith("data:")) {
      var arr = url.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], filename, { type: mime || mimeType });
      return Promise.resolve(file);
    }
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }
  const addShortStoryHandler = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", state?.title);
      formData.append("article", state?.article);
      formData.append("subCategoryId", state?.category);
      const blob = await urltoFile(state?.featuredImage);
      formData.append("featuredImage", blob);
      const token = getItemFromLocalStorage("TOKEN");
      const user = getItemFromLocalStorage("USER");
      const userId = JSON.parse(user)?._id;
      formData.append("userId", userId);
      if (challenge) {
        formData.append("challengeId", challenge?._id);
      }
      const { result, error } = await postRequestForm(
        "/api/secure/post/create-post",
        token,
        formData
      );

      if (result?.status === 200 || result?.status === 201) {
        const { post, category } = result?.data;
        toast.success("Post Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        if (challenge?._id) {
          const { result, error } = await putRequest(
            "/api/secure/challenge/join-challenge",
            token,
            {
              challengeId: challenge?._id,
              postId: post?._id,
            }
          );
          if (result?.status === 200 || result?.status === 201) {
            if (post.isApproved) {
              navigate(`/${product}/published`);
            } else {
              navigate(
                `/${product}/${category?.parentCategoryId?.slug}/under-moderation/${post.slug}`
              );
            }
          }
        } else {
          if (post.isApproved) {
            navigate(`/${product}/published`);
          } else {
            navigate(
              `/${product}/${category?.parentCategoryId?.slug}/under-moderation/${post.slug}`
            );
          }
        }
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getChallengeBySlug = async (challengeSlug) => {
    try {
      setLoading(true);
      console.log("challengeSlug", challengeSlug);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/challenge/get-challenge-by-slug`,
        token,
        {
          challengeSlug,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { challenge } = result?.data;
        if (challenge) {
          setChallenge(challenge);
        }
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Get Challenge By Id Error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (state?.challengeSlug) {
      getChallengeBySlug(state?.challengeSlug);
    }
  }, [state]);

  return (
    <Layout>
      <section className="select_background py-5">
        <Container>
          <h1 className="text-center">Preview</h1>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Row className="pt-3 justify-content-center">
              <Col lg={7} md={7} xs={12} className="text-center">
                <div className="d-flex flex-column my-3">
                  <div className="d-flex justify-content-center">
                    <div className="flex-grow-1 justify-content-start d-flex">
                      <h5>
                        <b>Title:{console.log(state)}</b>
                      </h5>
                    </div>
                    <div className="flex-grow-1 justify-content-end d-flex">
                      <p>{state?.title}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="flex-grow-1 justify-content-start d-flex">
                      <h5>
                        <b>Product:</b>
                      </h5>
                    </div>
                    <div className="flex-grow-1 justify-content-end d-flex">
                      <p>{formatString(product)}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="flex-grow-1 justify-content-start d-flex">
                      <h5>
                        <b>Category:</b>
                      </h5>
                    </div>
                    <div className="flex-grow-1 justify-content-end d-flex">
                      <p>{state?.categoryLabel}</p>
                    </div>
                  </div>
                  {state?.challengeSlug && (
                    <div className="d-flex justify-content-center">
                      <div className="flex-grow-1 justify-content-start d-flex">
                        <h5>
                          <b>Challenge:</b>
                        </h5>
                      </div>
                      <div className="flex-grow-1 justify-content-end d-flex">
                        <p>{formatString(state?.challengeSlug)}</p>
                      </div>
                    </div>
                  )}
                </div>
                <Image src={state?.featuredImage} fluid />
              </Col>
              <Col lg={6} md={6} xs={12} className="mt-5">
                <Link
                  to={`/${product}/upload/generate-card`}
                  state={{
                    title: state?.title,
                    article: state?.article,
                    category: state?.category,
                    categoryLabel: state?.categoryLabel,
                    challengeSlug: state?.challengeSlug,
                  }}
                  className="btn-default-3"
                >
                  Back
                </Link>
              </Col>
              <Col lg={6} md={6} xs={12} className="text-end mt-5">
                <Button
                  onClick={addShortStoryHandler}
                  className="btn-default-2 "
                >
                  Submit
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    FormControl,
    Row,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, putRequest } from "../../../helper/api";

import ReactSelect from "react-select";
import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Layout from "../../../layouts/admin/Layout";


const EditComment = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState();
    const [status, setStatus] = useState("");
    const statusOptions = [
        { value: "active", label: "Active" },
        { value: "deactive", label: "Deactive" },
    ];

    let commentId = "";
    const location = useLocation();
    if (location?.state?.commentId === "") {
        navigate("/Comments");
    } else {
        commentId = location?.state?.commentId;
    }
    const getLabelByValue = (options, value) => {
        const option = options?.find((option) => option.value === value);
        return option?.label;
    };
    
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
    const getCommentById = async (commentId) => {
        try {
            setLoading(true);
            const token = getItemFromLocalStorage("TOKEN");
            const { result, error } = await getRequest(
                `/api/secure/comment/get-comment`,
                token,
                {
                    commentId,
                }
            );
            if (result?.status === 200 || result?.status === 201 || result?.status === 304) {
                const { content, status } = result?.data?.comment;
                setContent(content);
                setStatus(status);
            } else if (error?.response?.status === 400) {
                toast.error(`${error?.response?.data?.message}`, {
                    position: "top-center", theme: "colored",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.log("Get Report Answer By Id Error", error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = getItemFromLocalStorage("TOKEN");
            const { result, error } = await putRequest(
                `/api/secure/comment/update-comment/${commentId}`,
                token,
                {
                    content,
                    status: typeof status === "object" ? status.value : status,
                }
            );
            if (result?.status === 200 || result?.status === 201) {
                toast.success("Comment Updated Successfully", {
                    position: "top-center", theme: "colored",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                navigate("/Comments");
            } else if (error?.response?.status === 400) {
                toast.error(`${error?.response?.data?.message}`, {
                    position: "top-center", theme: "colored",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getCommentById(commentId)
    }, [commentId])

    return (
        <Layout>
            <section id="Posts_list" className="pt-5">
                <Container>
                    {loading ? (
                        <Loader loading={true} />
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col lg={12} xs={12} className="mb-3">
                                    <Form.Label>Status</Form.Label>
                                    <ReactSelect
                                        placeholder="Select Status"
                                        onChange={statusChange}
                                        options={statusOptions}
                                        required
                                        value={
                                            typeof status === "object"
                                                ? status
                                                : status
                                                    ? {
                                                        value: status,
                                                        label: getLabelByValue(statusOptions, status),
                                                    }
                                                    : { value: "active", label: "Active" }
                                        }
                                    />
                                </Col>
                                <Col lg={12} xs={12} className="mb-3">
                                    <Form.Label>Content</Form.Label>
                                    <FormControl
                                        as="textarea"
                                        rows={3}
                                        placeholder="Comment Content"
                                        defaultValue={content}
                                        onChange={(e) => setContent(e.target.value)}
                                        required
                                    />
                                </Col>
                                <Col lg={12} xs={12} className="mb-3 text-center">
                                    <Button type="submit" variant="primary">
                                        Update
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Container>
            </section>
        </Layout>
    )
}

export default EditComment
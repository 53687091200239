import React, { useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArrowDown,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../helper/api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useLocation, useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import ReactSelect from "react-select";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-date-range";
import { getItemFromLocalStorage } from "../../../helper/helper";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  getFilterHistory,
  updateFilterHistory,
} from "../../../helper/filterHistory";

const Badges = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCategories, setTotalCategories] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getAllBadges = async (
    page = 1,
    limit = 10,
    search = "",
    status = "",
    startDate = "",
    endDate = ""
  ) => {
    try {
      setCurrentPage(page);
      setPerPage(limit);
      setSearchItem(search);
      setStatusFilter(status);
      setStartDate(startDate);
      setEndDate(endDate);
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/badge/get-badges`,
        token,
        {
          limit: limit,
          page: page,
          search: search,
          status: status ? status?.value : "",
          startDate: startDate,
          endDate: endDate,
        }
      );
      setTableHead([
        {
          name: "Badge Name",
          sortable: true,
          selector: (row) => row.name,
        },
        {
          name: "Badge Status",
          sortable: true,
          cell: (row, index, column, id) => (
            <>
              {row?.status === "active" && (
                <span className="badge bg-success">Active</span>
              )}
              {row?.status === "deactive" && (
                <span className="badge bg-danger">Deactive</span>
              )}
            </>
          ),
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editBadge(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              {/* <Button
                className="del_btn ms-1"
                size="sm"
                variant="danger"
                onClick={() => deleteBadge(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button> */}
            </>
          ),
        },
      ]);
      if (result?.status === 200 || result?.status === 201) {
        console.log("result", result);
        setTotalCategories(result?.data?.totalBadges);
        setTableData(result?.data?.badges);
      } else {
        setTotalCategories(0);
        setTableData([]);
      }
    } catch (error) {
      console.log("Get All Badges Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    updateFilterHistory(
      {
        page,
        limit: perPage,
        searchItem,
        status: statusFilter,
        startDate,
        endDate,
      },
      "Badges",
      navigate
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    updateFilterHistory(
      {
        page: 1,
        limit: newPerPage,
        searchItem,
        status: statusFilter,
        startDate,
        endDate,
      },
      "Badges",
      navigate
    );
  };

  const handleStatusFilter = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: perPage,
        searchItem,
        status: selectedOption,
        startDate,
        endDate,
      },
      "Badges",
      navigate
    );
  };

  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);
    setCurrentPage(1);
    getAllBadges(1, perPage, searchValue, statusFilter, startDate, endDate);
  };

  const editBadge = async (badgeId) => {
    navigate(`/editBadge`, {
      state: { badgeId },
    });
  };

  const csvData = useMemo(() => {
    return tableData?.map((badge) => ({
      id: badge._id,
      Name: badge.name,
      product_type: badge.productId?.name,
      badge: badge.categoryId?.name,
      sub_category: badge.subCategoryId?.name,
      status: badge.status,
      condition: badge.condition,
      count: badge.count,
      coins: badge.coins,
      created_at: moment(badge.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);

  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const handleSelect = (ranges) => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    updateFilterHistory(
      {
        page: 1,
        limit: perPage,
        searchItem,
        status: statusFilter,
        startDate,
        endDate,
      },
      "Badges",
      navigate
    );
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    // Parse query parameters from URL on component mount
    getFilterHistory(location, getAllBadges);
  }, [location]);

  return (
    <Layout>
      <section id="Categories_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col>
              <FormControl
                type="text"
                placeholder="Search Badges"
                className="me-2"
                value={searchItem}
                onChange={handleSearchCategory}
              />
            </Col>
            <Col>
              <Form.Group>
                <ReactSelect
                  placeholder="Status"
                  onChange={handleStatusFilter}
                  options={statusOptions}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col>
              <Dropdown
                show={isDropdownOpen}
                onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                autoClose={"outside"}
                className="w-100"
              >
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        updateFilterHistory(
                          {
                            page: 1,
                            limit: 10,
                            searchItem,
                            status: statusFilter,
                            startDate: "",
                            endDate: "",
                          },
                          "Badges",
                          navigate
                        );
                        setIsDropdownOpen(false);
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {tableData && (
              <Col>
                <CSVLink
                  data={csvData}
                  filename={"badges.csv"}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </CSVLink>
              </Col>
            )}
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalCategories} // Assuming you have a total count in your API response
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Badges;

import React, { useEffect, useState } from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../../layouts/admin/Layout";
import LanguageSettings from "./LanguageSettings";
import ReferralSettings from "./ReferralSettings";
import SiteSettings from "./SiteSettings";
import StatrupPopupSettings from "./StatrupPopupSettings";
import EmailTemplateSettings from "./EmailTemplateSettings";

function Settings() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("Tab");
  const [activeTab, setActiveTab] = useState("site-settings"); // Default active tab

  const settingsData = [
    { key: "site-settings", title: "Site Settings", Component: SiteSettings },
    {
      key: "referral-settings", // Corrected key
      title: "Referral Settings",
      Component: ReferralSettings,
    },
    {
      key: "language-settings",
      title: "Language Settings",
      Component: LanguageSettings,
    },
    {
      key: "statrup-popup-settings",
      title: "Statrup Popup Settings",
      Component: StatrupPopupSettings,
    },
    {
      key: "email-templates-settings",
      title: "Email Templates Settings",
      Component: EmailTemplateSettings,
    },
  ];

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    } else {
      setActiveTab("site-settings");
    }
  }, [tab]);

  return (
    <Layout>
      <section className="py-5 settings">
        <Container>
          <Tab.Container
            id="settings-tabs"
            activeKey={activeTab}
            onSelect={handleTabSelect}
          >
            <Nav variant="tabs" className="justify-content-center" as="ul">
              {settingsData.map((setting) => (
                <Nav.Item key={setting.key} as="li">
                  <Nav.Link
                    as={Link}
                    to={`/settings?Tab=${setting.key}`}
                    eventKey={setting.key}
                  >
                    {setting.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Tab.Content>
              {settingsData.map(({ key, Component }, index) => (
                <Tab.Pane key={`${key}-${index}`} eventKey={key}>
                  <Component
                    currentlyActive={activeTab}
                    actualComponent={key}
                  />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Container>
      </section>
    </Layout>
  );
}

export default Settings;

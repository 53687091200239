import { useState } from "react";
import { Button, Col, Container, Form, Ratio, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { postRequestForm } from "../../../helper/api";
import Layout from "../../../layouts/admin/Layout";
import { getItemFromLocalStorage } from "../../../helper/helper";
import DateTimePicker from "react-datetime-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";

const AddLiveStreaming = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [status, setStatus] = useState();
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toLocaleString("en-US")
  );
  const [description, setDescription] = useState("");
  const [expertDetails, setExpertDetails] = useState([
    {
      expertName: "",
      expertDescription: "",
      expertOrganization: "",
    },
  ]);
  const [anchorDetails, setAnchorDetails] = useState([
    {
      anchorName: "",
      anchorDescription: "",
    },
  ]);

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
    { value: "scheduled", label: "Scheduled" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const addPostHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // console.log("body", {
      //   title,
      //   status: typeof status === "object" ? status.value : status,
      //   schedule:
      //     startDate && status?.value === "scheduled"
      //       ? startDate.toISOString()
      //       : new Date().toISOString(),
      //   description,
      //   expertDetails,
      //   anchorDetails,
      //   videoId,
      // });
      if (!status) {
        toast.error("Please select status", {
          position: "top-center", theme: "colored",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (!videoId) {
        toast.error("Please enter Video ID", {
          position: "top-center", theme: "colored",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (!description) {
        toast.error("Please enter Description", {
          position: "top-center", theme: "colored",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (!title) {
        toast.error("Please enter Title", {
          position: "top-center", theme: "colored",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }

      // Validation for expertDetails
      // for (let i = 0; i < expertDetails.length; i++) {
      //   const rowsData = expertDetails[i];
      //   if (!rowsData?.expertName) {
      //     toast.error("Please enter Expert Name", {
      //       position: "top-center", theme: "colored",
      //       autoClose: 6000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: false,
      //       draggable: true,
      //       progress: undefined,
      //     });
      //     return;
      //   }
      //   if (!rowsData?.expertDescription) {
      //     toast.error("Please enter Expert Description", {
      //       position: "top-center", theme: "colored",
      //       autoClose: 6000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: false,
      //       draggable: true,
      //       progress: undefined,
      //     });
      //     return;
      //   }
      //   if (!rowsData?.expertOrganization) {
      //     toast.error("Please enter Expert Organization", {
      //       position: "top-center", theme: "colored",
      //       autoClose: 6000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: false,
      //       draggable: true,
      //       progress: undefined,
      //     });
      //     return;
      //   }
      // }
      // // Validation for anchorDetails
      // for (let i = 0; i < anchorDetails.length; i++) {
      //   const rowsData = anchorDetails[i];
      //   if (!rowsData?.anchorName) {
      //     toast.error("Please enter Anchor Name", {
      //       position: "top-center", theme: "colored",
      //       autoClose: 6000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: false,
      //       draggable: true,
      //       progress: undefined,
      //     });
      //     return;
      //   }
      //   if (!rowsData?.anchorDescription) {
      //     toast.error("Please enter Anchor Description", {
      //       position: "top-center", theme: "colored",
      //       autoClose: 6000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: false,
      //       draggable: true,
      //       progress: undefined,
      //     });
      //     return;
      //   }
      // }

      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        "/api/secure/liveStreaming/register-live-streaming",
        token,
        {
          title,
          status: typeof status === "object" ? status.value : status,
          schedule:
            startDate && status?.value === "scheduled"
              ? startDate.toISOString()
              : new Date().toISOString(),
          description,
          expertDetails,
          anchorDetails,
          videoId,
        }
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        toast.success("Live Streaming Created", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/liveStreamings");
      }
    } catch (error) {
      console.log("Create Live Streaming APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleStartDate = (date) => {
    setStartDate(date);
  };
  // Function to add a new row to the table
  const addRowTable = (tableData, setTableData) => {
    const addRow = {
      expertName: "",
      expertDescription: "",
      expertOrganization: "",
    };
    setTableData([...tableData, addRow]);
  };

  // Function to remove a row from the table based on the index
  const removeRowTable = (index, tableData, setTableData) => {
    const tableRow = [...tableData];
    tableRow.splice(index, 1);
    setTableData(tableRow);
  };

  // Function to update the value of a specific field in a row
  const onValUpdate = (i, event, tableData, setTableData) => {
    const { name, value } = event.target;
    const table = [...tableData];
    table[i][name] = value;
    setTableData(table);
  };

  // Inside your component, use these functions with the respective state variables
  // For expertDetails
  const handleExpertAddRow = () => {
    addRowTable(expertDetails, setExpertDetails);
  };

  const handleExpertRemoveRow = (index) => {
    removeRowTable(index, expertDetails, setExpertDetails);
  };

  const handleExpertValUpdate = (i, event) => {
    onValUpdate(i, event, expertDetails, setExpertDetails);
  };

  // For anchorDetails
  const handleAnchorAddRow = () => {
    addRowTable(anchorDetails, setAnchorDetails);
  };

  const handleAnchorRemoveRow = (index) => {
    removeRowTable(index, anchorDetails, setAnchorDetails);
  };

  const handleAnchorValUpdate = (i, event) => {
    onValUpdate(i, event, anchorDetails, setAnchorDetails);
  };

  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 ">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={addPostHandler}
            >
              <Row>
                <Col xl={12} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Youtube Video ID</Form.Label>
                    <Form.Control
                      name="videoID"
                      type="text"
                      placeholder="Youtube Video ID"
                      value={videoId}
                      onChange={(e) => setVideoId(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xl={status ? (status?.value !== "scheduled" ? 6 : 4) : 6}
                  sm={12}
                >
                  <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      name="title"
                      type="text"
                      placeholder="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xl={status ? (status?.value !== "scheduled" ? 6 : 4) : 6}
                  sm={12}
                >
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <ReactSelect
                      placeholder="Select Status"
                      onChange={statusChange}
                      options={statusOptions}
                      value={status ? status : ""}
                    />
                  </Form.Group>
                </Col>
                {status && status?.value === "scheduled" ? (
                  <Col xl={4} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Date to Go Live</Form.Label>
                      <DateTimePicker
                        onChange={handleStartDate}
                        value={new Date(startDate)}
                        format="d-MM-y h:mm:ss a"
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  <></>
                )}
                <Col xl={12} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Label>
                    <b>Expert Details</b>
                  </Form.Label>
                  <Table id="faqsTable" responsive striped>
                    <tbody>
                      {expertDetails.map((rowsData, index) => (
                        <tr key={index}>
                          <td>
                            <Table className="bg-white m-0">
                              <tbody>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Expert Name</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="expertName"
                                      defaultValue={rowsData.expertName}
                                      onChange={(event) =>
                                        handleExpertValUpdate(index, event)
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Expert Description</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="expertDescription"
                                      defaultValue={rowsData.expertDescription}
                                      onChange={(event) =>
                                        handleExpertValUpdate(index, event)
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Expert Organization</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="expertOrganization"
                                      defaultValue={rowsData.expertOrganization}
                                      onChange={(event) =>
                                        handleExpertValUpdate(index, event)
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                          <td>
                            <Button
                              className="btn-danger"
                              onClick={() => handleExpertRemoveRow(index)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="text-start">Add Expert Detail</td>
                        <td className="text-end">
                          <Button
                            className="btn-primary"
                            onClick={() => {
                              handleExpertAddRow();
                            }}
                          >
                            <FontAwesomeIcon icon={faAdd} />
                          </Button>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
                <Col sm={12}>
                  <Form.Label>
                    <b>Anchor Details</b>
                  </Form.Label>
                  <Table id="faqsTable" responsive striped>
                    <tbody>
                      {anchorDetails.map((rowsData, index) => (
                        <tr key={index}>
                          <td>
                            <Table className="bg-white m-0">
                              <tbody>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Anchor Name</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="anchorName"
                                      defaultValue={rowsData.anchorName}
                                      onChange={(event) =>
                                        handleAnchorValUpdate(index, event)
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Form.Label>
                                      <b>Anchor Description</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="anchorDescription"
                                      defaultValue={rowsData.anchorDescription}
                                      onChange={(event) =>
                                        handleAnchorValUpdate(index, event)
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                          <td>
                            <Button
                              className="btn-danger"
                              onClick={() =>
                                handleAnchorRemoveRow(index, anchorDetails)
                              }
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="text-start">Add Anchor Detail</td>
                        <td className="text-end">
                          <Button
                            className="btn-primary"
                            onClick={() => {
                              handleAnchorAddRow();
                            }}
                          >
                            <FontAwesomeIcon icon={faAdd} />
                          </Button>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
                <Col xl={12} sm={12} className="text-center">
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default AddLiveStreaming;

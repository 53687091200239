import React, { useEffect, useState } from "react";
import Layout from "../../layouts/public/Layout";

import { getRequest } from "../../helper/api";
import Loader from "../../components/Loader";

function TermsAndConditions() {
  const [isLoading, setLoading] = useState(true);
  const [content, setContent] = useState(null);

  const getPageByName = async (pageName) => {
    try {
      const response = await getRequest("/api/secure/page/terms", "", {
        pageName,
      });
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        const privacyAndPolicyData = response?.result?.data?.page;
        setContent(privacyAndPolicyData?.content);
      }
    } catch (error) {
      console.log("Get Page API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPageByName("Terms & Conditions");
  }, []);

  return (
    <Layout>
      <main id="Terms_conditions_main">
        <section className="Terms_conditions_sec">
          <div className="container">
            {isLoading ? (
              <Loader loading={isLoading} />
            ) : (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              </>
            )}
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default TermsAndConditions;

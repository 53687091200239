import { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import { getRequest, uploadURL } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Layout from "../../../layouts/admin/Layout";
import AddBlogPost from "./AddPost/AddBlogPost";
import AddImagePost from "./AddPost/AddImagePost";
import AddAudioPost from "./AddPost/AddAudioPost";
import AddVideoPost from "./AddPost/AddVideoPost";
import AddShortStoryPost from "./AddPost/AddShortStoryPost";

const AddPost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const product = queryParams.get("product");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentForm, setCurrentForm] = useState(null);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/product/get-all-products`,
        token
      );
      setProducts(response?.result?.data?.products);
    } catch (error) {
      console.log("Get All Products Error", error);
    } finally {
      setLoading(false);
    }
  };

  const loadForm = (product, state) => {
    switch (product) {
      case "blogs":
        setCurrentForm(<AddBlogPost state={state} />);
        break;
      case "short-stories":
        setCurrentForm(<AddShortStoryPost state={state} />);
        break;
      case "image-corner":
        setCurrentForm(<AddImagePost state={state} />);
        break;
      case "videos":
        setCurrentForm(<AddVideoPost state={state} />);
        break;
      case "audio-station":
        setCurrentForm(<AddAudioPost state={state} />);
        break;
      default:
        setCurrentForm(null);
    }
  };

  useEffect(() => {
    if (product) {
      loadForm(product, location.state);
    } else {
      setCurrentForm(null); // Reset the form when no product is selected
      getAllProducts();
    }
  }, [product, location.state]);

  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 upload-video">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : currentForm ? (
            currentForm
          ) : (
            <div id="menu-modal">
              <div className="modal-body">
                <Row>
                  {products.map(({ slug, featuredImage, name, _id }, index) => (
                    <Col
                      lg={
                        (products.length - 1) % 2 === 0 &&
                        index === products.length - 1
                          ? 12
                          : 6
                      }
                      md={
                        (products.length - 1) % 2 === 0 &&
                        index === products.length - 1
                          ? 12
                          : 6
                      }
                      key={index}
                    >
                      <Card>
                        <Card.Body>
                          <div className="card-logo">
                            <div className="card-svg">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(`?product=${slug}`, {
                                    state: { product: { _id, name, slug } },
                                  });
                                }}
                              >
                                <Image
                                  fluid
                                  src={uploadURL + featuredImage}
                                  alt={name}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="card-title">
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`?product=${slug}`, {
                                  state: { product: { _id, name } },
                                });
                              }}
                            >
                              {slug === "videos" ||
                              slug === "audio-station" ||
                              slug === "image-corner"
                                ? `Upload Your ${name}`
                                : `Write Your ${name}`}
                            </a>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default AddPost;

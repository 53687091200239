import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { getRequest, putRequest, uploadURL } from "../../../helper/api";
import { toast } from "react-toastify";
import Loader from "./../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";

function SiteSettings ({ currentlyActive, actualComponent }){
  const [loading, setLoading] = useState(true);
  const [siteId, setSiteId] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [logos, setLogos] = useState([]);
  const [logo, setLogo] = useState("");
  const [favicons, setFavicons] = useState([]);
  const [favicon, setFavicon] = useState("");
  const onChangeFavicon = async (imageList, addUpdateIndex) => {
    setFavicons(imageList);
    setFavicon(imageList[addUpdateIndex].file);
  };
  const onChangeLogo = async (imageList, addUpdateIndex) => {
    setLogos(imageList);
    setLogo(imageList[addUpdateIndex].file);
  };
  // Define the validation schema
  const validationSchema = Yup.object().shape({
    site_title: Yup.string().required("Site title is required"),
    fb_link: Yup.string().url("Invalid URL format"),
    insta_link: Yup.string().url("Invalid URL format"),
    yt_link: Yup.string().url("Invalid URL format"),
    tw_link: Yup.string().url("Invalid URL format"),
    appstore_link: Yup.string().url("Invalid URL format"),
    playstore_link: Yup.string().url("Invalid URL format"),
    site_link: Yup.string().url("Invalid URL format"),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    address: Yup.string().required("Address is required"),
    contact_us_iframe: Yup.string().required("Contact Us Map is required"),
    copyright: Yup.string().required("Copyright is required"),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const settings = {
        site_title: values.site_title,
        fb_link: values.fb_link,
        insta_link: values.insta_link,
        yt_link: values.yt_link,
        tw_link: values.tw_link,
        appstore_link: values.appstore_link,
        playstore_link: values.playstore_link,
        site_link: values.site_link,
        phone: values.phone,
        email: values.email,
        address: values.address,
        contact_us_iframe: values.contact_us_iframe,
        copyright: values.copyright,
      };
      if (favicon) {
        formData.append("siteFavicon", favicon);
      }
      if (logo) {
        formData.append("siteLogo", logo);
      }
      // Append the form values to the FormData object
      formData.append("siteId", siteId);
      formData.append("settings", JSON.stringify(settings));
      const token = getItemFromLocalStorage("TOKEN");
      const response = await putRequest(
        "/api/secure/site/update-site-settings",
        token,
        formData
      );

      if (response?.result?.status === 200 || response?.result?.status === 201) {
        setInitialValues({
          site_title: values.site_title,
          fb_link: values.fb_link,
          insta_link: values.insta_link,
          yt_link: values.yt_link,
          tw_link: values.tw_link,
          appstore_link: values.appstore_link,
          playstore_link: values.playstore_link,
          site_link: values.site_link,
          phone: values.phone,
          email: values.email,
          address: values.address,
          contact_us_iframe: values.contact_us_iframe,
          copyright: values.copyright,
        });
        toast.success("Site Settings Updated", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Update Site Settings API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getSiteSettings = async () => {
    try {
      const response = await getRequest("/api/secure/site/site-settings");
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        setInitialValues(response?.result?.data?.siteSettings[0].settings);
        setSiteId(response?.result?.data?.siteSettings[0].siteId);
        if (
          response?.result?.data?.siteSettings[0].siteLogo != null ||
          response?.result?.data?.siteSettings[0].siteLogo != undefined
        ) {
          setLogos([
            {
              data_url:
                uploadURL + response?.result?.data?.siteSettings[0].siteLogo,
            },
          ]);
        }
        if (
          response?.result?.data?.siteSettings[0].siteFavicon != null ||
          response?.result?.data?.siteSettings[0].siteFavicon != undefined
        ) {
          setFavicons([
            {
              data_url:
                uploadURL + response?.result?.data?.siteSettings[0].siteFavicon,
            },
          ]);
        }
      }
    } catch (error) {
      console.error("Update Site Settings API error", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentlyActive === actualComponent) {
      getSiteSettings();
    }
  }, [currentlyActive, actualComponent]);

  return (
    <section className="py-5">
      <Container>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="justify-content-center">
                  <Col sm={12} md={6} className="text-center">
                    <Form.Label>
                      <b>Site Favicon</b>
                    </Form.Label>
                    <ImageUploading
                      value={favicons}
                      onChange={onChangeFavicon}
                      dataURLKey="data_url"
                      maxNumber={1}
                      maxFileSize={1000000}
                      acceptType={["jpeg", "jpg", "gif", "png"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        <>
                          <div className="upload__image-wrapper mx-auto mb-5">
                            <button
                              type="button"
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                              {/* <i className="fal fa-plus"></i> */}
                            </button>
                            {imageList
                              ? imageList.map((image, index) => (
                                  <div key={index} className="image-item">
                                    <img src={image["data_url"]} alt="" />
                                    <div className="image-item__btn-wrapper">
                                      <button
                                        type="button"
                                        onClick={() => onImageUpdate(index)}
                                      >
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                      </button>
                                    </div>
                                  </div>
                                ))
                              : ""}
                          </div>
                          {errors && (
                            <div
                              id="imageUploadingErrors"
                              className="text-center"
                            >
                              {errors.maxFileSize && (
                                <Alert className="my-1" variant="danger">
                                  Selected file size exceed 1MB
                                </Alert>
                              )}
                              {errors.acceptType && (
                                <Alert className="my-1" variant="danger">
                                  Your selected file type is not allow
                                </Alert>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </ImageUploading>
                  </Col>
                  <Col sm={12} md={6} className="text-center">
                    <Form.Label>
                      <b>Site Logo</b>
                    </Form.Label>
                    <ImageUploading
                      value={logos}
                      onChange={onChangeLogo}
                      dataURLKey="data_url"
                      maxNumber={1}
                      maxFileSize={1000000}
                      acceptType={["jpeg", "jpg", "gif", "png"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        <>
                          <div className="upload__image-wrapper mx-auto mb-5">
                            <button
                              type="button"
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                              {/* <i className="fal fa-plus"></i> */}
                            </button>
                            {imageList
                              ? imageList.map((image, index) => (
                                  <div key={index} className="image-item">
                                    <img src={image["data_url"]} alt="" />
                                    <div className="image-item__btn-wrapper">
                                      <button
                                        type="button"
                                        onClick={() => onImageUpdate(index)}
                                      >
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                      </button>
                                    </div>
                                  </div>
                                ))
                              : ""}
                          </div>
                          {errors && (
                            <div
                              id="imageUploadingErrors"
                              className="text-center"
                            >
                              {errors.maxFileSize && (
                                <Alert className="my-1" variant="danger">
                                  Selected file size exceed 1MB
                                </Alert>
                              )}
                              {errors.acceptType && (
                                <Alert className="my-1" variant="danger">
                                  Your selected file type is not allow
                                </Alert>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </ImageUploading>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Site Title</Form.Label>
                      <Field
                        type="text"
                        name="site_title"
                        placeholder="Womanii"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="site_title"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Facebook Link</Form.Label>
                      <Field
                        type="url"
                        name="fb_link"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="fb_link"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Instagram Link</Form.Label>
                      <Field
                        type="url"
                        name="insta_link"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="insta_link"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Youtube Link</Form.Label>
                      <Field
                        type="url"
                        name="yt_link"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="yt_link"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Twitter Link</Form.Label>
                      <Field
                        type="url"
                        name="tw_link"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="tw_link"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Appstore Link</Form.Label>
                      <Field
                        type="url"
                        name="appstore_link"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="appstore_link"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Playstore Link</Form.Label>
                      <Field
                        type="url"
                        name="playstore_link"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="playstore_link"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Site Link</Form.Label>
                      <Field
                        type="url"
                        name="site_link"
                        placeholder="#"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="site_link"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Field
                        type="tel"
                        name="phone"
                        placeholder="+1 (800) 123 4467"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="womanii2023@gmail.com"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Address</Form.Label>
                      <Field
                        type="text"
                        name="address"
                        placeholder="1625 Bagwell Avenue, Gainesville United States"
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Contact Us Iframe</Form.Label>
                      <Field
                        type="text"
                        name="contact_us_iframe"
                        placeholder={`<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3297.7230903035984!2d-83.87998658805908!3d34.255608872968715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5f3902e980acb%3A0xf87439c30f0e22c!2sBagwell%20Dr%2C%20Gainesville%2C%20GA%2030504%2C%20USA!5e0!3m2!1sen!2s!4v1693910168755!5m2!1sen!2s" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`}
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="contact_us_iframe"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Copyright</Form.Label>
                      <Field
                        type="text"
                        name="copyright"
                        placeholder={`©${new Date().getFullYear()} Womanii.com ALL RIGHTS RESERVED.`}
                        as={Form.Control}
                      />
                      <ErrorMessage
                        name="copyright"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} className="text-center my-5">
                    <Button type="submit">Submit</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </section>
  );
};

export default SiteSettings;

import React, { useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import Layout from "../../../layouts/admin/Layout";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArrowDown,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRequest, postRequestForm } from "../../../helper/api";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "underscore";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import ReactSelect from "react-select";
import { DateRangePicker } from "react-date-range";
import { CSVLink } from "react-csv";
import { getItemFromLocalStorage } from "../../../helper/helper";

const Products = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [products, setProducts] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [statusOptions, setStatusOptions] = useState([
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getAllProducts = async (
    page = 1,
    limit = 10,
    search = "",
    status = "",
    startDate = "",
    endDate = ""
  ) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/product/get-all-products`,
        token,
        {
          limit: limit,
          page: page,
          search: search,
          status: status,
          startDate: startDate,
          endDate: endDate,
        }
      );
      setTableHead([
        {
          name: "Name",
          sortable: true,
          selector: (row) => row.name,
        },
        {
          name: "Slug",
          sortable: true,
          selector: (row) => row.slug,
        },
        {
          name: "Status",
          sortable: true,
          selector: (row) => row.status,
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="edit_btn me-1"
                size="sm"
                variant="primary"
                onClick={() => editProduct(row.slug, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                className="del_btn ms-1 d-none"
                size="sm"
                variant="danger"
                onClick={() => deleteProduct(row._id, row, index, column, id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ),
        },
      ]);
      if (response?.result?.status === 200 || response?.result?.status === 201) {
        setProducts(response?.result?.data?.products);
        setTableData(response?.result?.data?.products);
        setTotalPages(response?.result?.data?.totalPages);
      } else {
        setProducts([]);
        setTableData([]);
        setTotalPages(0);
      }
    } catch (error) {
      console.log("Get All Products Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllProducts(page, perPage, searchItem, statusFilter, startDate, endDate);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    getAllProducts(1, newPerPage, searchItem, statusFilter, startDate, endDate);
  };

  const handleStatusFilter = (selectedOption) => {
    const statusValue = selectedOption?.value;
    setStatusFilter(statusValue);
    setCurrentPage(1);
    getAllProducts(1, perPage, searchItem, statusValue, startDate, endDate);
  };

  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);
    setCurrentPage(1);
    getAllProducts(1, perPage, searchValue, statusFilter, startDate, endDate);
  };

  const csvData = useMemo(() => {
    return tableData?.map((user) => ({
      id: user._id,
      isDeleted: user.isDeleted ? "Yes" : "No",
      created_at: moment(user.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);

  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;

    getAllProducts(1, perPage, searchItem, statusFilter, startDate, endDate);
    setIsDropdownOpen(false);
  };

  const editProduct = async (productSlug) => {
    navigate(`/editProduct`, {
      state: { productSlug },
    });
  };

  const deleteProduct = async (productId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        `/api/secure/product/delete-product/${productId}`,
        token
      );

      if (response.result.status === 200) {
        toast.success("Product Deleted", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Deleting Product Error", error);
    } finally {
      getAllProducts();
      setPending(false);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <Layout>
      <section id="Products_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col>
              <FormControl
                type="text"
                placeholder="Search Products"
                className="me-2"
                value={searchItem}
                onChange={handleSearchCategory}
              />
            </Col>
            <Col>
              <Form.Group>
                <ReactSelect
                  placeholder="Status"
                  onChange={handleStatusFilter}
                  options={statusOptions}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col>
              <Dropdown
                show={isDropdownOpen}
                onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                autoClose={"outside"}
                className="w-100"
              >
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setStartDate();
                        setEndDate();
                        getAllProducts();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {tableData && (
              <Col>
                <CSVLink
                  data={csvData}
                  filename={"products.csv"}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </CSVLink>
              </Col>
            )}
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={_.sortBy(tableData, "total").reverse()}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalPages}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Products;
